/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { bool, PropTypes, string } from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectInput from "@cx/ui/SelectInput";
import AccordionGroup from "@cx/ui/AccordionGroup";
import { OperationContext } from "../../operation-context";
export default class ImportDealerOperationsModal extends React.Component {
  static contextType = OperationContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      make: ""
    };
  }

  onChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    this.setState({ make: value });
    this.fileNameWarning(this.state.file, value);
  };

  handelOnUploadFile = event => {
    if (event.target.files[0] === undefined) {
      this.setState({
        file: undefined
      });
      return;
    }
    this.setState({
      file: event.target.files[0]
    });
    this.fileNameWarning(event.target.files[0], this.state.make);
  };

  fileNameWarning = (file, make) => {
    this.setState({
      errMsg: undefined
    });
    if (file === undefined) return;
    if (make === undefined || make === "") return;
    const fileName = file.name;
    // eslint-disable-next-line no-alert
    // alert(fileName + make);
    if (fileName.toUpperCase().indexOf(make) === -1) {
      this.setState({
        errMsg:
          "Warning : The filename does not include the selected make. Please ensure that you are importing the correct operations."
      });
    }
  };

  initializeStates = () => {
    this.setState({
      make: undefined,
      file: undefined,
      errMsg: undefined
    });
  };

  render() {
    const disableImportBtnFlag =
      this.state.make === undefined ||
      this.state.make === "" ||
      this.state.file === undefined;
    const importOperations = this.props.importOperations;
    const { localeStrings } = this.context;
    return (
      <ModalDialog
        htmlId="importOperationsModalDialog"
        className="sample-modal-dialog"
        show={this.props.show}
        header={<ModalDialog.Title>{this.props.title}</ModalDialog.Title>}
        footer={
          <div>
            <Button
              htmlId="cancelDeleteOpsButton"
              onClick={this.props.closeDialog}
              text="Close Button"
            >
              <FormattedMessage
                defaultMessage="Cancel"
                id="xmm.portal.common.cancel_button"
              />
            </Button>
            <Button
              htmlId="okImportOpsButton"
              onClick={() => {
                importOperations(this.state.make, this.state.file);
                this.initializeStates();
              }}
              text="Import Operations"
              buttonStyle="primary"
              disabled={disableImportBtnFlag}
            >
              <FormattedMessage
                defaultMessage="Proceed"
                id="xmm.portal.common.proceed_button"
              />
            </Button>
          </div>
        }
        onHide={this.props.closeDialog}
      >
        <div>
          The import tool allows you to add new services or make changes to
          existing services from a CSV file. It is recommended to first export
          the operation list and use it as a template for importing additions
          and/or changes.
        </div>
        <AccordionGroup.Container
          htmlId="AccordionHelpText"
          independent
          className="xmm-help-modal"
        >
          <AccordionGroup.Item
            header="Show help"
            eventKey="1"
            htmlId="AccordionHelpExpanded"
          >
            <h6>File Requirements:</h6>
            <ul className="xmm-modal-help-text">
              <li>CSV format only</li>
              <li>
                Column headers must exactly match the headers in the exported
                operations file
              </li>
              <li>
                Added Operations must have the "INTERNAL_NAME" and
                "SERVICE_KIND" values populated appropriately
              </li>
            </ul>
            <h6>Helpful Tips:</h6>
            <ul className="xmm-modal-help-text">
              <li>
                To make changes to existing services via CSV, modify select
                values on the exported operations list, then re-import it.
              </li>
              <li>Do not modify the column headers from the exported file</li>
              <li>
                Do not modify the "INTERNAL_NAME" value of any existing services
                in the CSV. This value is used as the key, so modifying it will
                cause a new service to be added.
              </li>
              <li>
                Any Checkbox fields can only accept values of "1" (Checked) or
                "0" (Unchecked).
              </li>
              <li>
                The "META_VEHICLE_SCOPE" field can only accept values of "None",
                "Choose", "All" or "Scheduled"
              </li>
              <li>
                For newly added services, any unpopulated values will receive
                default values.
              </li>
              <li>
                These fields can only be edited through the application and will
                be ignored during import: "SERVICE_CATEGORY_NAME",
                "GLOBAL_DEALER"
              </li>
              <li>
                If you set an operation to "Choose" vehicle scope through the
                import file, the Vehicle rule must be specified through the
                application afterward
              </li>
            </ul>
          </AccordionGroup.Item>
        </AccordionGroup.Container>
        <SelectInput
          htmlId="makeSelect"
          label={localeStrings["xmm.portal.common.make_lbl"]}
          name="make"
          placeholder={localeStrings["xmm.portal.common.select_a_make_lbl"]}
          displayLabel={false}
          options={this.props.makesList}
          onChange={this.onChange}
        />
        <input type="file" accept=".csv" onChange={this.handelOnUploadFile} />
        <div className="xmm-error-msg">{this.state.errMsg}</div>
      </ModalDialog>
    );
  }
}

ImportDealerOperationsModal.propTypes = {
  show: bool,
  importOperations: PropTypes.func,
  closeDialog: PropTypes.func,
  makesList: PropTypes.array,
  title: string
};
