/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import NumericInput from "@cx/ui/NumericInput";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import { doesEmpty } from "../../../../../commonUtil/utils/object";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import NoPricingRow from "./NoPricingRow";
import { AppContext } from "../../../../app-context";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class TaxesRow extends React.Component {
  static contextType = AppContext;
  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.catalog !== prevState.catalog) {
      const { catalog } = nextProps;
      const applyToTaxes = getApplyTaxes(catalog);
      const isNone = applyToTaxes === "0-0" ? true : false;
      return {
        catalog,
        salesTaxRate: catalog.salesTaxRate,
        applyToTaxes,
        taxOptions: getTaxOptions(catalog),
        dirty: false,
        valid: true,
        errors: {
          salesTaxRate:
            !isNone && doesEmpty(catalog.salesTaxRate)
              ? "Rate is Required"
              : "",
          applyToTaxes: ""
        }
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.applyTaxChange = this.applyTaxChange.bind(this);
    this.onChangeRate = this.onChangeRate.bind(this);
    this.onBlurRate = this.onBlurRate.bind(this);

    const { catalog } = props;
    const applyToTaxes = getApplyTaxes(catalog);
    this.initializeLocaleValue();
    this.state = {
      salesTaxRate: catalog.salesTaxRate,
      applyToTaxes,
      taxOptions: getTaxOptions(catalog),
      dirty: false,
      valid: true,
      errors: {
        salesTaxRate: "",
        applyToTaxes: ""
      }
    };
  }
  componentDidMount() {}
  initializeLocaleValue() {
    this.notApplicableLabel = xlate("xmm.portal.settings.not_applicable_lbl");
    this.notApplicableTipLabel = xlate(
      "xmm.portal.settings.not_applicable_tip"
    );
  }
  // validation handler for rate field
  onBlurRate = event => {
    // NOTE: CX BUG - event.target.name is BLANK!!!
    const { value } = event.target;
    const { localeStrings } = this.context;
    let valid = false;
    this.setState(
      prevState => {
        const { errors } = prevState;
        if (!value) {
          errors.salesTaxRate =
            localeStrings["xmm.portal.errors.required_field"];
        } else if ((parseInt(value, 10) || 0) < 0) {
          // errors.salesTaxRate = "Minimum value for this field is 0";
          errors.salesTaxRate =
            localeStrings["xmm.portal.settings.min_value_warn"] + "0";
        } else if (value && (parseFloat(value) || 0) > 20) {
          // errors.salesTaxRate = "Maximum value for this field is 20";
          errors.salesTaxRate =
            localeStrings["xmm.portal.settings.max_value_warn"] + "20";
        } else {
          errors.salesTaxRate = "";
          valid = true;
        }
        return { errors, valid };
      },
      () => {
        // callback parent handler to push changes
        const { catalog, onUpdate } = this.props;
        const { valid, dirty, applyToTaxes } = this.state;
        if (dirty && valid) {
          let newVal = !doesEmpty(value) ? parseFloat(value).toFixed(5) : 0;
          newVal = parseFloat(newVal);
          if (applyToTaxes !== "0-0") {
            const applyTo = applyToTaxes.toString().split("-");
            let payload = null;
            // Flat pricing flow
            if (catalog.pricingMethod === 2) {
              payload = {
                salesTaxApplyToTotal: 1,
                salesTaxRate: newVal
              };
            } else {
              payload = {
                salesTaxApplyToParts: parseInt(applyTo[0], 10),
                salesTaxApplyToLabor: parseInt(applyTo[1], 10),
                salesTaxRate: newVal
              };
            }
            catalog.salesTaxRate = newVal;
            onUpdate(catalog, "salesTaxRate", payload);
            this.setState({ dirty: false, valid: true, salesTaxRate: newVal });
          }
        }
      }
    );
  };
  // min = 0 max = 20% for Rate with 5 decimals
  onChangeRate = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(this.state[name], value)) {
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            dirty: true, // set dirty flag to avoid server calls when there is no change
            [name]: value,
            errors
          };
        },
        () => {
          // callback to validate form
        }
      );
    }
  };
  // dropdown change
  applyTaxChange = (cxEvent, isValid) => {
    const { name, value } = cxEvent.target;
    const { catalog, onUpdate } = this.props;
    let rateError = false;
    let salesTaxApplyToParts = "";
    let salesTaxApplyToLabor = "";
    let salesTaxApplyToTotal = "";
    let { salesTaxRate } = this.state;
    if (isDifferentValue(this.state.applyToTaxes, value)) {
      if (value) {
        switch (value) {
          case "0-0":
            if (catalog.pricingMethod === 2) {
              // Flat pricing flow
              salesTaxApplyToTotal = 0;
              salesTaxRate = null;
            } else {
              salesTaxApplyToParts = 0;
              salesTaxApplyToLabor = 0;
              salesTaxRate = null;
            }
            break;
          case "1-0":
            salesTaxApplyToParts = 1;
            salesTaxApplyToLabor = 0;
            rateError = true;
            break;
          case "0-1":
            salesTaxApplyToParts = 0;
            salesTaxApplyToLabor = 1;
            rateError = true;
            break;
          case "1-1":
            salesTaxApplyToParts = 1;
            salesTaxApplyToLabor = 1;
            rateError = true;
            break;
          case "2-2":
            salesTaxApplyToTotal = 1;
            rateError = true;
            break;
          default:
            salesTaxApplyToParts = 0;
            salesTaxApplyToLabor = 0;
            salesTaxApplyToTotal = 0;
            salesTaxRate = null;
            break;
        }
        const applyToTaxes = value;
        const { errors, valid } = this.state;
        const errorRate = errors.salesTaxRate;
        // check if rate field is valid to proceed
        const isValid = value === "0-0" || (valid && !doesEmpty(salesTaxRate));
        errors.salesTaxRate =
          rateError && doesEmpty(salesTaxRate)
            ? "Rate is Required"
            : !valid
            ? errorRate
            : "";

        this.setState({
          applyToTaxes,
          errors
        });
        if (isValid) {
          let payload = null;
          // Flat pricing work flow
          if (catalog.pricingMethod === 2) {
            payload = {
              salesTaxApplyToTotal,
              salesTaxRate
            };
          } else {
            payload = {
              salesTaxApplyToParts,
              salesTaxApplyToLabor,
              salesTaxRate
            };
          }
          this.setState({
            applyToTaxes,
            salesTaxRate
          });
          onUpdate(catalog, name, payload);
        }
      }
    }
  };

  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  hasErrorStrings(errors) {
    if (Object.keys(errors).length) {
      const array1 = Object.values(errors);
      const iterator = array1.values();
      let errCount = 0;
      for (const value of iterator) {
        if (value === "" || value === null || value.length === 0) {
          // In case of valid error string
        } else if (value && typeof value === "string") {
          errCount++;
        }
      }
      return errCount === 0 ? false : true;
    } else {
      return false;
    }
  }

  render() {
    const { catalog } = this.props;
    const { salesTaxRate } = this.state;
    let rateVal = doesEmpty(salesTaxRate) ? "" : salesTaxRate;
    rateVal =
      typeof shopSupplyValue === "number" ? rateVal.toString() : rateVal;

    const disableRate = this.state.applyToTaxes === "0-0" ? true : false;
    const clsHide =
      this.state.applyToTaxes === "0-0"
        ? "xmm-fixed-col no-border"
        : "xmm-fixed-col no-border";
    const isAny = catalog.make === "ANY" ? true : false;
    const clsAny = isAny ? "xmm-form-content" : "hidden";
    const clsRow = catalog.make !== "ANY" ? "xmm-form-content" : "hidden";
    // const clsError = this.state.rateError ? "has-error" : "";
    const anyMakeRow = (
      <div className="xmm-colspan-label">
        {this.notApplicableLabel}
        <Tooltip
          htmlId="tipRight"
          position="right"
          tooltipContent={this.notApplicableTipLabel}
        >
          <IconInfoOutline htmlId="iconInfo" />
        </Tooltip>
      </div>
    );

    const defaultRow = (
      <>
        <div className="xmm-apply-field">
          <SelectInput
            htmlId="applyToTaxes"
            label="Apply to"
            displayLabel={false}
            name="applyToTaxes"
            onChange={this.applyTaxChange}
            placeholder="Select"
            displayPlaceholder={false}
            options={this.state.taxOptions}
            value={this.state.applyToTaxes}
          />
        </div>
        <div className={clsHide}>
          <NumericInput
            htmlId={"salesTaxRate-" + catalog.make}
            inputSuffix="%"
            label="Rate"
            displayLabel={false}
            name="salesTaxRate"
            onChange={this.onChangeRate}
            onBlur={this.onBlurRate}
            minLength={0}
            maxLength={8}
            allowDecimal
            value={rateVal || ""}
            disabled={disableRate}
            error={this.state.errors.salesTaxRate}
          />
        </div>
      </>
    );
    const noPricingRow = <NoPricingRow />;
    const rowHtml = catalog.pricingMethod === 0 ? noPricingRow : defaultRow;
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {" "}
          {catalog.make}{" "}
        </Col>
        <Col md={10} sm={10} className={clsRow}>
          {rowHtml}
        </Col>
        <Col md={10} sm={10} className={clsAny}>
          {anyMakeRow}
        </Col>
      </Row>
    );
  }
}

export default TaxesRow;
TaxesRow.propTypes = {
  catalog: PropTypes.object,
  onUpdate: PropTypes.func
};

function getApplyTaxes(catalog) {
  const {
    salesTaxApplyToParts,
    salesTaxApplyToLabor,
    salesTaxApplyToTotal,
    pricingMethod
  } = catalog;
  let taxValue = "0-0";
  if (pricingMethod === 2) {
    if (!salesTaxApplyToTotal || salesTaxApplyToTotal === 0) taxValue = "0-0";
    else if (salesTaxApplyToTotal === 1) taxValue = "2-2";
    else taxValue = "0-0";
  } else {
    if (!salesTaxApplyToParts && !salesTaxApplyToLabor) {
      taxValue = "0-0";
    } else {
      taxValue =
        salesTaxApplyToParts.toString() + "-" + salesTaxApplyToLabor.toString();
    }
  }
  return taxValue;
}

function getTaxOptions(catalog) {
  let taxOptions = [
    { value: "0-0", label: xlate("xmm.portal.common.none") },
    { value: "1-0", label: xlate("xmm.portal.common.parts_only") },
    { value: "0-1", label: xlate("xmm.portal.common.labor_only") },
    { value: "1-1", label: xlate("xmm.portal.nav.parts_labor") }
  ];
  // Total Flat Price workflow
  if (catalog.pricingMethod === 2) {
    taxOptions = [
      { value: "0-0", label: xlate("xmm.portal.common.none") },
      { value: "2-2", label: xlate("xmm.portal.common.total_flat_price") }
    ];
  }
  return taxOptions;
}
