/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import CustomNoRowsOverlay from "../../../../commonUtil/components/loadingmask/CustomNoRowsOverlay";
import Button from "@cx/ui/Button";
import IconMore from "@cx/ui/Icons/IconMore";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import SelectInput from "@cx/ui/SelectInput";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import { isArrayExist, isEmpty } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import {
  toEmptyStringIfUndefined,
  isEmptyString,
  isDifferentValue,
  isSameValue
} from "../../../../commonUtil/utils/string";
import {
  defaultToZeroIfNull,
  toFloat,
  toNumber
} from "../../../../commonUtil/utils/value";
import {
  blankValueFormatter,
  priceFormatter,
  toastMessageFormatter
} from "../../../../commonUtil/utils/formatter";
// import {
//   priceValueSetter
//   shopDurationValueSetter
// } from "../../../../commonUtil/utils/valuesetter";
import IntervalEditor from "../../../reusable/editors/IntervalEditor";
import OpcodeEditor from "../../../../commonUtil/editors/OpcodeEditor";
import PriceEditor from "../../../../commonUtil/editors/PriceEditor";
import FindOpCodesDialog from "../../../../commonUtil/dialog/FindOpCodesDialog";
import NumericEditor from "../../../../commonUtil/editors/NumericEditor";
import BulkCopyModal from "./bulkcopy/BulkCopyModal";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { PackageState } from "../../../../constants/ModuleConstants";
import PackageForm from "./content/PackageForm";
import BulkEditTabs from "./bulkedit/BulkEditTabs";
import { FormattedMessage } from "react-intl";
import { PackageContext } from "./package-context";
import { toast } from "@cx/ui/Toast";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import CustomTooltip from "../../../../commonUtil/components/reusable/CustomToolTip";
import ManageIntervalsModal from "../../../reusable/Intervals/ManageIntervalsModal";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";

let staticDealerOpcodesMap;

class PackageView extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    // Bind other external actions
    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.openBulkEditSlider = this.openBulkEditSlider.bind(this);
    this.closeBulkEditSlider = this.closeBulkEditSlider.bind(this);
    this.addActionSlider = this.addActionSlider.bind(this);
    this.hideSlider = this.hideSlider.bind(this);
    this.deletePackageFromModal = this.deletePackageFromModal.bind(this);
    this.closeSlider = this.closeSlider.bind(this);
    this.updateGridAfterSave = this.updateGridAfterSave.bind(this);
    this.handleDeletePackage = this.handleDeletePackage.bind(this);
    this.handleBulkCopyModal = this.handleBulkCopyModal.bind(this);
    this.getNewIntervalMileages = this.getNewIntervalMileages.bind(this);
    this.getIntervalMileages = this.getIntervalMileages.bind(this);
    this.changeSliderTitle = this.changeSliderTitle.bind(this);
    this.handleBulkCopyAction = this.handleBulkCopyAction.bind(this);
    this.closeBulkCopyModal = this.closeBulkCopyModal.bind(this);
    this.openManageIntervalsFromEditor = this.openManageIntervalsFromEditor.bind(
      this
    );
    this.onIntervalSaved = this.onIntervalSaved.bind(this);
    this.onRemoveInterval = this.onRemoveInterval.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    const localeStrings = context.localeStrings;
    this.deleteMsg = "";
    this.selectedMake = "";
    this.initializeLocaleValues();
    const gridOptions = {
      recordToDelete: null, //  store single pkg to delete
      isSinglePkgDelete: false,
      searchKey: context.packagesGrid.searchKey,
      packageType: context.packagesGrid.packageType,
      packagesGrid: context.packagesGrid,
      packages: null, // should be null - fix to skip "No records found" msg on grid load.
      editOption: null, // set values as edit, add, delete
      packageName: "",
      rowRecord: PackageState,
      sliderWidth: 700,
      showSlide: false,
      flexWidth: false,
      bulkEditSlide: false,
      bulkEditSliderWidth: 700,
      selectionlist: [],
      bulkEditMake: "",
      menuTypes: [],
      bulkEditPackageType: "",
      // disable props
      disableAction: false,
      disableBulkEdit: false,
      // props to support findopcode
      showOpCodeModal: false,
      setOpcodeValueFunc: null,
      findOpcodeServiceId: "",
      findOpcodeInternalName: "",
      findOpcodeDmsDescription: "",
      findOpcodeOpcode: "",
      showManageModal: false,
      selectedInterval: {},
      // ag-grid props
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false,
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        // minWidth: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClassRules: getCellClassRule(validationFields, editableFields, []),
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {
        opcodeStatusRenderer
      },
      frameworkComponents: {
        customTooltip: CustomTooltip,
        intervalEditor: IntervalEditor,
        opcodeEditor: OpcodeEditor,
        priceEditor: PriceEditor,
        numericEditor: NumericEditor,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomNoRowsOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.loading_msg"),
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        noRows: true,
        noRowsMessageFunc() {
          return xlate("xmm.portal.common.no_records_msg");
        }
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      // localeText: {
      //   filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
      //   selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
      //   totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
      //   totalAndFilteredRows:
      //     localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
      //   noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      // },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      rowSelection: "multiple", // allows multiple row selections with check column
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      columnTypes: {
        numberColumn: {
          maxWidth: 120,
          // minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        },
        noFilterColumn: {
          filter: false,
          editable: false,
          sortable: true,
          suppressMenu: true,
          enableRowGroup: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    // const { dealer, user } = this.context;
    window.addEventListener(
      "showFindOpcodeEvent",
      this.handleShowFindOpcodeEvent,
      false
    );
    window.addEventListener(
      "showManageIntervals",
      this.handleShowManageIntervals,
      false
    );
    window.addEventListener(
      "openManageIntervalsFromEditor",
      this.openManageIntervalsFromEditor,
      false
    );
    window.addEventListener(
      "removeIntervalFromList",
      this.onRemoveInterval,
      false
    );
    window.addEventListener("intervalSaved", this.onIntervalSaved, false);
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "showFindOpcodeEvent",
      this.handleShowFindOpcodeEvent,
      false
    );
    window.removeEventListener(
      "showManageIntervals",
      this.handleShowManageIntervals,
      false
    );
    window.removeEventListener(
      "openManageIntervalsFromEditor",
      this.openManageIntervalsFromEditor,
      false
    );
    window.removeEventListener("intervalSaved", this.onIntervalSaved, false);
    window.removeEventListener(
      "removeIntervalFromList",
      this.onRemoveInterval,
      false
    );
  }
  initializeLocaleValues() {
    this.pageTitle = xlate("xmm.portal.nav.packages");
    this.addPackageLabel = xlate("xmm.portal.common.add_package");
    this.addOverrideLabel = xlate("xmm.portal.common.add_override");
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.menuTypeLabel = xlate("xmm.portal.common.menutype");
    this.descriptionLabel = xlate("xmm.portal.grid.description");
    this.intervalLabel = xlate("xmm.portal.common.interval");
    this.vehiclesLabel = xlate("xmm.portal.grid.vehicles");
    this.opcodeLabel = xlate("xmm.portal.grid.opcode");
    this.opcodeStatusLabel = xlate("xmm.portal.grid.opcode_status");
    this.priceLabel = xlate("xmm.portal.packages.price_lbl");
    this.schedulingDurationLabel = xlate(
      "xmm.portal.common.schedule_duration_lbl"
    );
    this.packageTypeLabel = xlate("xmm.portal.grid.package_type");
    this.confirmDeleteLabel = xlate(
      "xmm.portal.packages.confirm_delete_an_override"
    );
    this.confirmDeleteXOverridesLabel = xlate(
      "xmm.portal.packages.confirm_delete_x_overrides"
    );
    this.bulkeditMultiMakesError = xlate(
      "xmm.portal.errors.bulkedit_package_overrides_multi_makes"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deleteError = xlate("xmm.portal.errors.deleting");
    this.deletePackageError = xlate("xmm.portal.packages.deleted.error");
    this.copyError = xlate("xmm.portal.errors.copying");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey, packageType } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const packagesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey,
        packageType
      };
      this.setState({
        packagesGrid
      });
      this.context.setPackagesGridState(packagesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const {
      colState,
      filterState,
      pivotState,
      searchKey,
      packageType
    } = this.state.packagesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.setState(
        {
          searchKey,
          packageType
        },
        prevState => {
          const event = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true
          });
          this.showAllColumns(packageType);
          this.applyTypeFilter(event, packageType);
          this.gridApi.setQuickFilter(searchKey);
          this.gridColumnApi.setColumnState(colState);
          this.gridColumnApi.setPivotMode(pivotState);
          // this.gridApi.setSortModel(sortState);
          this.assignColumnState(colState);
          this.gridApi.setFilterModel(filterState);
        }
      );
    }
  }

  onFirstDataRendered(params) {
    this.restoreGridState();
    this.sizeToFit();
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };

  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.id;
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // Move grid data load call within dealer opcodesMap call
    this.loadDealerOpcodesMapFromState();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };
  getEnhancedPricingPackages = datalist => {
    const { makelist } = this.context;
    const makeMap = {};
    makelist.forEach(m => {
      makeMap[m.make] = m;
    });
    return datalist.filter(p => {
      const { make, packageType } = p;
      return makeMap[make] && packageType !== "DURATION";
    });
  };
  updateState(datalist) {
    if (datalist) {
      const packages = this.getEnhancedPricingPackages(datalist);
      if (!packages || packages.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      this.setState(
        {
          packages
        },
        prevState => {
          this.sizeToFit();
          this.restoreGridState();
        }
      );
    }
  }
  /* First rest call to load packages for all makes */
  loadGridData() {
    const { dealerCode, locale } = this.context;

    const payload = {
      requestType: "",
      authId: "",
      dealerCode,
      make: "",
      locale,
      includeEnhanced: 1,
      showTemplate: 0,
      effectiveDate: "",
      menuTypeId: ""
    };
    const restUrl =
      "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedDealerPackages";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          const datalist = [];
          if (isArrayExist(data) && data.length > 0) {
            data.forEach(obj => {
              const { dmsOpcode, packageType } = obj;
              obj.dmsOpcodeStatus = "";
              if (packageType === "DMS_OPCODE" && dmsOpcode) {
                obj.dmsOpcodeStatus = getOpcodeStatus(dmsOpcode);
              }
              datalist.push(obj);
            });
          }
          hideBodyMask();
          this.updateState(datalist);
        }
      },
      error => {
        this.updateStatusBox(error.message, "error", false, true);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  // event handler to refresh grid data
  refreshGridData = () => {
    this.loadGridData();
    this.updateStatusBox("", "text", false);
  };
  // callback() to update grid with specific records for single make.
  updateGridRowsAfterSave = datalist => {
    if (datalist) {
      const packages = this.getEnhancedPricingPackages(datalist);
      packages.forEach(pkg => {
        const rowNode = this.gridApi.getRowNode(pkg.id);
        if (!rowNode) {
          const res = this.gridApi.applyTransaction({
            add: [pkg]
          });
          const rowNode = this.gridApi.getRowNode(pkg.id);
          rowNode.setSelected(false);
          this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
        } else {
          const rowNode = this.gridApi.getRowNode(pkg.id);
          rowNode.setData(pkg);
        }
      });
    }
  };

  /* Callback made after saving add/edit package from Form slider */
  getPackagesPerMake(makeValue) {
    const { dealerCode, locale } = this.context;
    const payload = {
      requestType: "",
      authId: "",
      dealerCode,
      make: makeValue,
      locale,
      includeEnhanced: 1,
      showTemplate: 0,
      effectiveDate: "",
      menuTypeId: ""
    };
    const restUrl =
      "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedDealerPackages";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          // console.log("Get packages per make", data);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateGridRowsAfterSave(data);
        }
      },
      error => {
        this.updateStatusBox(error.message, "error", false, true);
        this.gridApi && this.gridApi.hideOverlay();
        hideBodyMask();
      }
    );
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "menuTypeDescription",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function() {
      gridApi.refreshCells(params);
    }, 300);
  }
  /* "cellClicked" event handler fired on specific columns
     callback logic to open slider, show tooltip, icon click, show modal window etc
  */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "name") {
      const record = params.data;
      // convert id to strings
      record.intervalId = record.intervalId && record.intervalId.toString();
      record.menuTypeId = record.menuTypeId && record.menuTypeId.toString();
      record.metaVehicleScope = record.metaVehicleScope.toString();
      record.metaVehicleFilterId = !record.metaVehicleFilterId
        ? ""
        : record.metaVehicleFilterId.toString();
      // Edit case: set empty mileages[] to the record; these mileages will be updated when slider is opened.
      record.newIntervalMileages = [];
      // console.log("cellclicked for", record);
      // Here we send row record to Form slider
      this.setState(prevState => ({
        showSlide: !prevState.showSlide,
        editOption: "edit",
        packageName: record.name,
        rowRecord: record
      }));
    }
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    // console.log(
    //   "onCellValueChanged for field",
    //   params.colDef.field,
    //   params.oldValue,
    //   params.newValue,
    //   params
    // );
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // callback to save cell edit value
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.getSinglePackage(params);
      }
    }
  }
  refreshCell(record, field) {
    const rowNode = this.gridApi && this.gridApi.getRowNode(record.id);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const override = params.data;
    if (override && override.errors && override.errors[field]) {
      override.errors[field] = "";
    }
  }
  setFieldValidation(override, field, errorKey) {
    if (!override.errors) {
      override.errors = {};
    }
    override.errors[field] = xlate(errorKey);
    this.refreshCell(override, field);
    this.updateStatusBox(override.errors[field], "error", false, true);
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "price":
          if (newValue && (parseFloat(newValue) || 0) > 9999.99) {
            errorKey = "xmm.portal.errors.exceed_max_price";
          }
          break;
        case "shopDuration":
          if (newValue && (parseInt(newValue, 10) || 0) > 1440) {
            errorKey = "xmm.portal.validation.duration_max_value_exceeded";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }
  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      document.querySelector("#package-search-box").value = "";
      this.onSearchBoxChanged();
    }
  }
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#package-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState(
        {
          selectionlist: selectedRows
        },
        prevState => {
          const sameMake = this.verifySelectedMakes();
          if (!sameMake) {
            this.setState({
              disableBulkEdit: true, // true - prevent bulkedit when different makes selected
              bulkEditMake: "",
              bulkEditPackageType: ""
            });
          }
        }
      );
    }
  };
  /* open bulkdedit slider - Allow Bulkedit for packages with same make only
   */
  openBulkEditSlider = event => {
    event.preventDefault();
    const { selectionlist, disableBulkEdit } = this.state;
    if (selectionlist.length > 0 && disableBulkEdit) {
      toast.warning(this.bulkeditMultiMakesError, {
        autoClose: 8000,
        closeOnClick: true
      });
      return;
    }
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
  };
  /* handler for bulkdedit slider close  */
  closeBulkEditSlider = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
  };
  loadDealerOpcodesMapFromState = () => {
    const { dealerOpcodesMap } = this.state;
    if (!dealerOpcodesMap) {
      const { manualOpcodes } = this.context;
      if (!manualOpcodes || manualOpcodes.length === 0) {
        this.loadDealerOpcodes();
      } else {
        this.loadDealerOpcodesMap(manualOpcodes);
        this.loadGridData(); // When we revist packages page
      }
    }
  };
  /* External filter - filterBy packageType */
  onColumnViewChange = (cxEvent, isValid) => {
    const { value, name } = cxEvent.target;
    const filterValue = !value ? "ALL" : value;
    this.setState(
      {
        [name]: filterValue
      },
      prevState => {
        this.showAllColumns(filterValue);
        this.applyTypeFilter(cxEvent, filterValue);
        this.sizeToFit();
      }
    );
  };
  applyTypeFilter = (event, filterVal) => {
    if (this.gridApi) {
      const typeFilter = this.gridApi.getFilterInstance("packageType");
      const model = [];
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      if (filterVal && filterVal === "ALL") {
        typeFilter.setModel(null);
      } else if (filterVal && filterVal === "DMS_OPCODE_INVALID") {
        console.log("status", filterVal);
        model.push("DMS_OPCODE");
        typeFilter.setModel({ values: model });
        this.setStatusModel(filterVal);
      } else {
        model.push(filterVal);
        typeFilter.setModel({ values: model });
      }
      this.gridApi.onFilterChanged();

      // DON'T clear Search box
      // document.querySelector("#package-search-box").value = "";
      // this.gridApi.setQuickFilter("");
    }
  };
  // custom filter to apply on opcode status == invalid
  setStatusModel(filterVal) {
    const statusFilter = this.gridApi.getFilterInstance("dmsOpcodeStatus");
    const model = [];
    if (filterVal) {
      model.push("invalid");
      statusFilter.setModel({ values: model });
    }
  }
  /* show/hide columns based on package type */
  showAllColumns(filterValue) {
    const cols = [
      "checked",
      "make",
      "menuTypeDescription",
      "name",
      "intervalName"
    ];
    const hideCols = [
      "dmsOpcode",
      "dmsOpcodeStatus",
      "price",
      "shopDuration",
      "packageType"
    ];
    if (this.gridColumnApi) {
      // hide custom columns
      this.gridColumnApi.setColumnsVisible(hideCols, false);
      if (filterValue === "ALL") {
        cols.push("dmsOpcode");
        // cols.push("dmsOpcodeStatus");
        cols.push("price");
        cols.push("shopDuration");
      } else if (
        filterValue === "DMS_OPCODE" ||
        filterValue === "DMS_OPCODE_INVALID"
      ) {
        cols.push("dmsOpcode");
        // cols.push("dmsOpcodeStatus");
      } else if (filterValue === "PRICE") {
        cols.push("price");
      } else if (filterValue === "SHOP_DURATION") {
        cols.push("shopDuration");
      }
      // console.log("visible cols", cols);
      this.gridColumnApi.setColumnsVisible(cols, true);
    }
  }
  /* Findopcode editor events */
  openOpcodeModal = (
    findOpcodeServiceId,
    findOpcodeInternalName,
    findOpcodeOpcode,
    findOpcodeDmsDescription,
    setOpcodeValueFunc
  ) => {
    this.setState({
      showOpCodeModal: true,
      findOpcodeServiceId,
      findOpcodeInternalName,
      findOpcodeOpcode,
      findOpcodeDmsDescription,
      setOpcodeValueFunc
    });
  };

  closeOpcodeModal = () => {
    this.setState({ showOpCodeModal: false });
  };

  handleShowFindOpcodeEvent = event => {
    // data used from ag-grid record
    const { data, colDef } = event.detail;
    const { dmsOpcode } = data;
    const currentOpcodeDescription = "";
    // console.log("showopcode", data);
    this.openOpcodeModal(
      data.id.toString(),
      data.name,
      data.dmsOpcode,
      currentOpcodeDescription,
      value => {
        data.dmsOpcode = value;
        const params = {
          newValue: value,
          oldValue: dmsOpcode,
          value,
          data,
          colDef
        };
        this.onCellValueChanged(params);
      }
    );
  };

  handleShowManageIntervals = event => {
    // TODO
  };

  /* Method to save celleditor changes only */
  onSaveCellEdit(params, packageRecord) {
    const payload = this.getCellEditPayload(params, packageRecord);
    const restEndPoint = "ops/proxyapi/ddsproxy/rest/proc/editDealerPackage";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data && typeof data === "object") {
          let response = null;
          let msgToShow = "";
          // error case - {error: "An error has occurred."}
          if (data.error) {
            // toast.error("There was an error saving your changes.", {
            //   autoClose: 10000,
            //   closeOnClick: true
            // });
            this.updateStatusBox(this.saveError, "error", false, true);
          }
          if (data.response) {
            response = data.response;
            // Error case, package has conflcits
            if (response.statusCode !== 0) {
              msgToShow = toastMessageFormatter(response.statusMessage);
              // toast.error(toastMessageFormatter(msgToShow), {
              //   autoClose: 30000,
              //   closeOnClick: true
              // });
              this.updateStatusBox(msgToShow, "error", false, true);
            } else if (response.statusCode === 0 && response.statusMessage) {
              //  success case - show server msg
              msgToShow = response.statusMessage;
              toast.success(toastMessageFormatter(msgToShow), {
                autoClose: 5000,
                closeOnClick: true
              });
              this.updateStatusBox(this.savedMsg, "success", true);
            } else {
              // Edit success - callback to load packages
              const rowNode =
                this.gridApi && this.gridApi.getRowNode(packageRecord.id);
              rowNode.setData(packageRecord);
              const refreshParams = {
                force: true,
                rowNodes: [rowNode]
              };
              this.callRefreshAfterMillis(refreshParams, this.gridApi);
              this.updateStatusBox(this.savedMsg, "success", true);
            }
            if (params.colDef.field === "intervalName") {
              this.updateGridAfterSave(packageRecord);
            }
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
        // toast.error(msg, {
        //   autoClose: 5000,
        //   closeOnClick: true
        // });
        if (packageRecord.rollback) {
          delete packageRecord.rollback;
        }
      }
    );
  }
  /* Return payload for single cell edit */
  getCellEditPayload(params, packageRecord) {
    const { dealerCode } = this.context.dealer;
    const record = params.data;
    const field = params.colDef.field;
    const value = params.value;
    if (record.id && record.id > 0) {
      record[field] = value;
    }
    const payload = {
      packageId: record.id,
      name: record.name,
      make: record.make,
      dealerCode,
      packageType: record.packageType,
      metaVehicleScope: record.metaVehicleScope,
      metaVehicleFilterId: !record.metaVehicleFilterId
        ? null
        : record.metaVehicleFilterId,
      metaVehicles: [],
      menuTypeId: record.menuTypeId,
      intervalId: !record.intervalId ? null : record.intervalId,
      // update this using rest call to get mileages for this package
      newIntervalMileages: null,
      dmsOpcode: !record.dmsOpcode ? "" : record.dmsOpcode,
      duration: !record.duration ? null : record.duration,
      shopDuration: isNaN(toNumber(record.shopDuration))
        ? null
        : record.shopDuration,
      priceParts: isNaN(toFloat(record.priceParts)) ? null : record.priceParts,
      priceLabor: isNaN(toFloat(record.priceLabor)) ? null : record.priceLabor,
      price: isNaN(toFloat(record.price)) ? null : record.price,
      effectiveDate: ""
    };
    if (packageRecord && packageRecord.mileages) {
      const mileages = packageRecord.mileages.map(mile => mile.toString());
      payload.newIntervalMileages = mileages;
    }
    return payload;
  }
  getSinglePackage(params) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const record = params.data;
    const payload = {
      packageId: record.id,
      showAllVehicles: 0,
      effectiveDate: ""
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/getPackageWOMetavehicles";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          let packageData = {};
          if (!isArrayExist(data) && typeof data === "object") {
            packageData = data.response && data.response.package;
          }
          if (packageData && typeof packageData === "object") {
            packageData.dmsOpcode = toEmptyStringIfUndefined(
              packageData.dmsOpcode
            );
            let mileages = [];
            const miles = packageData.mileages && packageData.mileages.mileage;
            if (isArrayExist(miles) && miles.length > 0) {
              mileages = miles;
            } else if (!isArrayExist(miles)) {
              mileages.push(miles);
            }
            const field = params.colDef.field;
            const intervalUpdated =
              field === "intervalName" && params.data.outdata !== null;
            if (intervalUpdated) {
              // back up intervalId, intervalName, and mileages in case of revert
              const { intervalId, intervalName } = params.data;
              packageData.rollback = { mileages, intervalId, intervalName };
            }
            packageData[field] = params.value;
            packageData.mileages = mileages;
            if (intervalUpdated) {
              const { outdata } = params.data;
              packageData.mileages = this.getNewIntervalMileages(
                outdata,
                params.data
              );
              if (
                !isEmptyString(outdata.intervalId) &&
                isDifferentValue(outdata.intervalId, params.data.intervalId)
              ) {
                params.data.intervalId = outdata.intervalId;
              }
              // params.data.intervalName = outdata.intervalName;
              delete params.data.outdata;
            }
            // console.log("refine pkg", mileages, packageData);
            packageData.dmsOpcodeStatus = getOpcodeStatus(
              packageData.dmsOpcode
            );
            this.setState(
              {
                rowRecord: packageData
              },
              prevState => {
                this.onSaveCellEdit(params, packageData);
              }
            );
          }
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  changeSliderTitle() {
    this.setState({
      packageName: this.addPackageLabel,
      editOption: "add"
    });
  }
  getIntervalMileages = (intervalId, make) => {
    const { intervalsMapByMake } = this.context;
    const intervalsMap = intervalsMapByMake[make];
    const interval = intervalsMap[intervalId.toString()];
    let newIntervalMileages = [];
    if (interval) {
      newIntervalMileages = !interval.mileages
        ? []
        : interval.mileages.split(",");
    }
    return newIntervalMileages;
  };

  getNewIntervalMileages = (newPackage, rawPackage) => {
    const { intervalId, make } = newPackage;
    const { intervalsMapByMake } = this.context;
    const intervalsMap = intervalsMapByMake[make];
    let newIntervalMileages = [];
    if (newPackage.id === "") {
      // new package
      if (intervalId === "") {
        // single mileage point
        newIntervalMileages = [newPackage.intervalName];
      } else {
        // named interval
        const interval = intervalsMap[intervalId.toString()];
        if (interval) {
          newIntervalMileages = interval.mileages
            ? interval.mileages.split(",")
            : [];
        } else {
          newIntervalMileages = newPackage.newIntervalMileages;
        }
      }
    } else {
      // edit package
      if (intervalId === "") {
        // single mileage point
        newIntervalMileages = [newPackage.intervalName];
      } else {
        // check if intervalId changes during edit
        if (
          toNumber(newPackage.intervalId) === toNumber(rawPackage.intervalId)
        ) {
          // interval is not changed during the edit
          newIntervalMileages = newPackage.newIntervalMileages;
        } else {
          const interval = intervalsMap[intervalId.toString()];
          newIntervalMileages = interval.mileages
            ? interval.mileages.split(",")
            : [];
        }
      }
    }
    return newIntervalMileages;
  };
  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: "left",
        field: "checked",
        type: "actionColumn",
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        maxWidth: 40,
        minWidth: 40,
        width: 40
      },
      {
        headerName: localeStrings["xmm.portal.grid.make"],
        headerClass: "ag-text-header",
        pinned: "left",
        field: "make",
        sortingOrder: ["asc", "desc"],
        suppressSizeToFit: true,
        maxWidth: 150,
        minWidth: 100,
        editable: false,
        valueFormatter(params) {
          return params.value;
        },
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: this.menuTypeLabel,
        field: "menuTypeDescription",
        cellClass: "xmm-wrap-cell",
        tooltipField: "menuTypeName",
        tooltipComponentParams: { field: "menuTypeName" },
        tooltipComponent: "customTooltip",
        minWidth: 200,
        maxWidth: 300,
        filter: "agSetColumnFilter",
        filterParams: { buttons: ["clear"] }
      },
      {
        headerName: this.descriptionLabel,
        field: "name",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell xmm-link-cell",
        autoHeight: true,
        minWidth: 250,
        sortingOrder: ["asc", "desc"],
        valueFormatter: blankValueFormatter,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: this.intervalLabel,
        field: "intervalName",
        editable: true,
        // tooltipField: "intervalName",
        // tooltipComponentParams: { field: "intervalName" },
        // tooltipComponent: "customTooltip",
        cellClass: "editable-caret-cell xmm-wrap-cell",
        headerClass: "ag-text-header",
        cellEditor: "intervalEditor",
        cellEditorParams: {
          parentHandle: this
        },
        minWidth: 280,
        valueFormatter: blankValueFormatter,
        filter: "agTextColumnFilter",
        filterParams: { buttons: ["clear"] }
      },
      {
        headerName: this.vehiclesLabel,
        field: "metaVehicleFilterName",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        tooltipField: "metaVehicleFilterName",
        tooltipComponentParams: { field: "metaVehicleFilterName" },
        tooltipComponent: "customTooltip",
        autoHeight: true,
        minWidth: 200,
        editable: false,
        sortingOrder: ["asc", "desc"],
        valueGetter: metaVehicleFormatter,
        suppressSizeToFit: true,
        hide: true,
        filter: true
      },
      {
        headerName: this.opcodeLabel,
        field: "dmsOpcode",
        editable: true,
        headerClass: "ag-text-header",
        valueFormatter: doesnotExistFormatter,
        cellRendererFramework: cellRenderOpcode,
        cellEditorSelector(params) {
          if (params.data.packageType === "DMS_OPCODE") {
            return {
              component: "opcodeEditor"
            };
          } else {
            params.api.stopEditing();
            return null;
          }
        },
        cellClass: "editable-cell non-empty",
        suppressSizeToFit: true,
        minWidth: 120,
        width: 120,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: this.opcodeStatusLabel,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        hide: true,
        field: "dmsOpcodeStatus",
        editable: false,
        headerClass: "ag-text-header",
        cellRenderer: "opcodeStatusRenderer",
        valueGetter: opcodeStatusGetter,
        suppressSizeToFit: true,
        maxWidth: 120,
        minWidth: 80,
        width: 100,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: false,
          buttons: ["clear"]
        }
      },
      {
        headerName: this.priceLabel,
        field: "price",
        headerClass: "ag-numeric-header",
        type: "numberColumn",
        colId: "price",
        editable: true,
        cellClass: "editable-cell non-empty xmm-grid-price",
        cellEditorSelector(params) {
          if (params.data.packageType === "PRICE") {
            return {
              component: "priceEditor"
            };
          } else {
            params.api.stopEditing();
            return null;
          }
        },
        cellEditorParams: { keepInvalidValue: true },
        cellStyle: {
          textAlign: "right"
        },
        valueGetter(params) {
          return params.data.price;
        },
        valueFormatter: priceFormatter,
        // valueSetter: priceValueSetter,
        suppressSizeToFit: true,
        minWidth: 80,
        width: 80
      },
      {
        headerName: this.schedulingDurationLabel,
        field: "shopDuration",
        minWidth: 105,
        width: 105,
        type: "numberColumn",
        editable: true,
        cellEditorSelector(params) {
          if (params.data.packageType === "SHOP_DURATION") {
            return {
              component: "numericEditor"
            };
          } else {
            params.api.stopEditing();
            return null;
          }
        },
        cellEditorParams: { maxLength: 4 },
        cellClass: "editable-cell non-empty",
        suppressSizeToFit: true,
        // valueGetter(params) {
        //   return params.data.shopDuration;
        // },
        valueFormatter: doesnotExistFormatter
        // valueSetter: shopDurationValueSetter
      },
      {
        headerName: this.packageTypeLabel,
        type: "noFilterColumn",
        hide: true,
        field: "packageType",
        headerClass: "ag-text-header",
        valueFormatter: blankValueFormatter,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        maxWidth: 150,
        minWidth: 100
      }
      /* TODO - This column will be used for future release
      {
        headerName: "Vehicle Group",
        field: "metaVehicleScope",
        valueGetter: vehicleGroupValueGetter,
        filter: "agSetColumnFilter",
        hide: true,
        filterParams: {
          buttons: ["clear"]
        },
        maxWidth: 180,
        minWidth: 120
      },
      */
    ];
    return baseCols;
  }
  // Add package click event
  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: PackageState,
      packageName: this.addOverrideLabel
    }));
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.packages.add_override_click");
  };
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSlider = event => {
    const callback = () => {
      this.setState({
        showSlide: false
      });
    };
    const isDirtyFunc = () => {
      return this.refs.packageFormRef.state.dirty;
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showSlide: false
      });
    }
  };
  /* Hide Handler for Slider to close the drawer */
  hideSlider = event => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: PackageState,
      packageName: ""
    }));
  };
  /* Add, Edit, BUlkEdit case: Refresh Grid rows related selected make;
   */
  updateGridAfterSave = (record, restEndPoint) => {
    if (restEndPoint && restEndPoint.endsWith("Multiple")) {
      this.refreshGridData();
      return;
    }
    if (record) {
      this.getPackagesPerMake(record.make);
    }
  };

  /* Util - Allow Bulkedit for packages with same make only */
  verifySelectedMakes() {
    const { selectionlist } = this.state;
    let matched = false;
    let bulkEditPackageType = "";
    if (selectionlist.length > 0) {
      const uniqueMakes = [...new Set(selectionlist.map(x => x.make))];
      const uniqueTypes = [...new Set(selectionlist.map(x => x.packageType))];
      // console.log(uniqueMakes, uniqueTypes);
      if (uniqueMakes.length === 1) {
        matched = true;
        // if selected packages have  same packageType; then show related override valueField in Bulkedit
        if (uniqueTypes.length === 1) {
          bulkEditPackageType = uniqueTypes[0];
        }
        // console.log("matched", uniqueMakes[0], bulkEditPackageType);
        this.setState({
          disableBulkEdit: false,
          bulkEditMake: uniqueMakes[0],
          bulkEditPackageType
        });
      }
    }
    return matched;
    /* check for duplicate values in array
    const final = uniqueMakes.filter((item, index) => {
      return uniqueMakes.indexOf(item) !== index;
    });
    console.log(final);
    */
  }

  /* This comparator will orderby enabled menus and ascending by rank */
  menuTypeComparator = (menuType1, menuType2) => {
    if (!menuType1 || !menuType2) {
      return -1;
    }
    const make1 = menuType1.make.toUpperCase();
    const make2 = menuType2.make.toUpperCase();
    const comparison = 0;
    if (make1 === make2) {
      const enabled1 = menuType1.enabled;
      const enabled2 = menuType2.enabled;
      if (enabled1 === enabled2) {
        const rank1 = Number(defaultToZeroIfNull(menuType1.rank).toString());
        const rank2 = Number(defaultToZeroIfNull(menuType2.rank).toString());
        if (rank1 === rank2) {
          return 0;
        }
        return rank1 > rank2 ? 1 : -1;
      }
      return enabled1 > enabled2 ? -1 : 1;
    }
    return comparison;
  };

  loadMenuTypes = selectedMake => {
    const { dealerCode, locale } = this.context;
    const params = {
      dealerCode,
      locale,
      make: selectedMake
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedMenuTypes",
        method: "get",
        data: {},
        params
      },
      data => {
        const menuTypes = [];
        // sort the menu types by rank
        const rawMenuTypes = data.sort(this.menuTypeComparator);
        rawMenuTypes.forEach(m => {
          // display only un-selectable menus (i,e selectable = 0)
          if (m.selectable === 0) {
            m.label = m.description;
            m.value = m.menuTypeId.toString();
            menuTypes.push(m);
          }
        });
        this.setState({ menuTypes }, () => {
          // console.log(" sorted menus", menuTypes);
        });
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  loadDealerOpcodesMap = dealerOpcodes => {
    const dealerOpcodesMap = {};
    dealerOpcodes.forEach(dealerOpcode => {
      const { opcode } = dealerOpcode;
      dealerOpcodesMap[opcode.toLowerCase()] = dealerOpcode;
    });
    setDealerOpcodesMap(dealerOpcodesMap);
    this.setState(
      {
        dealerOpcodesMap
      },
      prevState => {
        // console.log("dealerOpcodes Map", dealerOpcodesMap);
        // ctrl.gridApi && ctrl.gridApi.refreshCells({ force: true });
      }
    );
  };

  loadDealerOpcodes = () => {
    // eslint-disable-next-line no-alert
    const dealerCode = this.context.dealerCode;
    const params = { dealerCode };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOpcodes",
        method: "get",
        data: {},
        params
      },
      dealerOpcodes => {
        this.context.setManualOpcodes(dealerOpcodes);
        this.loadDealerOpcodesMap(dealerOpcodes);
        // First time - call gridData
        this.loadGridData();
      },
      error => {
        toast.error("Failed to find dealer opcodes.");
      }
    );
  };

  handleBulkCopyModal = event => {
    const uniqueMakes = [...new Set(this.state.selectionlist.map(x => x.make))];
    if (uniqueMakes.length === 1) {
      this.setState({
        showBulkCopy: true,
        actionDetail: event.detail
      });
      this.loadMenuTypes(uniqueMakes[0]);
    } else {
      toast.error("Please select a single make only");
    }
  };

  handleBulkCopyAction = menuTypeId => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const restEndPoint = `ops/proxyapi/ddsproxy/rest/proc/bulkCopyPackages`;
    const selectionlist = this.state.selectionlist;
    const locale = "en";
    const packages = selectionlist.map(packages => packages.id.toString());
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: {
          packages,
          menuTypeId,
          locale
        },
        params: {}
      },
      response => {
        if (response.response.statusCode === 0) {
          toast.success(toastMessageFormatter(response.response.statusMessage));
          this.updateStatusBox(this.savedMsg, "success", true);
          this.refreshGridData();
        } else if (response.response.statusCode === 2) {
          // toast.error(toastMessageFormatter(response.response.statusMessage));
          const msg = toastMessageFormatter(response.response.statusMessage);
          this.updateStatusBox(msg, "error", false, true);
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.copyError;
        // toast.error(msg, {
        //   closeOnClick: true
        // });
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    this.closeBulkCopyModal();
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent(
      "xmm.packages.copy_overrides_different_menutype_click"
    );
  };

  closeBulkCopyModal = event => {
    this.setState({ showBulkCopy: false });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteConfirmation: false
    });
  };
  // handler hooked to  delete single pkg from slider
  deletePackageFromModal = recordToDelete => {
    this.hideSlider();
    const { selectionlist } = this.state;
    console.log("single pkg delete", selectionlist, recordToDelete.id);
    this.deleteMsg = this.confirmDeleteLabel;
    this.setState({
      showDeleteConfirmation: true,
      isSinglePkgDelete: true,
      recordToDelete
    });
  };
  // common handler for "proceed" btn within Delete speed bump
  deleteFunction = () => {
    this.deletePackage();
    this.closeDeleteModal();
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.packages.delete_packages_click");
  };
  // handler to delete selected packages at grid level
  handleDeletePackage = event => {
    const { selectionlist } = this.state;
    console.log("grid-level bulk delete", selectionlist);
    if (selectionlist.length > 1) {
      this.deleteMsg = this.confirmDeleteXOverridesLabel.replace(
        "%1",
        selectionlist.length
      );
    } else {
      this.deleteMsg = this.confirmDeleteLabel;
    }
    this.setState({
      showDeleteConfirmation: true,
      actionDetail: event.detail,
      isSinglePkgDelete: false,
      recordToDelete: null
    });
  };

  deletePackage() {
    this.updateStatusBox(this.deletingMsg, "pending", false);
    const restEndPoint = `ops/proxyapi/ddsproxy/rest/proc/deletePackage`;
    const { recordToDelete, selectionlist, isSinglePkgDelete } = this.state;
    let removeList = [];
    let packageIds = [];
    if (isSinglePkgDelete && !isEmpty(recordToDelete)) {
      packageIds.push(recordToDelete.id.toString());
      removeList.push(recordToDelete);
    } else {
      packageIds = selectionlist.map(packages => packages.id.toString());
      removeList = selectionlist;
    }
    console.log(
      "Before Delete",
      selectionlist,
      packageIds,
      recordToDelete,
      isSinglePkgDelete
    );
    // {"requestType":"","authId":"","packageIds":["5546506"],"effectiveDate":""}
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: {
          requestType: "",
          authId: "",
          packageIds,
          effectiveDate: ""
        },
        params: {}
      },
      response => {
        if (response[0] !== undefined && response[0].retVal !== 0) {
          this.updateStatusBox(this.deletePackageError, "error", false, true);
        } else {
          const res = this.gridApi.applyTransaction({
            remove: removeList
          });
          res.remove.forEach(function(rowNode) {
            console.log("Removed Row Node", rowNode);
          });
          this.updateStatusBox(this.deletedMsg, "success", true);
          this.setState({
            isSinglePkgDelete: false,
            recordToDelete: null
          });
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.deleteError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    // GTM - push click event to dataLayer
    if (removeList.length > 0) {
      gtmEvents.gtmTrackEvent("xmm.packages.delete_single_override_click");
    }
  }

  onIntervalSaved = event => {
    const { interval, addMode } = event.detail;
    const { make, intervalId } = interval;
    const { intervalsByMake, intervalsMapByMake } = this.context;
    const intervals = intervalsByMake[make];
    if (!intervals) {
      return;
    }
    if (addMode) {
      // insert into an intervals in intervalsByMake
      intervals.push(interval);
    } else {
      for (let index = 0; index < intervals.length; index++) {
        if (isSameValue(intervalId, intervals[index].intervalId)) {
          intervals[index] = interval;
          break;
        }
      }
    }
    // insert/update intervalsMapByMake
    const intervalsMap = intervalsMapByMake[make];
    intervalsMap[intervalId.toString()] = interval;
  };
  onRemoveInterval = event => {
    const interval = event.detail;
    const { make, intervalId } = interval;
    const { intervalsByMake, intervalsMapByMake } = this.context;
    const intervals = intervalsByMake[make];
    for (let index = 0; index < intervals.length; index++) {
      if (isSameValue(intervalId, intervals[index].intervalId)) {
        intervals.splice(index, 1);
        break;
      }
    }
    const intervalsMap = intervalsMapByMake[make];
    delete intervalsMap[intervalId.toString()];
  };

  openManageIntervalsFromEditor = event => {
    const { selectedInterval } = event.detail;
    this.setState({ selectedInterval, showManageModal: true }, () => {
      this.refs.manageIntervalsModalRef.setSelectedItem(selectedInterval);
    });
  };

  closeManageIntervalsModal = event => {
    this.setState({ showManageModal: false });
  };

  renderManageIntervalsModal = context => {
    const { localeStrings } = context;
    const groupChild = <div />;
    let widget = null;
    if (this.state.showManageModal) {
      widget = (
        <ManageIntervalsModal
          ref="manageIntervalsModalRef"
          show={this.state.showManageModal}
          title={localeStrings["xmm.portal.manage_intervals"]}
          closeModal={event => {
            if (
              context.discardUnsavedChanges(
                event,
                this.closeManageIntervalsModal,
                this.refs.manageIntervalsModalRef.isDirty
              )
            ) {
              this.closeManageIntervalsModal();
            }
          }}
          selectedInterval={this.state.selectedInterval}
          children={groupChild}
        />
      );
    }
    return widget;
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  handleExport = () => {
    const { dealerCode } = this.context;
    const columnKeys = [];
    this.gridColumnApi.getAllDisplayedColumns().forEach(col => {
      if (col.colId !== "checked") {
        columnKeys.push(col.colId);
      }
    });
    const params = {
      columnKeys,
      fileName: `Package_Overrides_from_${dealerCode}`,
      sheetName: `Package_Overrides_from_${dealerCode}`
    };
    this.gridApi.exportDataAsExcel(params);
  };

  render() {
    const {
      dealerCode,
      locale,
      localeStrings,
      makelist,
      webKey
    } = this.context;
    // set component state to package-context
    const contextValue = {
      dealerCode,
      locale,
      localeStrings,
      makelist,
      webKey,
      editOption: this.state.editOption,
      rowRecord: this.state.rowRecord,
      selectionlist: this.state.selectionlist,
      packages: this.state.packages,
      bulkEditMake: this.state.bulkEditMake,
      bulkEditPackageType: this.state.bulkEditPackageType,
      updateGridAfterSave: this.updateGridAfterSave,
      getNewIntervalMileages: this.getNewIntervalMileages,
      getIntervalMileages: this.getIntervalMileages,
      appContext: this.context // send appContent as props
    };
    const deleteModal = (
      <Confirmation
        htmlId="deletePackage"
        message={this.deleteMsg}
        proceedButtonStyle="danger"
        show={this.state.showDeleteConfirmation}
        actionFunction={this.deleteFunction}
        closeDialog={this.closeDeleteModal}
      />
    );
    const bulkCopyModal = (
      <BulkCopyModal
        show={this.state.showBulkCopy}
        bulkCopy={this.handleBulkCopyAction}
        closeDialog={this.closeBulkCopyModal}
        menuTypes={this.state.menuTypes}
      />
    );
    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div
          id="packagesGrid"
          className="ag-grid-container ag-theme-balham xmm-packages"
        >
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.packages}
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            onCellEditingStarted={event => {
              console.log("onCellEditingStarted...");
            }}
            onCellEditingStopped={event => {
              console.log("onCellEditingStopped...");
            }}
            onRowEditingStarted={event => {
              console.log("onRowEditingStarted...");
            }}
            onRowEditingStopped={event => {
              console.log("onRowEditingStopped...");
            }}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            onColumnVisible={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={false}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            /* settings to support row selections with checkbox column */
            onSelectionChanged={this.handleSelectionChanged}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            isRowSelectable={this.state.isRowSelectable}
            rowHeight={50}
            onFilterChanged={this.onFilterChanged}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const msgSection = null;
    const header = (
      <React.Fragment>
        {msgSection}
        <div className="content-header">
          <div className="xmm-main-title">
            <h3>{this.pageTitle}</h3>
          </div>
          <div className="xmm-form-header">
            {statusBox}{" "}
            <Button
              htmlId="openBulkeditBtn"
              buttonStyle="primary"
              disabled={false}
              onClick={this.openBulkEditSlider}
              className="xmm-bulkedit-btn"
              hidden={this.state.selectionlist.length < 2}
            >
              <FormattedMessage
                defaultMessage="Bulk Edit"
                id="xmm.portal.operations.bulkedit.title"
              />
              {" ("}
              {this.state.selectionlist.length}
              {")"}
            </Button>
            <Button
              htmlId="addActionBtn"
              disabled={this.state.disableAction}
              onClick={this.addActionSlider}
            >
              <FormattedMessage
                defaultMessage="Add Override"
                id="xmm.portal.common.add_override_lbl"
              />
            </Button>
            <div className="xmm-input-search">
              <input
                type="text"
                id="package-search-box"
                className="xmm-input"
                placeholder={this.searchLabel}
                onChange={this.onSearchBoxChanged}
                value={this.state.searchKey}
                autoComplete="off"
              />
            </div>
            <SelectInput
              htmlId="packageTypeSelect"
              placeholder="Select"
              disabled={false}
              displayLabel={false}
              displayPlaceholder={false}
              maxHeight={100}
              name="packageType"
              onChange={this.onColumnViewChange}
              label="View"
              value={this.state.packageType}
              options={[
                {
                  value: "ALL",
                  label: this.context.localeStrings[
                    "xmm.portal.packages.all_types_lbl"
                  ]
                },
                {
                  value: "PRICE",
                  label: this.context.localeStrings[
                    "xmm.portal.packages.price_lbl"
                  ]
                },
                {
                  value: "DMS_OPCODE",
                  label: this.context.localeStrings["xmm.portal.common.opcode"]
                },
                {
                  value: "DMS_OPCODE_INVALID",
                  label: this.context.localeStrings[
                    "xmm.portal.packages.opcode_invalid_lbl"
                  ]
                },
                {
                  value: "SHOP_DURATION",
                  label: this.context.localeStrings[
                    "xmm.portal.common.schedule_duration_lbl"
                  ]
                }
              ]}
            />
            <DropdownButton
              title={<IconMore />}
              id="opcodesActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              pullRight
            >
              <DropdownMenuItem
                htmlId="refreshBtn"
                eventKey={{ eventKey: ["refresh"] }}
                onSelect={this.refreshGridData}
              >
                {localeStrings["xmm.portal.common.refresh_button"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="clearFiltersBtn"
                eventKey={{ eventKey: ["clear-filters"] }}
                onSelect={this.clearFilters}
              >
                {localeStrings["xmm.portal.common.clear_filters"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="deleteBtn"
                eventKey={{ eventKey: ["delete"] }}
                disabled={this.state.selectionlist.length === 0}
                onSelect={this.handleDeletePackage}
              >
                {localeStrings["xmm.portal.common.delete_button"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="bulkCpyBtn"
                eventKey={{ eventKey: ["bulkCpy"] }}
                disabled={this.state.selectionlist.length === 0}
                onSelect={this.handleBulkCopyModal}
              >
                {localeStrings["xmm.portal.common.bulk_cpy_btn"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="exportBtn"
                eventKey={{ eventKey: ["export-pkg"] }}
                onSelect={this.handleExport}
              >
                {localeStrings["xmm.portal.packages.export_lbl"]}
              </DropdownMenuItem>
            </DropdownButton>
          </div>
        </div>
      </React.Fragment>
    );
    // Add components within slider
    const modalTitle = <span>{this.state.packageName}</span>;
    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
      >
        <div>
          <PackageForm
            key={"editPackageSlider"}
            ref="packageFormRef"
            rowRecord={this.state.rowRecord}
            editForm={true}
            editOption={this.state.editOption}
            hideSlider={this.hideSlider}
            updateGridAfterSave={this.updateGridAfterSave}
            deletePackage={this.deletePackageFromModal}
            changeSliderTitle={this.changeSliderTitle}
          />
        </div>
      </GenericSlider>
    );
    const bulkModalTitle = (
      <span>
        <FormattedMessage
          defaultMessage="Bulk Edit"
          id="xmm.portal.operations.bulkedit.title"
        />
        {": "} {this.state.selectionlist.length}{" "}
        {xlate("xmm.portal.nav.packages")}
      </span>
    );
    const bulkeditSlider = (
      <GenericSlider
        title={bulkModalTitle}
        htmlId="packageBulkEditSlider"
        showSlide={this.state.bulkEditSlide}
        toggleSlider={this.closeBulkEditSlider}
        sliderWidth={this.state.bulkEditSliderWidth}
        flexWidth={this.state.flexWidth}
      >
        <BulkEditTabs
          key={"bulkPackages"}
          closeSlider={this.closeBulkEditSlider}
          showFindOpcodeModal={this.openOpcodeModal}
        />
      </GenericSlider>
    );
    /* Best Match button to be disabled for Package overrides */
    const findOpCodesModal = (
      <FindOpCodesDialog
        disableBestMatch={true}
        showValidateCatalog={true}
        dealerCode={this.context.dealerCode}
        serviceId={this.state.findOpcodeServiceId}
        internalName={this.state.findOpcodeInternalName}
        dmsOpcode={this.state.findOpcodeOpcode}
        dmsDescription={this.state.findOpcodeDmsDescription}
        localeStrings={this.context.localeStrings}
        manualOpcodes={this.context.manualOpcodes}
        show={this.state.showOpCodeModal}
        closeDialog={this.closeOpcodeModal}
        setManualOpcodes={this.context.setManualOpcodes}
        setOpcodeValue={this.state.setOpcodeValueFunc}
      />
    );

    const manageIntervalsModal = this.renderManageIntervalsModal(this.context);

    return (
      <React.Fragment>
        <PackageContext.Provider value={contextValue}>
          {header}
          {gridWidget}
          {formSlider}
          {bulkeditSlider}
          {findOpCodesModal}
          {deleteModal}
          {bulkCopyModal}
          {manageIntervalsModal}
        </PackageContext.Provider>
      </React.Fragment>
    );
  }
}

export default PackageView;

PackageView.propTypes = {};

const editableFields = ["dmsOpcode", "price", "shopDuration"];
const validationFields = ["price", "shopDuration"];

function doesnotExistFormatter(params) {
  const val = toEmptyStringIfUndefined(params.value);
  if (val) {
    return val;
  } else {
    return "";
  }
}

function opcodeStatusGetter(params) {
  if (!params || !params.data || params.data.packageType !== "DMS_OPCODE") {
    return "";
  }
  const { dmsOpcode } = params.data;
  return !isEmptyString(dmsOpcode) &&
    staticDealerOpcodesMap &&
    staticDealerOpcodesMap[dmsOpcode.toLowerCase()]
    ? "valid"
    : "invalid";
}
/* call this method to update global constant object */
function setDealerOpcodesMap(map) {
  staticDealerOpcodesMap = map;
}

function opcodeStatusRenderer(params) {
  const validStr = opcodeStatusGetter(params);
  // console.log(
  //   "!!",
  //   params.data.menuTypeDescription,
  //   params.data.dmsOpcodeStatus,
  //   validStr
  // );
  switch (validStr) {
    case "valid":
      return "valid";
    // return '<i class="fas fa-check"></i>';
    case "invalid":
      return "invalid";
    // return '<i class="fas fa-exclamation-circle"></i>';
    default:
      break;
  }
  return validStr;
}
// render opcode value with status icon
function cellRenderOpcode(params) {
  if (!params || !params.data) {
    return "";
  }
  const opcodeVal = params.value;
  let opcodeStatus = params.data && params.data.dmsOpcodeStatus;
  opcodeStatus = !opcodeStatus ? "" : opcodeStatus.toLowerCase();
  let iconHtml = "";
  if (opcodeStatus) {
    if (opcodeStatus === "valid") {
      iconHtml = <i className="fas fa-check" />;
    } else if (opcodeStatus === "invalid") {
      iconHtml = <i className="fas fa-exclamation-circle" />;
    }
    return (
      <div className="xmm-override-opcode-cell">
        {toEmptyStringIfUndefined(opcodeVal)} &nbsp;&nbsp;
        <span>{iconHtml}</span>
      </div>
    );
  }
  return toEmptyStringIfUndefined(opcodeVal);
}

function getOpcodeStatus(dmsOpcode) {
  const opcodeStatus =
    staticDealerOpcodesMap &&
    staticDealerOpcodesMap[toEmptyStringIfUndefined(dmsOpcode).toLowerCase()]
      ? "valid"
      : "invalid";
  return opcodeStatus;
}

/* Formatter returns Meta vehicle Summary for Un-named filter; Filter name for Named fitlers;
   "All Vehicles" when metaVehicleScope = 1
*/
function metaVehicleFormatter(params) {
  const scope = params.data && params.data.metaVehicleScope;
  const make = params.data && params.data.make;
  const filterId =
    params.data &&
    (!params.data.metaVehicleFilterId ? null : params.data.metaVehicleFilterId);
  if (scope === 1) {
    return "All " + make + " Vehicles";
  } else if (scope === 0 && filterId) {
    return params.data.metaVehicleFilterName;
  }
}
/* eslint-enable no-console */
