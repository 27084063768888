/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import { AppContext } from "../../../app-context";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { bool, PropTypes, string } from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectInput from "@cx/ui/SelectInput";
import AccordionGroup from "@cx/ui/AccordionGroup";
import { xlate } from "../../../../commonUtil/i18n/locales";
export default class ImportDMSPartsPricingModal extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.importDmsPartsPricingLabel = xlate(
      "xmm.portal.common.import_dms_parts_pricing"
    );
    this.selectMakeLabel = xlate("xmm.portal.common.select_a_make_lbl");
    this.showHelpLabel = xlate("xmm.portal.common.show_help");
    this.state = {
      makes: []
    };
  }

  onChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    this.setState({ make: value });
    this.fileNameWarning(this.state.file, value);
  };

  handelOnUploadFile = event => {
    if (event.target.files[0] === undefined) {
      this.setState({
        file: undefined
      });
      return;
    }
    this.setState({
      file: event.target.files[0]
    });
    this.fileNameWarning(event.target.files[0], this.state.make);
  };

  fileNameWarning = (file, make) => {
    this.setState({
      errMsg: undefined
    });
    if (file === undefined) return;
    if (make === undefined || make === "") return;
  };

  initializeStates = () => {
    this.setState({ make: undefined, file: undefined, errMsg: undefined });
  };

  render() {
    const { localeStrings } = this.context;
    const disableImportBtnFlag =
      this.state.make === undefined ||
      this.state.make === "" ||
      this.state.file === undefined;
    const importParts = this.props.importParts;

    return (
      <ModalDialog
        htmlId="importOperationsModalDialog"
        className="sample-modal-dialog"
        show={this.props.show}
        header={<ModalDialog.Title>{this.props.title}</ModalDialog.Title>}
        footer={
          <div>
            <Button
              htmlId="cancelDeleteOpsButton"
              onClick={this.props.closeDialog}
              text="Close Button"
            >
              <FormattedMessage
                defaultMessage="Cancel"
                id="xmm.portal.common.cancel_button"
              />
            </Button>
            <Button
              htmlId="okImportOpsButton"
              onClick={() => {
                importParts(this.state.make, this.state.file);
                this.initializeStates();
              }}
              text={this.importDmsPartsPricingLabel}
              buttonStyle="primary"
              disabled={disableImportBtnFlag}
            >
              <FormattedMessage
                defaultMessage={this.importDmsPartsPricingLabel}
                id="xmm.portal.common.proceed_button"
              />
            </Button>
          </div>
        }
        onHide={this.props.closeDialog}
      >
        <div>{localeStrings["xmm.portal.parts.import_modal_CSV_txt"]}</div>
        <AccordionGroup.Container
          htmlId="AccordionHelpText"
          independent
          className="xmm-help-modal"
        >
          <AccordionGroup.Item
            header={this.showHelpLabel}
            eventKey="1"
            htmlId="AccordionHelpExpanded"
          >
            <h6>
              {localeStrings["xmm.portal.parts.import_modal_requirements_txt"]}
            </h6>
            <ul className="xmm-modal-help-text">
              <li>
                {localeStrings["xmm.portal.parts.import_modal_format_txt"]}
              </li>
              <li>
                {localeStrings["xmm.portal.parts.import_modal_columns_txt"]}
                <ul>
                  <li>
                    {
                      localeStrings[
                        "xmm.portal.parts.import_modal_part_number_txt"
                      ]
                    }
                  </li>
                  <li>
                    {localeStrings["xmm.portal.parts.import_modal_price_txt"]}
                  </li>
                </ul>
              </li>
            </ul>
            <h6>{localeStrings["xmm.portal.parts.import_modal_tips_txt"]}</h6>
            <ul className="xmm-modal-help-text">
              <li>
                {
                  localeStrings[
                    "xmm.portal.parts.import_modal_importing_tip_txt"
                  ]
                }
              </li>
              <li>
                {localeStrings["xmm.portal.parts.import_modal_during_tip_txt"]}
              </li>
            </ul>
          </AccordionGroup.Item>
        </AccordionGroup.Container>
        <SelectInput
          htmlId="makeSelect"
          label="Make"
          name="make"
          placeholder={this.selectMakeLabel}
          displayLabel={false}
          options={this.props.makesList}
          onChange={this.onChange}
        />
        <input type="file" accept=".csv" onChange={this.handelOnUploadFile} />
        <div className="xmm-error-msg">{this.state.errMsg}</div>
      </ModalDialog>
    );
  }
}

ImportDMSPartsPricingModal.propTypes = {
  show: bool,
  title: string,
  importParts: PropTypes.func,
  closeDialog: PropTypes.func,
  makesList: PropTypes.array
};
