/* This Component used for No rows found case only.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
/**
 * @class CustomNoRowsOverlay
 */
export default class CustomNoRowsOverlay extends Component {
  render() {
    const content = (
      <div className="xmm-loader-msg">
        <i className="fa fa-frown-o" />
        <span> {this.props.noRowsMessageFunc()}</span>
      </div>
    );
    return content;
  }
}

CustomNoRowsOverlay.propTypes = {
  noRowsMessageFunc: PropTypes.func,
  noRows: PropTypes.bool
};
