/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../components/app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import FullPageModal from "@cx/ui/FullPageModal";
import NumericInput from "@cx/ui/NumericInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import TextInput from "@cx/ui/TextInput";
import TextArea from "@cx/ui/TextArea";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import Tooltip from "@cx/ui/Tooltip";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import {
  MenuTypeTemplate,
  MenuTypeEditTemplate
} from "../../../../../constants/ModuleConstants";
import {
  // isDifferentValue,
  isSameValue,
  toEmptyStringIfUndefined,
  sanitize,
  sanitizeMle
} from "../../../../../commonUtil/utils/string";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import { doesEmpty } from "../../../../../commonUtil/utils/object";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import TranslationPage from "../../../Settings/Translations/TranslationPage";
export default class MenuTypeForm extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.makes && nextProps.makes !== prevState.makes) ||
      (nextProps.menuTypes && nextProps.menuTypes !== prevState.menuTypes) ||
      (nextProps.menuType && nextProps.menuType !== prevState.menuType)
    ) {
      const { makes, menuTypes, menuType } = nextProps;
      this.convertTagsStringToArray(menuType);
      this.preSelectMake(menuType, makes);
      const inheritMenuTypes = getInheritMenuTypes(
        menuType.make,
        menuType,
        menuTypes
      );
      const normalDrivingType =
        menuType && menuType.tags && menuType.tags.indexOf("Normal") !== -1;
      const severeDrivingType =
        menuType && menuType.tags && menuType.tags.indexOf("Severe") !== -1;
      // const { makes, menuTypes, menuType } = nextProps;
      const originalDescription = menuType ? menuType.description : "";
      return {
        makes,
        menuTypes,
        menuType,
        originalDescription,
        inheritMenuTypes,
        normalDrivingType,
        severeDrivingType
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangeCheck = this.onChangeCheck.bind(this);
    this.onInheritOpcodesClick = this.onInheritOpcodesClick.bind(this);
    this.onInheritPriceOverrideClick = this.onInheritPriceOverrideClick.bind(
      this
    );
    this.updateStatusBox = this.updateStatusBox.bind(this);
    const { makes, menuTypes, menuType } = props;
    this.convertTagsStringToArray(menuType);
    this.preSelectMake(menuType, makes);
    const inheritMenuTypes = getInheritMenuTypes(
      menuType.make,
      menuType,
      menuTypes
    );
    const normalDrivingType =
      menuType && menuType.tags && menuType.tags.indexOf("Normal") !== -1;
    const severeDrivingType =
      menuType && menuType.tags && menuType.tags.indexOf("Severe") !== -1;
    const originalDescription = menuType ? menuType.description : "";
    this.initializeLocaleValues();

    this.inheritPkgPricesToggleRef = React.createRef();

    const drivingConditions = this.getDrivingConditions(context, menuType.make);

    this.state = {
      drivingConditions,
      makes,
      // origMenuType: Object.assign({}, props.menuType),
      menuType,
      menuTypes,
      originalDescription,
      inheritMenuTypes,
      normalDrivingType,
      severeDrivingType,
      dirty: false,
      valid: false,
      errors: { makeError: "", descriptionError: "" }
    };
  }

  componentDidMount() {}
  initializeLocaleValues() {
    this.translationLabel = xlate("xmm.portal.nav.translations");
    this.makeLabel = xlate("xmm.portal.common.make_lbl");
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.menuNameLabel = xlate("xmm.portal.common.menu_name");
    this.drivingTypeLabel = xlate("xmm.portal.common.driving_Type");
    this.rankLabel = xlate("xmm.portal.common.rank");
    this.menuBenefitsLabel = xlate("xmm.portal.common.menu_benefits");
    this.inheritFromMenuLabel = xlate("xmm.portal.common.inherit_from_menu");
    this.inheritPackageOpcodeLabel = xlate(
      "xmm.portal.common.inherit_package_opcode"
    );
    this.inheritPackagePriceOverridesLabel = xlate(
      "xmm.portal.common.inherit_package_price_overrides"
    );
    this.displayDetailedPricingLabel = xlate(
      "xmm.portal.common.display_detailed_pricing"
    );
    this.selectableLabel = xlate("xmm.portal.common.selectable");
    this.serviceSelectedLabel = xlate("xmm.portal.common.services_selected");
    this.byDefaultLabel = xlate("xmm.portal.common.by_default");
    this.normalLabel = xlate("xmm.portal.preview.vehicle.normal_lbl");
    this.severeLabel = xlate("xmm.portal.preview.vehicle.severe_lbl");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.duplicateErrorMsg = xlate("xmm.portal.menutype.menu_name_duplicate");
    this.inheritPkgPriceOverridesTooltip = xlate(
      "xmm.portal.common.inherit_package_price_overrides_tooltip"
    );
  }
  getDrivingConditions(context, make) {
    const { selectableDrivingConditionsMap } = context;
    const drvCondMap = selectableDrivingConditionsMap[make];
    const drivingConditions = drvCondMap ? drvCondMap.drivingConditions : [];
    return drivingConditions;
  }
  convertTagsStringToArray(menuType) {
    const { tags } = menuType;
    if (doesEmpty(tags)) {
      menuType.tags = [];
    } else if (typeof tags === "string") {
      menuType.tags = tags.split(",");
    }
  }
  /* Add Menutype case -  method will pre-select make in case we have one make;   */
  preSelectMake(menuType, makes) {
    if (!menuType.menuTypeId) {
      if (Array.isArray(makes) && makes.length === 1) {
        const { dealerCode, value, variant } = makes[0];
        menuType.make = value;
        menuType.dealerCode = dealerCode;
        menuType.variant = variant;
      }
    }
  }
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const { menuType, menuTypes } = this.state;
    if (!menuType || !menuTypes) {
      return;
    }
    switch (name) {
      case "make":
        if (value && value.length !== 0) {
          const { dealerCode, make, variant, showDrivingConditions } = value[0];
          if (menuType.make !== make) {
            menuType.dealerCode = dealerCode;
            menuType.make = value;
            menuType.variant = variant;
            const inheritMenuTypes = getInheritMenuTypes(
              make,
              menuType,
              menuTypes
            );
            const drivingConditions = this.getDrivingConditions(
              this.context,
              menuType.make[0].make
            );
            this.setState({
              drivingConditions,
              menuType,
              inheritMenuTypes,
              showDrivingConditions
            });
            this.markDirty();
          } else {
            this.setState({ showDrivingConditions });
          }
        } else {
          const showDrivingConditions = 0;
          menuType.make = value;
          this.setState({ menuType, showDrivingConditions });
        }
        break;
      case "includesId":
        if (
          Array.isArray(menuType.includesId) &&
          Array.isArray(value) &&
          value.length !== 0
        ) {
          if (
            menuType.includesId.length !== value.length ||
            (value.length !== 0 &&
              menuType.includesId[0].value !== value[0].value)
          ) {
            menuType[name] = value;
            if (menuType.selectable) {
              const includesMenu = { includesId: value[0].value };
              if (!this.isInheritedMenuNotSelectable(includesMenu)) {
                menuType.inheritPkgPrices = 0;
                this.inheritPkgPricesToggleRef.current.setState({
                  checked: false
                });
              }
            }
            this.setState({ menuType });
            this.markDirty();
          }
        } else {
          menuType[name] = value;
          this.setState({ menuType });
        }
        break;

      // case "includesId": {
      //   const newIncludesId = Array.isArray(value)
      //     ? value.length !== 0
      //       ? value[0].value
      //       : ""
      //     : value;
      //   if (isDifferentValue(menuType.includesId, newIncludesId)) {
      //     menuType[name] = newIncludesId !== "none" ? newIncludesId : null;
      //     this.setState({ menuType });
      //     this.markDirty();
      //   }
      //   break;
      // }

      default: {
        if (
          toEmptyStringIfUndefined(menuType[name]) !==
          toEmptyStringIfUndefined(value)
        ) {
          menuType[name] =
            name === "benefits" ? sanitizeMle(value) : sanitize(value);
          this.setState({ menuType });
          this.markDirty();
        }
        break;
      }
    }
  };

  onBlur = (cxEvent, domEvent) => {
    // add logic like validate value on blur event
    const valid = this.validate(true);
    this.setState({ valid });
  };

  /* checkbox change handler */
  onChangeCheck = event => {
    const { menuType } = this.state;
    let { normalDrivingType, severeDrivingType } = this.state;
    const { name, checked } = event.target;
    let tag = null;
    switch (name) {
      case "normalDrivingType":
        tag = "Normal";
        normalDrivingType = checked;
        break;
      case "severeDrivingType":
        tag = "Severe";
        severeDrivingType = checked;
        break;
      default:
        break;
    }
    if (tag) {
      const tags = menuType.tags ? menuType.tags : [];
      if (checked) {
        const index = tags.indexOf(tag);
        if (index === -1) {
          tags.push(tag);
        }
      } else {
        const index = tags.indexOf(tag);
        if (index !== -1) {
          tags.splice(index, 1);
        }
      }
      menuType.tags = tags;
      this.setState({
        menuType,
        normalDrivingType,
        severeDrivingType
      });
      this.markDirty();
    }
  };

  onToggleClickStateUpdate(name, cxEvent) {
    const { value } = cxEvent.target;
    const { menuType } = this.state;
    menuType[name] = value === "checked" ? 1 : 0;
    if (name === "alacarte" && value === "unchecked") {
      menuType.selectable = 0;
      // menuType.selectAll = 0;
      this.refs.selectableRef.setState({ checked: false });
    }
    // if (name === "selectable" && value === "unchecked") {
    //   menuType.selectAll = 0;
    // }
    this.setState({ menuType });
    this.markDirty();
  }

  onInheritOpcodesClick = (cxEvent, domEvent) => {
    this.onToggleClickStateUpdate("inheritPkgOpcodes", cxEvent);
  };

  onInheritPriceOverrideClick = (cxEvent, domEvent) => {
    this.onToggleClickStateUpdate("inheritPkgPrices", cxEvent);
  };

  onAlacarteClick = (cxEvent, domEvent) => {
    this.onToggleClickStateUpdate("alacarte", cxEvent);
  };

  onSelectableClick = (cxEvent, domEvent) => {
    const { value } = cxEvent.target;
    if (value !== "checked") {
      const { menuType } = this.state;
      menuType.inheritPkgPrices = 0;
      this.inheritPkgPricesToggleRef.current.setState({ checked: false });
    }
    this.onToggleClickStateUpdate("selectable", cxEvent);
  };

  onSelectAllClick = (cxEvent, domEvent) => {
    this.onToggleClickStateUpdate("selectAll", cxEvent);
  };

  getPostData(menuType, addMode) {
    // add and edit menu type template have different field order for saving data
    const template = addMode ? MenuTypeTemplate : MenuTypeEditTemplate;
    const menuTypePayload = Object.assign({}, template);
    const keys = Object.keys(menuType);
    const { locale } = this.context;
    if (addMode) {
      menuType.name = menuType.description;
    }
    keys.forEach(key => {
      if (menuTypePayload[key] !== undefined) {
        menuTypePayload[key] = menuType[key];
      }
    });
    menuTypePayload.locale = locale;

    if (Array.isArray(menuTypePayload.make)) {
      const list = menuTypePayload.make;
      menuTypePayload.make = list[0].make;
    }
    if (Array.isArray(menuTypePayload.includesId)) {
      const list = menuTypePayload.includesId;
      menuTypePayload.includesId =
        list.length !== 0 && list[0].value !== "none" ? list[0].value : null;
    }
    return menuTypePayload;
  }

  updateMenuTypeAfterSave(menuType, addMode) {
    if (typeof menuType.menuTypeId === "string") {
      menuType.menuTypeId = parseInt(menuType.menuTypeId, 10);
    }

    window.dispatchEvent(
      new CustomEvent("updateMenuTypeAfterSave", {
        detail: {
          menuType,
          addMode
        },
        bubbles: true,
        cancelable: true
      })
    );
  }

  onSave = event => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { menuType, menuTypes, originalDescription } = this.state;
    const { menuTypeId } = menuType;
    const addMode = menuTypeId === "";
    const restApiName = addMode ? "addDealerMenuType" : "editDealerMenuType";
    const restEndPoint = `ops/proxyapi/ddsproxy/rest/proc/${restApiName}`;
    const data = this.getPostData(menuType, addMode);

    if (menuType.description !== originalDescription) {
      const matches = menuTypes.filter(m => {
        return (
          m.description.toLowerCase() === data.description.toLowerCase() &&
          m.make === data.make
        );
      });
      if (matches.length !== 0) {
        this.updateStatusBox(this.duplicateErrorMsg, "error", false, true);
        return;
      }
    }

    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data,
        params: {}
      },
      data => {
        // NOTE: we get blank response on success; call this service to get single catalog record
        // ag-grid API to update rowData for edited cell.
        if (data && data.response) {
          if (data.response.statusCode === 0) {
            const respMenuType = data.response.menuType;
            const menuTypeId = respMenuType.id;
            if (addMode) {
              menuType.menuTypeId = menuTypeId;
              menuType.parentId = null;
            }
            const { make, includesId } = menuType;
            if (Array.isArray(make)) {
              menuType.make = make[0].value;
            }
            if (Array.isArray(includesId)) {
              if (includesId.length !== 0) {
                menuType.includesId = includesId[0].value;
              } else {
                menuType.includesId = "";
              }
            }
            this.updateMenuTypeAfterSave(menuType, addMode);
            // save logic
            this.updateStatusBox(this.savedMsg, "success", true);

            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.closeSlider();
              }, 2000);
            });
          }
          if (data.response.statusCode === 2) {
            const { statusMessage } = data.response;
            this.updateStatusBox(statusMessage, "error", false, true);
          }
        } else {
          // save logic
          const { error } = data;
          const errMsg = error
            ? error
            : xlate("xmm.portal.errors.save_data_error");
          this.updateStatusBox(errMsg, "error", false, true);
        }
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        this.updateStatusBox(errMsg, "error", false, true);
      }
    );
  };

  markDirty() {
    const valid = this.validate(false);
    const dirty = true;
    this.setState({ dirty, valid });
  }

  validate(showErrors) {
    const { make, description } = this.state.menuType;
    const makeSelected = this.isMakeSelected(make);
    const makeError = makeSelected ? "" : "Missing Make";
    const descriptionError =
      description !== ""
        ? description.length > 64
          ? xlate("xmm.portal.menutype.menu_name_max_length")
          : ""
        : xlate("xmm.portal.menutype.menu_name_req");
    const valid =
      makeSelected && description !== "" && description.length <= 64;
    if (showErrors) {
      this.setState({
        valid,
        errors: { makeError, descriptionError }
      });
    }

    return valid;
  }

  isMakeSelected(make) {
    if (typeof make === "string") {
      return make !== "";
    }
    return Array.isArray(make) && make.length !== 0;
  }
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  closeTranslationsModal = () => {
    this.setState({ showTranslationModal: false });
  };
  isInheritedMenuNotSelectable(menuType) {
    let includesId = "";
    if (!isNaN(menuType.includesId) && menuType.includesId > 0) {
      includesId = menuType.includesId;
    } else if (Array.isArray(menuType.includesId)) {
      if (menuType.includesId.length !== 0) {
        includesId = menuType.includesId[0].value;
      }
    }
    if (includesId) {
      const { menuTypes } = this.props;
      const inheritedMenus = menuTypes.filter(m =>
        isSameValue(m.menuTypeId, includesId)
      );
      if (inheritedMenus.length === 1) {
        return !inheritedMenus[0].selectable;
      }
    }
    return false;
  }
  render() {
    const { localeStrings, hasNonbaseLocale } = this.context;
    let editSection = null;
    const msgSection = null;
    const {
      drivingConditions,
      makes,
      menuType,
      inheritMenuTypes,
      normalDrivingType,
      severeDrivingType,
      showDrivingConditions,
      dirty,
      valid
    } = this.state;

    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );

    if (menuType) {
      const {
        description,
        make,
        menuTypeId,
        inheritPkgOpcodes,
        inheritPkgPrices,
        alacarte,
        selectAll,
        selectable,
        dealerModifiable,
        parentId
      } = menuType;
      const addMode = menuTypeId === "";
      const oemFlag = dealerModifiable === 0 && parentId !== null;
      const parentNotSelectable = this.isInheritedMenuNotSelectable(menuType);
      const showPkgPriceOverride = selectable && parentNotSelectable;
      const buttonLabel = this.saveLabel;
      const sectionClassName =
        addMode && (make === "" || make.length === 0) ? "hide" : "show-grid";
      const priceOverrideClass =
        addMode && (make === "" || make.length === 0)
          ? "hide"
          : showPkgPriceOverride
          ? "show-grid"
          : "hide";
      const inheritPkgOpcodesEnabled =
        inheritPkgOpcodes != null && inheritPkgOpcodes !== 0;
      const inheritPkgPricesEnabled =
        inheritPkgPrices != null && inheritPkgPrices !== 0;
      const alacarteEnabled = alacarte != null && alacarte !== 0;
      const selectAllEnabled = selectAll != null && selectAll !== 0;
      const selectableEnabled = selectable != null && selectable !== 0;
      const hideClassName = selectableEnabled ? "" : "hide";
      const hideDrivingTypeClassName =
        showDrivingConditions !== 1 ? "hide" : "";
      const saveable = dirty && valid;
      const translatable = !addMode;
      const translationButtonClass =
        hasNonbaseLocale && translatable ? "" : "hide";

      const modalTitle = `< ${this.translationLabel} - ${description}`;
      const translationModal = (
        <FullPageModal
          htmlId="translationFullModal"
          header={
            <div className="xmm-main-title">
              <h3>
                <Button
                  buttonStyle="link"
                  onClick={this.closeTranslationsModal}
                >
                  {modalTitle}
                </Button>
              </h3>
            </div>
          }
          show={this.state.showTranslationModal}
          onHide={this.closeTranslationsModal}
          closeButton={true}
        >
          <div>
            <TranslationPage
              type="MENU_TYPE"
              name={toEmptyStringIfUndefined(description)}
              id={toEmptyStringIfUndefined(menuTypeId)}
            />
          </div>
        </FullPageModal>
      );
      const normalClass = drivingConditions.includes("Normal")
        ? "xmm-checkbox-container xmm-horizontal-form"
        : "hide";
      const severeClass = drivingConditions.includes("Severe")
        ? "xmm-checkbox-container xmm-horizontal-form"
        : "hide";
      // show form when edit clicked or add clicked
      editSection = (
        <Card autoComplete="off">
          <div className="xmm-tab-bar">
            {statusBox}
            {translationModal}
            <span className="xmm-msg">{msgSection}</span>
            <Button
              htmlId="editTranslations"
              className={translationButtonClass}
              size="small"
              form="opsForm"
              // buttonStyle="primary"
              disabled={!translatable}
              onClick={() => {
                this.setState({ showTranslationModal: true });
              }}
            >
              {
                this.context.localeStrings[
                  "xmm.portal.translation.edit_translations"
                ]
              }
            </Button>{" "}
            <Button
              htmlId="saveAction"
              buttonStyle="primary"
              size="small"
              disabled={!saveable}
              onClick={this.onSave}
            >
              {buttonLabel}
            </Button>
          </div>
          <Grid htmlId="settingGrid">
            <Row className="show-grid">
              <Col xs={5} md={5}>
                <span className="float-right">
                  {this.makeLabel} <span className="xmm-red-label">*</span>{" "}
                </span>
              </Col>
              <Col xs={6} md={6}>
                <SearchableSelect
                  displayPlaceholder={false}
                  placeholder={
                    localeStrings["xmm.portal.common.select_make_label"]
                  }
                  className="xmm-scrollable-select"
                  htmlId="menuTypeMake"
                  label=""
                  name="make"
                  disabled={!addMode}
                  enableMultiSelect={false}
                  maxHeight={150}
                  onChange={this.onChange}
                  options={makes}
                  value={menuType.make}
                  displayLabel={false}
                  error={this.state.errors.makeError}
                />
              </Col>
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5}>
                <span className="float-right">
                  {this.menuNameLabel} <span className="xmm-red-label">*</span>{" "}
                </span>
              </Col>
              <Col xs={6} md={6}>
                <TextInput
                  htmlId="menuTypeDesc"
                  label="Description"
                  name="description"
                  disabled={oemFlag}
                  maxLength={64}
                  // required
                  value={menuType.description || ""}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  displayLabel={false}
                  placeholder={
                    localeStrings["xmm.portal.menutype.menu_name.label"]
                  }
                  error={this.state.errors.descriptionError}
                />
              </Col>
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5} className={hideDrivingTypeClassName}>
                <span className="float-right">{this.drivingTypeLabel}</span>
              </Col>
              <Col xs={6} md={6} className={hideDrivingTypeClassName}>
                <div className="menu-types-checkboxes">
                  <div className={normalClass}>
                    <input
                      type="checkbox"
                      // className="form-checkbox"
                      name="normalDrivingType"
                      id="normalDrivingType"
                      checked={normalDrivingType}
                      disabled={oemFlag}
                      onChange={this.onChangeCheck}
                    />
                    <span className="xmm-checkmark" />
                    <span className="label-checkbox">{this.normalLabel}</span>
                  </div>
                  <div className={severeClass}>
                    <input
                      type="checkbox"
                      // className="form-checkbox"
                      name="severeDrivingType"
                      id="severeDrivingType"
                      checked={severeDrivingType}
                      disabled={oemFlag}
                      onChange={this.onChangeCheck}
                    />
                    <span className="xmm-checkmark" />
                    <span className="label-checkbox">{this.severeLabel}</span>
                  </div>
                </div>
              </Col>{" "}
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5}>
                <span className="float-right">{this.rankLabel}</span>
              </Col>
              <Col xs={6} md={6}>
                <NumericInput
                  htmlId="menuTypeRank"
                  label="Label"
                  name="rank"
                  displayLabel={false}
                  disabled={oemFlag}
                  onChange={this.onChange}
                  value={toEmptyStringIfUndefined(menuType.rank)}
                  maxLength={5}
                  minLength={0}
                />
              </Col>
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5}>
                <span className="float-right">{this.menuBenefitsLabel}</span>
              </Col>
              <Col xs={6} md={6}>
                <TextArea
                  htmlId="menuBenefit"
                  label="Menu Benefits"
                  name="benefits"
                  disabled={false}
                  maxLength={4000}
                  rows={4}
                  value={menuType.benefits || ""}
                  onChange={this.onChange}
                  displayLabel={false}
                  layout="horizontal"
                />
              </Col>
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5}>
                <span className="float-right">
                  {this.inheritFromMenuLabel}{" "}
                  <span className="xmm-red-label"> </span>
                </span>
              </Col>
              <Col xs={6} md={6}>
                <SearchableSelect
                  displayPlaceholder={false}
                  placeholder={
                    localeStrings["xmm.portal.menutype.inhert_from_menu.label"]
                  }
                  className="xmm-scrollable-select"
                  htmlId="inhertitMenuType"
                  label=""
                  name="includesId"
                  enableMultiSelect={false}
                  maxHeight={150}
                  onChange={this.onChange}
                  options={inheritMenuTypes}
                  value={menuType.includesId}
                  displayLabel={false}
                  disabled={oemFlag}
                />
              </Col>
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5}>
                <span className="text-float-right">
                  {this.inheritPackageOpcodeLabel}
                </span>
              </Col>
              <Col xs={6} md={6}>
                <ToggleSwitch
                  htmlId="inheritPackageOpcodes"
                  className="menu-type-show-disabled-toggle"
                  defaultChecked={inheritPkgOpcodesEnabled}
                  disabled={oemFlag}
                  onClick={this.onInheritOpcodesClick}
                />
              </Col>
            </Row>
            <Row className={priceOverrideClass}>
              <Col xs={5} md={5}>
                <span className="text-float-right">
                  {this.inheritPackagePriceOverridesLabel}
                </span>
              </Col>
              <Col xs={6} md={6}>
                <Tooltip tooltipContent={this.inheritPkgPriceOverridesTooltip}>
                  <div>
                    <ToggleSwitch
                      htmlId="inheritPackagePriceOverrides"
                      ref={this.inheritPkgPricesToggleRef}
                      className="menu-type-show-disabled-toggle"
                      defaultChecked={inheritPkgPricesEnabled}
                      // disabled={oemFlag}
                      onClick={this.onInheritPriceOverrideClick}
                    />
                  </div>
                </Tooltip>
              </Col>
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5}>
                <span className="text-float-right">
                  {this.displayDetailedPricingLabel}
                </span>
              </Col>
              <Col xs={6} md={6}>
                <ToggleSwitch
                  htmlId="alacarte"
                  className="menu-type-show-disabled-toggle"
                  defaultChecked={alacarteEnabled}
                  disabled={oemFlag}
                  onClick={this.onAlacarteClick}
                />
              </Col>
            </Row>
            <Row className={sectionClassName}>
              <Col xs={5} md={5}>
                <span className="text-float-right">{this.selectableLabel}</span>
              </Col>
              <Col xs={2} md={2}>
                <ToggleSwitch
                  htmlId="selectable"
                  ref="selectableRef"
                  className="menu-type-show-disabled-toggle"
                  defaultChecked={selectableEnabled}
                  disabled={!alacarteEnabled || oemFlag}
                  onClick={this.onSelectableClick}
                />
              </Col>
              <Col xs={3} md={3} className={hideClassName}>
                <div className="text-float-right">
                  {this.serviceSelectedLabel}
                </div>
                <div className="text-float-right">{this.byDefaultLabel}</div>
              </Col>
              <Col xs={2} md={2} className={hideClassName}>
                <ToggleSwitch
                  htmlId="selectAll"
                  className="menu-type-show-disabled-toggle"
                  defaultChecked={selectAllEnabled}
                  disabled={!selectableEnabled || oemFlag}
                  onClick={this.onSelectAllClick}
                />
              </Col>
            </Row>
          </Grid>
        </Card>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

function getInheritMenuTypes(make, menuType, menuTypes) {
  const { menuTypeId } = menuType;
  if (make === "") {
    return [];
  }
  const filteredMenuTypes = menuTypes
    .filter(m => {
      return (
        m.make === make && (menuTypeId === "" || menuTypeId !== m.menuTypeId)
      );
    })
    .map(m => {
      return { label: m.description, value: m.menuTypeId.toString() };
    });
  filteredMenuTypes.splice(0, 0, {
    label: "None",
    value: "none"
  });
  return filteredMenuTypes;
}

MenuTypeForm.propTypes = {
  makes: PropTypes.array,
  menuTypes: PropTypes.array,
  menuType: PropTypes.object,
  closeSlider: PropTypes.func
};

/* eslint-enable no-console */
