/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import { PropTypes } from "prop-types";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
// import { toast } from "@cx/ui/Toast";
// import { makeSecureRestApi } from "../../../api/xmmAxios";
import {
  isDifferentValue
  // toEmptyStringIfUndefined
} from "../../../commonUtil/utils/string";
// import { isArrayExist } from "../../../commonUtil/utils/object";
import { getAllVehicleGroupName } from "../../../commonUtil/utils/menu";
import ManageVehicleGroupModal from "../../../components/reusable/VehicleGroups/ManageVehicleGroupModal";
import CreateVehicleGroupModal from "../../../components/reusable/VehicleGroups/CreateVehicleGroupModal";
import {
  createVehicleGroupOptions,
  getGroupNameList
} from "./VehicleGroupUtil";
import { xlate } from "../../../commonUtil/i18n/locales";

export default class VehicleGroupSelector extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      isDifferentValue(
        nextProps.data.metaVehicleFilterId,
        prevState.data.metaVehicleFilterId
      ) ||
      isDifferentValue(
        nextProps.data.metaVehicleScope,
        prevState.data.metaVehicleScope
      ) ||
      isDifferentValue(nextProps.data.make, prevState.data.make) ||
      isDifferentValue(nextProps.error, prevState.error)
    ) {
      updateState(nextProps, prevState);

      const { data, error } = nextProps;
      const { dealerCode, make, variant } = data;
      return {
        data,
        dealerCode,
        make,
        variant,
        metaVehicleFilterId: data.metaVehicleFilterId,
        error,
        showContentPanel: false,
        searchText: ""
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleVehicleGroupSelected = this.handleVehicleGroupSelected.bind(
      this
    );
    this.onRemoveVehicleGroupFromList = this.onRemoveVehicleGroupFromList.bind(
      this
    );
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.showPanel = this.showPanel.bind(this);

    // Create & Manage Vehicle Group
    this.onCloseCreateVehicleGroup = this.onCloseCreateVehicleGroup.bind(this);

    const { context, data, error } = props;
    const { dealerCode, make, metaVehicleFilterId, variant } = data;
    this.allVehiclesTmpl = xlate("xmm.portal.common.all_make_vehicles");
    const metaVehicleGroupName =
      metaVehicleFilterId === ""
        ? getAllVehicleGroupName(make, this.allVehiclesTmpl)
        : "";
    const { localeStrings } = context;
    this.initializeLocaleValues(localeStrings);
    this.state = {
      data,
      dealerCode,
      make,
      variant,
      metaVehicleFilterId,
      metaVehicleGroupName,
      error,
      searchText: "",
      showContentPanel: false,
      showManageVGroupModal: false,
      showCreateVehicleGroupModal: false,
      dealerMakeVehiclesGroupMap: {}, // hashmap of dealer vehicle groups (key = make)
      makeVehicleGroupsMap: {}, // hashmap of dealer and variant vehicle groups (key = make)
      vehicleGroupOptions: [],
      sortedVehicleGroups: [],
      filteredVehicleGroupOptions: []
    };
  }

  componentDidMount() {
    if (this.props.autoLoad) {
      // const { context } = this.props;
      // const { vehicleGroupCallbackParams } = context;
      // const { metaVehicleFilterId } = this.props.data;
      // vehicleGroupCallbackParams.vehicleGroupCallback = this.handleVehicleGroupCallback;
      // vehicleGroupCallbackParams.vehicleGroup = null;
      this.getGroupNameList();
    }
    window.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener(
      "vehicleGroupSelected",
      this.handleVehicleGroupSelected
    );
    window.addEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
    const { fromEditor } = this.props;
    if (!fromEditor) {
      window.addEventListener(
        "dealerNameFilterEvent",
        this.onDealerNameFilterEvent,
        false
      );
      window.addEventListener(
        "closeCreateVehicleModalEvent",
        this.onCloseCreateVehicleGroup,
        false
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener(
      "vehicleGroupSelected",
      this.handleVehicleGroupSelected
    );
    window.removeEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
    const { fromEditor } = this.props;
    if (!fromEditor) {
      window.removeEventListener(
        "dealerNameFilterEvent",
        this.onDealerNameFilterEvent,
        false
      );
      window.removeEventListener(
        "closeCreateVehicleModalEvent",
        this.onCloseCreateVehicleGroup,
        false
      );
    }
  }

  initializeLocaleValues(localeStrings) {
    this.searchLabel = localeStrings["xmm.portal.common.search_label"];
    this.manageVehicleGroupsLabel =
      localeStrings["xmm.portal.vehiclegroups.modal_lbl"];
    this.createVehicleGroupLabel =
      localeStrings["xmm.portal.vehiclegroups.create_lbl"];
  }

  handleVehicleGroupCallback = (
    dealerMakeVehiclesGroupMap,
    makeVehicleGroupsMap,
    sortedVehicleGroups
  ) => {
    const vehicleGroupOptions = createVehicleGroupOptions(
      makeVehicleGroupsMap,
      this.props.data.make
    );
    this.setVehicleGroupName(makeVehicleGroupsMap);
    this.setState({
      dealerMakeVehiclesGroupMap,
      makeVehicleGroupsMap,
      sortedVehicleGroups,
      vehicleGroupOptions,
      filteredVehicleGroupOptions: [...vehicleGroupOptions]
    });
    const { vehicleGroupCallbackParams } = this.props.context;
    const { vehicleGroup } = vehicleGroupCallbackParams;
    if (vehicleGroup && this.refs.manageVehGpModalRef) {
      this.refs.manageVehGpModalRef.setSelectedItem(vehicleGroup);
    }
  };

  setVehicleGroupName = makeVehicleGroupsMap => {
    const { make, metaVehicleFilterId, metaVehicleScope } = this.state.data;
    if (metaVehicleScope === "1") {
      this.setState({
        metaVehicleGroupName: getAllVehicleGroupName(make, this.allVehiclesTmpl)
      });
    } else if (metaVehicleFilterId !== "") {
      const vehicleGroups = makeVehicleGroupsMap[make];
      if (vehicleGroups) {
        const matches = vehicleGroups.filter(vg => {
          return !isDifferentValue(vg.metaVehicleFilterId, metaVehicleFilterId);
        });
        if (matches.length !== 0) {
          const vehicleGroup = matches[0];
          const metaVehicleGroupName = vehicleGroup.name
            ? vehicleGroup.name
            : "Missing Vehicle Group Name and Description";
          this.setState({ metaVehicleGroupName });
        }
      }
    }
  };

  /* This call made get named filters for a dealercode
   */
  getGroupNameList(vehicleGroup) {
    const { context } = this.props;
    const { vehicleGroupCallbackParams } = context;
    const { metaVehicleFilterId } = this.props.data;
    vehicleGroupCallbackParams.vehicleGroupCallback = this.handleVehicleGroupCallback;
    vehicleGroupCallbackParams.vehicleGroup = vehicleGroup;
    getGroupNameList(metaVehicleFilterId, context);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  isClickedOutside = event => {
    // console.log(
    //   "isClickedOutside",
    //   this.wrapperRef && !this.wrapperRef.contains(event.target)
    // );
    return this.wrapperRef && !this.wrapperRef.contains(event.target);
  };

  handleClickOutside = event => {
    // console.log("VehicleGroupSelector::handleClickOutside");
    if (this.wrapperRef && this.isClickedOutside(event)) {
      this.hidePanel();
    }
  };

  handleVehicleGroupSelected = event => {
    event.stopPropagation();
    const { nextProps, prevState } = event.detail;
    if (isDifferentValue(nextProps.data.make, prevState.data.make)) {
      // async call
      this.getGroupNameList();
    } else {
      if (
        isDifferentValue(
          nextProps.data.metaVehicleFilterId,
          prevState.data.metaVehicleFilterId
        ) ||
        isDifferentValue(
          nextProps.data.metaVehicleScope,
          prevState.data.metaVehicleScope
        )
      ) {
        const { makeVehicleGroupsMap } = this.state;
        this.setVehicleGroupName(makeVehicleGroupsMap);
      }
    }
  };

  onDealerNameFilterEvent = event => {
    event.preventDefault();
    event.stopPropagation();
    const vehicleGroup =
      event.detail && Object.keys(event.detail).length !== 0
        ? event.detail
        : null;
    this.getGroupNameList(vehicleGroup);
  };

  onRemoveVehicleGroupFromList = event => {
    event.preventDefault();
    event.stopPropagation();
    const metaVehicleFilter = event.detail;
    if (metaVehicleFilter) {
      const {
        dealerMakeVehiclesGroupMap,
        makeVehicleGroupsMap,
        sortedVehicleGroups,
        filteredVehicleGroupOptions,
        vehicleGroupOptions
      } = this.state;
      this.removeItemFromMap(metaVehicleFilter, dealerMakeVehiclesGroupMap);
      this.removeItemFromMap(metaVehicleFilter, makeVehicleGroupsMap);
      const sortedVehicleGroupsMap = this.findMakeVehicleGroupMap(
        metaVehicleFilter,
        sortedVehicleGroups
      );
      this.removeItemFromMap(metaVehicleFilter, sortedVehicleGroupsMap);
      const newVehicleGroupOptions = vehicleGroupOptions.filter(item => {
        return (
          item.value.toString() !==
          metaVehicleFilter.metaVehicleFilterId.toString()
        );
      });
      const newFilteredVehicleGroupOptions = filteredVehicleGroupOptions.filter(
        item => {
          return (
            item.value.toString() !==
            metaVehicleFilter.metaVehicleFilterId.toString()
          );
        }
      );
      const newState = {
        dealerMakeVehiclesGroupMap,
        makeVehicleGroupsMap,
        sortedVehicleGroups,
        vehicleGroupOptions: newVehicleGroupOptions,
        filteredVehicleGroupOptions: newFilteredVehicleGroupOptions
      };
      const { data } = this.state;
      if (metaVehicleFilter.metaVehicleFilterId === data.metaVehicleFilterId) {
        // data.metaVehicleScope = "1";
        data.metaVehicleFilterId = "";
        data.name = getAllVehicleGroupName(data.make, this.allVehiclesTmpl);
        this.props.onChange({
          name: "metaVehicleFilterSelected",
          detail: { data }
        });
        newState.metaVehicleGroupName = data.name;
      }
      this.setState(newState);
    }
  };

  findMakeVehicleGroupMap(metaVehicleFilter, sortedVehicleGroups) {
    const list = sortedVehicleGroups.filter(map => {
      return map[metaVehicleFilter.make];
    });
    return list.length !== 0 ? list[0] : null;
  }

  removeItemFromMap(metaVehicleFilter, map) {
    const list = map[metaVehicleFilter.make];
    if (list) {
      const newList = list.filter(item => {
        return (
          item.metaVehicleFilterId.toString() !==
          metaVehicleFilter.metaVehicleFilterId.toString()
        );
      });
      map[metaVehicleFilter.make] = newList;
    }
  }

  onSearchBoxChanged = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { searchText } = this.state;
    if (value === searchText) {
      return;
    }
    if (domEvent && domEvent.type === "blur") {
      return;
    }
    const { vehicleGroupOptions } = this.state;
    const showContentPanel = true;
    if (!value || value === "") {
      this.setState({
        showContentPanel,
        filteredVehicleGroupOptions: [...vehicleGroupOptions],
        searchText: value
      });
    } else {
      const filteredVehicleGroupOptions = vehicleGroupOptions.filter(vg => {
        return vg.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      this.setState({
        filteredVehicleGroupOptions,
        searchText: value,
        showContentPanel
      });
    }
  };

  getKey(vehicleGroup) {
    return `key_${vehicleGroup.value}`;
  }

  onClick = event => {
    const { showContentPanel } = this.state;
    if (!showContentPanel) {
      this.showPanel();
    } else {
      this.validateAfterHiddingPanel();
    }
  };

  onBlur = (cxEvent, isValid, domEvent) => {
    setTimeout(() => {
      if (this.props.onBlur) {
        this.props.onBlur(cxEvent, isValid, domEvent);
      }
    }, 300);
  };

  onChange = (cxEvent, isValid, domEvent) => {
    if (domEvent && domEvent.type === "blur") {
      this.onBlur(cxEvent, isValid, domEvent);
    }
  };

  showPanel = () => {
    const { showContentPanel } = this.state;
    if (!showContentPanel) {
      const showPanelUp = this.shouldShowContentPanelUp();
      this.setState({ showPanelUp, showContentPanel: !showContentPanel });
    }
  };

  hidePanel = () => {
    const { showContentPanel } = this.state;
    if (showContentPanel) {
      this.setState({
        showContentPanel: false
        // searchText: "" // this.state.data.intervalName
      });
    }
  };

  validateAfterHiddingPanel = () => {
    this.hidePanel();
    const { touched } = this.state;
    if (touched) {
      this.props.onBlur();
    }
  };

  shouldShowContentPanelUp() {
    const gridBoundingBox = document
      .querySelector("#grid-wrapper")
      .getBoundingClientRect();
    const selectorBoundingBox = this.wrapperRef.getBoundingClientRect();
    console.log("getBoundingClientRect", gridBoundingBox, selectorBoundingBox);
    const panelHeight = 200;
    // if bottom of panel is beyond bottom of the grid rows
    if (selectorBoundingBox.bottom + panelHeight > gridBoundingBox.bottom) {
      if (selectorBoundingBox.top - panelHeight > gridBoundingBox.top + 40) {
        // show panel up
        return true;
      } else {
        // place panel somewhere in the middle of the grid
        return undefined;
      }
    }
    return false;
  }

  onCloseCreateVehicleGroup = () => {
    // event.preventDefault();
    // event.stopPropagation();
    this.showCreateVehicleGroupModal(false);
  };
  showCreateVehicleGroupModal = showCreateVehicleGroupModal => {
    this.setState({ showCreateVehicleGroupModal });
  };
  closeManageVGroupsModal = event => {
    this.setState({ showManageVGroupModal: false });
  };
  openManageVehicleGroupsFromEditor = () => {
    const { make, metaVehicleFilterId } = this.state.data;
    let selectedVehicleGroup;
    if (metaVehicleFilterId === "") {
      selectedVehicleGroup = {
        make: "",
        variant: "",
        dealerCode: "",
        name: "",
        metaVehicleFilterId: ""
      };
    } else {
      selectedVehicleGroup = this.findVehicleGroupByMake(
        make,
        metaVehicleFilterId
      );
      if (!selectedVehicleGroup) {
        selectedVehicleGroup = {
          make: "",
          variant: "",
          dealerCode: "",
          name: "",
          metaVehicleFilterId: ""
        };
      }
    }
    const { sortedVehicleGroups } = this.state;
    window.dispatchEvent(
      new CustomEvent("openManageVehicleGroupsFromEditor", {
        detail: { selectedVehicleGroup, sortedVehicleGroups },
        bubbles: true,
        cancelable: true
      })
    );
  };
  openManageVGroupsModal = event => {
    const { fromEditor } = this.props;
    if (fromEditor) {
      this.openManageVehicleGroupsFromEditor();
    } else {
      this.updateSelectedVehicleGroup();
    }
  };
  updateSelectedVehicleGroup() {
    const { make, metaVehicleFilterId } = this.state.data;
    const selectedVehicleGroup = this.findVehicleGroupByMake(
      make,
      metaVehicleFilterId
    );
    this.setState({ showManageVGroupModal: true }, () => {
      if (this.refs.manageVehGpModalRef) {
        this.refs.manageVehGpModalRef.setSelectedItem(selectedVehicleGroup);
      }
    });
  }
  findVehicleGroupByMake(make, metaVehicleFilterId) {
    if (
      make === "" ||
      metaVehicleFilterId === "" ||
      isNaN(metaVehicleFilterId)
    ) {
      return null;
    }
    const vehicleGroups = this.state.dealerMakeVehiclesGroupMap[make];
    if (vehicleGroups === undefined) {
      return null;
    }
    const vehicleGroup = vehicleGroups.filter(vg => {
      return (
        vg.metaVehicleFilterId.toString() === metaVehicleFilterId.toString()
      );
    });
    return vehicleGroup.length !== 0 ? vehicleGroup[0] : null;
  }

  renderManageVehicleGroupModal = context => {
    const { fromEditor } = this.props;
    if (fromEditor) {
      return "";
    }
    const groupChild = <div />;
    return (
      <ManageVehicleGroupModal
        ref="manageVehGpModalRef"
        show={this.state.showManageVGroupModal}
        title={xlate("xmm.portal.vehiclegroups.modal_lbl")}
        closeModal={event => {
          if (
            context.discardUnsavedChanges(
              event,
              this.closeManageVGroupsModal,
              this.refs.manageVehGpModalRef.isDirty
            )
          ) {
            this.closeManageVGroupsModal();
          }
        }}
        sortedVehicleGroups={this.state.sortedVehicleGroups}
        children={groupChild}
      />
    );
  };

  openCreateVehicleGroupModal = () => {
    const { fromEditor } = this.props;
    if (fromEditor) {
      const { sortedVehicleGroups } = this.state;
      const showCreateVehicleGroupModal = true;
      window.dispatchEvent(
        new CustomEvent("openCreateVehicleGroupFromEditor", {
          detail: { showCreateVehicleGroupModal, sortedVehicleGroups },
          bubbles: true,
          cancelable: true
        })
      );
    } else {
      this.showCreateVehicleGroupModal(true);
    }
  };
  renderCreateVehicleGroupModal = context => {
    // const { context } = this.props;
    // const { showCreateVehicleGroupModal } = this.state;
    // if (!showCreateVehicleGroupModal) {
    //   return "";
    // }
    const { dealerCode, make, variant } = this.state.data;
    return (
      <CreateVehicleGroupModal
        ref="createVehGpModalRef"
        show={this.state.showCreateVehicleGroupModal}
        title={xlate("xmm.portal.vehiclegroups.create_lbl")}
        closeModal={event => {
          if (
            context.discardUnsavedChanges(
              event,
              this.onCloseCreateVehicleGroup,
              this.refs.createVehGpModalRef.isDirty
            )
          ) {
            this.onCloseCreateVehicleGroup();
          }
        }}
        dealerCode={dealerCode}
        make={make}
        variant={variant}
        sortedVehicleGroups={this.state.sortedVehicleGroups}
        children={<div />}
      />
    );
  };

  drawVehicleGroupPanel = () => {
    const { disabled, fromEditor, hideAllVehicles } = this.props;
    const {
      data,
      filteredVehicleGroupOptions,
      metaVehicleGroupName,
      searchText,
      showContentPanel,
      showPanelUp
    } = this.state;
    const contentPanelClass = showContentPanel
      ? showPanelUp === undefined
        ? "selector-panel selector-panel-top"
        : showPanelUp
        ? fromEditor
          ? "selector-panel vehicle-group-selector-editor-up"
          : "selector-panel vehicle-group-selector-panel-up"
        : "selector-panel selector-panel-down"
      : "hide";
    const allMakeVehicles = getAllVehicleGroupName(
      data.make,
      this.allVehiclesTmpl
    );
    const allMakeClassName = hideAllVehicles ? "hide" : "";
    return (
      <form autoComplete="off">
        <div
          className="selector-container"
          disabled={disabled}
          ref={this.setWrapperRef}
        >
          <div
            onClick={() => {
              this.onClick();
            }}
          >
            <TextInput
              ref="vehicleGroupRef"
              htmlId="vehicleGroupRef"
              className="selector-search"
              displayLabel={false}
              value={metaVehicleGroupName}
              placeholder={this.searchLabel}
              onBlur={this.onBlur}
              onChange={this.onChange}
              // onFocus={this.showPanel}
              error={this.props.error}
            >
              <TextInput.Addon
                addonType="button"
                htmlId="vehicleGroupDownArrow"
                className="btn--icon"
                onClick={this.onClick}
              >
                <IconKeyboardArrowDown
                  htmlId="vehicleGroupSearchIcon"
                  className="pull-right"
                />
              </TextInput.Addon>
            </TextInput>
          </div>
          <div className={contentPanelClass}>
            <div className="selector-full-width">
              <div className="xmm-input-search">
                <input
                  type="text"
                  id="vehicle-group-search-box"
                  className="xmm-input pull-right"
                  placeholder={this.searchLabel}
                  value={searchText}
                  onChange={this.onSearchBoxChanged}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className={allMakeClassName}>
              <ul className="selector-list-group">
                <li
                  className="list-group-item"
                  onClick={event => {
                    const { data } = this.state;
                    data.metaVehicleScope = "1";
                    data.metaVehicleFilterId = "";
                    data.name = allMakeVehicles;
                    this.setState({ metaVehicleGroupName: data.name });
                    this.props.onChange({
                      name: "metaVehicleFilterSelected",
                      detail: { data }
                    });
                    this.hidePanel();
                  }}
                >
                  {allMakeVehicles}
                </li>
              </ul>
              <hr className="selector-separator" />
            </div>
            <div className="vehicle-selector-scroll">
              <ul className="selector-list-group">
                {filteredVehicleGroupOptions.map(vg => {
                  const { label, value } = vg;
                  return (
                    <li
                      className={getClassName(value, "")} // TBD
                      key={this.getKey(vg)}
                      onClick={event => {
                        const { data } = this.state;
                        data.metaVehicleScope = "0";
                        data.metaVehicleFilterId = value;
                        data.name = label;
                        this.setState({ metaVehicleGroupName: data.name });
                        // data.newIntervalMileages = interval.mileages;
                        this.props.onChange({
                          name: "metaVehicleFilterSelected",
                          detail: { data }
                        });
                        this.hidePanel();
                      }}
                    >
                      {label}
                    </li>
                  );
                })}
              </ul>
            </div>
            <hr className="selector-separator" />
            <Button
              htmlId="manageVehicleGroups"
              className="btn--icon"
              buttonStyle="link"
              size="sm"
              onClick={() => {
                this.openManageVGroupsModal(true);
              }}
            >
              {this.manageVehicleGroupsLabel}
            </Button>
            <br />
            <Button
              htmlId="createVehicleGroup"
              className="btn--icon"
              buttonStyle="link"
              size="sm"
              onClick={() => {
                this.openCreateVehicleGroupModal();
              }}
            >
              {this.createVehicleGroupLabel}
            </Button>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const vehicleGroupPanel = this.drawVehicleGroupPanel();
    const manageVehicleGroups = this.renderManageVehicleGroupModal(
      this.props.context
    );
    const createVehicleGroup = this.renderCreateVehicleGroupModal(
      this.props.context
    );
    return (
      <React.Fragment>
        {vehicleGroupPanel}
        {createVehicleGroup}
        {manageVehicleGroups}
      </React.Fragment>
    );
  }
}

VehicleGroupSelector.propTypes = {
  context: PropTypes.object,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fromEditor: PropTypes.bool,
  // rowHeight: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autoLoad: PropTypes.bool,
  hideAllVehicles: PropTypes.bool
};

function getClassName(currentId, selectedlId) {
  return currentId === selectedlId
    ? "list-group-item active"
    : "list-group-item";
}

function updateState(nextProps, prevState) {
  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent("vehicleGroupSelected", {
        detail: { nextProps, prevState },
        bubbles: true,
        cancelable: true
      })
    );
  }, 200);
}
