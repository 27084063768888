/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
import DropdownMenu from "@cx/ui/DropdownMenu";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import { shouldShowContentPanelUp } from "../../../../../../commonUtil/utils/menu";

export default class DealerRuleEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    console.log("DealerRuleEditor", props);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data } = props;
    return {
      dealerScheduleRule: data,
      highlightAllOnFocus
    };
  }

  onClick = event => {
    console.log("DealerRuleEditor.onClick", event);
  };

  getValue() {
    return this.state.dealerScheduleRule;
  }

  // for testing
  setValue(newValue) {
    //
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      const menuItemsUp = shouldShowContentPanelUp(
        "#grid-wrapper",
        this.wrapperRef,
        60
      );
      this.setState({
        highlightAllOnFocus: false,
        menuItemsUp
      });
    }
  }

  isPopup() {
    return true;
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  shouldShowContentPanelUp() {
    const gridBoundingBox = document
      .querySelector("#grid-wrapper")
      .getBoundingClientRect();
    const menuBoundingBox = this.wrapperRef.getBoundingClientRect();
    // console.log("getBoundingClientRect", gridBoundingBox, selectorBoundingBox);
    const panelHeight = 60;
    // if bottom of panel is beyond bottom of the grid rows
    if (menuBoundingBox.bottom + panelHeight > gridBoundingBox.bottom) {
      if (menuBoundingBox.top - panelHeight > gridBoundingBox.top + 40) {
        // show menu items up
        return true;
      } else {
        // show menu itmes down
        return false;
      }
    }
    return false;
  }

  renderDealerRuleMoreButton = data => {
    const { parentHandle } = this.props;
    const { menuItemsUp } = this.state;
    const { deleteDealerRule } = parentHandle;
    const { context } = parentHandle;
    const { localeStrings } = context;
    const deleteDealerRuleText =
      localeStrings["xmm.portal.common.delete_button"];
    return (
      <DropdownMenu
        // ref="dropdownButtonRef"
        buttonStyle="link"
        buttonText={<IconMore />}
        displayCaret={false}
        id="dealerRuleActionBtn"
        className="xmm-rule-dropdown-menu"
        dropup={menuItemsUp}
        pullRight
        size="small"
        defaultOpen={true}
      >
        <DropdownMenuItem
          htmlId="deleteDealerRule"
          eventKey={{ eventKey: ["deleteDealerRule"] }}
          onSelect={event => {
            deleteDealerRule(data);
            this.props.api.stopEditing();
          }}
        >
          {deleteDealerRuleText}
        </DropdownMenuItem>
      </DropdownMenu>
    );
  };
  render() {
    const { dealerScheduleRule } = this.state;
    const dealerRuleButton = this.renderDealerRuleMoreButton(
      dealerScheduleRule
    );
    return (
      <React.Fragment>
        <div ref={this.setWrapperRef}>{dealerRuleButton}</div>
      </React.Fragment>
    );
  }
}

DealerRuleEditor.propTypes = {
  data: PropTypes.object,
  parentHandle: PropTypes.object
};
