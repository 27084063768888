export const InspectionItemCategory = "service category";

export function updateOperationStatuses(operation, operationStatuses) {
  operation.priceStatus = operationStatuses.priceStatus;
  operation.contentEnabledAlacarte = operationStatuses.contentEnabledAlacarte;
  operation.contentEnabledMenus = operationStatuses.contentEnabledMenus;
  operation.laborTimeRange = operationStatuses.laborTimeRange;
  operation.dealerLaborRateCodeId = operationStatuses.dealerLaborRateCodeId;
  operation.numPricingOverrides = operationStatuses.numPricingOverrides;
  operation.numOpcodeOverrides = operationStatuses.numOpcodeOverrides;
}

export function getInspectionItemDisplay(inspectionItem) {
  if (!inspectionItem) {
    return "";
  }
  const {
    category,
    categoryName,
    itemTypeName,
    inspectionItemName
  } = inspectionItem;
  const theCategorty = category ? category : categoryName;
  const theInspectionItem = itemTypeName ? itemTypeName : inspectionItemName;
  return `${theCategorty} - ${theInspectionItem}`;
}
