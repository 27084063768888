/* Taskrunner to run any function within intervals */
/* eslint-disable no-console */

const taskConfig = {
  interval: 30000, // duration 30 secs/ 30,000 millisecs (for testing, interval=15000ms, duration=60000ms with 4 repeat)
  duration: 600000, //  duration 10 mins/ 600,000 millisecs
  repeat: 20 // repeat 20 times to display message (600 sec /30 sec)
};

let timer = 0;
let taskInterval;
let paused = true;
const duration = taskConfig.repeat * taskConfig.interval; // total 10 mins

/* Timer will run within interval - logger purpose  */
function startTimer() {
  timer = timer + 1;
  const plug = !paused ? "ON" : "OFF";
  console.log("Timer", plug, timer, timer * taskConfig.interval);
  suspendTask();
}

/* Pass callback() function as argu this  */
export function startTask(callback, instance) {
  console.log("~~~~~ Task started ~~~~~~~~");

  stopTask();
  taskInterval = setInterval(function() {
    startTimer();
    if (typeof callback === "function") {
      callback(instance);
    }
  }, taskConfig.interval);
  paused = false;
}

// suspend tasj aftet "n" repeats and then destroy
export function suspendTask() {
  if (timer === duration) {
    stopTask();
  }
}
export function stopTask() {
  if (taskInterval !== undefined) {
    window.clearInterval(taskInterval);
  }
  taskInterval = resetTask(taskInterval);
  const plug = !paused ? "ON" : "OFF";
  console.log("Timer", plug, timer);
  console.log("~~~~~ Task stopped ~~~~~~~~");
}

export function resetTask(intervalObj) {
  timer = 0;
  paused = true;
  if (intervalObj !== undefined) {
    window.clearInterval(intervalObj);
  }
  return undefined;
}

// Testing by calling sibling scope
/*
const repeat = 10; // repeat 10 times
const interval = 1000;
export function sampleTask() {
  const myInterval = setInterval(function() {
    repeatTask(myInterval, repeat);
  }, interval);
}

const repeatTask = function(myInterval, repeat) {
  const d = new Date();
  setTimeout(function() {
    console.log("foo called", d.toLocaleTimeString());
  }, 500);
  if (timer === repeat) {
    clearInterval(myInterval);
  }
};
*/

/* How to use Promise with timeout */
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
export const startTimeout = callback => {
  sleep(500).then(() => {
    console.log("Timeout started");
    callback();
  });
};
