/* eslint-disable no-console */
import * as Yup from "yup";
import { xlate } from "../../commonUtil/i18n/locales";

// Bind localeStrings for error messages
export function getFormValidationSchema(editOption, localeStrings) {
  // define Yup validation schema for Settings Formik fields
  const AddFormSchema = {
    // name: Yup.string().required("Name is Required"),
    internalName: Yup.string().required(
      localeStrings["xmm.portal.errros.internal_name.req"]
    ),
    make: Yup.string().required(
      localeStrings["xmm.portal.errors.make_is_required"]
    ),
    serviceKind: Yup.string().required(
      localeStrings["xmm.portal.errros.service_kind.req"]
    ),
    rank: Yup.number()
      .nullable()
      .typeError(localeStrings["xmm.portal.errros.rank.number_valid"])
      .min(1, localeStrings["xmm.portal.errros.rank.min_valid"])
      .max(99999, localeStrings["xmm.portal.errros.rank.max_valid"])
      .positive(localeStrings["xmm.portal.errros.rank.positive_check"])
      .integer(),
    shopDuration: Yup.number()
      .typeError(localeStrings["xmm.portal.errros.duration.number_valid"])
      .nullable()
      .max(1440, localeStrings["xmm.portal.errros.duration.max_valid"])
      .positive(localeStrings["xmm.portal.errros.rank.positive_check"])
      .integer()
  };
  if (editOption === "add") {
    return Yup.object().shape(AddFormSchema);
  } else {
    const editFormSchema = Object.assign(
      {
        name: Yup.string().required(
          localeStrings["xmm.portal.errors.name_required"]
        )
      },
      AddFormSchema
    );
    return Yup.object().shape(editFormSchema);
  }
}

/* we can validate all fields instead of Yup validator to use in Formik Form */
export const validate = values => {
  const errors = {};
  // check if any field have errors
  if (!values.name) {
    errors.name = xlate("xmm.portal.errors.name_required");
  }
  if (!values.make) {
    errors.make = xlate("xmm.portal.errors.make_is_required");
  }
  if (!values.serviceKind) {
    errors.serviceKind = xlate("xmm.portal.errros.service_kind.req");
  }
  if (values.rank && values.rank.length > 4) {
    errors.rank = "Rank must be 4 digits long";
  }
  // if (Object.keys(errors).length) {
  //   throw errors;
  // }
  return errors;
};

// to validate single field value
// eg: <Field name="age" validate={validateRequired("age", 78)} />
export const validateRequired = (name, value) =>
  value ? null : `${name} is required`;

// This validates null entry
export function validateNullValue(value) {
  let error;
  // const regex = /^[a-zA-Z ]+$/;
  if (value === null || value === undefined || value === "") {
    error = "This field is Required";
    // } else if (!regex.test(value)) {
    //   error = "This field is Required";
  }
  return error;
}

// This regex to allow alpha-numeric with space
export function validateAlphaNumSpace(value, name) {
  let error;
  // const regex = /^([a-zA-Z0-9 *]){0,}$/i;
  const regex = /^[a-zA-Z0-9]([a-zA-Z0-9 '_\-*]){0,}$/i;
  if (value && !regex.test(value)) {
    error = `${name} field requires a valid input`;
  }
  return error;
}

// This regex used in textfield like opcode, allow Punctuation characters   ,.!:"\;/'_&?%#@~$(){}[]
export function validateAlphaNumPunctuation(value) {
  let error;
  const regex = /^([a-zA-Z0-9 ,.!:"\\;/'_&\-*?%#@~$(){}[\]\u00C0-\u00ff]){0,}$/i;
  if (value && !regex.test(value)) {
    // console.log("has error", value);
    error = "This field requires a valid text.";
  }
  return error;
}

// This regex used in textfield like pricecaption, allow Punctuation with special characters   ,.!:"\;/'_&-*?%#@~$(){}[]=+<>
export function validateAlphaNumWithSpecialCharacters(value) {
  let error;
  const regex = /^([a-zA-Z0-9 ,.!:"\\;/'_&\-*?%#@~$(){}[\]=+<>]){0,}$/i;
  if (value && !regex.test(value)) {
    // console.log("is error", value);
    error = "This field requires a valid text.";
  }
  return error;
}

// This regex used in number with decimal characters
export function validateNumberWithDecimal(value) {
  let error;
  const regex = /^([0-9]){0,}(.)?([0-9]){0,2}$/;
  if (value && !regex.test(value)) {
    error = "This field requires a valid number.";
  }
  return error;
}
// regex to allow numbers only
export function validateNumberOnly(value) {
  let error;
  const regex = /^[0-9*]+$/;
  if (value && !regex.test(value)) {
    error = "This field requires a valid number.";
  }
  return error;
}

export const isFactoryOperation = value => {
  return !(
    value === null ||
    value === "" ||
    value === 0 ||
    value === undefined
  );
};
/* value convertor used for checkbox fields */
export const encodeNullToEmpty = value => {
  return value === null || value === undefined ? "" : value;
};
export const encodeNullToInteger = value => {
  // console.log("encode checkbox", value, typeof value);
  return value === null || value === undefined ? 0 : value;
};
export const isSelected = value => {
  return value === 1 || value === "1" || value === true || value === "true";
};
export const isArray = val => {
  return Array.isArray(val);
};

export const isObject = val => {
  return typeof val === "object" && !Array.isArray(val) && val !== null;
};

export const encodeNullValues = values => {
  const newValues = Object.assign({}, values);

  // Iterate each property and check for a null value - then reset
  // to empty string if null is found - iterate recursivly for objects
  Object.keys(newValues).forEach(key => {
    const value = newValues[key];
    if (value === null || value === undefined) {
      newValues[key] = "";
    } else if (typeof value === "number") {
      newValues[key] = value.toString();
    } else if (isObject(value)) {
      newValues[key] = encodeNullValues(value);
    }
  });
  return newValues;
};

export const decodeNullValues = (values, initialValues) => {
  const newValues = Object.assign({}, values);
  const matchValues = initialValues; // get this formik state when initialized values

  Object.keys(newValues).forEach(key => {
    const value = newValues[key];
    const matchValue = matchValues[key];

    // If we get an empty string - then check in matchValues for a null value
    // to place on key instead of the empty string
    if (typeof value === "string" && !value && matchValue === null) {
      newValues[key] = null;
    } else {
      if (isObject(value)) {
        newValues[key] = decodeNullValues(value, matchValue);
      }
    }
  });
  return newValues;
};
