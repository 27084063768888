/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import MenusPanel from "./content/MenusPanel";
import IndividualOperationsTable from "./content/individual/IndividualOperationsTable";
import InspectOperationsTable from "./content/inspect/InspectOperationsTable";
import PreviewContext from "./preview-context";

class ResultTabs extends React.Component {
  static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.alacarteResults !== prevState.alacarteResults ||
      nextProps.menuResults !== prevState.menuResults
    ) {
      const { menuResults, alacarteResults } = nextProps;
      return { menuResults, alacarteResults };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    const { localeStrings } = context.appContext;
    this.menuPackagesLabel =
      localeStrings["xmm.portal.preview.vehicle.menu_packages_lbl"];
    this.individualOperationsLabel =
      localeStrings["xmm.portal.preview.vehicle.individual_operations_lbl"];
    this.inspectPreviewUnpublishedLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_unpublished"];
    this.inspectPreviewPublishedNoVin =
      localeStrings["xmm.portal.preview.vehicle.inspect_in_published"];
    this.inspectPreviewLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_preview_lbl"];

    const { menuResults, alacarteResults } = props;
    this.state = { menuResults, alacarteResults };
  }
  componentDidMount() {}

  render() {
    const { preview, showInspect } = this.context.appContext;
    const { resultTabs } = preview;
    const { selectedIndex } = resultTabs;
    const { alacarteResults, menuResults } = this.state;
    const active = selectedIndex;
    return showInspect ? (
      <Tabs htmlId="ResultTabs" {...active}>
        <Tab active={active === "0"} label={this.menuPackagesLabel}>
          <MenusPanel selectedIndex="0" result={menuResults} />
        </Tab>
        <Tab active={active === "1"} label={this.individualOperationsLabel}>
          {" "}
          <IndividualOperationsTable
            selectedIndex="1"
            result={alacarteResults}
          />
        </Tab>
        <Tab active={active === "2"} label={this.inspectPreviewLabel}>
          <InspectOperationsTable selectedIndex="2" />
        </Tab>
      </Tabs>
    ) : (
      <Tabs htmlId="ResultTabs">
        <Tab active={active === "0"} label={this.menuPackagesLabel}>
          <MenusPanel selectedIndex="0" result={menuResults} />
        </Tab>
        <Tab active={active === "1"} label={this.individualOperationsLabel}>
          {" "}
          <IndividualOperationsTable
            selectedIndex="1"
            result={alacarteResults}
          />
        </Tab>
      </Tabs>
    );
  }
}
export default ResultTabs;

ResultTabs.propTypes = {
  showCancel: PropTypes.bool,
  menuResults: PropTypes.object,
  alacarteResults: PropTypes.object
};

/* eslint-enable no-console */
