/* eslint-disable no-console */
/* TODO - use transformer for request and response data to parse and bind to UI */
import axios from "axios";
import jwtDecode from "jwt-decode";
import * as gtag from "../components/utils/gtag-manager";
import { httpCodes } from "../components/utils/error-codes";
import { xlate } from "../commonUtil/i18n/locales";
// import { toast } from "@cx/ui/Toast";
import uiConfig from "../commonUtil/config/UIConfig.json";

const MAX_REFRESH_CALLS = 3;
const DealerCheck = "/ops/dealerapi/settings";
const UserCheck = "/ops/userapi/getUserDetails";

export let baseUrl = window.location.origin;
export let baseLoginUrl = "";
export let baseImgUrl = "";
export let consumerPortalUrl = "";
const hostname = window.location.hostname;
const tokens = hostname.split(".");
export let namespace = tokens[1];

if (hostname.indexOf("catalog.xtime.com") !== -1) {
  namespace = "prod";
} else if (hostname.indexOf("catalog.xtime.net.au") !== -1) {
  namespace = "prodaus";
} else if (hostname.indexOf("localhost") !== -1) {
  // default localhost to dev5
  namespace = "dev5";
}

export const DEFAULT_LOCALE = "en_US";
const NETWORK_ERROR = "Network Error";
let xmmAxios = null;

export function initializeUiConfig(uiConfig) {
  const hostname = window.location.hostname;
  if (hostname.indexOf("localhost") !== -1) {
    // baseUrl = "http://localhost.charlesproxy.com:8080";
    baseUrl = "http://localhost:8080";
  } else {
    baseUrl = uiConfig[namespace].catalogManagerUrl;
  }
  baseLoginUrl = uiConfig[namespace].dealerPortalLoginUrl;
  baseImgUrl = uiConfig[namespace].catalogImageUrl;
  consumerPortalUrl = uiConfig[namespace].consumerPortalUrl;
}

export function loadUiConfig(checkModuleAccess) {
  initializeUiConfig(uiConfig);
  createXmmAxios();
  checkModuleAccess();
  // const { origin } = window.location;
  // const url = `${origin}/data/UIConfig.json`;
  // axios
  //   .get(url)
  //   .then(response => {
  //     console.log(response);
  //     initializeUiConfig(response.data);
  //     createXmmAxios();
  //     checkModuleAccess();
  //   })
  //   .catch(error => {
  //     toast.error("Error loading UiConfig", {
  //       closeOnClick: true
  //     });
  //   });
}

function createXmmAxios() {
  xmmAxios = axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: "application/json",
      "catalog-locale": getLocaleString(),
      // "Accept-Language": getLocaleString(),
      "Content-Type": "application/json"
    }
  });

  // Global axios defaults
  xmmAxios.defaults.headers.post["Content-Type"] = "application/json";
  // Now all requests using this instance will wait 2.5 seconds before timing out
  // xmmAxios.defaults.timeout = 5000;
  // xmmAxios.defaults.crossDomain = true;

  // instance.interceptors.request...
  // Add a request interceptor
  xmmAxios.interceptors.request.use(
    request => {
      request.startTime = new Date();
      // console.log("xmmAxios request", request);
      // Edit request config
      return request;
    },
    error => {
      // console.log(error);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor- to handle response and error objects
  xmmAxios.interceptors.response.use(
    function(response) {
      logResponseTime(response.config, response);

      // TODO - we can transform raw response to common format, only when each rest API return data{}
      const { status } = response;
      if (status === 200 || status === 201) {
        // console.log("xmmAxios.interceptors.response.status", status);
        return response;
      } else {
        hideBodyMask(); // generic case - remove masking for any failure calls
        trackErrorCode(response);
        throw new Error(response);
      }
    },
    error => {
      // response error handling
      // console.log("xmmAxios error", error);
      if (error.response && error.response.status) {
        const { status } = error.response;
        trackErrorCode(error.response);
        if (status === 503) {
          redirectToDefaultPage();
        } else if (status === 401 || status === 404) {
          return error.response;
        }
        // console.error(`%c Server ERROR: ${error}`, "background: #f00; color: #fff");
        hideBodyMask(); // generic case - remove masking for any failure calls
      }
      throw error;
    }
  );
}

// if any rest api fails, redirect to default page - https://catalog.devxmm.xtimeappsnp.xtime.com
export function redirectToDefaultPage(webKey) {
  let loginUrl = baseLoginUrl;
  if (webKey) {
    loginUrl += "/?pt=cm";
    if (!["prod", "prodaus"].includes(namespace)) {
      loginUrl += `&cmenv=${namespace}`;
    }
    loginUrl += `&webKey=${webKey}`;
  }
  document.location.replace(loginUrl);
}

/* Create instance of axios to support rest endpoint
https://opsmanager.devxmm.nonprod.west2.xtimeappsnp.com/
*/

function getLocaleString() {
  let locale = localStorage.getItem("locale");
  if (!locale) {
    locale = DEFAULT_LOCALE;
  }
  return locale;
}
/* set locole in localStorage */
export function setLocale(locale) {
  localStorage.setItem("locale", locale);
}

// Note: If we want to remove interceptor
// xmmAxios.interceptors.request.eject(reqInterceptor);

/* Method return service URL */
function getURLOnly(str) {
  // return str.split("xtime.com/")[1];
  return str.split("?")[0];
}

/* Track rest API response time in GA  */
function logResponseTime(request, response) {
  let serviceName = "";
  const start = request.startTime;
  const end = new Date().getTime();
  const loadTime = end - start.getTime();
  if (request) {
    serviceName = getURLOnly(request.url);
    const logObj = {
      method: request.method,
      status: response.status,
      serviceName,
      loadTime,
      requestPayload: createRequestPayload(request)
    };

    // console.log("xmmAxios logResponseTime ", request, response);
    gtag.filterServiceName(logObj, response);
  }
}
function createRequestPayload(request) {
  let payload = "";
  if (request.method === "get") {
    payload = !request.params ? "" : JSON.parse(JSON.stringify(request.params));
  } else if (request.method === "post") {
    payload = !request.data ? "" : JSON.parse(JSON.stringify(request.data));
  }
  return payload;
}

/* util to capture Http Errors in GA */
function trackErrorCode(response) {
  const error = httpCodes[response.status];
  const logObj = {
    eventName: "restException",
    method: response.config.method,
    statusCode: response.status,
    message: !error ? "UNKNOWN Error" : error,
    serviceName: getURLOnly(response.config.url)
  };
  gtag.trackError(logObj, response);
}
export function jwtXsession(callback, errorHandler) {
  // console.log("jwtXsession");
  // todo: need to test width Dave Anderson's change
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");
  // const hostname = window.location.hostname;
  if (hostname.indexOf("localhost") === -1) {
    if (accessToken == null || refreshToken == null) {
      // detect pending xsession calls
      const pendingXsessionCalls = localStorage.getItem("pendingXsessionCalls");
      if (!pendingXsessionCalls || pendingXsessionCalls.length === 0) {
        localStorage.setItem("pendingXsessionCalls", []);
      } else {
        // push pending xsession calls to the queue
        pendingXsessionCalls.push({ callback, errorHandler });
        return;
      }
      // need to get accessToken
      // console.log("accessToken/refreshToken are null", accessToken,refreshToken );
      axios
        .post(`${baseLoginUrl}/rest/login/jwt/xsession`, null, {
          withCredentials: true
        })
        .then(response => {
          // console.log("jwtSession.response", response);
          if (
            response.status === 200 &&
            response.data &&
            // response.data.success &&
            response.data.data
          ) {
            accessToken = response.data.data.accessToken;
            refreshToken = response.data.data.refreshToken;
            const timeOffsetInSecs = localStorage.getItem("timeOffsetInSecs");
            if (!timeOffsetInSecs) {
              const decodedAccessToken = jwtDecode(accessToken);
              const currentTimeInSecs = Math.floor(new Date().getTime() / 1000);
              const timeDiffInSecs = decodedAccessToken.iat - currentTimeInSecs;
              localStorage.setItem("timeOffsetInSecs", timeDiffInSecs);
              console.log("timeOffsetInSecs", timeDiffInSecs);
            }
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            callback(response.data.data);
            const pendingXsessionCalls = localStorage.getItem(
              "pendingXsessionCalls"
            );
            if (pendingXsessionCalls) {
              console.log("pendingXsessionCalls", pendingXsessionCalls);
              pendingXsessionCalls.forEach(apiCall => {
                // call
                apiCall.callback(response.data.data);
              });
              localStorage.removeItem("pendingXsessionCalls");
            }
          } else {
            handleSessionExpiration(response.data);
          }
        })
        .catch(error => {
          // handle errors in common place
          // console.log("error", error);
          if (errorHandler) {
            const message =
              !error || error.message === NETWORK_ERROR
                ? xlate("xmm.portal.network.error")
                : error.message;
            errorHandler({
              success: false,
              message
            });
          }
        });
    } else {
      // accessToken and refreshToken exist
      // const decoded = jwtDecode(accessToken);
      // console.log(decoded);
      callback({ accessToken });
    }
  } else {
    // localhost
    callback();
  }
}

export function jwtRefresh(callback, errorHandler) {
  if (hostname.indexOf("localhost") !== -1) {
    if (callback) {
      callback();
    }
    return;
  }
  // console.log("calling jwtRefresh");
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");

  // detect pending refresh calls
  // const pendingRefreshCalls = localStorage.getItem("pendingRefreshCalls");
  // if (!pendingRefreshCalls) {
  //   localStorage.setItem("pendingRefreshCalls", []);
  // } else {
  //   // push pending refresh calls to the queue
  //   pendingRefreshCalls.push({ callback, errorHandler });
  //   return;
  // }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  // NOTE:  do NOT send any custom http header, itw would cause jwt/refresh to choke
  if (refreshToken !== null) {
    headers.Authorization = `Bearer ${refreshToken}`;
  }
  axios({
    url: `${baseLoginUrl}/rest/login/jwt/refresh`,
    method: "get",
    data: {},
    params: {},
    headers
  })
    .then(response => {
      if (
        (response.status === 200 || response.status === 201) &&
        response.data
      ) {
        // console.log(response.data);
        accessToken = response.data.accessToken;
        refreshToken = response.data.refreshToken;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        if (callback) {
          callback();
        }
        // const pendingRefreshCalls = localStorage.getItem("pendingRefreshCalls");
        // if (pendingRefreshCalls) {
        //   console.log("pendingRefreshCalls", pendingRefreshCalls);
        //   pendingRefreshCalls.forEach(apiCall => {
        //     // call
        //     apiCall.callback();
        //   });
        // }
      }
      // localStorage.removeItem("pendingRefreshCalls");
      // if (response.status === 401) {
      //   handleSessionExpiration(response);
      // }
    })
    .catch(error => {
      // failed to get refresh token, let's call /jwt/xsession
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("pendingRefreshCalls");
      // handleSessionExpiration(error);
      jwtXsession(callback, errorHandler);
    });
}

function handleSessionExpiration(eventObject) {
  console.log("handleSessionExpiration", eventObject);
  window.dispatchEvent(
    new CustomEvent("sessioinExpired", {
      detail: { eventObject },
      bubbles: true,
      cancelable: true
    })
  );
}

function getResponseURL(response) {
  if (response && response.request && response.request.responseURL) {
    return response.request.responseURL;
  }
  return "";
}

export function makeSecureRestApi(params, respondHandler, errorHandler) {
  // console.log("makeSecureRestApi", params);
  // const hostname = window.location.hostname;
  if (hostname.indexOf("localhost") === -1) {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      const decodedAccessToken = jwtDecode(accessToken);
      const currentTimeInSecs = Math.floor(
        (new Date().getTime() + 5000) / 1000
      );
      const timeOffsetInSecs = parseInt(
        localStorage.getItem("timeOffsetInSecs"),
        10
      );
      // console.log("decodedAccessToken", decodedAccessToken);
      // console.log(
      //   "makeSecureRestApi",
      //   decodedAccessToken.exp,
      //   currentTimeInSecs,
      //   timeOffsetInSecs
      // );
      if (currentTimeInSecs + timeOffsetInSecs > decodedAccessToken.exp) {
        jwtRefresh(() => {
          makeRestApi(params, respondHandler, errorHandler);
        }, errorHandler);
        return;
      }
    }
  }
  jwtXsession(() => {
    makeRestApi(params, respondHandler, errorHandler);
  }, errorHandler);
}

function incrementRefreshCount(params) {
  if (!params.refreshCount) {
    params.refreshCount = 1;
  }
  params.refreshCount++;
}
function makeRestApi(params, respondHandler, errorHandler) {
  // console.log("calling makeRestApi", params);
  // Http Header
  const accessToken = localStorage.getItem("accessToken");
  const headers = params.headers
    ? params.headers
    : {
        Accept: "application/json",
        "Content-Type": "application/json"
      };
  if (accessToken !== null) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  if (params.dataType === "csv") {
    // alert("Datatype is csv")
    headers["Content-Type"] = "text/csv";
    headers["Accept"] = "text/csv";
  }
  // headers["Accept-Language"] = getLocaleString();
  headers["catalog-locale"] = getLocaleString();
  xmmAxios({
    url: params.url,
    method: params.method,
    data: params.data,
    params: params.params,
    headers,
    responseType: params.responseType
  })
    .then(response => {
      // console.log("response.status", response.status);
      if (response.status === 200 || response.status === 201) {
        respondHandler(response.data, response.headers);
      } else if (response.status === 401) {
        const responseURL = response ? getResponseURL(response) : "";
        if (responseURL.indexOf(DealerCheck) !== -1) {
          xlate("xmm.portal.common.unauthorized_dealer");
        } else if (responseURL.indexOf(UserCheck) !== -1) {
          xlate("xmm.portal.common.unauthorized_user");
        } else {
          // refresh access and refresh token
          jwtRefresh(() => {
            incrementRefreshCount(params);
            if (params.refreshCount > MAX_REFRESH_CALLS) {
              xlate("xmm.portal.common.unauthorized_user");
              return response.status;
            }
            makeRestApi(params, respondHandler, errorHandler);
          }, errorHandler);
        }
      }
      return response.status;
    })
    .catch(error => {
      // handle errors in common place
      // refresh access and refresh token
      // console.log("catch error at url", params.url);
      if (errorHandler) {
        const responseURL =
          error && error.response ? getResponseURL(error.response) : "";
        if (responseURL.indexOf(DealerCheck) !== -1) {
          // handleFatalApplicationAccess("dealer");
          // } else if (responseURL.indexOf(UserCheck) !== -1) {
          xlate("", error.response.data.message || error.response.data);
          // errorHandler(error);
        } else {
          if (error && error.message === NETWORK_ERROR) {
            error.message = xlate("xmm.portal.network.error");
          }
          errorHandler(error);
        }
      }
      return Promise.reject(error);
    });
}

export function throwGenericError(response, message) {
  let computedMessage = `request to ${response.config.url} failed`;
  if (!message) {
    computedMessage += " with no message";
  } else {
    computedMessage += ` with message: ${message}`;
  }
  throw new Error(computedMessage);
}

/**
 * This util to apply masking effect on full page
 */
export function showBodyMask() {
  const rootElement = document.getElementById("bodymask");
  const name = "xmm-loading-mask";
  const arr = rootElement.className.split(" ");
  if (arr.indexOf(name) === -1) {
    rootElement.className += " " + name;
  }
}
// Util to remove masking effect on full page
export function hideBodyMask() {
  const rootElement = document.getElementById("bodymask");
  rootElement.className = rootElement.className.replace(
    /\bxmm-loading-mask\b/g,
    ""
  );
}

export function getMakeBrandLogoUrl(make, variant) {
  const brandLogoUrl =
    make && variant
      ? `${baseImgUrl}/oe/xmm/getBrandLogo?make=${make}&variant=${variant}&locale=US_EN&key=DEFAULT_LOGO`
      : // `${baseUrl}/ops/proxyapi/internaloeproxy/xmm/getBrandLogo?make=${make}&variant=${variant}&locale=US_EN&key=DEFAULT_LOGO`
        "";
  return brandLogoUrl;
}

export function getVehicleImage(make, model, year, countryCode) {
  const imageUrl = `${baseImgUrl}/oe/getVehicleImage?make=${encodeURI(
    make
  )}&model=${encodeURI(model)}&year=${year}&countryCode=${countryCode}`;
  return imageUrl;
}

/**
 * returns loginUrl based on environment
 * @returns {string}
 */
export function getLoginUrl() {
  return baseLoginUrl;
}

export default xmmAxios;
/* eslint-enable no-console */
