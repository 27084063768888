/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import NumericInput from "@cx/ui/NumericInput";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import SelectInput from "@cx/ui/SelectInput";
import Tooltip from "@cx/ui/Tooltip";
import { AppContext } from "../../../../app-context";
import { DrivingConditionOptions } from "../../../../../constants/ModuleConstants";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../commonUtil/utils/string";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class AdvancedRow extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onChangeSkipTrim = this.onChangeSkipTrim.bind(this);
    this.onDefaultDrivingConditionChange = this.onDefaultDrivingConditionChange.bind(
      this
    );
    this.onChangeInput = this.onChangeInput.bind(this);
    const { catalog } = props;
    const {
      make,
      skipTrim,
      defaultDrivingCondition,
      showDrivingConditions,
      annualMileage,
      menuMileageTolerance,
      menuMonthTolerance
    } = catalog;
    const { selectableDrivingConditionsMap } = context;
    const drvCondMap = selectableDrivingConditionsMap[make];
    const selectableDriviingConditionsByMake = drvCondMap
      ? drvCondMap.drivingConditions
      : [];
    const drivingCondtionOptions = selectableDriviingConditionsByMake
      ? DrivingConditionOptions.filter(item =>
          selectableDriviingConditionsByMake.includes(item.value)
        )
      : [];
    this.state = {
      skipTrim,
      showTrim: skipTrim,
      drivingCondtionOptions,
      showDrivingConditions,
      defaultDrivingCondition: toEmptyStringIfUndefined(
        defaultDrivingCondition
      ),
      annualMileage,
      menuMileageTolerance,
      menuMonthTolerance,
      errors: {}
    };
  }
  componentDidMount() {}

  onChangeSkipTrim = event => {
    const target = event.target;
    const { name } = target;
    /* US348077 - skipTrim=0 => check UI field; skipTrim=1 => uncheck UI field */
    const newValue = target.checked ? 0 : 1;
    this.setState({ showTrim: newValue });
    const { catalog, onUpdate } = this.props;
    catalog[name] = newValue;
    const payload = {
      [name]: newValue
    };
    onUpdate(catalog, name, payload);
  };

  onDefaultDrivingConditionChange = (cxEvent, isValid) => {
    const { name, value } = cxEvent.target;
    const { catalog, onUpdate } = this.props;
    this.setState({ [name]: value });
    catalog[name] = value;
    const payload = {
      [name]: value
    };
    onUpdate(catalog, name, payload);
  };

  // CX BUG - blur event not exposed; Hence handle blur event inside onChange()
  onChangeInput = (event, isValid, domEvent) => {
    const { name, value } = event.target;
    if (domEvent && domEvent.type === "blur") {
      if (isDifferentValue(this.state[name], value)) {
        console.log(`name: ${name} value: ${value}`);
        const { errors } = this.state;
        errors[name] = this.getError(name, value);
        this.setState({ errors, [name]: value });
        if (!errors[name]) {
          const { catalog, onUpdate } = this.props;
          catalog[name] = value;
          const payload = {
            [name]: value
          };
          onUpdate(catalog, name, payload);
        }
      }
    }
  };

  getError(name, value) {
    const newValue = toEmptyStringIfUndefined(value) !== "" ? value : "";
    if (newValue === "") {
      return "";
    }
    let errorMsg = "";
    switch (name) {
      case "annualMileage":
        if (parseInt(newValue, 10) > 50000) {
          errorMsg = xlate("xmm.portal.errors.annual_mileage_estimate");
        }
        break;
      case "menuMileageTolerance":
        if (parseInt(newValue, 10) > 10000) {
          errorMsg = xlate("xmm.portal.errors.menu_mileage_tolerance");
        }
        break;
      case "menuMonthTolerance":
        if (parseInt(newValue, 10) > 12) {
          errorMsg = xlate("xmm.portal.errors.menu_month_tolerance");
        }
        break;
      default:
        break;
    }
    return errorMsg;
  }

  renderTrim(localeStrings) {
    const { catalog } = this.props;
    const isAny = catalog.make === "ANY";
    return !isAny ? (
      <div className="xmm-fixed-col">
        <div className="xmm-checkbox-container">
          <input
            className="form-checkbox"
            name="skipTrim"
            id="skipTrim"
            type="checkbox"
            checked={!this.state.showTrim || 0}
            onChange={this.onChangeSkipTrim}
          />
          <span className="xmm-checkmark" />
          <span className="label-checkbox" />
        </div>
      </div>
    ) : (
      <div className="xmm-fixed-col no-border">
        <div className="xmm-colspan-label">
          {localeStrings["xmm.portal.settings.not_applicable_lbl"]}{" "}
          <Tooltip
            htmlId="tipRight"
            tooltipContent={
              localeStrings["xmm.portal.settings.not_applicable_tip"]
            }
            position="right"
          >
            <IconInfoOutline htmlId="iconInfo" />
          </Tooltip>
        </div>
      </div>
    );
  }
  renderDrivingConditions(localeStrings) {
    const { catalog } = this.props;
    const { showDrivingConditions } = this.state;
    const isAny = catalog.make === "ANY";
    return !isAny && showDrivingConditions ? (
      <div className="xmm-fixed-col no-border">
        <div className="xmm-default-driving-condition-field">
          <SelectInput
            htmlId="defaultDrivingCondition"
            // label="Select"
            displayLabel={false}
            name="defaultDrivingCondition"
            onChange={this.onDefaultDrivingConditionChange}
            placeholder=""
            displayPlaceholder={true}
            options={this.state.drivingCondtionOptions}
            value={this.state.defaultDrivingCondition}
          />
        </div>
      </div>
    ) : (
      <div className="xmm-fixed-col no-border">
        <div className="xmm-colspan-label">
          {localeStrings["xmm.portal.settings.not_applicable_lbl"]}{" "}
          <Tooltip
            htmlId="tipRight"
            tooltipContent={
              localeStrings["xmm.portal.settings.not_applicable_tip"]
            }
            position="right"
          >
            <IconInfoOutline htmlId="iconInfo" />
          </Tooltip>
        </div>
      </div>
    );
  }
  renderValueServices(localeStrings) {
    const { isBmwValueServicesEnabled } = this.props;
    const { catalog } = this.props;
    const isAny = catalog.make === "ANY";
    const isBmwValueSvcs =
      (catalog.make === "BMW" || catalog.make === "MINI") &&
      isBmwValueServicesEnabled
        ? true
        : false;
    if (!isBmwValueServicesEnabled) {
      return "";
    }
    return !isAny && isBmwValueSvcs ? (
      <div className="xmm-fixed-col">
        <div className="xmm-checkbox-container">
          <input
            className="form-checkbox"
            name="bmwValueService"
            id="bmwValueService"
            type="checkbox"
            checked={isBmwValueServicesEnabled}
            disabled={true}
            // onChange={this.onChangeSkipTrim}
          />
          <span className="xmm-checkmark" />
          <span className="label-checkbox" />
        </div>
      </div>
    ) : (
      <div className="xmm-fixed-col no-border">
        <div className="xmm-colspan-label">
          {localeStrings["xmm.portal.settings.not_applicable_lbl"]}{" "}
          <Tooltip
            htmlId="tipRight"
            tooltipContent={
              localeStrings["xmm.portal.settings.not_applicable_tip"]
            }
            position="right"
          >
            <IconInfoOutline htmlId="iconInfo" />
          </Tooltip>
        </div>
      </div>
    );
  }

  renderAnnualMileageEstimate(localeStrings) {
    const { catalog } = this.props;
    const { errors, annualMileage } = this.state;
    const isAny = catalog.make === "ANY";
    return !isAny ? (
      <div className="xmm-fixed-col no-border">
        <NumericInput
          htmlId="annualMileage"
          name="annualMileage"
          displayLabel={false}
          layout="horizontal"
          autoInsertCommas
          onChange={this.onChangeInput}
          value={annualMileage || ""}
          inputSuffix={catalog.defaultUnits}
          maxLength={5}
          minLength={0}
          error={errors.annualMileage}
        />
      </div>
    ) : (
      <div className="xmm-fixed-col no-border">
        <div className="xmm-colspan-label">
          {localeStrings["xmm.portal.settings.not_applicable_lbl"]}{" "}
          <Tooltip
            htmlId="tipRight"
            tooltipContent={
              localeStrings["xmm.portal.settings.not_applicable_tip"]
            }
            position="right"
          >
            <IconInfoOutline htmlId="iconInfo" />
          </Tooltip>
        </div>
      </div>
    );
  }
  renderMenuMileageTolerance(localeStrings) {
    const { catalog } = this.props;
    const { errors, menuMileageTolerance } = this.state;
    const isAny = catalog.make === "ANY";
    return !isAny ? (
      <div className="xmm-fixed-col no-border">
        <NumericInput
          htmlId="menuMileageTolerance"
          name="menuMileageTolerance"
          displayLabel={false}
          layout="horizontal"
          autoInsertCommas
          onChange={this.onChangeInput}
          value={menuMileageTolerance || ""}
          inputSuffix={catalog.defaultUnits}
          maxLength={5}
          minLength={0}
          error={errors.menuMileageTolerance}
        />
      </div>
    ) : (
      <div className="xmm-fixed-col no-border">
        <div className="xmm-colspan-label">
          {localeStrings["xmm.portal.settings.not_applicable_lbl"]}{" "}
          <Tooltip
            htmlId="tipRight"
            tooltipContent={
              localeStrings["xmm.portal.settings.not_applicable_tip"]
            }
            position="right"
          >
            <IconInfoOutline htmlId="iconInfo" />
          </Tooltip>
        </div>
      </div>
    );
  }
  renderMenuMonthTolerance(localeStrings) {
    const { catalog } = this.props;
    const { errors, menuMonthTolerance } = this.state;
    const isAny = catalog.make === "ANY";
    return !isAny ? (
      <div className="xmm-fixed-col no-border">
        <NumericInput
          htmlId="menuMonthTolerance"
          name="menuMonthTolerance"
          displayLabel={false}
          layout="horizontal"
          autoInsertCommas
          onChange={this.onChangeInput}
          value={menuMonthTolerance || ""}
          maxLength={2}
          minLength={0}
          error={errors.menuMonthTolerance}
        />
      </div>
    ) : (
      <div className="xmm-fixed-col no-border">
        <div className="xmm-colspan-label">
          {localeStrings["xmm.portal.settings.not_applicable_lbl"]}{" "}
          <Tooltip
            htmlId="tipRight"
            tooltipContent={
              localeStrings["xmm.portal.settings.not_applicable_tip"]
            }
            position="right"
          >
            <IconInfoOutline htmlId="iconInfo" />
          </Tooltip>
        </div>
      </div>
    );
  }
  render() {
    const { localeStrings } = this.context;
    const { catalog } = this.props;
    const trimDiv = this.renderTrim(localeStrings);
    const drivingConditionsDiv = this.renderDrivingConditions(localeStrings);
    const valueServicesDiv = this.renderValueServices(localeStrings);
    const annualMileageDiv = this.renderAnnualMileageEstimate(localeStrings);
    const menuMileageToleranceDiv = this.renderMenuMileageTolerance(
      localeStrings
    );
    const menuMonthToleranceDiv = this.renderMenuMonthTolerance(localeStrings);
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {catalog.make}
        </Col>
        <Col md={10} sm={10} className="xmm-form-content">
          {trimDiv}
          {drivingConditionsDiv}
          {valueServicesDiv}
          {annualMileageDiv}
          {menuMileageToleranceDiv}
          {menuMonthToleranceDiv}
          <div>&nbsp;</div>
        </Col>
      </Row>
    );
  }
}

export default AdvancedRow;
AdvancedRow.propTypes = {
  catalog: PropTypes.object,
  isBmwValueServicesEnabled: PropTypes.bool,
  onUpdate: PropTypes.func
};
