/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../components/app-context";
import AccordionGroup from "@cx/ui/AccordionGroup";
import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";
import SelectionOperationsGridContainer from "./SelectOperationsGridContainer";
import SelectMenuContainer from "./SelectMenuContainer";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
// import { toast } from "@cx/ui/Toast";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../../api/xmmAxios";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import { doesEmpty } from "../../../../../commonUtil/utils/object";

class DealerMenuForm extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.isDirty = this.isDirty.bind(this);
    this.onTotalMenuCount = this.onTotalMenuCount.bind(this);
    this.onTotalOperationCount = this.onTotalOperationCount.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.initializeLocaleValues();
    this.state = {
      intervalName: "",
      intervalId: "",
      metaVehicleFilterId: "",
      metaVehicleScope: "",
      menuTypeId: "",
      operationId: "",
      dirty: false,
      valid: false,
      errors: {},
      totalMenuCount: 0,
      totalOperationCount: 0
    };
  }

  componentDidMount() {
    window.addEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }
  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }
  onTotalMenuCount = totalMenuCount => {
    this.setState({ totalMenuCount });
  };
  onTotalOperationCount = totalOperationCount => {
    this.setState({ totalOperationCount });
  };
  onValidate = () => {
    const { operationGridRef, selectMenuContainerRef } = this.refs;
    const valid =
      selectMenuContainerRef &&
      selectMenuContainerRef.isValid() &&
      operationGridRef &&
      operationGridRef.isValid();
    this.setState({ valid });
    console.log("DealerMenuForm.onValidate", valid);
  };

  validate = () => {
    // const { }
  };

  // validation utils
  // call this for each field change event
  // markDirty(fieldName, validate) {
  //   if (validate) {
  //     const valid = this.validate(fieldName);
  //     this.setState({ dirty: true, valid });
  //   } else {
  //     this.setState({ dirty: true });
  //   }
  // }
  isDirty = () => {
    return (
      this.refs.operationGridRef.isDirty() ||
      this.refs.selectMenuContainerRef.isDirty()
    );
  };

  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    // const {
    //   errors,
    //   make,
    //   menuTypeId,
    //   intervalId,
    //   intervalName,
    //   metaVehicleScope,
    //   metaVehicleFilterId
    // } = this.state;
    // validate for these fields only
  }

  addDealerMenuRules = (selectionlist, selectMenuCriteriaList) => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    showBodyMask();
    let count = selectionlist.length * selectMenuCriteriaList.length;
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/editDealerScheduleRuleP";
    selectionlist.forEach(operation => {
      const { serviceId } = operation;
      selectMenuCriteriaList.forEach(ruleCriteria => {
        const { menuTypeId, interval, vehicleGroup } = ruleCriteria;
        const { dealerCode, make, variant, metaVehicleFilterId } = vehicleGroup;
        const { intervalId, intervalName } = interval;
        const mileages = doesEmpty(intervalId) ? [intervalName] : null;
        const data = {
          dealerScheduleRuleId: "",
          make,
          variant,
          dealerCode,
          serviceId,
          menuTypeId,
          metaVehicleFilterId,
          intervalId,
          mileages
        };
        if (!doesEmpty(intervalId)) {
          delete data.mileages;
        }
        makeSecureRestApi(
          {
            url: restUrl,
            method: "post",
            data
          },
          data => {
            if (data) {
              console.log(data);
              // hideBodyMask();
              // this.gridApi && this.gridApi.hideOverlay();
              // TODO: Replace Row
            }
            count--;
            if (count <= 0) {
              hideBodyMask();
              this.updateStatusBox(this.savedMsg, "success", true);
              this.props.updateDealerMenuGrid();
              this.props.updateContentStatus(selectionlist);
              setTimeout(() => {
                this.props.hideSlider();
              }, 2000);
            }
          },
          error => {
            count--;
            const msg = error["message"]
              ? error.message
              : "There was an error while adding dealer menu rule.  Please try again later.";
            // toast.error(msg, {
            //   closeOnClick: true
            // });
            this.updateStatusBox(msg, "error", false, true);
            // this.gridApi && this.gridApi.showNoRowsOverlay();
            hideBodyMask();
          }
        );
      });
    });
  };

  onCreateRules = event => {
    // const { hideSlider } = this.props;
    // TODO:
    const selectionlist = this.refs.operationGridRef.getSelectionList();
    const selectMenuCriteriaList = this.refs.selectMenuContainerRef.getSelectMenuCriteriaList();
    console.log(selectionlist, selectMenuCriteriaList);
    this.addDealerMenuRules(selectionlist, selectMenuCriteriaList);
    // hideSlider(event);
  };

  renderSelectOperationAccordionHeader = () => {
    const { localeStrings } = this.context;
    const { totalOperationCount } = this.state;
    // const totalDisplay = totalOperationCount > 0 ? () : "";
    const title = localeStrings["xmm.portal.dealermenus.select_operations"];
    return (
      <div>
        <span>{title}</span>
        <Badge htmlId="badgeBlue" color="blue" className="pull-right">
          {totalOperationCount}
        </Badge>
      </div>
    );
  };
  renderSelectMenuAccordionHeader = () => {
    const { localeStrings } = this.context;
    const { totalMenuCount } = this.state;
    // const totalDisplay = totalOperationCount > 0 ? () : "";
    const title = localeStrings["xmm.portal.dealermenus.select_menu"];
    return (
      <div>
        <span>{title}</span>
        <Badge htmlId="badgeBlue" color="blue" className="pull-right">
          {totalMenuCount}
        </Badge>
      </div>
    );
  };
  renderCreateRuleButtonLabel = () => {
    const { localeStrings } = this.context;
    const { totalMenuCount, totalOperationCount, valid } = this.state;
    const totalRules = totalMenuCount * totalOperationCount;
    // const ruleText = totalRules === 1 ? "Rule" : "Rules";
    return totalRules === 0 || !valid
      ? localeStrings["xmm.portal.dealermenus.create_rules"]
      : localeStrings["xmm.portal.common.create_x_rules"].replace(
          "%1",
          totalRules
        );
  };
  renderCreateRuleSummary = () => {
    const { totalMenuCount, totalOperationCount, valid } = this.state;
    const totalRules = totalMenuCount * totalOperationCount;
    return totalRules === 0 || !valid
      ? ""
      : xlate("xmm.portal.common.create_x_rules_for_y_operations")
          .replace("%1", totalMenuCount)
          .replace("%2", totalOperationCount);
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    const { make, menuTypes } = this.props;
    const { valid } = this.state;
    const selectOperationAccordionHeader = this.renderSelectOperationAccordionHeader();
    const selectMenuAccordionHeader = this.renderSelectMenuAccordionHeader();
    const createRuleButtonLabel = this.renderCreateRuleButtonLabel();
    const createRuleSummary = this.renderCreateRuleSummary();
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    return (
      <React.Fragment>
        <div className="xmm-tab-bar">
          {statusBox}{" "}
          <span className="xmm-creating-rules-label">{createRuleSummary}</span>
          <Button
            htmlId="createRules"
            className=""
            size="small"
            form="opsForm"
            buttonStyle="primary"
            disabled={!valid}
            onClick={this.onCreateRules}
          >
            {createRuleButtonLabel}
          </Button>
        </div>
        <div className="xmm-slider-tab-scroll comment-form">
          <AccordionGroup.Container
            htmlId="dealerMenuOperationAccordionGroup"
            independent
          >
            <AccordionGroup.Item
              header={selectOperationAccordionHeader}
              eventKey="1"
              htmlId="dealerMenuOperationAccordion"
              defaultExpanded
            >
              <SelectionOperationsGridContainer
                ref="operationGridRef"
                make={make}
                menuTypes={menuTypes}
                onTotalCount={this.onTotalOperationCount}
                onValidate={this.onValidate}
              />
            </AccordionGroup.Item>
          </AccordionGroup.Container>
          <AccordionGroup.Container
            htmlId="dealerRuleConfigAccordionGroup"
            independent
          >
            <AccordionGroup.Item
              header={selectMenuAccordionHeader}
              eventKey="2"
              htmlId="dealerRuleConfigAccordion"
            >
              <SelectMenuContainer
                ref="selectMenuContainerRef"
                make={make}
                menuTypes={menuTypes}
                onTotalCount={this.onTotalMenuCount}
                onValidate={this.onValidate}
              />
            </AccordionGroup.Item>
          </AccordionGroup.Container>
        </div>
      </React.Fragment>
    );
  }
}

export default DealerMenuForm;

DealerMenuForm.propTypes = {
  make: PropTypes.string,
  menuTypes: PropTypes.array,
  hideSlider: PropTypes.func,
  updateDealerMenuGrid: PropTypes.func,
  updateContentStatus: PropTypes.func
};

/* eslint-enable no-console */
