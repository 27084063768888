/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import SearchableSelect from "@cx/ui/SearchableSelect";
import {
  makeSecureRestApi,
  hideBodyMask,
  showBodyMask
} from "../../../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import { AppContext } from "../../../../../app-context";
import { xlate } from "../../../../../../commonUtil/i18n/locales";

export default class CopyToOperationModal extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.createDealerRules = this.createDealerRules.bind(this);
    this.initializeOperations = this.initializeOperations.bind(this);
    this.loadDealerOperations = this.loadDealerOperations.bind(this);
    this.initializeLocaleValues();
    this.state = {
      serviceId: "",
      dealerOperations: [],
      serviceIdMap: {}
    };
  }

  componentDidMount() {
    this.initializeOperations();
  }
  initializeLocaleValues() {
    this.selectOperationLabel = xlate("xmm.portal.common.select_operation");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }

  onChange = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (domEvent && domEvent.type === "blur") {
      return;
    }
    const { serviceId } = this.state;
    if (serviceId !== optionValue) {
      this.setState({ serviceId: optionValue });
    }
  };

  initializeOperations = () => {
    const { dealerScheduleRules } = this.props;
    const serviceIdMap = {};
    dealerScheduleRules.forEach(rule => {
      serviceIdMap[rule.serviceId.toString()] = true;
    });
    const { menuTypeId } = dealerScheduleRules[0];
    this.setState({ menuTypeId, serviceIdMap }, () => {
      this.loadDealerOperations();
    });
  };
  getPayload = () => {
    const { serviceId } = this.state;
    const { dealerScheduleRules } = this.props;
    const ruleIds = dealerScheduleRules.map(rule => {
      return rule.dealerScheduleRuleId.toString();
    });
    return { dealerScheduleRuleIds: ruleIds, serviceId };
  };
  createDealerRules = () => {
    const { updateStatusBox } = this.props;
    updateStatusBox(this.savingMsg, "pending", false);
    const data = this.getPayload();
    const method = "post";
    const url = "/ops/proxyapi/ddsproxy/rest/proc/copyToOperation";
    makeSecureRestApi(
      {
        url,
        method,
        data
      },
      dataList => {
        console.log(dataList);
        this.props.updateDealerMenuGrid();
        updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  loadDealerOperations = () => {
    const { dealerCode, makeVariantMap } = this.context;
    const { make, dealerScheduleRules } = this.props;
    const { variant } = makeVariantMap[make];
    const dealerScheduleRuleIds = dealerScheduleRules.map(rule => {
      return rule.serviceId.toString();
    });
    showBodyMask();
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/table/dealerOperation",
        method: "get",
        params: { dealerCode, make, variant }
      },
      dataList => {
        const dealerOperations = dataList
          .filter(op => {
            return (
              op.serviceKind === "maintenance" &&
              !dealerScheduleRuleIds.includes(op.serviceId.toString())
            );
          })
          .map(m => {
            const { internalName, serviceId } = m;
            return {
              label: internalName,
              value: serviceId.toString()
            };
          });
        this.setState({ dealerOperations });
        hideBodyMask();
      },
      error => {
        toast.error(error.message);
        hideBodyMask();
      }
    );
  };

  render() {
    const { closeDialog } = this.props;
    const { serviceId, dealerOperations } = this.state;
    return (
      <div>
        <ModalDialog
          htmlId="bulkCopyModalDialog"
          className="xmm-copy-to-operation-rules-modal"
          show={this.props.show}
          header={
            <ModalDialog.Title>
              <FormattedMessage
                defaultMessage="Copy Schedule Rules to another Operation"
                id="xmm.portal.dealermenus.copy_to_operation"
              />
            </ModalDialog.Title>
          }
          footer={
            <div>
              <Button
                htmlId="cancelCopyButton"
                onClick={closeDialog}
                text="Close Button"
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="xmm.portal.common.cancel_button"
                />
              </Button>
              <Button
                htmlId="okCopyButton"
                onClick={() => {
                  this.createDealerRules();
                  closeDialog();
                }}
                text="Select"
                buttonStyle="primary"
                disabled={!serviceId}
              >
                <FormattedMessage
                  defaultMessage="Copy"
                  id="xmm.portal.common.copy_label"
                />
              </Button>
            </div>
          }
          onHide={this.props.closeDialog}
        >
          <div className="xmm-copy-to-operation-rules-container">
            <SearchableSelect
              // displayPlaceholder={false}
              className="full-row xmm-scrollable-select"
              // ref="typeahead"
              htmlId="operationSelect"
              displayLabel={false}
              label=""
              placeholder={this.selectOperationLabel}
              name="operationSelect"
              enableMultiSelect={false}
              maxHeight={200}
              onChange={this.onChange}
              options={dealerOperations}
              value={serviceId}
            />
          </div>
        </ModalDialog>
      </div>
    );
  }
}

CopyToOperationModal.propTypes = {
  show: PropTypes.bool,
  copyToOperation: PropTypes.func,
  updateDealerMenuGrid: PropTypes.func,
  updateStatusBox: PropTypes.func,
  closeDialog: PropTypes.func,
  make: PropTypes.string,
  dealerScheduleRules: PropTypes.array
};
