/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import IconStar from "@cx/ui/Icons/IconStar";
import IconStarEmpty from "@cx/ui/Icons/IconStarEmpty";
import { toast } from "@cx/ui/Toast";
import { PropTypes } from "prop-types";
import PreviewContext from "../../preview-context";
import {
  getUserFavs,
  addUserFav,
  deleteUserFav,
  existUserPref
} from "./UserPrefs";
// import { isSameValue } from "../../../../../commonUtil/utils/string";

function vehicleTitle(vehicle) {
  const title = vehicle.year + " " + vehicle.make + " " + vehicle.model;
  return title;
}
class VehicleCard extends React.Component {
  static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selected !== prevState.selected) {
      const { selected } = nextProps;
      const { vehicleItemClass, vehicleCardClass } = getVehicleCardStyle(
        selected
      );
      return { selected, vehicleItemClass, vehicleCardClass };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.toggleFavorite = this.toggleFavorite.bind(this);
    const { selected } = props;
    const { vehicleItemClass, vehicleCardClass } = getVehicleCardStyle(
      selected
    );
    this.state = { selected, vehicleItemClass, vehicleCardClass };
  }
  componentDidMount() {
    this.setFavorite();
  }

  setFavorite = () => {
    const { previewSettings } = this.context.appContext;
    const { vehicle } = this.props;
    const { favorites } = previewSettings;
    const { id } = vehicle;
    const isFavorite = existUserPref(favorites, id);
    this.setState({ isFavorite });
  };
  refreshUserFavs = (dealerCode, username, previewSettings) => {
    getUserFavs(dealerCode, username, previewSettings, () => {
      const { onFavorite } = this.props;
      onFavorite();
    });
  };
  toggleFavorite = event => {
    // event.preventDefault();
    event.stopPropagation();
    const { dealerCode, previewSettings } = this.context.appContext;
    const { vehicle } = this.props;
    const { make, id } = vehicle;
    const metaVehicleId = id;
    const { isFavorite } = this.state;
    const addFavorite = !isFavorite;
    this.setState({ isFavorite: addFavorite });
    const username = this.context.appContext.user.userName;
    const userFavs = { username, make, metaVehicleId, dealerCode };
    if (addFavorite) {
      addUserFav(userFavs, () => {
        this.refreshUserFavs(dealerCode, username, previewSettings);
      });
    } else {
      deleteUserFav(userFavs, () => {
        this.refreshUserFavs(dealerCode, username, previewSettings);
      });
    }
  };
  renderFavoriteIcon = isFavorite => {
    return isFavorite ? (
      <IconStar htmlId="IconFavorite" />
    ) : (
      <IconStarEmpty htmlId="IconFavorite" />
    );
  };
  checkCappedPricing = (makeObj, search) => {
    const regDate =
      makeObj.hasOwnProperty("cappedPricing") && makeObj.cappedPricing === 1
        ? new Date("01/01/" + search.year.toString())
        : "";
    return regDate;
  };
  createSearch(vehicle) {
    const {
      make,
      model,
      year,
      trim,
      engineSize,
      engineType,
      driveType,
      transmissionType
    } = vehicle;
    return {
      vin: "",
      make,
      model,
      year,
      trim,
      engineSizeType: `${engineSize}|${engineType}`,
      driveType,
      transmissionType,
      drivingCondition: "",
      isPreview: false
    };
  }
  // dispatchPreview = vehicle => {
  //   const { updatePreviewSearch } = this.context.appContext;
  //   const search = this.createSearch(vehicle);
  //   updatePreviewSearch(search);
  //   window.dispatchEvent(
  //     new CustomEvent("previewWithParams", {
  //       detail: {
  //         drivingCondition: "",
  //         isPreview: false,
  //         vehicle
  //       },
  //       bubbles: true,
  //       cancelable: true
  //     })
  //   );
  // };

  render() {
    const { index, vehicle } = this.props;
    const { isFavorite, vehicleItemClass, vehicleCardClass } = this.state;
    const favoriteIcon = this.renderFavoriteIcon(isFavorite);
    return (
      <div
        className="xmm-vehicle-full-col"
        onClick={event => {
          const {
            appContext,
            previewVehicle,
            setSelectedVehicleId
          } = this.context;
          const { makeVariantMap } = appContext;
          if (!makeVariantMap[vehicle.make]) {
            toast.warning(`${vehicle.make} is no longer supported.`);
            return;
          }
          this.props.onSelect();
          vehicle.regDate = this.checkCappedPricing(
            makeVariantMap[vehicle.make],
            vehicle
          );
          setSelectedVehicleId(vehicle.id.toString());
          vehicle.isPreview = true;
          previewVehicle(vehicle, []);
        }}
        onMouseOver={event => {
          const { selected } = this.state;
          if (!selected) {
            const vehicleItemClass = "xmm-vehicle-box-hover";
            const vehicleCardClass =
              "xmm-vehicle-card xmm-fav-card xmm-vehicle-card-hover";
            this.setState({ vehicleItemClass, vehicleCardClass });
          }
        }}
        onMouseOut={event => {
          const { selected } = this.state;
          if (!selected) {
            const vehicleItemClass = "xmm-vehicle-box";
            const vehicleCardClass = "xmm-vehicle-card xmm-fav-card";
            this.setState({ vehicleItemClass, vehicleCardClass });
          }
        }}
      >
        <div className={vehicleItemClass}>
          <div className={vehicleCardClass}>
            <div
              className="vehicle-image"
              style={{ backgroundImage: `url(${vehicle.image})` }}
            />

            {/* <img
              className="vehicle-img"
              alt={vehicleTitle(vehicle)}
              src={vehicle.image}
            /> */}
            <div className="xmm-vehicle-description">
              <strong>{vehicleTitle(vehicle)}</strong>
              <div>
                {vehicle.trim} {vehicle.engineType} {vehicle.engineSize}{" "}
                {vehicle.driveType} {vehicle.transmissionType}
              </div>
            </div>
            <div className="xmm-icon-col">
              <Button
                htmlId={`favoriteBtn${index}`}
                className="btn--icon"
                aria-label="Add"
                buttonStyle="link"
                size="sm"
                onClick={this.toggleFavorite}
              >
                {favoriteIcon}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleCard;

VehicleCard.propTypes = {
  index: PropTypes.number,
  vehicle: PropTypes.object,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onFavorite: PropTypes.func
  /*
  vehicle: shape({
    metaVehicleId: string,
    image: string,
    make: string,
    model: string,
    year: string,
    trim: string,
    engineType: string,
    engineSize: string,
    driveType: string,
    transmissionType: string
  })
  */
};

function getVehicleCardStyle(selected) {
  const vehicleItemClass = selected
    ? "xmm-vehicle-box-selected"
    : "xmm-vehicle-box";
  const vehicleCardClass = selected
    ? "xmm-vehicle-card xmm-fav-card xmm-vehicle-card-hover "
    : "xmm-vehicle-card xmm-fav-card";
  return { vehicleItemClass, vehicleCardClass };
}

/* eslint-enable no-console */
