/* eslint-disable no-console */

/* This objects are freezed, used for cell renderer  */
export const YesNoMap = {
  "0": "NO",
  "1": "YES",
  null: "NO"
};

/* This objects are freezed, used for cell renderer  */
export const YesNoDefault = {
  "0": "NO",
  "1": "YES"
};

/* This objects are freezed, used for cell renderer  */
export let DaysMap = {
  "1": "1 Day",
  "2": "2 Days",
  "3": "3 Days",
  "4": "4 Days",
  "5": "5 Days",
  "6": "6 Days",
  "7": "7 Days"
};
// Place holder - not used yet
export const DaysArray = [
  { "1": "1 Day" },
  { "2": "2 Days" },
  { "3": "3 Days" },
  { "4": "4 Days" },
  { "5": "5 Days" },
  { "6": "6 Days" },
  { "7": "7 Days" }
];

export let ServiceKindMap = {
  repair: "Repair1",
  maintenance: "Maintenance1",
  autorecall: "Auto Recall1",
  declined: "Declined1"
};

export const MVC_CHOOSE_VEHICLES = "0";
export const MVC_ALL_VEHICLES = "1";

export const MetaVehicleScopeMap = {
  "-1": "None",
  "3": "Factory Assigned",
  "1": "All Vehicles",
  "0": "Choose Vehicles",
  "4": "Unscheduled Only",
  "2": "All except Supported Makes"
};

export const MetaVehicleScopeAnyCatalogMap = {
  "-1": "None",
  "1": "All Vehicles",
  "2": "All except Supported Makes"
};

export const MetaVehicleScopeVariantCatalogMap = {
  "-1": "None",
  "3": "Factory Assigned",
  "1": "All Vehicles",
  "0": "Choose Vehicles",
  "4": "Unscheduled Only"
};

export const MetaVehicleScopeDealerCatalogMap = {
  "-1": "None",
  "1": "All Vehicles",
  "0": "Choose Vehicles", // ???
  "4": "Unscheduled Only"
};

export let EnabledDisabledOptions = [
  { value: "1", label: "Enabled" },
  { value: "0", label: "Disabled" }
];

export let ApplicationVisibilityOptions = [
  { value: "0", label: "All" },
  { value: "1", label: "Internal only" },
  { value: "-1", label: "None" }
];

export let DrivingConditionOptions = [
  { value: "Normal", label: "Normal" },
  { value: "Severe", label: "Severe" }
];

export const RoundToNone = "0";
export const RoundToCentsOnDollar = "1";
export const RoundToNextNickel = "1a";
export const RoundToNextDime = "2";
export const RoundToNextDollar = "3";
export const RoundToNextTenDollars = "4";

export let RoundToOptions = [
  { value: RoundToNone, label: "None" },
  { value: RoundToCentsOnDollar, label: "Round to Cents on the Dollar" },
  { value: RoundToNextNickel, label: "Round to Next Nickel" },
  { value: RoundToNextDime, label: "Round to Next Dime" },
  { value: RoundToNextDollar, label: "Round to Next Dollar" },
  { value: RoundToNextTenDollars, label: "Round to Ten Dollars" }
];

export const PriceStatusMap = {
  "Default Labor and Parts": "Default Labor and Parts",
  "Default Price Only": "Default Price Only",
  "Has Pricing Overrides": "Has Pricing Overrides",
  "No Pricing": "No Pricing",
  "Value Pricing": "Value Pricing"
};

export const PriceLevel = {
  DMS: "DMS",
  MANUAL: "MANUAL",
  MSRP: "MSRP"
};

export const UnitOfMeasure = {
  Gallons: "Gallons",
  Liters: "Liters",
  Milliliters: "Milliliters",
  Ounces: "Ounces",
  Pints: "Pints",
  Quarts: "Quarts"
};

export const MetaVehicleFilterParams = {
  metaVehicleFilterId: "",
  make: "",
  models: [],
  startYear: "",
  endYear: "",
  trims: [],
  engineTypes: [],
  engineSizes: [],
  driveTypes: [],
  transmissionTypes: [],
  bodyTypes: [],
  fuelTypes: [],
  excludedModels: [],
  excludedYears: [],
  excludedTrims: [],
  excludedEngineTypes: [],
  excludedEngineSizes: [],
  excludedDriveTypes: [],
  excludedTransmissionTypes: [],
  includedSets: [],
  includedSetOp: "",
  excludedSets: [],
  excludedSetOp: "",
  excludedBodyTypes: [],
  excludedFuelTypes: [],
  filterName: "",
  dealerCode: ""
};

/* This object to initialize bulkedit form */
export const initBulkEditOperation = {
  dmsOpcode: "",
  rank: "",
  shopDuration: "",
  price: "",
  priceCaption: "",
  loanerAllowed: "",
  waiterAllowed: "",
  enabled: "",
  reservableByCustomers: "",
  appVisibility: "",
  description: "",
  storeHoursDisplay: "",
  metaVehicleScope: "",
  metaVehicleFilterId: "",
  dealerLaborRateCodeId: "",
  serviceCategoryId: ""
};
export const initBulkEditPartsPricing = {
  source: "",
  price: ""
};
export const initBulkEditFluidsPricing = {
  source: "",
  price: "",
  uom: ""
};
/* Since React gives warning on null values when binded with Form fields,
   convert null values to {""} before Formik field binding
   Usage - This object used for default form state in Add case, close slider
   Default case - Allow waiters should be enabled (ask from ASC team)
*/
export const OperationState = {
  alternateNames: "",
  commentsRequired: null,
  dealerCode: "",
  description: "",
  dmsOpcode: "",
  enabled: 1, // default value {1} for App visibility dropdown
  hasDealerMenu: "",
  internalName: "",
  isDescDealerUpdateable: 1,
  isDurationDealerUpdateable: 1,
  isNameDealerUpdateable: 1,
  isOpcodeDealerUpdateable: 1,
  loanerAllowed: null,
  make: "",
  mandatoryInAppt: null,
  mandatoryInApptUpdateable: 1,
  metaVehicleFilterId: "",
  metaVehicleScope: "1",
  name: "",
  parentId: "",
  price: "",
  priceCaption: "",
  rank: "",
  reservableByCustomers: 1, // default value {1} for App visibility dropdown
  serviceCategoryId: "",
  serviceCategoryName: "",
  serviceHoursCount: "",
  serviceTag: "",
  inspectionItem: "",
  serviceId: "",
  serviceKind: "maintenance",
  shopDuration: "",
  unscheduledDefault: "",
  variant: "",
  waiterAllowed: 1
};

export const CloneOperationState = Object.assign({}, OperationState);

export const PartsPricingState = {
  price: ""
};

// mock response - simpleMetaVehiclefilter
export const vehicleGroupState = {
  metaVehicleFilterId: 1880250,
  make: "ACURA",
  models: ["ILX"],
  startYear: null,
  endYear: null,
  trims: null,
  engineTypes: ["V6"],
  engineSizes: null,
  driveTypes: null,
  transmissionTypes: null,
  includedSets: null,
  includedSetOp: "any",
  excludedModels: null,
  excludedYears: [],
  excludedTrims: null,
  excludedEngineTypes: null,
  excludedEngineSizes: null,
  excludedDriveTypes: null,
  excludedTransmissionTypes: null,
  excludedSets: null,
  excludedSetOp: "any",
  bodyTypes: null,
  fuelTypes: null,
  excludedBodyTypes: null,
  excludedFuelTypes: null,
  description: "Model=ILX, Engine Type=V6",
  name: "V8 Gas"
};
// mock payload - to send for show vehicles widget
export const payloadShowVehicles = {
  make: "ACURA",
  variant: "ACURAUSA_ENH2",
  dealerCode: "XTIMEMOTORS14",
  locale: "en_US",
  countryId: "",
  models: ["MDX"],
  startYear: "",
  endYear: "",
  trims: [],
  engineTypes: ["V6", "I5"],
  engineSizes: [],
  driveTypes: ["AWD", "4WD"],
  transmissionTypes: [],
  bodyTypes: [],
  fuelTypes: [],
  excludedModels: [],
  excludedYears: [],
  excludedTrims: [],
  excludedEngineTypes: [],
  excludedEngineSizes: [],
  excludedDriveTypes: [],
  excludedTransmissionTypes: [],
  includedSets: ["3682", "601"],
  includedSetOp: "any",
  excludedSets: [],
  excludedSetOp: "any",
  excludedBodyTypes: [],
  excludedFuelTypes: []
};
// TODO - add custom cols in ag-grid here- example
export const customCols = [
  {
    headerName: "Log",
    width: 100,
    cellRenderer: params => {
      const button = document.createElement("button");
      button.innerHTML = `Log me`;
      button.addEventListener("click", () => {
        // console.table(params.node.data);
      });
      return button;
    },
    pinned: "right",
    editable: false
  },
  // keep this action column to display status icon in future
  {
    headerName: "",
    field: "serviceId",
    pinned: "left",
    type: "actionColumn",
    cellRenderer(params) {
      const index = Number(params.value);
      if (typeof index !== "number" || isNaN(index)) {
        return;
      } else {
        return '<i class="fas fa-edit"></i>';
      }
    },
    width: 60
  }
];

export const AllMetaVehicleCriteriaList = Object.freeze([
  "Set(s)",
  "Model(s)",
  "Trim(s)",
  "Start Year", // only IS
  "End Year", // only IS, NONE if startYear is NONE
  "Year(s)", // "is in range of" or "is not"
  "Engine Type(s)",
  "Engine Size(s)",
  "Drive Type(s)",
  "Transmission Type(s)",
  "Body Type(s)",
  "Fuel Type(s)"
]);

export const SETS = "Set(s)";
export const MODELS = "Model(s)";
export const TRIMS = "Trim(s)";
export const YEARS = "Year(s)";
export const ENGINE_TYPES = "Engine Type(s)";
export const ENGINE_SIZES = "Engine Size(s)";
export const DRIVE_TYPES = "Drive Type(s)";
export const TRANSMISSION_TYPES = "Transmission Type(s)";
export const BODY_TYPES = "Body Type(s)";
export const FUEL_TYPES = "Fuel Type(s)";

export const AllMetaVehicleCriteriaMinusStartEndYear = Object.freeze([
  SETS,
  MODELS,
  TRIMS,
  YEARS,
  ENGINE_TYPES,
  ENGINE_SIZES,
  DRIVE_TYPES,
  TRANSMISSION_TYPES,
  BODY_TYPES,
  FUEL_TYPES
]);

export const NonUsaMetaVehicleCriteriaMinusStartEndYear = Object.freeze([
  SETS,
  MODELS,
  TRIMS,
  YEARS,
  ENGINE_TYPES,
  ENGINE_SIZES,
  DRIVE_TYPES,
  TRANSMISSION_TYPES
]);

export const MetaVehicleCriteria = Object.freeze({
  sets: [],
  models: [],
  trims: [],
  startYear: "",
  endYear: "",
  years: [], // is in range of OR is not
  engineTypes: [],
  engineSizes: [],
  driveTypes: [],
  transmissionTypes: [],
  bodyTypes: [],
  fuelTypes: []
});

export const MetaVehicleCriterion = Object.freeze({
  name: "",
  operator: "", // "is" or "isnot" or "is in range of"
  values: []
});

export const MetaVehicleFields = Object.freeze([
  "includedSets",
  "excludedSets",
  "models",
  "excludedModels",
  "trims",
  "excludedTrims",
  "startYear",
  "endYear",
  "excludedYears",
  "engineTypes",
  "excludedEngineTypes",
  "engineSizes",
  "excludedEngineSizes",
  "driveTypes",
  "excludedDriveTypes",
  "transmissionTypes",
  "excludedTransmissionTypes",
  "bodyTypes",
  "excludedBodyTypes",
  "fuelTypes",
  "excludedFuelTypes"
]);

export const AddMetaVehicleFilter = Object.freeze({
  make: "",
  models: [""],
  startYear: "",
  endYear: "",
  trims: [""],
  engineTypes: [""],
  engineSizes: [""],
  driveTypes: [""],
  transmissionTypes: [""],
  bodyTypes: [""],
  fuelTypes: [""],
  excludedModels: [""],
  excludedYears: [""],
  excludedTrims: [""],
  excludedEngineTypes: [""],
  excludedEngineSizes: [""],
  excludedDriveTypes: [""],
  excludedTransmissionTypes: [""],
  includedSets: [""],
  includedSetOp: "any",
  excludedSets: [""],
  excludedSetOp: "any",
  excludedBodyTypes: [""],
  excludedFuelTypes: [""],
  filterName: "",
  dealerCode: ""
});

export const FindMetaVehDetWFilter = Object.freeze({
  make: "",
  variant: "",
  dealerCode: "",
  locale: "en_US",
  countryId: "",
  models: [""],
  startYear: "",
  endYear: "",
  trims: [],
  engineTypes: [],
  engineSizes: [],
  driveTypes: [],
  transmissionTypes: [],
  bodyTypes: [],
  fuelTypes: [],
  excludedModels: [],
  excludedYears: [],
  excludedTrims: [],
  excludedEngineTypes: [],
  excludedEngineSizes: [],
  excludedDriveTypes: [],
  excludedTransmissionTypes: [],
  includedSets: [],
  includedSetOp: "any",
  excludedSets: [],
  excludedSetOp: "any",
  excludedBodyTypes: [],
  excludedFuelTypes: []
});

/* Default Interval record used for Add case */
export const IntervalRecordDef = {
  intervalId: "",
  name: "",
  abbreviation: "",
  units: "miles",
  mileageFrom: "",
  mileageTo: "",
  mileageInterval: "",
  mileages: "",
  make: "",
  variant: "",
  usageCount: 0
};

/* Default labor rate record used for Add case */
export const LaborRateRecord = {
  make: "",
  dealerCode: "",
  variant: "",
  dealerLaborRateCodeId: "",
  description: "",
  laborRateCode: "",
  unpublishedPackageRate: "",
  unpublishedStandaloneRate: "",
  publishedPackageRate: "",
  publishedStandaloneRate: ""
};
/* Test record used in BasicGrid prototype */
export const DefaultRecord = {
  alternateNames: "",
  commentsRequired: null,
  dealerCode: "",
  description: "",
  dmsOpcode: "",
  enabled: 1, // default value {1} for App visibility dropdown
  hasDealerMenu: "",
  internalName: "",
  isDescDealerUpdateable: 1,
  isDurationDealerUpdateable: 1,
  isNameDealerUpdateable: 1,
  isOpcodeDealerUpdateable: 1,
  loanerAllowed: null,
  make: "",
  mandatoryInAppt: null,
  mandatoryInApptUpdateable: 1,
  metaVehicleFilterId: "",
  metaVehicleScope: "1",
  name: "",
  parentId: "",
  price: "",
  priceCaption: "",
  rank: "",
  reservableByCustomers: 1, // default value {1} for App visibility dropdown
  serviceCategoryId: "",
  serviceCategoryName: "",
  serviceHoursCount: "",
  serviceId: "",
  serviceKind: "",
  shopDuration: "",
  unscheduledDefault: "",
  variant: "",
  waiterAllowed: null
};

/* Packages - use default record */
export const PackageState = {
  id: "",
  name: "",
  packageType: "",
  make: "",
  intervalId: "",
  intervalName: "",
  menuTypeId: "",
  menuTypeName: "",
  menuTypeDescription: "",
  metaVehicleScope: "1", // All vehicles
  metaVehicleFilterId: "", // value exist when metaVehicleScope = 0
  dmsOpcode: "",
  shopDuration: "",
  price: "",
  priceParts: 0,
  priceLabor: 0,
  startDate: "",
  metaVehicles: "",
  newIntervalMileages: [],
  mileages: {}
};
export const PackageTemplate = {
  requestType: "",
  authId: "",
  name: "",
  make: "",
  dealerCode: "",
  metaVehicleScope: "1", // All vehicles
  metaVehicleFilterId: "",
  metaVehicles: [],
  menuTypeId: null,
  intervalId: null,
  newIntervalMileages: [],
  dmsOpcode: "",
  duration: "",
  shopDuration: "",
  priceParts: 0,
  priceLabor: 0,
  price: null,
  effectiveDate: ""
};
export const PackageEditTemplate = {
  packageId: "",
  name: "",
  make: "",
  dealerCode: "",
  packageType: "",
  metaVehicleScope: "1", // All vehicles
  metaVehicleFilterId: null,
  metaVehicles: [],
  menuTypeId: null,
  intervalId: null,
  newIntervalMileages: [],
  dmsOpcode: "",
  duration: "",
  shopDuration: "",
  priceParts: 0,
  priceLabor: 0,
  price: null,
  effectiveDate: ""
};

export const MenuTypeTemplate = {
  requestType: "",
  authId: "",
  make: "",
  variant: "",
  dealerCode: "",
  name: "",
  description: "",
  benefits: "",
  icon: "",
  extId: null,
  alacarte: 0,
  selectable: 0,
  selectAll: null,
  dealerModifiable: 1,
  inheritPkgOpcodes: 0,
  rank: null,
  includesId: null,
  enabled: 1,
  partsPriceSource: null,
  alternatePricingLevelId: null,
  tags: [],
  locale: null,
  inheritPkgPrices: 0
};

export const MenuTypeEditTemplate = {
  requestType: "",
  authId: "",
  menuTypeId: "",
  make: "",
  variant: "",
  dealerCode: "",
  name: "",
  description: "",
  benefits: "",
  icon: "",
  extId: null,
  alacarte: 0,
  selectable: 0,
  selectAll: null,
  dealerModifiable: 1,
  inheritPkgOpcodes: 0,
  rank: null,
  includesId: null,
  enabled: 1,
  partsPriceSource: null,
  alternatePricingLevelId: null,
  tags: [],
  locale: null,
  inheritPkgPrices: 0
};

export const AboutUsTemplate = {
  dealerName: "",
  dealerCode: "",
  application: "",
  url: "",
  buildVersion: "",
  browser: "",
  operatingSystem: "",
  userName: "",
  copyYear: ""
};

export const SearchFieldTemplate = {
  vin: "",
  make: "",
  year: "",
  model: "",
  trim: "",
  engineSizeType: "",
  engineType: "",
  engineSize: "",
  driveType: "",
  transmissionType: "",
  modelValue: "",
  trimValue: "",
  engineSizeTypeValue: "",
  engineTypeValue: "",
  engineSizeValue: "",
  driveTypeValuee: "",
  transmissionTypeValue: "",
  drivingCondition: "",
  defaultDrivingCondition: "",
  laborTimePrecision: "",
  regDate: "",
  isPreview: true // Default - unpublish mode
};

export const VehicleDescriptor = {
  make: "",
  year: "",
  model: "",
  trim: "",
  engineSizeType: "",
  driveType: "",
  transmissionType: "",
  metaVehicleId: ""
};

export let CreatedByOptions = [
  { label: "All Types", value: "all" },
  { label: "Dealer", value: "dealer" },
  { label: "Factory", value: "factory" }
];

export const TestActionsList = [
  { label: "Drain & Refill", value: "Drain & Refill" },
  { label: "Inspect", value: "Inspect" },
  { label: "Throttle System", value: "Throttle System" }
];

export const BlankSelectMenuCriteria = {
  menuTypeId: "",
  interval: {
    make: "",
    variant: "",
    dealerCode: "",
    intervalId: "",
    intervalName: ""
  },
  vehicleGroup: {
    make: "",
    variant: "",
    dealerCode: "",
    metaVehicleFilterId: "",
    metaVehicleScope: "1"
  }
};

export const AddAnyMakePayload = {
  requestType: "",
  authId: "",
  make: "",
  variant: "",
  dealerCode: "",
  serviceCategoryId: "",
  internalName: "",
  name: "",
  description: "",
  benefits: "",
  icon: "",
  dmsOpcode: "",
  duration: "",
  shopDuration: "",
  priceParts: "",
  priceLabor: "",
  price: "",
  priceAlacarte: "",
  priceFactor: "",
  priceOffset: "",
  parentId: "",
  rank: "",
  serviceKind: "",
  metaVehicleScope: "",
  abbreviation: "",
  waiterAllowed: "",
  loanerAllowed: "",
  reservableByCustomers: 1,
  commentsRequired: "",
  nameDealerUpdateable: "",
  descDealerUpdateable: "",
  opcodeDealerUpdateable: "",
  durationDealerUpdateable: "",
  abbrevDealerUpdateable: "",
  priceCaption: "",
  pricePosition: "",
  alternateText: "",
  mandatoryInAppt: "",
  unscheduledDefault: "",
  enabled: 1,
  dominatedById: "",
  positionId: "",
  extId: "",
  splitFromId: "",
  tags: [],
  locale: "en_US",
  ignoreDurationUnits: 1,
  modUser: ""
};

export const EditAnyMakePayload = {
  requestType: "",
  authId: "",
  serviceId: "",
  make: "ANY",
  serviceCategoryId: "",
  internalName: "",
  name: "",
  description: "",
  benefits: "",
  icon: "",
  dmsOpcode: "",
  duration: "",
  shopDuration: "",
  priceParts: "",
  priceLabor: "",
  price: "",
  priceAlacarte: "",
  priceFactor: "",
  priceOffset: "",
  rank: "",
  serviceKind: "",
  abbreviation: "",
  waiterAllowed: "",
  loanerAllowed: "",
  reservableByCustomers: 1,
  commentsRequired: "",
  nameDealerUpdateable: 1,
  descDealerUpdateable: 1,
  opcodeDealerUpdateable: 1,
  durationDealerUpdateable: 1,
  abbrevDealerUpdateable: 1,
  priceCaption: "",
  pricePosition: "",
  alternateText: "",
  mandatoryInAppt: "",
  unscheduledDefault: "",
  enabled: 1,
  dominatedById: "",
  positionId: "",
  extId: "",
  tags: [],
  locale: "en_US",
  ignoreDurationUnits: 1,
  modUser: ""
};

/* Define width, height for specific components here */
export const COMP_SIZES = Object.freeze({
  MAX_WIDTH: 400,
  MAX_HEIGHT: 150
});

// const newLocal = ["name", "internalName", "description"];
// export const SerivceLocaleKeys = newLocal;

export let CreatedByMap = {
  dealer: "Dealer",
  factory: "Factory"
};

export const IncludedInspectionTags = [
  "inspect",
  "disclaimer",
  "lubricate",
  "reset",
  "tighten",
  "test"
];

export const PartsFluidsPriceColumnIdBySource = {
  DMS: "dmsPrice",
  MSRP: "msrp",
  MANUAL: "price"
};

export const NO_PRICING_ANY_CATALOG = {
  baseLocale: "en_US",
  isStaging: 0,
  make: "ANY",
  label: "ANY",
  value: "ANY",
  pricingMethod: 0,
  variant: "SERVICETAB"
};

export function getCatalogFromMakeVariantMap(makeVariantMap, make) {
  let catalog = makeVariantMap[make];
  if (!catalog && make === "ANY") {
    const dealerCatalogs = Object.values(makeVariantMap);
    const { dealerCode } = dealerCatalogs[0];
    catalog = Object.assign({ dealerCode }, NO_PRICING_ANY_CATALOG);
  }
  return catalog;
}

/* util to translate Static objects  */
export const translateObjects = localeStrings => {
  ServiceKindMap = {
    repair: localeStrings["xmm.portal.common.repair_lbl"],
    maintenance: localeStrings["xmm.portal.common.maintenance_lbl"],
    autorecall: localeStrings["xmm.portal.common.autorecall_lbl"],
    declined: localeStrings["xmm.portal.common.decline_service_lbl"]
  };
  CreatedByMap = {
    dealer: localeStrings["xmm.portal.common.dealer_lbl"],
    factory: localeStrings["xmm.portal.common.factory_lbl"]
  };
  DaysMap = {
    "1": localeStrings["xmm.portal.common.1_day"],
    "2": localeStrings["xmm.portal.common.2_day"],
    "3": localeStrings["xmm.portal.common.3_day"],
    "4": localeStrings["xmm.portal.common.4_day"],
    "5": localeStrings["xmm.portal.common.5_day"],
    "6": localeStrings["xmm.portal.common.6_day"],
    "7": localeStrings["xmm.portal.common.7_day"]
  };

  CreatedByOptions = [
    { label: localeStrings["xmm.portal.common.all_types_lbl"], value: "all" },
    { label: localeStrings["xmm.portal.common.dealer_lbl"], value: "dealer" },
    {
      label: localeStrings["xmm.portal.common.factory_lbl"],
      value: "factory"
    }
  ];
  MetaVehicleScopeMap["-1"] = localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeMap["3"] =
    localeStrings["xmm.portal.common.factory_assigned"];
  MetaVehicleScopeMap["1"] = localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeMap["0"] = localeStrings["xmm.portal.common.choose_vehicles"];
  MetaVehicleScopeMap["4"] =
    localeStrings["xmm.portal.common.unscheduled_only"];
  MetaVehicleScopeMap["2"] =
    localeStrings["xmm.portal.common.all_except_supported_makes"];

  MetaVehicleScopeAnyCatalogMap["-1"] = localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeAnyCatalogMap["1"] =
    localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeAnyCatalogMap["2"] =
    localeStrings["xmm.portal.common.all_except_supported_makes"];

  MetaVehicleScopeVariantCatalogMap["-1"] =
    localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeVariantCatalogMap["3"] =
    localeStrings["xmm.portal.common.factory_assigned"];
  MetaVehicleScopeVariantCatalogMap["1"] =
    localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeVariantCatalogMap["0"] =
    localeStrings["xmm.portal.common.choose_vehicles"];
  MetaVehicleScopeVariantCatalogMap["4"] =
    localeStrings["xmm.portal.common.unscheduled_only"];

  MetaVehicleScopeDealerCatalogMap["-1"] =
    localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeDealerCatalogMap["1"] =
    localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeDealerCatalogMap["0"] =
    localeStrings["xmm.portal.common.choose_vehicles"];
  MetaVehicleScopeDealerCatalogMap["4"] =
    localeStrings["xmm.portal.common.unscheduled_only"];

  EnabledDisabledOptions = [
    { value: "1", label: localeStrings["xmm.portal.common.enabled"] },
    { value: "0", label: localeStrings["xmm.portal.common.disabled"] }
  ];

  ApplicationVisibilityOptions = [
    { value: "0", label: localeStrings["xmm.portal.common.all_lbl"] },
    { value: "1", label: localeStrings["xmm.portal.common.internal_only_lbl"] },
    { value: "-1", label: localeStrings["xmm.portal.common.none"] }
  ];

  DrivingConditionOptions = [
    {
      value: "Normal",
      label: localeStrings["xmm.portal.preview.vehicle.normal_lbl"]
    },
    {
      value: "Severe",
      label: localeStrings["xmm.portal.preview.vehicle.severe_lbl"]
    }
  ];

  RoundToOptions = [
    { value: "0", label: localeStrings["xmm.portal.common.none"] },
    {
      value: "1",
      label: localeStrings["xmm.portal.common.round_to_cents_on_dollar"]
    },
    {
      value: "1a",
      label: localeStrings["xmm.portal.common.round_to_next_nickel"]
    },
    {
      value: "2",
      label: localeStrings["xmm.portal.common.round_to_next_dime"]
    },
    {
      value: "3",
      label: localeStrings["xmm.portal.common.round_to_next_dollar"]
    },
    {
      value: "4",
      label: localeStrings["xmm.portal.common.round_to_next_10_dollars"]
    }
  ];

  PriceStatusMap["Default Labor and Parts"] =
    localeStrings["xmm.portal.common.default_labor_and_parts"];
  PriceStatusMap["Default Price Only"] =
    localeStrings["xmm.portal.common.default_price_only"];
  PriceStatusMap["Has Pricing Overrides"] =
    localeStrings["xmm.portal.common.has_priciing_override"];
  PriceStatusMap["No Pricing"] = localeStrings["xmm.portal.common.no_priciing"];
  PriceStatusMap["Value Pricing"] =
    localeStrings["xmm.portal.common.value_pricing"];

  PriceLevel.DMS = localeStrings["xmm.portal.common.dms_label"];
  PriceLevel.MANUAL = localeStrings["xmm.portal.common.manual"];
  PriceLevel.MSRP = localeStrings["xmm.portal.common.msrp"];

  UnitOfMeasure.Gallons = localeStrings["xmm.portal.common.gallons"];
  UnitOfMeasure.Liters = localeStrings["xmm.portal.common.liters"];
  UnitOfMeasure.Milliliters = localeStrings["xmm.portal.common.milliliters"];
  UnitOfMeasure.Ounces = localeStrings["xmm.portal.common.ounces"];
  UnitOfMeasure.Pints = localeStrings["xmm.portal.common.pints"];
  UnitOfMeasure.Quarts = localeStrings["xmm.portal.common.quarts"];

  YesNoDefault["0"] = localeStrings["xmm.portal.common.no_label"];
  YesNoDefault["1"] = localeStrings["xmm.portal.common.yes_label"];
  YesNoMap["0"] = localeStrings["xmm.portal.common.no_label"];
  YesNoMap["1"] = localeStrings["xmm.portal.common.yes_label"];
  YesNoMap[null] = localeStrings["xmm.portal.common.no_label"];
};

/* eslint-enable no-console */
