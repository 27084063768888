/* eslint-disable no-console */
import React, { Component } from "react";
import { OperationContext } from "../../../operation-context";
import { PropTypes } from "prop-types";
import BaseSettings from "./BaseSettings";
import OverridesGrid from "./OverridesGrid";
import { getCatalogFromMakeVariantMap } from "../../../../../../constants/ModuleConstants";
import * as gtmEvents from "../../../../../utils/gtag-eventlist";
class PricingOpcode extends Component {
  static contextType = OperationContext;

  constructor(props, context) {
    super(props, context);
    const { operation } = props;
    const { make } = operation;
    const { makeVariantMap } = context.appContext;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    this.state = { operation, pricingMethod };
  }

  componentDidMount() {
    // GTM - push pageview event to dataLayer
    gtmEvents.trackGtagPageview("/operations/operations/pricing-opcodes");
  }

  render() {
    const { operation, pricingMethod } = this.state;
    const { make } = operation;

    const baseSettings =
      pricingMethod === 0 ? (
        ""
      ) : (
        <BaseSettings
          operation={operation}
          updatePriceStatus={this.props.updatePriceStatus}
        />
      );
    const overrideGrid =
      make === "ANY" ? (
        ""
      ) : (
        <OverridesGrid
          operation={operation}
          updatePriceStatus={this.props.updatePriceStatus}
        />
      );

    return (
      <div>
        {baseSettings}
        {overrideGrid}
      </div>
    );
  }
}

export default PricingOpcode;

PricingOpcode.propTypes = {
  operation: PropTypes.object,
  updatePriceStatus: PropTypes.func
};
