/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
// import DropdownButton from "react-bootstrap/lib/DropdownButton";
import DropdownMenu from "@cx/ui/DropdownMenu";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import { shouldShowContentPanelUp } from "../../../../../../commonUtil/utils/menu";
import { xlate } from "../../../../../../commonUtil/i18n/locales";

export default class InheritedRuleEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    console.log("InheritedRuleEditor", props);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data } = props;
    return {
      dealerScheduleRule: data,
      highlightAllOnFocus
    };
  }

  onClick = event => {
    console.log("InheritedRuleEditor.onClick", event);
  };

  getValue() {
    return this.state.dealerScheduleRule;
  }

  // for testing
  setValue(newValue) {
    //
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      const menuItemsUp = shouldShowContentPanelUp(
        "#grid-wrapper",
        this.wrapperRef,
        60
      );
      this.setState({
        highlightAllOnFocus: false,
        menuItemsUp
      });
    }
  }

  isPopup() {
    return true;
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  renderInheritedRuleMoreButton = data => {
    const { inheritedCount, serviceId, menuTypeId } = data;
    const { parentHandle } = this.props;
    const { menuItemsUp } = this.state;
    const { showAllInheritedRules, excludeAllInheritedRules } = parentHandle;
    const { context } = parentHandle;
    const { localeStrings } = context;
    const excludeAllInheritedRulesText =
      localeStrings["xmm.portal.dealermenus.exclude_all_inherited_schedules"];
    const showAllInheritedRulesText = xlate(
      "xmm.portal.common.show_x_inherited_schedules"
    ).replace("%1", inheritedCount); // `Show ${inheritedCount} Inherited Schedules`;
    return (
      <DropdownMenu
        buttonStyle="link"
        buttonText={<IconMore />}
        displayCaret={false}
        className="xmm-rule-dropdown-menu"
        dropup={menuItemsUp}
        pullRight
        size="small"
        defaultOpen={true}
      >
        <DropdownMenuItem
          htmlId="excludeAllInteritedRules"
          eventKey={{ eventKey: ["excludeAllInteritedRules"] }}
          onSelect={event => {
            excludeAllInheritedRules(data);
            this.props.api.stopEditing();
          }}
        >
          {excludeAllInheritedRulesText}
        </DropdownMenuItem>
        <DropdownMenuItem
          htmlId="showAllInheritedRules"
          eventKey={{ eventKey: ["showAllInheritedRules"] }}
          onSelect={event => {
            showAllInheritedRules(serviceId.toString(), menuTypeId.toString());
            this.props.api.stopEditing();
          }}
        >
          {showAllInheritedRulesText}
        </DropdownMenuItem>
      </DropdownMenu>
    );
  };
  render() {
    const { dealerScheduleRule } = this.state;
    const inheritedScheduleRuleButton = this.renderInheritedRuleMoreButton(
      dealerScheduleRule
    );
    return (
      <React.Fragment>
        <div ref={this.setWrapperRef}>{inheritedScheduleRuleButton}</div>
      </React.Fragment>
    );
  }
}

InheritedRuleEditor.propTypes = {
  data: PropTypes.object,
  parentHandle: PropTypes.object
};
