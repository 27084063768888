/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import Alert from "@cx/ui/Alert";
import Button from "@cx/ui/Button";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import IconMore from "@cx/ui/Icons/IconMore";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
// import SearchableSelect from "@cx/ui/SearchableSelect";
import SelectInput from "@cx/ui/SelectInput";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import {
  getAllVehicleGroupName,
  navigateToMenuLink
} from "../../../../commonUtil/utils/menu";
// import { isArrayExist } from "../../../../commonUtil/utils/object";
import { nullSetter } from "../../../../commonUtil/utils/valuesetter";
import {
  toEmptyStringIfUndefined,
  isEmptyString,
  isDifferentValue,
  isSameValue
} from "../../../../commonUtil/utils/string";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { DefaultRecord } from "../../../../constants/ModuleConstants";
import DealerMenuForm from "./content/DealerMenuForm";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import CustomTooltip from "../../../../commonUtil/components/reusable/CustomToolTip";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import IntervalEditor from "../../../reusable/editors/IntervalEditor";
import DealerRuleEditor from "./content/editors/DealerRuleEditor";
import ExcludedRuleEditor from "./content/editors/ExcludedRuleEditor";
import InheritedRuleEditor from "./content/editors/InheritedRuleEditor";
import VehicleGroupEditor from "../../../reusable/editors/VehicleGroupEditor";
import ManageIntervalsModal from "../../../reusable/Intervals/ManageIntervalsModal";
import CreateVehicleGroupModal from "../../../reusable/VehicleGroups/CreateVehicleGroupModal";
import ManageVehicleGroupModal from "../../../reusable/VehicleGroups/ManageVehicleGroupModal";
import { toast } from "@cx/ui/Toast";
import CopyToMenuTypeModal from "./content/CopyTo/CopyToMenuTypeModal";
import CopyToOperationModal from "./content/CopyTo/CopyToOperationModal";
import CopyToVehicleGroupModal from "./content/CopyTo/CopyToVehicleGroupModal";
import CopyToIntervalModal from "./content/CopyTo/CopyToIntervalModal";
import ShowInheritedRulesModal from "./content/ShowInheritedRulesModal";
import BulkEditPane from "./content/BulkEditPane";
import { optionListComparator } from "../../../../commonUtil/utils/list";
import PickyFilter from "../../../../commonUtil/filters/PickyFilter";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import XmmTooltip from "./content/XmmTooltip";
import {
  findMakeVehicleGroupMap,
  removeItemFromMap,
  createVehicleGroupOptions,
  getGroupNameList
} from "../../../reusable/VehicleGroups/VehicleGroupUtil";
import { xlate } from "../../../../commonUtil/i18n/locales";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { doesEmpty } from "../../../../commonUtil/utils/object";

class DealerMenusPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    // this.onChangeGroupBy = this.onChangeGroupBy.bind(this);
    this.onPickyChangeGroupBy = this.onPickyChangeGroupBy.bind(this);
    this.onColumnRowGroupChanged = this.onColumnRowGroupChanged.bind(this);
    this.onFilterByMake = this.onFilterByMake.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    // bind other external actions
    this.addActionSlider = this.addActionSlider.bind(this);
    this.hideSlider = this.hideSlider.bind(this);
    this.closeSlider = this.closeSlider.bind(this);
    // this.updateGridAfterSave = this.updateGridAfterSave.bind(this);
    this.updateRules = this.updateRules.bind(this);
    this.openManageIntervalsFromEditor = this.openManageIntervalsFromEditor.bind(
      this
    );
    this.openManageVehicleGroupsFromEditor = this.openManageVehicleGroupsFromEditor.bind(
      this
    );
    this.openCreateVehicleGroupFromEditor = this.openCreateVehicleGroupFromEditor.bind(
      this
    );
    this.onSetNamedVehicleFilter = this.onSetNamedVehicleFilter.bind(this);
    this.handleDeleteDealerScheduleRules = this.handleDeleteDealerScheduleRules.bind(
      this
    );
    this.openDeleteDealerScheduleRulesModal = this.openDeleteDealerScheduleRulesModal.bind(
      this
    );
    this.onIntervalSaved = this.onIntervalSaved.bind(this);
    this.onRemoveInterval = this.onRemoveInterval.bind(this);
    this.onRemoveVehicleGroupFromList = this.onRemoveVehicleGroupFromList.bind(
      this
    );

    // this.copyToAnotherMenuType = this.copyToAnotherMenuType.bind(this);
    // this.copyToAnotherOperation = this.copyToAnotherOperation.bind(this);
    // this.copyToAnotherVehicleGroup = this.copyToAnotherVehicleGroup.bind(this);
    // this.copyToAnotherInterval = this.copyToAnotherInterval.bind(this);
    this.showAllInheritedRules = this.showAllInheritedRules.bind(this);
    this.closeShowAllInheritedRulesModal = this.closeShowAllInheritedRulesModal.bind(
      this
    );
    this.copyDealerRule = this.copyDealerRule.bind(this);
    this.deleteDealerRule = this.deleteDealerRule.bind(this);
    this.excludeAllInheritedRules = this.excludeAllInheritedRules.bind(this);
    this.inheritFromMenuTypeRules = this.inheritFromMenuTypeRules.bind(this);
    this.renderDealerRuleMoreButton = this.renderDealerRuleMoreButton.bind(
      this
    );
    this.openBulkEditSlider = this.openBulkEditSlider.bind(this);
    this.closeBulkEditSlider = this.closeBulkEditSlider.bind(this);
    this.updateGridAfterBulkEdit = this.updateGridAfterBulkEdit.bind(this);
    this.updateContentStatus = this.updateContentStatus.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    const { localeStrings, makelist } = context;
    const { columnDefs, columnDefsMap } = this.getColumnList(localeStrings);
    let selectedMake =
      makelist && makelist.length === 1 ? makelist[0].make : "";
    selectedMake = context.dealerMenusGrid.make;
    this.initializeLocaleValues();
    const gridOptions = {
      // other state props
      make: selectedMake,
      searchKey: context.dealerMenusGrid.searchKey,
      dealerMenusGrid: context.dealerMenusGrid,
      pageTitle: this.pageTitle,
      editOption: null, // set values as edit, add, delete
      hasNoDealerMenus: true,
      allMenuTypes: [],
      menuTypes: [],
      rowData: null,
      dealerRulesMap: {},
      rowRecord: DefaultRecord,
      sliderWidth: 500,
      flexWidth: true,
      showSlide: false,
      // disableAction: false,
      selectionlist: [],
      bulkEditSlide: false,
      bulkEditSliderWidth: 575,
      // ag-grid props
      // rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      columnDefs,
      columnDefsMap,
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        cellClassRules: getCellClassRules(),
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: true,
        sortingOrder: ["asc", "desc", null],
        minWidth: 120,
        autoHeight: true,
        // filter: "agTextColumnFilter",
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        suppressKeyboardEvent: applyCustomKeyNavigation
        // rowGroup: true
      },
      multiSortKey: "ctrl",
      // components: {
      //   actionCellRenderer
      // },
      frameworkComponents: {
        customTooltip: CustomTooltip,
        dealerRuleEditor: DealerRuleEditor,
        excludedRuleEditor: ExcludedRuleEditor,
        inheritedRuleEditor: InheritedRuleEditor,
        intervalEditor: IntervalEditor,
        vehicleGroupEditor: VehicleGroupEditor,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: this.noMenuRulesLabel,
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      // localeText: {
      //   filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
      //   selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
      //   totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
      //   totalAndFilteredRows:
      //     localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
      //   noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      // },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 120,
          minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      rowSelection: "multiple",
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: false
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    this.loadMenuTypesForAllMakes();
    window.addEventListener(
      "openManageIntervalsFromEditor",
      this.openManageIntervalsFromEditor,
      false
    );
    window.addEventListener(
      "openManageVehicleGroupsFromEditor",
      this.openManageVehicleGroupsFromEditor,
      false
    );
    window.addEventListener(
      "openCreateVehicleGroupFromEditor",
      this.openCreateVehicleGroupFromEditor,
      false
    );
    window.addEventListener(
      "removeIntervalFromList",
      this.onRemoveInterval,
      false
    );
    window.addEventListener("intervalSaved", this.onIntervalSaved, false);
    // vehicle group selector events
    window.addEventListener(
      "dealerNameFilterEvent",
      this.onDealerNameFilterEvent,
      false
    );
    window.addEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
    window.addEventListener(
      "closeCreateVehicleModalEvent",
      this.onCloseCreateVehicleGroup,
      false
    );
    window.addEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "openManageIntervalsFromEditor",
      this.openManageIntervalsFromEditor,
      false
    );
    window.removeEventListener(
      "openManageVehicleGroupsFromEditor",
      this.openManageVehicleGroupsFromEditor,
      false
    );
    window.removeEventListener(
      "openCreateVehicleGroupFromEditor",
      this.openCreateVehicleGroupFromEditor,
      false
    );
    window.removeEventListener("intervalSaved", this.onIntervalSaved, false);
    window.removeEventListener(
      "removeIntervalFromList",
      this.onRemoveInterval,
      false
    );
    // vehicle group selector events
    window.removeEventListener(
      "dealerNameFilterEvent",
      this.onDealerNameFilterEvent,
      false
    );
    window.removeEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
    window.removeEventListener(
      "closeCreateVehicleModalEvent",
      this.onCloseCreateVehicleGroup,
      false
    );
    window.removeEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }
  initializeLocaleValues() {
    this.allVehiclesTmpl = xlate("xmm.portal.common.all_make_vehicles");
    this.addRulesLabel = xlate("xmm.portal.common.add_rules");
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.groupByLabel = xlate("xmm.portal.common.group_by");
    this.scheduleRuleGroupByList = [
      {
        label: xlate("xmm.portal.common.group_by_menu_type"),
        value: "menuType"
      },
      {
        label: xlate("xmm.portal.common.group_by_operation"),
        value: "internalName"
      },
      {
        label: xlate("xmm.portal.common.group_by_vehicle_group"),
        value: "metaVehicleFilterName"
      },
      {
        label: xlate("xmm.portal.common.group_by_interval"),
        value: "intervalName"
      }
    ];
    this.inheritFromLabel = xlate("xmm.portal.common.inherit_from");
    this.inheritedSchedulesLabel = xlate(
      "xmm.portal.common.inherited_schedules"
    );
    this.menuTypesLabel = xlate("xmm.portal.nav.menu_types");
    this.messagePart1Label = xlate(
      "xmm.portal.dealermenus.no_enabled_menutypes_1"
    );
    this.messagePart2Label = xlate(
      "xmm.portal.dealermenus.no_enabled_menutypes_2"
    );
    this.messageLabel = xlate("xmm.portal.dealermenus.no_enabled_menutypes");
    this.deleteOneRuleLabel = xlate("xmm.portal.common.deleete_1_rule");
    this.deleteXRulesLabel = xlate("xmm.portal.common.deleete_x_rules");
    this.deleteRulesLabel = xlate("xmm.portal.common.deleete_rules");
    this.deleteInvalidRulesLabel = xlate(
      "xmm.portal.dealermenus.disabled_buttons_tooltip"
    );
    this.confirmDeleteOneRuleLabel = xlate(
      "xmm.portal.common.confirm_delete_1_rule"
    );
    this.confirmDeleteXRulesLabel = xlate(
      "xmm.portal.common.confirm_delete_x_rules"
    );
    this.addRulesLabel = xlate("xmm.portal.common.add_rules");
    this.pageTitle = xlate("xmm.portal.nav.dealermenus");
    this.noMenuRulesLabel = xlate("xmm.portal.common.no_menu_rules");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deletingErrorMsg = xlate("xmm.portal.errors.deleting");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey, make } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const dealerMenusGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey,
        make
      };
      this.setState({
        dealerMenusGrid
      });
      this.context.setDealerMenusGridState(dealerMenusGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const {
      colState,
      filterState,
      pivotState,
      searchKey
    } = this.state.dealerMenusGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      // this.gridApi.setSortModel(sortState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.gridApi.setQuickFilter(searchKey);
    }
  }
  onFirstDataRendered(params) {
    this.restoreGridState();
    this.sizeToFit();
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };

  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(rule) {
    return this.getRuleKey(rule);
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.gridApi.hideOverlay();
  };
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "menuType",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "internalName",
        sortIndex: 1,
        sort: "asc"
      },
      {
        colId: "metaVehicleFilterName",
        sortIndex: 2,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  preselectMake() {
    const { makelist } = this.context;
    let make = this.state.make;
    if (make === "") {
      make = makelist && makelist.length === 1 ? makelist[0].make : "";
    }
    if (make !== "") {
      this.onFilterByMake({ target: { value: make } });
    }
  }
  createDealerRulesMap = (data, skipUpdate) => {
    const dealerRulesMap = {};
    data.forEach(rule => {
      const key = this.getRuleKey(rule);
      if (!skipUpdate) {
        this.updateVehicleAndIntervalData(rule);
      }
      dealerRulesMap[key] = rule;
    });
    return dealerRulesMap;
  };
  createAddUpdateDeleteLists = datalist => {
    const addRuleList = [];
    const updateRuleList = [];
    const deleteRuleList = [];
    const newDealerRulesMap = {};
    // get current rows and rule map
    const { rowData } = this.state;
    const dealerRulesMap = this.createDealerRulesMap(rowData, true);
    datalist.forEach(rule => {
      const key = this.getRuleKey(rule);
      newDealerRulesMap[key] = rule;
      const oldRule = dealerRulesMap[key];
      if (oldRule) {
        // if rule exists in old list then it is an updated rule
        // copy data from new rule to old rule since we need to maintain old object reference for the update
        if (rule.scheduleStatus !== oldRule.scheduleStatus) {
          // Object.assign(oldRule, rule);
          // updateRuleList.push(oldRule);
          updateRuleList.push(rule);
        }
        // if (isDifferentValue(rule.inheritedCount, oldRule.inheritedCount)) {
        //   oldRule.inheritedCount = rule.inheritedCount;
        // }
        // Object.assign(oldRule, rule);
        // updateRuleList.push(oldRule);
        // updateRuleList.push(rule);
      } else {
        // else rule doesn't exist in old list; i.e. it is a new rule
        this.updateVehicleAndIntervalData(rule);
        addRuleList.push(rule);
        rowData.push(rule);
      }
    });
    // check if old rules are deleted; i.e. not in the latest rule list
    for (let index = rowData.length - 1; index >= 0; index--) {
      const rule = rowData[index];
      const key = this.getRuleKey(rule);
      if (!newDealerRulesMap[key]) {
        deleteRuleList.push(rule);
        rowData.splice(index, 1);
      }
    }
    return {
      addRuleList,
      updateRuleList,
      deleteRuleList,
      dealerRulesMap: newDealerRulesMap
    };
  };
  updateVehicleAndIntervalData = rule => {
    const { dealerCode } = this.context;
    const { metaVehicleFilterId } = rule;
    const { make } = this.state;
    rule.dealerCode = dealerCode;
    rule.make = make;
    if (rule.scheduleStatus.indexOf("Inherited") !== -1) {
      // fix up vehical group to same as intervalName,which is "Inherited from ..."
      rule.metaVehicleFilterName = rule.intervalName;
    } else if (rule.scheduleStatus.indexOf("Excluded") !== -1) {
      // set both vehicle group and interval names as Excluded
      rule.intervalName = "Excluded";
      rule.metaVehicleFilterName = "Excluded";
    } else if (isEmptyString(metaVehicleFilterId)) {
      // Update vehicle group name to All Make Vehicles for dealer
      rule.metaVehicleFilterName = getAllVehicleGroupName(
        make,
        this.allVehiclesTmpl
      );
    }
    return rule;
  };
  getRuleKey = rule => {
    const { dealerScheduleRuleId, menuTypeId, serviceId } = rule;
    if (dealerScheduleRuleId) {
      return dealerScheduleRuleId.toString();
    }
    return menuTypeId.toString().concat(serviceId.toString());
  };
  /* Call this API to return content status, price status, laborrateDesc for given operation */
  loadContentAndPriceStatuses = serviceIds => {
    const updatedContentStatuses = [];
    let count = 0;
    const { dealerCode } = this.context;
    serviceIds.forEach(serviceId => {
      makeSecureRestApi(
        {
          url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationsList",
          method: "get",
          data: {},
          params: { dealerCode, serviceId }
        },
        response => {
          // console.log(response);
          count++;
          const contentStatus = response.data[0];
          updatedContentStatuses.push(contentStatus);
          if (count >= serviceIds.length) {
            this.context.updateOperationAfterSave(
              "updateStatuses",
              updatedContentStatuses
            );
          }
        },
        error => {
          count++;
          toast.error(`${error.message} for count=${count}`);
        }
      );
    });
  };
  updateContentStatus = ruleList => {
    const serviceIdMap = {};
    ruleList.forEach(rule => {
      serviceIdMap[rule.serviceId.toString()] = true;
    });
    const serviceIds = Object.keys(serviceIdMap);
    this.loadContentAndPriceStatuses(serviceIds);
  };
  updateRules = (datalist, updateMode) => {
    if (datalist) {
      if (updateMode) {
        const {
          addRuleList,
          updateRuleList,
          deleteRuleList,
          dealerRulesMap
        } = this.createAddUpdateDeleteLists(datalist);

        if (deleteRuleList.length > 0) {
          const res = this.gridApi.applyTransaction({
            remove: deleteRuleList
          });
          console.log("", res);
          this.updateContentStatus(deleteRuleList);
        }
        if (updateRuleList.length > 0) {
          const res = this.gridApi.applyTransaction({
            update: updateRuleList
          });
          console.log("", res);
        }
        if (addRuleList.length > 0) {
          const res = this.gridApi.applyTransaction({
            add: addRuleList
          });
          if (res && res.add && res.add.length !== 0) {
            this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
          }
          console.log("", res);
        }
        // update rule map
        this.setState({ dealerRulesMap });
      } else {
        const dealerRulesMap = this.createDealerRulesMap(datalist);
        // this.setState({ dealerRulesMap });
        this.setState(
          {
            dealerRulesMap,
            rowData: datalist,
            selectionlist: []
          },
          () => {
            this.applySortConfig();
            this.sizeToFit();
          }
        );
        if (datalist.length === 0) {
          // show 'no rows' overlay
          this.gridApi && this.gridApi.showNoRowsOverlay();
        } else {
          // clear all overlays
          this.gridApi && this.gridApi.hideOverlay();
        }
      }
    }
  };
  loadMenuTypesForAllMakes = () => {
    const { dealerCode, makelist, locale } = this.context;
    const dealerModifiable = 1;
    const enabled = 1;
    const makesMap = {};
    makelist.forEach(m => {
      makesMap[m.make] = true;
    });
    // make and variant -- only for proc params
    const make = "";
    const variant = "";
    // showBodyMask();
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedMenuTypes",
        method: "get",
        data: {},
        params: { dealerCode, locale, make, variant, dealerModifiable, enabled }
      },
      allMenuTypes => {
        const makeEnabledMenuTypeMap = {};
        allMenuTypes.forEach(menuType => {
          const { description, make, menuTypeId } = menuType;
          menuType.label = description;
          menuType.value = menuTypeId.toString();
          makeEnabledMenuTypeMap[make] = true;
        });
        this.setState({ makeEnabledMenuTypeMap, allMenuTypes }, () => {
          this.preselectMake();
        });
      },
      error => {
        // hideBodyMask();
        toast.error(error.message);
      }
    );
  };
  updateDealerMenuGrid = () => {
    this.loadGridData(true);
  };
  loadGridData = updateMode => {
    const { dealerCode, locale } = this.context;
    const { make } = this.state;
    const payload = { make, dealerCode, locale };
    const restUrl =
      "/ops/proxyapi/ddsproxy/rest/proc/findAllDealerScheduledOps";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      rules => {
        if (rules) {
          // console.log(data);
          // hideBodyMask();
          this.updateRules(rules, updateMode);
          // this.restoreGridState();
        }
        setTimeout(() => {
          hideBodyMask();
        }, 100);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  };
  // event handler to refresh grid data
  refreshGridData = () => {
    this.loadGridData();
    // if needed update local state
  };

  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function() {
      gridApi.refreshCells(params);
    }, 300);
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    console.log("onCellValueChanged", params.oldValue, params.newValue, params);
    // const field = params.colDef.field;
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // callback to save each cell edited value
      this.onSaveCellEdit(params);
    } else {
      // no changes on cell
      return;
    }
  }
  updateGroupByState = groupsBy => {
    this.setState({ groupsBy }, () => {
      this.sizeToFit();
    });
  };
  onPickyChangeGroupBy = groupsBy => {
    this.updateGroupByState(groupsBy);
    const { columnDefs, columnDefsMap } = this.state;
    let groupName = "";
    columnDefs.forEach(columnDef => {
      columnDef.rowGroup = false;
      columnDef.rowGroupIndex = null;
    });
    groupsBy.forEach((groupBy, index) => {
      const field = groupBy.value;
      const columnDef = columnDefsMap[field];
      columnDef.rowGroup = true;
      columnDef.rowGroupIndex = index;
      groupName = groupBy.label;
    });
    this.gridApi.setColumnDefs(columnDefs);
    this.clearGridSelections();
    this.trackGroupByEvent(groupName);
  };
  trackGroupByEvent = groupName => {
    let tagObj = "";
    if (groupName && groupName !== "") {
      tagObj = {
        eventResult: groupName,
        eventFeature: "Maintenance Menus> Dealer Menus Page ",
        eventFeatures: "dealer menus page> " + groupName,
        trackPageUrl:
          "maintenance-menus/dealer-menus/" + groupName.toLowerCase()
      };
      gtmEvents.gtmTrackEventWithParams(
        "xmm.dealermenus.group_by_click",
        tagObj
      );
    }
  };
  // onChangeGroupBy = (cxEvent, isValid, domEvent) => {
  //   if (domEvent && domEvent.type === "blur") {
  //     return;
  //   }
  //   const { value } = cxEvent.target;
  //   if (!this.gridApi) {
  //     return;
  //   }
  //   this.updateGroupByState(value);
  //   const { columnDefs, columnDefsMap } = this.state;
  //   columnDefs.forEach(columnDef => {
  //     columnDef.rowGroup = false;
  //     columnDef.rowGroupIndex = null;
  //   });
  //   value.forEach((groupBy, index) => {
  //     const field = groupBy.value;
  //     const columnDef = columnDefsMap[field];
  //     columnDef.rowGroup = true;
  //     columnDef.rowGroupIndex = index;
  //   });
  //   this.gridApi.setColumnDefs(columnDefs);
  // };
  onColumnRowGroupChanged(event) {
    // console.log("Column Event: " + event.type, event);
    const { columns } = event;
    const { groupsBy } = this.state;
    if (columns && columns.length !== 0) {
      if (!groupsBy || groupsBy.length !== columns.length) {
        // Somethihg is changed between the group by columns and groupBy list (maybe user drags and drops manually in Row Group panel)
        this.syncGroupByFilters(columns);
      } else {
        // check to see if the order is the same
        this.syncGroupByFilters(columns);
      }
    } else if (groupsBy && groupsBy.length !== 0) {
      this.updateGroupByState([]);
    }
  }
  syncGroupByFilters = columns => {
    const { columnDefsMap } = this.state;
    const groupsBy = columns.map((col, index) => {
      const { colDef } = col;
      const { field } = colDef;
      const columnDef = columnDefsMap[field];
      columnDef.rowGroup = true;
      columnDef.rowGroupIndex = index;
      return this.lookupScheduleRuleGroupByItem(field);
    });
    this.updateGroupByState(groupsBy);
  };
  lookupScheduleRuleGroupByItem = field => {
    for (let index = 0; index < this.scheduleRuleGroupByList.length; index++) {
      if (field === this.scheduleRuleGroupByList[index].value) {
        return this.scheduleRuleGroupByList[index];
      }
    }
    return null;
  };
  onSaveCellEdit(params) {
    const { field } = params.colDef;
    const dealerScheduleRule = params.data;
    this.editDealerScheduleRule(dealerScheduleRule, field);
  }
  /* celledit handler to save edits */
  savePayload(gridParams, value) {
    const record = gridParams.data;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = "/saveapi";
    const payload = this.getCellEditPayload(record, value);
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        if (!response.success) {
          toast.error("There was an error saving your changes.", {
            closeOnClick: true
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  editDealerScheduleRule = (dealerScheduleRule, field) => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { outdata } = dealerScheduleRule;
    if (
      field === "intervalName" &&
      outdata &&
      isDifferentValue(outdata.intervalId, dealerScheduleRule.intervalId)
    ) {
      dealerScheduleRule.intervalId = outdata.intervalId;
      dealerScheduleRule.intervalName = outdata.intervalName;
      delete dealerScheduleRule.outdata;
    }
    if (
      field === "metaVehicleFilterName" &&
      outdata &&
      // !isEmptyString(outdata.metaVehicleFilterId) &&
      isDifferentValue(
        outdata.metaVehicleFilterId,
        dealerScheduleRule.metaVehicleFilterId
      )
    ) {
      dealerScheduleRule.metaVehicleFilterId = outdata.metaVehicleFilterId;
      delete dealerScheduleRule.outdata;
    }

    const {
      menuTypeId,
      serviceId,
      intervalId,
      intervalName,
      dealerCode,
      make,
      variant,
      dealerScheduleRuleId,
      metaVehicleFilterId
    } = dealerScheduleRule;
    const data = {
      dealerScheduleRuleId,
      make,
      variant,
      dealerCode,
      serviceId,
      menuTypeId,
      metaVehicleFilterId: toEmptyStringIfUndefined(metaVehicleFilterId),
      intervalId: toEmptyStringIfUndefined(intervalId),
      mileages: doesEmpty(intervalId) ? [intervalName] : null
    };
    if (!doesEmpty(intervalId)) {
      delete data.mileages;
    }
    // showBodyMask();
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/editDealerScheduleRuleP";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      data => {
        if (data) {
          if (Array.isArray(data) && data.length !== 0) {
            dealerScheduleRule.intervalId = data[0].intervalId;
            this.gridApi.applyTransaction({
              update: [dealerScheduleRule]
            });
          }
        }
        this.updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  };
  copyDealerRule = dealerScheduleRule => {
    // TODO
  };
  deleteDealerRule = dealerScheduleRule => {
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/deleteDealerScheduleRule";
    const { dealerScheduleRuleId } = dealerScheduleRule;
    const params = { dealerScheduleRuleId };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
      },
      data => {
        if (data) {
          console.log(data);
          // TODO: Update Row
        }
        this.updateDealerMenuGrid();
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while deleting scheule rule.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
    gtmEvents.gtmTrackEvent("xmm.dealermenus.deleterule_click");
  };

  handleDeleteDealerScheduleRules = event => {
    this.updateStatusBox(this.deletingMsg, "pending", false);
    const { selectionlist } = this.state;
    if (selectionlist) {
      let count = 0;
      const restUrl =
        "/ops/proxyapi/ddsproxy/rest/proc/deleteDealerScheduleRule";
      selectionlist.forEach(dealerScheduleRule => {
        // const { menuTypeId, serviceId } = dealerScheduleRule;
        const { dealerScheduleRuleId } = dealerScheduleRule;
        // const params = { menuTypeId, serviceId };
        const params = { dealerScheduleRuleId };
        makeSecureRestApi(
          {
            url: restUrl,
            method: "get",
            params
          },
          data => {
            if (data) {
              console.log(data);
              // TODO: Update Row
            }
            count++;
            if (selectionlist.length === count) {
              this.updateStatusBox(this.deletedMsg, "success", true);
              this.updateDealerMenuGrid();
              // toast.success("Selected schedule rules are deleted.");
            }
          },
          error => {
            const msg = error["message"]
              ? error.message
              : this.deletingErrorMsg;
            this.updateStatusBox(msg, "error", false, true);
          }
        );
      });
    }
    gtmEvents.gtmTrackEvent("maintenance-menus/dealer-menus/delete-rules");
    this.closeDeleteDealerScheduleRulesModal();
  };

  getCellEditPayload(record, value) {
    let payload = {};
    payload = {
      dealerCode: record.dealerCode,
      make: record.make
    };
    return payload;
  }

  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };

  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#page-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };
  getCellClassRule() {
    const cellClassRules = {
      // eslint-disable-next-line object-shorthand
      "editable-cell": function(params) {
        const { scheduleStatus } = params.data;
        const grayItalicStyled = scheduleStatus !== "Changed Intervals";
        return grayItalicStyled;
      }
    };
    return cellClassRules;
  }

  getColumnList(localeStrings) {
    const columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: params => {
        //   return params.data["scheduleStatus"] === "Changed Intervals";
        // },
        checkboxSelection: true,
        enableRowGroup: true,
        pinned: "left",
        field: "scheduleId",
        filter: false,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        maxWidth: 37,
        minWidth: 37,
        width: 37
      },
      {
        headerName: localeStrings["xmm.portal.common.menutype"],
        headerClass: "ag-text-header",
        field: "menuType",
        // sort: "asc",
        // sortingOrder: ["asc", "desc"],
        // rowGroup: true,
        // cellClass: cellClassFunction,
        suppressSizeToFit: true,
        minWidth: 260,
        // maxWidth: 200,
        editable: false,
        valueFormatter(params) {
          return params.value;
        },
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.dealermenus.grid.operation"],
        field: "internalName",
        headerClass: "ag-text-header",
        // tooltipField: "serviceName",
        // tooltipComponentParams: { field: "serviceName" },
        // tooltipComponent: "customTooltip",
        // sortingOrder: ["asc", "desc"],
        cellClass: "xmm-wrap-cell",
        // cellClass: cellClassFunction,
        minWidth: 300,
        // maxWidth: 400,
        // width: 400,
        autoHeight: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerClass: "ag-text-header",
        headerName: localeStrings["xmm.portal.common.vehiclegroup"],
        field: "metaVehicleFilterName",
        editable: true,
        // sortingOrder: ["asc", "desc"],
        // cellClass: cellClassFunction,
        valueFormatter: formatNonDealerScheduleHeader,
        cellEditorSelector(params) {
          const { scheduleStatus } = params.data;
          if (scheduleStatus === "Changed Intervals") {
            return {
              component: "vehicleGroupEditor"
            };
          }
          params.api.stopEditing();
          return null;
        },
        cellEditorParams: {
          parentHandle: this
        },
        filter: "agSetColumnFilter",
        filterParams: { buttons: ["clear"] },
        minWidth: 200
        // tooltipField: "metaVehicleFilterName",
        // tooltipComponent: "customTooltip",
        // tooltipComponentParams: { field: "metaVehicleFilterName" }
      },
      {
        headerClass: "ag-text-header",
        headerName: localeStrings["xmm.portal.common.interval"],
        field: "intervalName",
        editable: true,
        valueFormatter: formatNonDealerScheduleHeader,
        // cellClass: cellClassFunction,
        cellEditorSelector(params) {
          const { scheduleStatus } = params.data;
          if (scheduleStatus === "Changed Intervals") {
            return {
              component: "intervalEditor"
            };
          }
          params.api.stopEditing();
          return null;
        },
        cellEditorParams: {
          parentHandle: this
        },
        filter: "agSetColumnFilter",
        filterParams: { buttons: ["clear"] },
        minWidth: 200
        // tooltipField: "intervalName",
        // tooltipComponent: "customTooltip",
        // tooltipComponentParams: { field: "intervalName" }
      },
      {
        headerName: "",
        field: "",
        // tooltipField: "description",
        // tooltipComponentParams: { field: "description" },
        // cellClass: "xmm-wrap-cell",
        pinned: "right",
        editable: true,
        cellClass: "editable-cell",
        cellEditorSelector(params) {
          const { scheduleStatus } = params.data;
          if (scheduleStatus && scheduleStatus.indexOf("Inherited") !== -1) {
            return { component: "inheritedRuleEditor" };
          } else if (
            scheduleStatus &&
            scheduleStatus.indexOf("Changed") !== -1
          ) {
            return { component: "dealerRuleEditor" };
          } else if (
            scheduleStatus &&
            scheduleStatus.indexOf("Excluded") !== -1
          ) {
            return { component: "excludedRuleEditor" };
          }
          params.api.stopEditing();
          return null;
        },
        cellEditorParams: {
          parentHandle: this
        },
        cellRendererFramework: this.renderDealerRuleMoreButton,
        // cellRenderer: "actionCellRenderer",
        enableRowGroup: false,
        maxWidth: 61,
        minWidth: 61,
        width: 61,
        suppressColumnsToolPanel: true,
        valueSetter: nullSetter
        // width: 50,
        // filter: "agTextColumnFilter",
        // filterParams: { buttons: ["clear"] }
      }
    ];
    const columnDefsMap = {};
    columnDefs.forEach(columnDef => {
      const { field } = columnDef;
      columnDefsMap[field] = columnDef;
    });
    return { columnDefs, columnDefsMap };
  }
  // bind to add operation click event
  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: DefaultRecord,
      serviceName: this.addRulesLabel
    }));
    gtmEvents.gtmTrackEvent("xmm.dealermenus.addrules_click");
  };
  /* Hide Handler for Slider to close the drawer
   */
  hideSlider = event => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide
      // editOption: "cancel",
      // rowRecord: DefaultRecord,
      // serviceName: ""
    }));
  };
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSlider = event => {
    const callback = () => {
      this.setState({
        showSlide: false
      });
    };
    const isDirtyFunc = () => {
      return this.refs.dealerMenuFormRef.isDirty();
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showSlide: false
      });
    }
  };
  /* Update Grid rows when new/existing record saved from Form slider
     Add case: Refresh all Grid rows;
     Edit case: Update Grid row with recently saved
  */
  // updateGridAfterSave = (record, editOption) => {
  //   if (this.gridApi && record) {
  //     if (editOption === "add") {
  //       const { rowData } = this.state;
  //       const res = this.gridApi.applyTransaction({
  //         add: [record]
  //       });
  //       // get the row node with ID
  //       const rowNode = this.gridApi.getRowNode(record.serviceId);
  //       this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
  //       rowNode.setSelected(false);
  //       const gridParams = {
  //         force: true,
  //         rowNodes: [rowNode]
  //       };
  //       rowData.push(record);
  //       this.setState({ rowData });
  //       this.callRefreshAfterMillis(gridParams, this.gridApi);
  //     } else if (editOption === "edit") {
  //       const rowNode = this.gridApi.getRowNode(record.serviceId);
  //       rowNode.setData(record);
  //     }
  //   }
  // };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };

  onFilterByMake = changeEvent => {
    const make = changeEvent.target.value;
    this.setState({ make }, () => {
      if (make !== "") {
        const { makeEnabledMenuTypeMap } = this.state;
        const selectionlist = [];
        if (makeEnabledMenuTypeMap && !makeEnabledMenuTypeMap[make]) {
          this.setState({
            hasNoDealerMenus: true,
            rowData: [],
            selectionlist
          });
        } else {
          this.setState({ hasNoDealerMenus: false, selectionlist });
          this.loadGridData();
          this.loadMenuTypes(make);
        }
      }
    });
  };
  loadMenuTypes = make => {
    const { allMenuTypes } = this.state;
    const menuTypes = allMenuTypes
      .filter(menuType => {
        return menuType.make === make;
      })
      .sort(optionListComparator);
    this.setState({ menuTypes });
  };

  openManageIntervalsFromEditor = event => {
    const { selectedInterval } = event.detail;
    this.setState({ selectedInterval, showManageModal: true }, () => {
      this.refs.manageIntervalsModalRef.setSelectedItem(selectedInterval);
    });
  };
  closeManageIntervalsModal = event => {
    this.setState({ showManageModal: false });
  };
  renderManageIntervalsModal = context => {
    const { localeStrings } = context;
    const groupChild = <div />;
    let widget = null;
    if (this.state.showManageModal) {
      widget = (
        <ManageIntervalsModal
          ref="manageIntervalsModalRef"
          show={this.state.showManageModal}
          title={localeStrings["xmm.portal.manage_intervals"]}
          closeModal={event => {
            if (
              context.discardUnsavedChanges(
                event,
                this.closeManageIntervalsModal,
                this.refs.manageIntervalsModalRef.isDirty
              )
            ) {
              this.closeManageIntervalsModal();
            }
          }}
          selectedInterval={this.state.selectedInterval}
          children={groupChild}
        />
      );
    }
    return widget;
  };
  onIntervalSaved = event => {
    event.preventDefault();
    event.stopPropagation();
    const { interval } = event.detail;
    const { make, intervalId } = interval;
    const { intervalsByMake, intervalsMapByMake } = this.context;
    const intervals = intervalsByMake[make];
    let found = false;
    for (let index = 0; index < intervals.length; index++) {
      if (isSameValue(interval.intervalId, intervals[index].intervalId)) {
        intervals[index] = interval;
        found = true;
        break;
      }
    }
    if (!found) {
      // insert into an intervals in intervalsByMake
      intervals.push(interval);
    }
    // insert/update intervalsMapByMake
    const intervalsMap = intervalsMapByMake[make];
    intervalsMap[intervalId.toString()] = interval;
  };
  onRemoveInterval = event => {
    event.preventDefault();
    event.stopPropagation();
    const interval = event.detail;
    const { make, intervalId } = interval;
    const { intervalsByMake, intervalsMapByMake } = this.context;
    const intervals = intervalsByMake[make];
    for (let index = 0; index < intervals.length; index++) {
      if (isSameValue(intervalId, intervals[index].intervalId)) {
        intervals.splice(index, 1);
        break;
      }
    }
    const intervalsMap = intervalsMapByMake[make];
    delete intervalsMap[intervalId.toString()];
  };
  onDealerNameFilterEvent = event => {
    event.preventDefault();
    event.stopPropagation();
    const vehicleGroup = event.detail;
    // event.detail && Object.keys(event.detail).length !== 0
    //   ? event.detail
    //   : null;
    this.setState({ data: vehicleGroup }, () => {
      this.getGroupNameList(vehicleGroup);
    });
  };
  onSetNamedVehicleFilter = event => {
    const { dealerScheduleRule } = this.state;
    if (!dealerScheduleRule) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const { name, metaVehicleFilterId } = event.detail;
    dealerScheduleRule.metaVehicleFilterId = metaVehicleFilterId.toString();
    dealerScheduleRule.metaVehicleFilterName = name;
    this.editDealerScheduleRule(dealerScheduleRule, "metaVehicleFilterName");
  };

  // findMakeVehicleGroupMap(metaVehicleFilter, sortedVehicleGroups) {
  //   const list = sortedVehicleGroups.filter(map => {
  //     return map[metaVehicleFilter.make];
  //   });
  //   return list.length !== 0 ? list[0] : null;
  // }
  // removeItemFromMap(metaVehicleFilter, map) {
  //   const list = map[metaVehicleFilter.make];
  //   if (list) {
  //     const newList = list.filter(item => {
  //       return (
  //         item.metaVehicleFilterId.toString() !==
  //         metaVehicleFilter.metaVehicleFilterId.toString()
  //       );
  //     });
  //     map[metaVehicleFilter.make] = newList;
  //   }
  // }
  onRemoveVehicleGroupFromList = event => {
    const { sortedVehicleGroups } = this.state;
    if (!sortedVehicleGroups) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const metaVehicleFilter = event.detail;
    if (metaVehicleFilter) {
      const { sortedVehicleGroups } = this.state;
      const sortedVehicleGroupsMap = findMakeVehicleGroupMap(
        metaVehicleFilter,
        sortedVehicleGroups
      );
      removeItemFromMap(metaVehicleFilter, sortedVehicleGroupsMap);
      this.setState({ sortedVehicleGroups });
    }
  };

  setVehicleGroupName = makeVehicleGroupsMap => {
    const { make, metaVehicleFilterId, metaVehicleScope } = this.state.data;
    if (metaVehicleScope === "1") {
      this.setState({
        metaVehicleGroupName: getAllVehicleGroupName(make, this.allVehiclesTmpl)
      });
    } else if (metaVehicleFilterId !== "") {
      const vehicleGroups = makeVehicleGroupsMap[make];
      if (vehicleGroups) {
        const matches = vehicleGroups.filter(vg => {
          return !isDifferentValue(vg.metaVehicleFilterId, metaVehicleFilterId);
        });
        if (matches.length !== 0) {
          const vehicleGroup = matches[0];
          const metaVehicleGroupName = vehicleGroup.name
            ? vehicleGroup.name
            : "Missing Vehicle Group Name and Description";
          this.setState({ metaVehicleGroupName });
        }
      }
    }
  };

  handleVehicleGroupCallback = (
    dealerMakeVehiclesGroupMap,
    makeVehicleGroupsMap,
    sortedVehicleGroups
  ) => {
    const vehicleGroupOptions = createVehicleGroupOptions(
      makeVehicleGroupsMap,
      this.state.data.make
    );
    this.setVehicleGroupName(makeVehicleGroupsMap);
    this.setState({
      dealerMakeVehiclesGroupMap,
      makeVehicleGroupsMap,
      sortedVehicleGroups,
      vehicleGroupOptions,
      filteredVehicleGroupOptions: [...vehicleGroupOptions]
    });
    const { vehicleGroupCallbackParams } = this.context;
    const { vehicleGroup } = vehicleGroupCallbackParams;
    if (vehicleGroup && this.refs.manageVehGpModalRef) {
      this.refs.manageVehGpModalRef.setSelectedItem(vehicleGroup);
    }
  };
  /* This call made get named filters for a dealercode
   */
  getGroupNameList(vehicleGroup) {
    const { context } = this;
    const { vehicleGroupCallbackParams } = context;
    const { metaVehicleFilterId } = this.state.data;
    vehicleGroupCallbackParams.vehicleGroupCallback = this.handleVehicleGroupCallback;
    vehicleGroupCallbackParams.vehicleGroup = vehicleGroup;
    getGroupNameList(metaVehicleFilterId, context);
  }

  openManageVehicleGroupsFromEditor = event => {
    const { selectedVehicleGroup, sortedVehicleGroups } = event.detail;
    this.setState(
      {
        selectedVehicleGroup,
        sortedVehicleGroups,
        showManageVehicleGroupsModal: true
      },
      () => {
        this.refs.manageVehGpModalRef.setSelectedItem(selectedVehicleGroup);
      }
    );
  };

  closeManageVehicleGroupsModal = event => {
    this.setState({ showManageVehicleGroupsModal: false });
  };

  renderManageVehicleGroupsModal = context => {
    const groupChild = <div />;
    let widget = null;
    if (this.state.showManageVehicleGroupsModal) {
      widget = (
        <ManageVehicleGroupModal
          ref="manageVehGpModalRef"
          show={this.state.showManageVehicleGroupsModal}
          title={xlate("xmm.portal.vehiclegroups.modal_lbl")}
          closeModal={event => {
            if (
              context.discardUnsavedChanges(
                event,
                this.closeManageVehicleGroupsModal,
                this.refs.manageVehGpModalRef.isDirty
              )
            ) {
              this.closeManageVehicleGroupsModal();
            }
          }}
          sortedVehicleGroups={this.state.sortedVehicleGroups}
          children={groupChild}
        />
      );
    }
    return widget;
  };

  openCreateVehicleGroupFromEditor = event => {
    const { showCreateVehicleGroupModal, sortedVehicleGroups } = event.detail;
    this.setState({ showCreateVehicleGroupModal, sortedVehicleGroups });
  };
  onCloseCreateVehicleGroup = () => {
    this.showCreateVehicleGroupModal(false);
  };
  showCreateVehicleGroupModal = showCreateVehicleGroupModal => {
    this.setState({ showCreateVehicleGroupModal });
  };
  renderCreateVehicleGroupModal = context => {
    let widget = null;
    if (this.state.showCreateVehicleGroupModal) {
      const { dealerCode, makeVariantMap } = context;
      const { make } = this.state;
      const { variant } = makeVariantMap[make];
      widget = (
        <CreateVehicleGroupModal
          ref="createVehGpModalRef"
          show={this.state.showCreateVehicleGroupModal}
          title={xlate("xmm.portal.vehiclegroups.create_lbl")}
          closeModal={event => {
            if (
              context.discardUnsavedChanges(
                event,
                this.onCloseCreateVehicleGroup,
                this.refs.createVehGpModalRef.isDirty
              )
            ) {
              this.onCloseCreateVehicleGroup();
            }
          }}
          dealerCode={dealerCode}
          make={make}
          variant={variant}
          sortedVehicleGroups={this.state.sortedVehicleGroups}
          children={<div />}
        />
      );
    }
    return widget;
  };

  openDeleteDealerScheduleRulesModal = event => {
    const { selectionlist } = this.state;
    if (selectionlist.length > 1) {
      this.deleteMsg = this.confirmDeleteXRulesLabel.replace(
        "%1",
        selectionlist.length
      );
    } else {
      this.deleteMsg = this.confirmDeleteOneRuleLabel;
    }
    this.setState({ showDeleteDealerScheduleRulesModal: true });
  };

  closeDeleteDealerScheduleRulesModal = event => {
    this.setState({ showDeleteDealerScheduleRulesModal: false });
  };

  // openCopyToAnotherMenuType = event => {
  //   this.setState({ showCopyToMenuType: true });
  // };
  // copyToAnotherMenuType = (dealerScheduleRules, menuTypeId) => {
  //   // TODO
  //   console.log(dealerScheduleRules, menuTypeId);
  // };
  // openCopyToAnotherOperation = event => {
  //   this.setState({ showCopyToOperation: true });
  // };
  // copyToAnotherOperation = (dealerScheduleRules, serviceId) => {
  //   // TODO
  //   console.log(dealerScheduleRules, serviceId);
  // };
  // copyToAnotherVehicleGroup = event => {
  //   // TODO
  // };
  // copyToAnotherInterval = event => {
  //   // TODO
  // };
  showAllInheritedRules = (serviceId, menuTypeId) => {
    const showInheritedRules = true;
    this.setState({ showInheritedRules, serviceId, menuTypeId });
  };
  closeShowAllInheritedRulesModal = () => {
    this.setState({
      showInheritedRules: false,
      serviceId: "",
      menuTypeId: ""
    });
  };
  excludeAllInheritedRules = scheduleRule => {
    showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    const { serviceId, menuTypeId } = scheduleRule;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/excludeDealerOperation",
        method: "get",
        params: { menuTypeId, serviceId }
      },
      response => {
        // this.setState({ inheritedRules });
        scheduleRule.scheduleStatus = "Excluded";
        scheduleRule.intervalName = "Excluded";
        scheduleRule.metaVehicleFilterName = "Excluded";
        this.gridApi.applyTransaction({
          update: [scheduleRule]
        });
        this.updateDealerMenuGrid();
        this.gridApi && this.gridApi.hideOverlay();
        hideBodyMask();
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  };
  inheritFromMenuTypeRules = scheduleRule => {
    showBodyMask();
    this.gridApi && this.gridApi.showLoadingOverlay();
    const { serviceId, menuTypeId } = scheduleRule;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/revertDealerOperation",
        method: "get",
        params: { menuTypeId, serviceId }
      },
      response => {
        const { menuType } = scheduleRule;
        scheduleRule.scheduleStatus = `${this.inheritFromLabel} ${menuType}`;
        scheduleRule.intervalName = this.inheritedSchedulesLabel;
        scheduleRule.metaVehicleFilterName = this.inheritedSchedulesLabel;
        scheduleRule.inheritedCount = "";
        this.gridApi.applyTransaction({
          update: [scheduleRule]
        });
        // this.updateDealerMenuGrid();
        this.gridApi && this.gridApi.hideOverlay();
        hideBodyMask();
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  };

  renderCopyToMenuTypeModal = showCopyToMenuType => {
    const { make, menuTypes, selectionlist } = this.state;
    return showCopyToMenuType ? (
      <CopyToMenuTypeModal
        make={make}
        dealerScheduleRules={selectionlist}
        menuTypes={menuTypes}
        show={showCopyToMenuType}
        updateDealerMenuGrid={this.updateDealerMenuGrid}
        updateStatusBox={this.updateStatusBox}
        closeDialog={() => {
          this.setState({ showCopyToMenuType: false });
        }}
        // copyToMenuType={this.copyToAnotherMenuType}
      />
    ) : (
      ""
    );
  };
  renderCopyToOperationModal = showCopyToOperation => {
    const { make, selectionlist } = this.state;
    return showCopyToOperation ? (
      <CopyToOperationModal
        make={make}
        dealerScheduleRules={selectionlist}
        show={showCopyToOperation}
        updateDealerMenuGrid={this.updateDealerMenuGrid}
        updateStatusBox={this.updateStatusBox}
        closeDialog={() => {
          this.setState({ showCopyToOperation: false });
        }}
        // copyToOperation={this.copyToAnotherOperation}
      />
    ) : (
      ""
    );
  };
  renderCopyToVehicleGroupModal = showCopyToVehicleGroup => {
    const { make, selectionlist } = this.state;
    return showCopyToVehicleGroup ? (
      <CopyToVehicleGroupModal
        make={make}
        dealerScheduleRules={selectionlist}
        show={showCopyToVehicleGroup}
        updateDealerMenuGrid={this.updateDealerMenuGrid}
        updateStatusBox={this.updateStatusBox}
        closeDialog={() => {
          this.setState({ showCopyToVehicleGroup: false });
        }}
        // copyToVehicleGroup={this.copyToAnotherVehicleGroup}
      />
    ) : (
      ""
    );
  };
  renderCopyToIntervalModal = showCopyToInterval => {
    const { make, selectionlist } = this.state;
    return showCopyToInterval ? (
      <CopyToIntervalModal
        make={make}
        dealerScheduleRules={selectionlist}
        show={showCopyToInterval}
        updateDealerMenuGrid={this.updateDealerMenuGrid}
        updateStatusBox={this.updateStatusBox}
        closeDialog={() => {
          this.setState({ showCopyToInterval: false });
        }}
        // copyToInterval={this.copyToAnotherInterval}
      />
    ) : (
      ""
    );
  };
  renderShowAllInheritedRulesModal = showInheritedRules => {
    const { localeStrings } = this.context;
    const { serviceId, menuTypeId } = this.state;
    return showInheritedRules ? (
      <ShowInheritedRulesModal
        serviceId={serviceId}
        menuTypeId={menuTypeId}
        localeStrings={localeStrings}
        closeDialog={this.closeShowAllInheritedRulesModal}
      />
    ) : (
      ""
    );
  };
  renderNoEnabledMenuTypesAlert = hasNoDealerMenus => {
    const { make } = this.state;
    if (hasNoDealerMenus) {
      const locationDescriptor = {
        pathname: "/menutypes",
        search: window.location.search
      };
      const menuTypeLabel = this.menuTypesLabel;
      // const messagePart1 =
      //   localeStrings["xmm.portal.dealermenus.no_enabled_menutypes_1"];
      // const messagePart2 =
      //   localeStrings["xmm.portal.dealermenus.no_enabled_menutypes_2"];
      const linkSection = (
        <Link
          to={locationDescriptor}
          // className="float-right small"
          onClick={event => {
            const { updateSelectedMenuType } = this.context;
            updateSelectedMenuType(make);
            navigateToMenuLink("menus", "menutypes");
          }}
        >
          {menuTypeLabel}
        </Link>
      );
      // const message = this.messageLabel.replace("%1", linkSection);
      return (
        <Alert
          htmlId="noEnabledMenuTypesAlert"
          type="warning"
          // displayCloseButton
        >
          <div>
            {this.messagePart1Label} {linkSection} {this.messagePart2Label}
          </div>
        </Alert>
      );
    }
    return "";
  };
  renderDealerRuleMoreButton = params => {
    return <IconMore className="xmm-rule-dropdown-menu-renderer" />;
    // return (
    //   <DropdownMenu
    //     buttonStyle="link"
    //     buttonText={<IconMore />}
    //     displayCaret={false}
    //     className="xmm-rule-dropdown-menu-renderer"
    //     // pullRight
    //     size="small"
    //   />
    // );
  };
  isInheritedOrExcludedRuleSelected = selectionlist => {
    for (let index = 0; index < selectionlist.length; index++) {
      const { scheduleStatus } = selectionlist[index];
      if (
        scheduleStatus.indexOf("Inherited") !== -1 ||
        scheduleStatus.indexOf("Excluded") !== -1
      ) {
        return true;
      }
    }
    return false;
  };
  openBulkEditSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
  };
  closeBulkEditSlider = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
    this.gridApi.redrawRows(this.params);
  };
  updateGridAfterBulkEdit = (selectionlist, columns) => {
    this.gridApi.applyTransaction({ update: selectionlist });
    const rowNodes = selectionlist.map(data =>
      this.gridApi.getRowNode(data.dealerScheduleRuleId)
    );
    this.gridApi.flashCells({ rowNodes, columns });
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  render() {
    const { localeStrings, makelist } = this.context;
    // const makelistFilter = getAllMakeFilters(this.context);

    const {
      hasNoDealerMenus,
      make,
      menuTypes,
      groupsBy,
      selectionlist
    } = this.state;
    const disabled = make === "" || hasNoDealerMenus;
    const rulesSelected = selectionlist && selectionlist.length !== 0;
    const isInheritedOrExcludedRuleSelected = this.isInheritedOrExcludedRuleSelected(
      selectionlist
    );
    const deleteCount = selectionlist.length;
    const deleteSchedulesLabel = rulesSelected
      ? deleteCount > 1
        ? this.deleteXRulesLabel.replace("%1", deleteCount)
        : this.deleteOneRuleLabel
      : this.deleteRulesLabel;
    // const deleteSchedulesLabel = `Delete${selectionCount}`;
    const disabledButtonsTooltip = isInheritedOrExcludedRuleSelected
      ? this.deleteInvalidRulesLabel
      : " ";
    const tooltipTrigger =
      isInheritedOrExcludedRuleSelected && selectionlist.length !== 0
        ? ["hover", "focus"]
        : [];
    const gridClass = make === "" || hasNoDealerMenus ? "hide" : "";
    const gridWidget = (
      <div id="grid-wrapper" className={gridClass}>
        <div id="mainGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            // deltaColumnMode={true} // deprecated - for row grouping
            applyColumnDefOrder={true} // If the desired behaviour is that Columns should be ordered to match the new set of Column Definitions, set the grid property
            suppressMenuHide={false}
            suppressContextMenu={true}
            suppressRowClickSelection={true}
            getRowNodeId={this.getRowNodeId}
            rowData={this.state.rowData}
            singleClickEdit={true}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            isRowSelectable={this.state.isRowSelectable}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            onColumnRowGroupChanged={this.onColumnRowGroupChanged}
            onGridReady={this.onGridReady}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            // getRowNodeId={this.getRowNodeId}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            enableCellChangeFlash={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowHeight={50}
            onFilterChanged={this.onFilterChanged}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    const msgSection = null;
    const header = (
      <React.Fragment>
        {msgSection}
        <div className="content-header">
          <div className="xmm-main-title">
            <h3>{this.state.pageTitle}</h3>
            <SelectInput
              htmlId="makeFilter"
              name="make"
              label="Make"
              className="xmm-dealermenu-make-label"
              placeholder={localeStrings["xmm.portal.common.select_a_make_lbl"]}
              displayPlaceholder={true}
              displayLabel={false}
              layout="horizontal"
              onChange={this.onFilterByMake}
              options={makelist}
              value={this.state.make}
              maxHeight={200}
            />
            {/*
                <SearchableSelect
                  displayPlaceholder={false}
                  placeholder={
                    localeStrings["xmm.portal.common.select_make_label"]
                  }
                  className="xmm-scrollable-select"
                  htmlId="dealerMenuMake"
                  label=""
                  name="make"
                  // disabled={!addMode}
                  enableMultiSelect={false}
                  maxHeight={150}
                  onChange={this.onChange}
                  options={makes}
                  value={menuType.make}
                  displayLabel={false}
                  error={this.state.errors.makeError}
                />
                */}
          </div>
          <div className="xmm-form-header">
            {statusBox}{" "}
            <Button
              htmlId="openBulkeditBtn"
              buttonStyle="primary"
              disabled={!rulesSelected || isInheritedOrExcludedRuleSelected}
              onClick={this.openBulkEditSlider}
              className="xmm-bulkedit-btn"
              hidden={this.state.selectionlist.length < 2}
            >
              <XmmTooltip
                htmlId="bulkEditTooltip"
                tooltipContent={disabledButtonsTooltip}
                trigger={tooltipTrigger}
              >
                <div>
                  <FormattedMessage
                    defaultMessage="Bulk Edit"
                    id="xmm.portal.operations.bulkedit.title"
                  />
                  {" ("}
                  {this.state.selectionlist.length}
                  {")"}
                </div>
              </XmmTooltip>
            </Button>
            <DropdownButton
              title={
                <XmmTooltip
                  htmlId="copyToDropdownButtonTooltip"
                  tooltipContent={disabledButtonsTooltip}
                  trigger={tooltipTrigger}
                >
                  {localeStrings["xmm.portal.dealermenus.copy_to_button_label"]}
                </XmmTooltip>
              }
              id="opcodesActionBtn"
              className="xmm-dealermenu-copy-to"
              disabled={!rulesSelected || isInheritedOrExcludedRuleSelected}
              pullRight
            >
              <DropdownMenuItem
                htmlId="copyToAnotherMenuType"
                eventKey={{ eventKey: ["copyToAnotherMenuType"] }}
                // disabled={!rulesSelected}
                onSelect={event => {
                  this.setState({ showCopyToMenuType: true });
                  gtmEvents.gtmTrackEvent(
                    "xmm.dealermenus.copytoanothermenutype_click"
                  );
                }}
              >
                {localeStrings["xmm.portal.dealermenus.another_menutype"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="copyToAnotherOperation"
                eventKey={{ eventKey: ["copyToAnotherOperation"] }}
                // disabled={!rulesSelected}
                onSelect={event => {
                  this.setState({ showCopyToOperation: true });
                  gtmEvents.gtmTrackEvent(
                    "xmm.dealermenus.copytoanotheroperation_click"
                  );
                }}
              >
                {localeStrings["xmm.portal.dealermenus.another_operation"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="copyToAnotherVehicleGroup"
                eventKey={{ eventKey: ["copyToAnotherVehicleGroup"] }}
                // disabled={!rulesSelected}
                onSelect={event => {
                  this.setState({ showCopyToVehicleGroup: true });
                  gtmEvents.gtmTrackEvent(
                    "xmm.dealermenus.copytoanothervehiclegroup_click"
                  );
                }}
              >
                {localeStrings["xmm.portal.dealermenus.another_vehiclegroup"]}
              </DropdownMenuItem>
              <DropdownMenuItem
                htmlId="copyToAnotherInterval"
                eventKey={{ eventKey: ["copyToAnotherInterval"] }}
                // disabled={!rulesSelected}
                onSelect={event => {
                  this.setState({ showCopyToInterval: true });
                  gtmEvents.gtmTrackEvent(
                    "xmm.dealermenus.copytoanotherinterval_click"
                  );
                }}
              >
                {localeStrings["xmm.portal.dealermenus.another_interval"]}
              </DropdownMenuItem>
            </DropdownButton>
            <Button
              htmlId="addActionBtn"
              disabled={disabled}
              onClick={this.addActionSlider}
              className="float-right"
            >
              {this.addRulesLabel}
            </Button>
            <div className="xmm-input-search float-right">
              <input
                type="text"
                id="page-search-box"
                className="xmm-input"
                disabled={disabled}
                placeholder={this.searchLabel}
                value={this.state.searchKey}
                autoComplete="off"
                onInput={this.onSearchBoxChanged}
              />
            </div>
            <div className="xmm-picky-schedule-groupby">
              <PickyFilter
                htmlId="operationScheduleGroupByPicky"
                name="schedule-groupby"
                disabled={disabled}
                placeholder={this.groupByLabel}
                label=""
                initialValue={groupsBy}
                options={this.scheduleRuleGroupByList}
                includeFilter={false}
                includeSelectAll={false}
                numberDisplayed={1}
                dropdownHeight={200}
                onChange={this.onPickyChangeGroupBy}
              />
            </div>
            <DropdownButton
              title={
                <XmmTooltip
                  htmlId="moreTooltip"
                  tooltipContent={disabledButtonsTooltip}
                  trigger={tooltipTrigger}
                >
                  <IconMore />
                </XmmTooltip>
              }
              id="moreActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              disabled={!rulesSelected || isInheritedOrExcludedRuleSelected}
              pullRight
            >
              {/*
                  <DropdownMenuItem
                    htmlId="showSummaryState"
                    eventKey={{ eventKey: ["showSummaryState"] }}
                    onSelect={this.refreshGridData}
                  >
                    {localeStrings["xmm.portal.dealermenus.show_summary_state"]}
                  </DropdownMenuItem>
                  */}
              <DropdownMenuItem
                htmlId="deleteSchedules"
                eventKey={{ eventKey: ["deleteSchedules"] }}
                onSelect={this.openDeleteDealerScheduleRulesModal}
                disabled={!rulesSelected || isInheritedOrExcludedRuleSelected}
              >
                {deleteSchedulesLabel}
              </DropdownMenuItem>
            </DropdownButton>
          </div>
        </div>
      </React.Fragment>
    );
    // Add components within slider
    const modalTitle = <span>{this.state.serviceName}</span>;
    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editCommonSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
      >
        <div>
          <DealerMenuForm
            ref="dealerMenuFormRef"
            key={"editFormSlider"}
            make={make}
            menuTypes={menuTypes}
            hideSlider={this.hideSlider}
            updateDealerMenuGrid={this.updateDealerMenuGrid}
            updateContentStatus={this.updateContentStatus}
          />
        </div>
      </GenericSlider>
    );
    const bulkModalTitle = (
      <span>
        <FormattedMessage
          defaultMessage="Bulk Edit"
          id="xmm.portal.fluids_pricing.bulkedit.title"
        />
        {": "} ({this.state.selectionlist.length}){" "}
        {xlate("xmm.portal.common.rules")}
      </span>
    );
    const bulkeditSlider = (
      <GenericSlider
        title={bulkModalTitle}
        htmlId="bulkEditSlider"
        showSlide={this.state.bulkEditSlide}
        toggleSlider={this.closeBulkEditSlider}
        sliderWidth={this.state.bulkEditSliderWidth}
        flexWidth={false}
      >
        <BulkEditPane
          // key={"bulkEditDealerRules"}
          closeSlider={this.closeBulkEditSlider}
          updateGridAfterBulkEdit={this.updateGridAfterBulkEdit}
          updateStatusBox={this.updateStatusBox}
          selectionlist={this.state.selectionlist}
        />
      </GenericSlider>
    );

    const manageIntervalsModal = this.renderManageIntervalsModal(this.context);
    const createVehicleGroupModal = this.renderCreateVehicleGroupModal(
      this.context
    );
    const manageVehicleGroupsModal = this.renderManageVehicleGroupsModal(
      this.context
    );
    const {
      showCopyToMenuType,
      showCopyToOperation,
      showCopyToVehicleGroup,
      showCopyToInterval,
      showInheritedRules
    } = this.state;
    const copyToMenuTypeModal = this.renderCopyToMenuTypeModal(
      showCopyToMenuType
    );
    const copyToOperationModal = this.renderCopyToOperationModal(
      showCopyToOperation
    );
    const copyToVehicleGroupModal = this.renderCopyToVehicleGroupModal(
      showCopyToVehicleGroup
    );
    const copyToIntervalModal = this.renderCopyToIntervalModal(
      showCopyToInterval
    );
    const deleteConfirmationDialog = (
      <Confirmation
        htmlId="deleteDealerScheduleRules"
        message={this.deleteMsg}
        proceedButtonStyle="danger"
        show={this.state.showDeleteDealerScheduleRulesModal}
        actionFunction={this.handleDeleteDealerScheduleRules}
        closeDialog={this.closeDeleteDealerScheduleRulesModal}
      />
    );
    const showInheritedRulesModal = this.renderShowAllInheritedRulesModal(
      showInheritedRules
    );
    const noEnabledMenuTypesAlert = this.renderNoEnabledMenuTypesAlert(
      hasNoDealerMenus && make !== ""
    );

    return (
      <React.Fragment>
        {formSlider}
        {header}
        {noEnabledMenuTypesAlert}
        {gridWidget}
        {manageIntervalsModal}
        {createVehicleGroupModal}
        {manageVehicleGroupsModal}
        {deleteConfirmationDialog}
        {copyToMenuTypeModal}
        {copyToOperationModal}
        {copyToVehicleGroupModal}
        {copyToIntervalModal}
        {showInheritedRulesModal}
        {bulkeditSlider}
      </React.Fragment>
    );
  }
}

export default DealerMenusPage;

DealerMenusPage.propTypes = {};

// function actionCellRenderer(params) {
//   return ". . .";
//   // return <b>&hellip;</b>;
// }

// function cellClassFunction(params) {
//   if (!params.data) {
//     return "";
//   }
//   const { field } = params.colDef;
//   const { scheduleStatus } = params.data;
//   if (scheduleStatus && scheduleStatus.indexOf("Inherited") !== -1) {
//     return "xmm-dealermenu-inherited-rules";
//   }
//   return field === "internalName" ? "xmm-wrap-cell" : "editable-caret-cell";
// }

function formatNonDealerScheduleHeader(params) {
  const { data, value } = params;
  if (data) {
    if (data.scheduleStatus.indexOf("Inherited") !== -1) {
      return xlate("xmm.portal.common.inherited_schedules"); // "Inherited Schedules";
    } else if (data.scheduleStatus.indexOf("Excluded") !== -1) {
      return xlate("xmm.portal.common.excluded"); // "Inherited Schedules";
    }
  }
  return value;
}

function inheritedCellRule(params) {
  if (!params || !params.data) {
    return false;
  }
  const { field } = params.colDef;
  const { scheduleStatus } = params.data;
  if (
    (field === "intervalName" || field === "metaVehicleFilterName") &&
    scheduleStatus &&
    scheduleStatus.indexOf("Inherited") !== -1
  ) {
    return true;
  }
  return false;
}

function editableCaretCellRule(params) {
  if (!params || !params.data) {
    return false;
  }
  const { field } = params.colDef;
  const { scheduleStatus } = params.data;
  if (
    (field === "intervalName" || field === "metaVehicleFilterName") &&
    scheduleStatus &&
    scheduleStatus.indexOf("Changed Intervals") !== -1
  ) {
    return true;
  }
  return false;
}

function getCellClassRules() {
  const cellClassRules = {
    // eslint-disable-next-line object-shorthand
    "xmm-dealermenu-inherited-rules": inheritedCellRule,
    // eslint-disable-next-line object-shorthand
    "editable-caret-cell": editableCaretCellRule
  };
  return cellClassRules;
}

/* eslint-enable no-console */
