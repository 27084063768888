/* eslint-disable react/prop-types */
/* eslint-disable no-console */
// eslint-disable react/prefer-stateless-function
import React from "react";
import SelectInput from "@cx/ui/SelectInput";

const StyledSelect = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      placeholder,
      label,
      field,
      form,
      customevent,
      ...otherProps
    },
    ref
  ) => {
    // console.log(
    //   "render Styleselect form > props of " + field.name,
    //   form,
    //   otherProps
    // );
    const inputStyles = {};
    if (form.touched[field.name] && form.errors[field.name]) {
      inputStyles.borderColor = "red";
    }
    // If props has custom value for appVisibility field
    let fieldvalue = form.values[field.name] || "";
    if (otherProps.customValue) {
      fieldvalue = otherProps.customValue;
    }
    return (
      <SelectInput
        {...field}
        {...otherProps}
        ref={ref}
        style={inputStyles}
        htmlId={otherProps.id}
        className={
          form.errors[field.name] && form.touched[field.name]
            ? "xmm-error-input"
            : ""
        }
        disabled={otherProps.disabled || false}
        displayLabel={false}
        displayPlaceholder={false}
        maxHeight={100}
        name={field.name}
        value={fieldvalue}
        options={otherProps.options}
        onChange={changeEvent => {
          // call parent event handler onChange of child field, to update field value to formik state
          customevent(changeEvent, field, form);
          // console.log("Select CMP level event", changeEvent.target.value);
          // call in-built formik.Field handler
          onChange(changeEvent.target.value);
        }}
        /*
        onBlur={e => {
          // console.log("Select cmp blur", e.target.value);
          // call in-built Field handler
          onBlur(e.target.value);
        }}
        */
        placeholder={placeholder ? placeholder : "Select..."}
      />
    );
  }
);

export default StyledSelect;
StyledSelect.displayName = "StyledSelect";

/*
Example:
  <Field
    name="make"
    component={StyledSelect}
    id="makeSelect"
    disabled=false
    customevent={this.ownMakeChange}
  />
*/
