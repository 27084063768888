import LaborRatesPage from "./LaborRates/LaborRatesPage";
import FluidsPricing from "./FluidsPricing/FluidsPricing";
import PartsPricing from "./PartsPricing/PartsPricing";
// bundle components into one object and export
const PartsLaborViews = {
  FluidsPricing,
  PartsPricing,
  LaborRatesPage
};

export default PartsLaborViews;
