/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import Button from "@cx/ui/Button";
import { AppContext } from "../../../components/app-context";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import { commasFormatter } from "../../../commonUtil/utils/formatter";

class MileageGrid extends Component {
  static contextType = AppContext;

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("MileageGrid nextProps", nextProps);
    if (nextProps.mileages !== prevState.mileages) {
      return {
        mileages: nextProps.mileages,
        intervalId: nextProps.intervalId
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    const localeStrings = context.localeStrings;
    const gridOptions = {
      // other props
      // intervalId: "",
      mileages: props.mileages,
      selectionlist: [],
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        suppressMenu: true,
        sortingOrder: ["desc", "asc", null],
        minWidth: 80,
        autoHeight: true,
        rowGroup: false
      },
      rowData: [],
      rowSelection: "multiple", // allows multiple row selections on columns
      frameworkComponents: {},
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agSelectedRowCountComponent", align: "left" }
        ]
      }
    };
    this.state = gridOptions;
  }

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState(
        {
          selectionlist: selectedRows
        },
        prevState => {
          // callback if any
        }
      );
    }
  };

  // remove rows from ag-grid
  deleteSelectedMiles = event => {
    const { selectionlist, mileages } = this.state;
    const miles = [...mileages]; // create TRUE copy
    if (selectionlist.length > 0) {
      const res = this.gridApi.applyTransaction({
        remove: selectionlist
      });
      res.remove.forEach(function(rowNode) {
        console.log("Removed Row Node", rowNode);
      });

      for (let index = selectionlist.length - 1; index >= 0; index--) {
        const foundIndex = miles.findIndex(
          item => item === selectionlist[index]
        );
        if (foundIndex !== -1) {
          miles.splice(foundIndex, 1);
        }
      }
      this.props.updateMileagesFromGrid(miles, true);
    }
  };
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "mileage",
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  autoSizeCols() {
    const allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function(column) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };

  reset() {
    this.setState({
      mileages: []
    });
  }

  /* Call this setter when Generate Mileages clicked or when Interval form is loaded  */
  setMileages = (mileages, intervalId) => {
    if (mileages) {
      // Note: Add timeout to update React state when called from Sync function
      setTimeout(() => {
        this.setState({
          intervalId,
          isDeleted: false,
          mileages
        });
      }, 200);
    }
  };

  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.common.mileages"],
        headerClass: "ag-numeric-header",
        field: "mileage",
        autoHeight: true,
        valueFormatter: commasFormatter,
        cellStyle: {
          textAlign: "right",
          "padding-right": "25px"
        },
        sortingOrder: ["asc", "desc"]
      }
    ];
    return baseCols;
  }

  render() {
    const showGridClass =
      "xmm-mileage-container ag-theme-balham ag-grid-overlay-scroll";

    const gridWidget = (
      <div id="showMileagesGrid" className={showGridClass}>
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressMenuHide={false}
          // suppressContextMenu={true}
          rowData={this.state.mileages}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          animateRows={true}
          statusBar={this.state.statusBar}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          enableBrowserTooltips={false}
          /* settings to support row selections on columns */
          onSelectionChanged={this.handleSelectionChanged}
          rowSelection={this.state.rowSelection}
          rowDeselection={true}
        />
      </div>
    );
    return (
      <React.Fragment>
        <Row>
          <Col xs={10} className="xmm-vehicles-grid">
            {gridWidget}
            <Button
              htmlId="deleteMileagesBtn"
              size="small"
              disabled={this.state.selectionlist.length === 0}
              onClick={this.deleteSelectedMiles}
            >
              {this.context.localeStrings["xmm.portal.common.delete_selected"]}
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default MileageGrid;

MileageGrid.propTypes = {
  intervalId: PropTypes.string,
  mileages: PropTypes.array,
  updateMileagesFromGrid: PropTypes.func
};
/* eslint-enable no-console */
