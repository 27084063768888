/* eslint-disable react/display-name, react/no-multi-comp */
/* eslint-disable no-console */
import React from "react";
// import DemoPage from "./pages/Demo/DemoPage";
// import ChartPage from "./pages/Demo/ChartPage";
import OperationViews from "./pages/Operations/index";
import MenuViews from "./pages/Menus/index";
import Dashboard from "./pages/Dashboard";
import PartsLaborViews from "./pages/PartsLabor/index";
import SettingsViews from "./pages/Settings/index";
import Reports from "./pages/Reports/index";

// create an empty map
const moduleKeys = {
  demo: "demo",
  dashboard: "dashboard",
  settings: "settings",
  operations: "operations",
  menus: "menus",
  partslabor: "partslabor",
  reports: "reports",
  preview: "preview"
};

export const moduleMapping = new Map();
export const routes = [];

// Uncomment these demo module to see basic ag-grid components
/*
const demoModule = [
  {
    path: "/demo",
    exact: true,
    leaf: false,
    label: "Demo",
    nodeName: "demo",
    parentLocaleKey: "xmm.portal.nav.demo",
    parentName: moduleKeys.demo,
    localekey: "xmm.portal.nav.demopage",
    main: () => <DemoPage />
  },
  {
    path: "/chart",
    exact: true,
    leaf: false,
    label: "Demo Chart",
    nodeName: "chart",
    parentLocaleKey: "xmm.portal.nav.demo",
    parentName: moduleKeys.demo,
    localekey: "xmm.portal.nav.chartpage",
    main: () => <ChartPage />
  }
];
*/
const Operations = OperationViews.Operations;
const IntegratedOperations = OperationViews.IntegratedOperations;
const OpcodeValidation = OperationViews.OpcodeValidation;
const operationModule = [
  {
    path: "/operations",
    exact: true,
    leaf: false,
    label: "Operations",
    nodeName: "operations",
    parentLocaleKey: "xmm.portal.nav.operations",
    parentName: moduleKeys.operations,
    localekey: "xmm.portal.nav.operations",
    main: () => <Operations />
  },
  {
    path: "/opcodevalidation",
    exact: true,
    leaf: false,
    label: "Op Code Validation",
    nodeName: "opcodevalidation",
    parentLocaleKey: "xmm.portal.nav.operations",
    parentName: moduleKeys.operations,
    localekey: "xmm.portal.nav.opcode_validation",
    main: () => <OpcodeValidation />
  },
  {
    path: "/integratedoperations",
    exact: true,
    leaf: false,
    label: "Integrated Operations",
    nodeName: "integratedoperations",
    parentLocaleKey: "xmm.portal.nav.operations",
    parentName: moduleKeys.operations,
    localekey: "xmm.portal.nav.integrated_operations",
    main: () => <IntegratedOperations />
  }
];

const MenuTypesPage = MenuViews.MenuTypesPage;
const DealerMenusPage = MenuViews.DealerMenusPage;
const PackagesPage = MenuViews.PackagesPage;

const menusModule = [
  {
    path: "/menutypes",
    exact: true,
    leaf: false,
    label: "Menu Types",
    nodeName: "menutypes",
    parentLocaleKey: "xmm.portal.nav.menus",
    parentName: moduleKeys.menus,
    localekey: "xmm.portal.nav.menu_types",
    main: () => <MenuTypesPage />
  },
  {
    path: "/dealermenus",
    exact: true,
    leaf: false,
    label: "Dealer Menus",
    nodeName: "dealermenus",
    parentLocaleKey: "xmm.portal.nav.menus",
    parentName: moduleKeys.menus,
    localekey: "xmm.portal.nav.dealermenus",
    main: () => <DealerMenusPage />
  },
  {
    path: "/packages",
    exact: true,
    leaf: false,
    label: "Package Overrides",
    nodeName: "packages",
    parentLocaleKey: "xmm.portal.nav.menus",
    parentName: moduleKeys.menus,
    localekey: "xmm.portal.nav.packages",
    main: () => <PackagesPage />
  }
];

const dashboardModule = [
  {
    path: "/",
    exact: true,
    leaf: true,
    label: "Dashboard",
    nodeName: "dashboard",
    parentLocaleKey: "xmm.portal.nav.dashboard",
    parentName: moduleKeys.dashboard,
    localekey: "xmm.portal.nav.dashboard",
    main: () => <Dashboard />
  }
];
const reportsdModule = [
  {
    path: "/reports",
    exact: true,
    leaf: true,
    label: "Reports",
    nodeName: "reports",
    parentLocaleKey: "xmm.portal.nav.reports",
    parentName: moduleKeys.reports,
    localekey: "xmm.portal.nav.reports",
    main: () => <Reports />
  }
];
const Settings = SettingsViews.Settings;
const VehicleGroups = SettingsViews.VehicleGroups;
const Intervals = SettingsViews.Intervals;
const Translations = SettingsViews.Translations;
const settingsModule = [
  {
    path: "/settings",
    exact: true,
    leaf: false,
    label: "Configuration",
    nodeName: "configuration",
    parentLocaleKey: "xmm.portal.nav.settings",
    parentName: moduleKeys.settings,
    localekey: "xmm.portal.nav.settings",
    main: () => <Settings />
  },
  {
    path: "/vehiclegroups",
    exact: true,
    leaf: false,
    label: "Vehicle Groups",
    nodeName: "vehiclegroups",
    parentLocaleKey: "xmm.portal.nav.settings",
    parentName: moduleKeys.settings,
    localekey: "xmm.portal.nav.vehicle_groups",
    main: () => <VehicleGroups />
  },
  {
    path: "/intervals",
    exact: true,
    leaf: false,
    label: "Intervals",
    nodeName: "intervals",
    parentLocaleKey: "xmm.portal.nav.settings",
    parentName: moduleKeys.settings,
    localekey: "xmm.portal.nav.intervals",
    main: () => <Intervals />
  },
  {
    path: "/translations",
    exact: true,
    leaf: false,
    label: "Translations",
    nodeName: "translations",
    parentLocaleKey: "xmm.portal.nav.settings",
    parentName: moduleKeys.settings,
    localekey: "xmm.portal.nav.translations",
    main: () => <Translations />
  }
];

const FluidsPricing = PartsLaborViews.FluidsPricing;
const PartsPricing = PartsLaborViews.PartsPricing;
const LaborRatesPage = PartsLaborViews.LaborRatesPage;
const partslaborModule = [
  {
    path: "/partspricing",
    exact: true,
    leaf: false,
    label: "Parts Pricing",
    nodeName: "partspricing",
    parentLocaleKey: "xmm.portal.nav.parts_labor",
    parentName: moduleKeys.partslabor,
    localekey: "xmm.portal.nav.parts_pricing",
    main: () => <PartsPricing />
  },
  {
    path: "/fluidspricing",
    label: "Fluid Pricing",
    exact: true,
    leaf: false,
    nodeName: "fluidspricing",
    parentLocaleKey: "xmm.portal.nav.parts_labor",
    parentName: moduleKeys.partslabor,
    localekey: "xmm.portal.nav.fluids_pricing",
    main: () => <FluidsPricing />
  },
  {
    path: "/laborrates",
    exact: true,
    leaf: false,
    label: "Labor Rates",
    nodeName: "laborrates",
    parentLocaleKey: "xmm.portal.nav.parts_labor",
    parentName: moduleKeys.partslabor,
    localekey: "xmm.portal.nav.labor_rates",
    main: () => <LaborRatesPage />
  }
];

// @params {key} as "operations"
export function getModule(key) {
  return moduleMapping.get(key);
}

function setModule(key, moduleObj) {
  moduleMapping.set(key, moduleObj);
}
/* Add modules to hashmap here in proper order */
function setRouteModules(calculatedPricing, anyMakeOnly, hasNonbaseLocale) {
  setModule(moduleKeys.dashboard, dashboardModule);
  if (!anyMakeOnly) {
    if (hasNonbaseLocale) {
      setModule(moduleKeys.settings, settingsModule);
    } else {
      const settingsModuleWithoutTranslation = [
        settingsModule[0],
        settingsModule[1],
        settingsModule[2]
      ];
      setModule(moduleKeys.settings, settingsModuleWithoutTranslation);
    }
  } else {
    if (hasNonbaseLocale) {
      setModule(moduleKeys.settings, [settingsModule[0], settingsModule[3]]);
    } else {
      setModule(moduleKeys.settings, [settingsModule[0]]);
    }
  }
  setModule(moduleKeys.operations, operationModule);
  if (!anyMakeOnly) {
    setModule(moduleKeys.menus, menusModule);
  }
  if (calculatedPricing) {
    setModule(moduleKeys.partslabor, partslaborModule);
  }
  if (!anyMakeOnly) {
    setModule(moduleKeys.reports, reportsdModule);
  }
  // setModule(moduleKeys.demo, demoModule);
}

export function printModules() {
  for (const key of moduleMapping.keys()) {
    console.log(key);
  }
  console.log("get routes for operations", getModule("Maintenance Menus"));
  moduleMapping.forEach(function(value, key) {
    console.log("values", typeof value);
    console.log(key, JSON.stringify(value, null, 2));
  });
}
function clearModuleMapping() {
  moduleMapping.clear();
}
/* primary method called in App.js to initiate route hashmap */
export function initiateModules(
  calculatedPricing,
  anyMakeOnly,
  hasNonbaseLocale
) {
  clearModuleMapping();
  setRouteModules(calculatedPricing, anyMakeOnly, hasNonbaseLocale);
  updateRoutes();
  // printModules();
  // console.log("module size", moduleMapping.size);
}

/* This method returns HashMap with header menus */
export function getModuleMapping(
  calculatedPricing,
  anyMakeOnly,
  hasNonbaseLocale
) {
  initiateModules(calculatedPricing, anyMakeOnly, hasNonbaseLocale);
  return moduleMapping;
}

/* This method will iterate over all module mapping and construct Routes[] */
export function updateRoutes() {
  routes.length = 0;
  const routesList = [];
  for (const value of moduleMapping.values()) {
    value.forEach(function(route, index) {
      routesList.push(route);
      routes.push(route);
    });
  }
  // console.log("Total Routes[]", routes);
}
