/* eslint-disable no-console */
import React from "react";
import DropdownMenu from "@cx/ui/DropdownMenu";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import { AppContext } from "../../../components/app-context";
import { PropTypes } from "prop-types";

class AddGroupMenuButton extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.checkForUnsavedChanges = this.checkForUnsavedChanges.bind(this);
    this.onSelectMake = this.onSelectMake.bind(this);
    const { localeStrings } = context;
    this.addButtonLabel = localeStrings["xmm.portal.common.add_button"];
    this.state = {
      groupNames: [],
      supportedMakes: context.makelist
    };
  }

  /* common select handler for dropdownMenuItem  */
  onSelectMake = (eventKey, event) => {
    // console.log("selected eventKey:", eventKey);
    window.dispatchEvent(
      new CustomEvent("addVehicleGroupEvent", {
        detail: eventKey,
        bubbles: true,
        cancelable: true
      })
    );
    window.document.dispatchEvent(new MouseEvent("click"));
    // callback something
  };
  checkForUnsavedChanges = (eventKey, event) => {
    const { discardUnsavedChanges } = this.context;
    const { isFormDirty, resetForm } = this.props;
    if (
      discardUnsavedChanges(
        event,
        () => {
          resetForm();
          this.onSelectMake(eventKey, event);
        },
        isFormDirty
      )
    ) {
      this.onSelectMake(eventKey, event);
    }
  };
  generateItems = () => {
    const items = (
      <React.Fragment>
        {this.state.supportedMakes.map(c => {
          /*
          makeObj = { dealerCode: "XTIMEMOTORS14",
              label: "ACURA",
              pricingMethod: 1,
              value: "ACURA",
              variant: "ACURAUSA_ENH2" }
          */
          return (
            <DropdownMenuItem
              key={c.label}
              htmlId={c.value}
              eventKey={c}
              onSelect={this.checkForUnsavedChanges}
            >
              {c.value}
            </DropdownMenuItem>
          );
        })}
      </React.Fragment>
    );
    return items;
  };
  render() {
    const items = this.generateItems();
    return (
      <DropdownMenu
        buttonText={this.addButtonLabel}
        htmlId="AddVehicleGroupBtn"
        className="scroll-enabled"
        size="small"
      >
        {items}
      </DropdownMenu>
    );
  }
}

export default AddGroupMenuButton;

AddGroupMenuButton.propTypes = {
  isFormDirty: PropTypes.func,
  resetForm: PropTypes.func
};
