/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import Col from "@cx/ui/Col";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import IconMore from "@cx/ui/Icons/IconMore";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import { isArrayExist, isEmpty } from "../../../../commonUtil/utils/object";
import {
  toEmptyStringIfUndefined,
  isEmptyString,
  isSameValue
} from "../../../../commonUtil/utils/string";
import PriceEditor from "../../../../commonUtil/editors/PriceEditor";
import TextEditor from "../../../../commonUtil/editors/TextEditor";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { priceFormatter } from "../../../../commonUtil/utils/formatter";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toast } from "@cx/ui/Toast";
import SelectInput from "@cx/ui/SelectInput";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import { FormattedMessage } from "react-intl";
import Button from "@cx/ui/Button";
import {
  PartsPricingState,
  PriceLevel,
  UnitOfMeasure
} from "../../../../constants/ModuleConstants";
import BulkEditPane from "./BulkEditPane";
import { FluidsPricingContext } from "./fluids-pricing-context";
import ImportDMSPartsPricingModal from "../PartsPricing/ImportDMSPartsPricingModal";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";

class FluidsPricing extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Add Default filter to show only Calculated pricing workflow supported records.
    const supportedMakeList = context.makelist.filter(function(obj) {
      return obj.pricingMethod === 1;
    });
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.onFluidsStatusChange = this.onFluidsStatusChange.bind(this);
    this.openImportPartsModal = this.openImportPartsModal.bind(this);
    this.closeImportPartsModal = this.closeImportPartsModal.bind(this);
    this.openBulkEditSlider = this.openBulkEditSlider.bind(this);
    this.closeBulkEditSlider = this.closeBulkEditSlider.bind(this);
    this.updateGridAfterBulkEdit = this.updateGridAfterBulkEdit.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    // bind other external actions
    const localeStrings = context.localeStrings;
    this.importDmsFuidsPricingLabel = xlate(
      "xmm.portal.common.import_fluids_pricing"
    );
    this.uomlist = [];
    const gridOptions = {
      // other state props
      variantType: context.partsPricingGrid.variantType,
      searchKey: context.partsPricingGrid.searchKey,
      fluidsPricingGrid: context.fluidsPricingGrid,
      supportedMakeList,
      pageTitle: "Fluid Pricing",
      editOption: null, // set values as edit, add, delete
      operationName: "",
      flexWidth: false,
      showSlide: false,
      loadOperation: PartsPricingState,
      selectionlist: [],
      bulkEditSlide: false,
      bulkEditSliderWidth: 450,
      // ag-grid props
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      columnDefs: this.getColumnList(localeStrings, this.uomlist),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        cellClassRules: getCellClassRule(["price"], ["price"], []),
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {
        iconCellRendererMSRP,
        iconCellRendererDMS,
        iconCellRendererManual,
        descriptionCellRenderer,
        partNumberCellRenderer
      },
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        textEditor: TextEditor,
        priceEditor: PriceEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      // localeText: {
      //   filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
      //   selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
      //   totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
      //   totalAndFilteredRows:
      //     localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
      //   noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      // },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 120,
          minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "multiple",
      isRowSelectable(rowNode) {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        showImportPartsModal: false,
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {}
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey, variantType } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const fluidsPricingGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey,
        variantType
      };
      // const keys = Object.keys(this.gridApi.getFilterModel());
      // console.log(fluidsPricingGrid, keys);
      this.setState({
        fluidsPricingGrid
      });
      this.context.setFluidsPricingGridState(fluidsPricingGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const {
      colState,
      filterState,
      pivotState,
      searchKey,
      variantType
    } = this.state.fluidsPricingGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey,
          variantType
        },
        prevState => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  onFirstDataRendered(params) {
    this.restoreGridState();
    this.sizeToFit();
  }
  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        // console.log("before clear> fitler", filterModel);
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      document.querySelector("#parts-pricing-search-box").value = "";
      this.gridApi.setQuickFilter("");
    }
  }
  // bind to add PartsPricing click event
  addPartsPricingSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editPartsPricing: "add",
      loadPartsPricing: PartsPricingState,
      partsPricingName: "Add Parts Pricing"
    }));
  };
  /* handler for open bulkdedit slider */
  openBulkEditSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
  };
  closeBulkEditSlider = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState(prevState => ({
      bulkEditSlide: !prevState.bulkEditSlide
    }));
    this.gridApi.redrawRows(this.params);
  };
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  handleCsvImport = (make, file) => {
    const { localeStrings } = this.context;
    this.setState({ showImportPartsModal: false });
    this.gridApi.showLoadingOverlay();
    const { dealerCode, makeVariantMap } = this.context;
    const { variant } = makeVariantMap[make];
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    this.updateStatusBox(
      localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    makeSecureRestApi(
      {
        url: "ops/upload/batchUpdateParts",
        method: "post",
        data: formData,
        params: { dealerCode, make, variant },
        headers
      },
      response => {
        this.gridApi.hideOverlay();
        if (!response.success) {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.errors.import_error"],
            "error",
            false,
            true
          );
        } else {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );
          // toast.success(`${response.data} Fluids updated with DMS Pricing`, {
          //   autoClose: 10000
          // });
          setTimeout(() => {
            this.refreshParts();
          }, 3000);
        }
      },
      error => {
        toast.error(error.message);
        this.gridApi.hideOverlay();
      }
    );
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.fluidpricing.import_click");
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerPartId; // primary or unique key of record
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.loadUomData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };
  updateState(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (isEmpty(data[i]["description"])) {
            data[i]["description"] = data[i]["oemDescription"];
          }
          if (isEmpty(data[i]["partNumber"])) {
            data[i]["partNumber"] = data[i]["oemPartNumber"];
          }
        }
        datalist = data;
      }
      if (isArrayExist(datalist) && datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      let variantType = "ALL";
      // Read variant type if passed from Dashbaord
      const { variantFilters } = this.context;
      if (variantFilters && Object.keys(variantFilters).length > 0) {
        variantType = variantFilters.variantType;
      }
      this.setState(
        {
          rowData: datalist,
          variantType
        },
        prevState => {
          this.sizeToFit();
          // this.restoreGridState();
          if (variantFilters && Object.keys(variantFilters).length > 0) {
            this.setMakeModel(variantFilters.make);
            this.applyStatusFilter(null, variantFilters.variantType);
            // reset variantFilter in App context
            this.context.updateVariantFilters(null);
          }
        }
      );
    }
  }
  updateUOM(data, uomlist) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].name !== "Grams" &&
          data[i].name !== "Inches" &&
          data[i].name !== "Millimeters"
        ) {
          uomlist[data[i].name] = data[i].unitOfMeasureId;
          uomlist.length++;
        }
      }
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const payload = {
      dealerCode
    };
    const restUrl = `/ops/proxyapi/ddsproxy/rest/table/dealerPart?partType=fluid&enabled=1`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          this.gridApi && this.gridApi.hideOverlay();
          hideBodyMask();
          // Add Default filter to show only Calculated pricing workflow supported records.
          const filterList = data.filter(function(obj) {
            return obj.pricingMethod === 1;
          });
          this.updateState(filterList);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }
  loadUomData() {
    const payload = {};
    const restUrl = `/ops/proxyapi/ddsproxy/rest/table/unitOfMeasure`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    // this.gridApi && this.gridApi.showLoadingOverlay();
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          // console.log(data);
          hideBodyMask();
          this.updateUOM(data, this.uomlist);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "description",
        sortIndex: 1,
        sort: "asc"
      },
      {
        colId: "partNumber",
        sortIndex: 2,
        sort: "asc"
      },
      {
        colId: "apiSpec",
        sortIndex: 3,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  callRefreshAfterMillis(params, gridApi) {
    gridApi.redrawRows(params);
    setTimeout(function() {
      gridApi.refreshCells(params);
    }, 30);
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent(params) {
    // do nothing for now
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) ===
      toEmptyStringIfUndefined(params.newValue)
    ) {
      return;
    } else {
      if (params.colDef.field === "price") {
        if (params.newValue === "") {
          params.data.source = "MSRP";
        } else {
          params.data.source = "MANUAL";
        }
        if (this.validateField(params)) {
          this.clearFieldValidation(params);
          this.onSaveCellEdit(params);
        }
        return;
      } else {
        if (params.colDef.field === "uom") {
          const arr = this.uomlist;
          params.data.unitOfMeasureId = arr[params.newValue];
        }
        if (params.colDef.field === "description") {
          params.data.description = params.newValue;
        }
        if (params.colDef.field === "partNumber") {
          params.data.partNumber = params.newValue;
        }
        this.onSaveCellEdit(params);
      }
    }
  }
  onSaveCellEdit(params) {
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    this.savePayload(params, params.value);
  }
  /* celledit handler to save edits */
  savePayload(gridParams, value) {
    const record = gridParams.data;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/dealerPart/${
      record.dealerPartId
    }/?_method=put`;
    const payload = this.getCellEditPayload(record, value);
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        if (!response.success) {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.errors.save_error"],
            "error",
            false,
            true
          );
        } else {
          this.updateStatusBox(
            this.context.localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );
          const { dealerPart } = response;
          const { field } = gridParams.colDef;
          if (field === "description" || field === "partNumber") {
            if (isEmpty(dealerPart[field])) {
              dealerPart[field] =
                field === "description"
                  ? dealerPart.oemDescription
                  : dealerPart.oemPartNumber;
            }
          }
          Object.assign(gridParams.data, dealerPart);
          this.gridApi.applyTransaction({
            update: [gridParams.data]
          });
          // this.refreshCell(record, field);
          this.callRefreshAfterMillis(gridParams, this.gridApi);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  getCellEditPayload(record, value) {
    let payload = {};

    payload = {
      source: record.source,
      price: record.price,
      description: record.description,
      partNumber: record.partNumber,
      unitOfMeasureId: record.unitOfMeasureId,
      dealerPartId: record.dealerPartId
    };

    return payload;
  }
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#parts-pricing-search-box")
        .value;
      this.gridApi.setQuickFilter(searchKey);
      this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };
  onFluidsStatusChange = (cxEvent, isValid, domEvent) => {
    const { value, name } = cxEvent.target;
    this.setState(
      {
        [name]: value
      },
      prevState => {
        this.applyStatusFilter(cxEvent, value);
        this.sizeToFit();
      }
    );
  };
  refreshParts = () => {
    this.loadGridData();
  };
  // This Make Filter used when Parts page launched from Dashboard
  setMakeModel(filterVal) {
    if (this.gridApi) {
      const makeFilter = this.gridApi.getFilterInstance("make");
      if (filterVal && filterVal === "all") {
        makeFilter.setModel(null);
      } else {
        const data = [];
        data.push(filterVal);
        const model = {
          type: "set",
          values: data
        };
        makeFilter.setModel(model);
      }
    }
  }
  applyStatusFilter = (event, filterVal) => {
    if (this.gridApi) {
      const statusAddedFilter = this.gridApi.getFilterInstance("variantAdded");
      const statusChangedFilter = this.gridApi.getFilterInstance(
        "variantChanged"
      );
      if (filterVal && filterVal === "ADDED") {
        statusAddedFilter.setModel({
          type: "startsWith",
          filter: "1"
        });
        statusChangedFilter.setModel({
          type: "",
          filter: ""
        });
      }
      if (filterVal && filterVal === "CHANGED") {
        statusAddedFilter.setModel({
          type: "",
          filter: ""
        });
        statusChangedFilter.setModel({
          type: "startsWith",
          filter: "1"
        });
      }
      if (filterVal === "ALL") {
        statusAddedFilter.setModel({
          type: "",
          filter: ""
        });
        statusChangedFilter.setModel({
          type: "",
          filter: ""
        });
      }
      // Get grid to run filter operation again
      this.gridApi.onFilterChanged();
    }
  };
  getColumnList(localeStrings, uomlist) {
    const baseCols = [
      {
        headerName: "",
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        children: [
          {
            headerName: "",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            pinned: "left",
            field: "dealerPartId",
            suppressSizeToFit: true,
            suppressColumnsToolPanel: true, // hide item in sidebar.columns
            filter: false,
            maxWidth: 37,
            minWidth: 37,
            width: 37
          },
          {
            headerName: localeStrings["xmm.portal.grid.make"],
            headerClass: "ag-text-header",
            cellClass: "xmm-wrap-cell",
            field: "make",
            sortingOrder: ["asc", "desc"],
            maxWidth: 150,
            minWidth: 120,
            width: 120,
            editable: false,
            valueFormatter(params) {
              return params.value;
            },
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName: localeStrings["xmm.portal.grid.description"],
            headerClass: "ag-text-header",
            field: "description",
            editable: true,
            cellClass: "editable-cell",
            maxWidth: 300,
            minWidth: 190,
            width: 190,
            sortingOrder: ["asc", "desc"],
            valueFormatter(params) {
              return params.value;
            },
            cellRenderer: "descriptionCellRenderer",
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName:
              localeStrings["xmm.portal.fluids_pricing.grid_part_number"],
            headerClass: "ag-text-header",
            field: "partNumber",
            editable: true,
            cellClass: "editable-cell",
            valueFormatter(params) {
              return params.value;
            },
            cellRenderer: "partNumberCellRenderer",
            maxWidth: 160,
            minWidth: 160,
            width: 160,
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName:
              localeStrings["xmm.portal.fluids_pricing.fluid_specification"],
            headerClass: "ag-text-header",
            field: "apiSpec",
            editable: false,
            valueFormatter: fluidSpecFormatter,
            maxWidth: 400,
            minWidth: 290,
            width: 290,
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName:
              localeStrings["xmm.portal.fluids_pricing.grid_selling_uom"],
            headerClass: "ag-text-header",
            field: "uom",
            editable: true,
            cellClass: "editable-caret-cell",
            cellEditorSelector(params) {
              return {
                component: "agRichSelectCellEditor",
                params: {
                  values: uomObj(params, uomlist)
                }
              };
            },
            refData: UnitOfMeasure,
            maxWidth: 120,
            minWidth: 120,
            width: 120,
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            }
          },
          {
            headerName:
              localeStrings[
                "xmm.portal.fluids_pricing.grid_active_price_level"
              ],
            field: "source",
            editable: true,
            headerClass: "ag-text-header",
            cellClass: sourceCellClass,
            cellEditorSelector(params) {
              if (priceCount(params) < 2) {
                params.api.stopEditing();
                return null;
              }
              return {
                component: "agRichSelectCellEditor",
                params: {
                  values: extractValues(params)
                }
              };
            },
            refData: PriceLevel,
            filter: "agSetColumnFilter",
            filterParams: {
              buttons: ["clear"]
            },
            maxWidth: 120,
            minWidth: 120,
            width: 120
          }
        ]
      },
      {
        headerName:
          localeStrings["xmm.portal.fluids_pricing.grid_group_header"],
        children: [
          {
            headerName: localeStrings["xmm.portal.grid.msrp"],
            field: "msrp",
            editable: false,
            headerClass: "ag-numeric-header",
            // cellClass: "xmm-wrap-cell xmm-grid-price",
            // cellStyle: { "text-align": "right" },
            cellRenderer: "iconCellRendererMSRP",
            type: "numberColumn",
            valueFormatter: priceFormatter,
            maxWidth: 130,
            minWidth: 100,
            width: 100
          },
          {
            headerName:
              localeStrings["xmm.portal.fluids_pricing.grid_DMSPrice"],
            field: "dmsPrice",
            editable: false,
            headerClass: "ag-numeric-header",
            // cellClass: "xmm-wrap-cell xmm-grid-price",
            // cellStyle: { "text-align": "right" },
            cellRenderer: "iconCellRendererDMS",
            type: "numberColumn",
            valueFormatter: priceFormatter,
            maxWidth: 130,
            minWidth: 100,
            width: 100
          },
          {
            headerName: localeStrings["xmm.portal.fluids_pricing.grid_Price"],
            field: "price",
            colId: "price",
            editable: true,
            headerClass: "ag-numeric-header",
            cellEditor: "priceEditor",
            // cellClass: "editable-cell xmm-grid-price",
            // cellStyle: { "text-align": "right" },
            cellRenderer: "iconCellRendererManual",
            cellEditorParams: { keepInvalidValue: true },
            type: "numberColumn",
            // valueFormatter: priceFormatter,
            maxWidth: 130,
            minWidth: 100,
            width: 100
          }
        ]
      },
      {
        headerName:
          localeStrings["xmm.portal.fluids_pricing.status_new_fluids_only"],
        field: "variantAdded",
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        filterParams: {
          cellHeight: 0
        }
      },
      {
        headerName:
          localeStrings["xmm.portal.fluids_pricing.status_changed_fluids_only"],
        field: "variantChanged",
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        filterParams: {
          cellRenderer: null
        }
      }
    ];
    return baseCols;
  }
  handleRowSelected = event => {
    // Do Nothing
  };
  // when bulkedit saved saved from slider, callback to update records of selected rows in operation grid
  updateGridAfterBulkEdit = (results, columns) => {
    if (this.gridApi && !isEmpty(results)) {
      this.gridApi.applyTransaction({ update: results });
      const rowNodes = results.map(data =>
        this.gridApi.getRowNode(data.dealerPartId)
      );
      this.gridApi.flashCells({ rowNodes, columns });
      this.context.updateOperationAfterSave("bulk", results);
    }
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };

  openImportPartsModal = event => {
    this.setState({
      showImportPartsModal: true
    });
  };

  closeImportPartsModal = event => {
    this.setState({ showImportPartsModal: false });
    if (this.refs.importDMSPartsPricingRef) {
      this.refs.importDMSPartsPricingRef.initializeStates();
    }
  };
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    // console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.refreshCell(operation, field);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerPartId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "price":
          if (newValue && (parseFloat(newValue) || 0) > 9999.99) {
            errorKey = "xmm.portal.errors.exceed_max_price";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
      this.callRefreshAfterMillis(params, this.gridApi);
    }
    return !errorKey;
  }
  render() {
    const { localeStrings } = this.context;
    const contextValue = {
      selectionlist: this.state.selectionlist,
      dealerPartId: this.state.dealerPartId,
      appContext: this.context,
      updateGridAfterBulkEdit: this.updateGridAfterBulkEdit
    };
    const importDMSPartsPricingModal = (
      <ImportDMSPartsPricingModal
        ref="importDMSPartsPricingRef"
        show={this.state.showImportPartsModal}
        importParts={this.handleCsvImport}
        closeDialog={this.closeImportPartsModal}
        makesList={this.state.supportedMakeList}
        title={this.importDmsFuidsPricingLabel}
      />
    );

    const gridWidget = (
      <div id="grid-wrapper">
        <div id="mainGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.rowData}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            enableCellChangeFlash={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowHeight={35}
            onFilterChanged={this.onFilterChanged}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const header = (
      <React.Fragment>
        {importDMSPartsPricingModal}
        <div className="xmm-parts-pricing-header">
          <Grid htmlId="pageHead">
            <Row className="show-grid">
              <Col xs={12} sm={6} className="full-col">
                <h3>{this.state.pageTitle}</h3>
              </Col>
              <Col xs={12} sm={6} className="full-col xmm-header-right">
                <DropdownButton
                  title={<IconMore />}
                  id="opcodesActionBtn"
                  className="xmm-dotted-dropdown btn--icon"
                  pullRight
                >
                  <DropdownMenuItem
                    htmlId="importDMSPartsPricingBtn"
                    eventKey={{ eventKey: ["importDMSPartsPricing"] }}
                    disabled={false}
                    onSelect={this.openImportPartsModal}
                  >
                    {localeStrings["xmm.portal.common.import_fluids_pricing"]}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    htmlId="clearFiltersBtn"
                    eventKey={{ eventKey: ["clear-filters"] }}
                    onSelect={this.clearFilters}
                  >
                    {localeStrings["xmm.portal.common.clear_filters"]}
                  </DropdownMenuItem>
                </DropdownButton>
                <SelectInput
                  htmlId="PartsStatusSelect"
                  placeholder="Select"
                  className="float-right"
                  disabled={false}
                  displayLabel={false}
                  displayPlaceholder={false}
                  maxHeight={100}
                  name="variantType"
                  onChange={this.onFluidsStatusChange}
                  label="View"
                  value={this.state.variantType}
                  options={[
                    {
                      value: "ALL",
                      label: this.context.localeStrings[
                        "xmm.portal.fluids_pricing.status_any"
                      ]
                    },
                    {
                      value: "ADDED",
                      label: this.context.localeStrings[
                        "xmm.portal.fluids_pricing.status_new_fluids_only"
                      ]
                    },
                    {
                      value: "CHANGED",
                      label: this.context.localeStrings[
                        "xmm.portal.fluids_pricing.status_changed_fluids_only"
                      ]
                    }
                  ]}
                />
                <div className="xmm-input-search float-right">
                  <input
                    type="text"
                    id="parts-pricing-search-box"
                    className="xmm-input"
                    placeholder={
                      localeStrings["xmm.portal.common.search_label"]
                    }
                    onChange={this.onSearchBoxChanged}
                    value={this.state.searchKey}
                    autoComplete="off"
                  />
                </div>
                <Button
                  htmlId="openBulkeditBtn"
                  buttonStyle="primary"
                  onClick={this.openBulkEditSlider}
                  className="xmm-bulkedit-btn float-right"
                  hidden={this.state.selectionlist.length < 2}
                >
                  <FormattedMessage
                    defaultMessage="Bulk Edit"
                    id="xmm.portal.fluids_pricing.bulkedit.title"
                  />
                  {" ("}
                  {this.state.selectionlist.length}
                  {")"}
                </Button>
                <div className="float-right">{statusHtml}</div>
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
    const bulkModalTitle = (
      <span>
        <FormattedMessage
          defaultMessage="Bulk Edit"
          id="xmm.portal.fluids_pricing.bulkedit.title"
        />
        {": "} {this.state.selectionlist.length} {" Fluids"}
      </span>
    );
    const bulkeditSlider = (
      <GenericSlider
        title={bulkModalTitle}
        htmlId="bulkEditSlider"
        showSlide={this.state.bulkEditSlide}
        toggleSlider={this.closeBulkEditSlider}
        sliderWidth={this.state.bulkEditSliderWidth}
        flexWidth={false}
      >
        <BulkEditPane
          key={"bulkFluidsPricing"}
          closeSlider={this.closeBulkEditSlider}
          selectionlist={this.state.selectionlist}
          uomlist={this.uomlist}
          updateStatusBox={this.updateStatusBox}
        />
      </GenericSlider>
    );
    return (
      <React.Fragment>
        <FluidsPricingContext.Provider value={contextValue}>
          {header}
          {gridWidget}
          {bulkeditSlider}
        </FluidsPricingContext.Provider>
      </React.Fragment>
    );
  }
}
function uomObj(params, uomlist) {
  return Object.keys(uomlist);
}
function extractValues(params) {
  const arr = [];
  if (!isEmptyString(params.data["msrp"])) {
    arr["MSRP"] = "MSRP";
  }
  if (!isEmptyString(params.data["dmsPrice"])) {
    arr["DMS"] = "DMS";
  }
  if (!isEmptyString(params.data["price"])) {
    arr["MANUAL"] = "MANUAL";
  }
  return Object.keys(arr);
}
function priceCount(params) {
  let count = 0;
  if (!isEmptyString(params.data["msrp"])) {
    count++;
  }
  if (!isEmptyString(params.data["dmsPrice"])) {
    count++;
  }
  if (!isEmptyString(params.data["price"])) {
    count++;
  }
  return count;
}
function iconCellRendererMSRP(params) {
  return iconCellRenderer(params, "MSRP");
}
function iconCellRendererDMS(params) {
  return iconCellRenderer(params, "DMS");
}
function iconCellRendererManual(params) {
  return iconCellRenderer(params, "MANUAL");
}
function iconCellRenderer(params, level) {
  let statusValue = toEmptyStringIfUndefined(params.data["source"]);
  statusValue = statusValue ? statusValue.toUpperCase() : "";
  // console.log(params.data.serviceId, params.value);

  let iconHtml = "";
  if (statusValue === level) {
    if (priceFormatter(params) === "-") {
      iconHtml = priceFormatter(params);
    } else {
      const { field } = params.colDef;
      if (field === "price" && params.data.errors && params.data.errors.price) {
        iconHtml = priceFormatter(params);
      } else {
        iconHtml =
          '<div class="xmm-flex-cell"><i class="fas fa-check"></i> <span>' +
          priceFormatter(params) +
          "</span></div>";
      }
    }
  } else {
    iconHtml = priceFormatter(params);
  }
  return iconHtml;
}
function descriptionCellRenderer(params) {
  const { data } = params;
  if (!data) {
    return "";
  }
  const { description, oemDescription } = data;
  if (isSameValue(description, oemDescription)) {
    return params.value;
  } else {
    return (
      params.value +
      '&nbsp;<span title=" ' +
      toEmptyStringIfUndefined(oemDescription) +
      ' " class="fas fa-pen float-right"></span>'
    );
  }
}
function partNumberCellRenderer(params) {
  const { data } = params;
  if (!data) {
    return "";
  }
  const { partNumber, oemPartNumber } = data;
  if (isSameValue(partNumber, oemPartNumber)) {
    return params.value;
  } else {
    return (
      params.value +
      '&nbsp;<span title=" ' +
      toEmptyStringIfUndefined(oemPartNumber) +
      ' " class="fas fa-pen float-right"></span>'
    );
  }
}
function fluidSpecFormatter(params) {
  const { apiSpec, tradeName, oilType } = params.data;
  let value = "";
  if (toEmptyStringIfUndefined(apiSpec) !== "") {
    value = value.concat(toEmptyStringIfUndefined(apiSpec));
  }
  if (toEmptyStringIfUndefined(tradeName) !== "") {
    const separator = value.length !== 0 ? ", " : "";
    value = value.concat(separator, toEmptyStringIfUndefined(tradeName));
  }
  if (toEmptyStringIfUndefined(oilType) !== "") {
    const separator = value.length !== 0 ? ", " : "";
    value = value.concat(separator, toEmptyStringIfUndefined(oilType));
  }
  return value;
}

function sourceCellClass(params) {
  return priceCount(params) < 2 ? "" : "editable-caret-cell";
}

export default FluidsPricing;
/* eslint-enable no-console */
