/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
import DropdownMenu from "@cx/ui/DropdownMenu";
import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import { shouldShowContentPanelUp } from "../../../../../../commonUtil/utils/menu";

export default class ExcludedRuleEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    console.log("ExcludedRuleEditor", props);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data } = props;
    return {
      dealerScheduleRule: data,
      highlightAllOnFocus
    };
  }

  onClick = event => {
    console.log("ExcludedRuleEditor.onClick", event);
  };

  getValue() {
    return this.state.dealerScheduleRule;
  }

  // for testing
  setValue(newValue) {
    //
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      if (this.state.highlightAllOnFocus) {
        const menuItemsUp = shouldShowContentPanelUp(
          "#grid-wrapper",
          this.wrapperRef,
          60
        );
        this.setState({
          highlightAllOnFocus: false,
          menuItemsUp
        });
      }
    }
  }

  isPopup() {
    return true;
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  renderExcludedRuleMoreButton = data => {
    const { parentHandle } = this.props;
    const { menuItemsUp } = this.state;
    const { inheritFromMenuTypeRules } = parentHandle;
    // const { context } = parentHandle;
    // const { localeStrings } = context;
    const { menuType } = data;
    const interitedFromMenuTypeText = `Inherit All Schedules from ${menuType}`;
    // localeStrings["xmm.portal.dealermenus.include_inherited_schedules"];
    return (
      <DropdownMenu
        // ref="dropdownButtonRef"
        buttonStyle="link"
        buttonText={<IconMore />}
        displayCaret={false}
        id="includeInteritedRuleActionBtn"
        className="xmm-rule-dropdown-menu"
        dropup={menuItemsUp}
        pullRight
        size="small"
        defaultOpen={true}
      >
        <DropdownMenuItem
          htmlId="includeInteritedRules"
          eventKey={{ eventKey: ["includeInteritedRules"] }}
          onSelect={event => {
            inheritFromMenuTypeRules(data);
            this.props.api.stopEditing();
          }}
        >
          {interitedFromMenuTypeText}
        </DropdownMenuItem>
      </DropdownMenu>
    );
  };
  render() {
    const { dealerScheduleRule } = this.state;
    const excludedScheduleRuleButton = this.renderExcludedRuleMoreButton(
      dealerScheduleRule
    );
    return (
      <React.Fragment>
        <div ref={this.setWrapperRef}>{excludedScheduleRuleButton}</div>
      </React.Fragment>
    );
  }
}

ExcludedRuleEditor.propTypes = {
  data: PropTypes.object,
  parentHandle: PropTypes.object
};
