/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
// import PropTypes from "prop-types";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { DaysOfWeekOptions } from "../constants/StoreHourConstants";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

export default class WeekDaysEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }
    return {
      weeksDaysOption: startValue,
      highlightAllOnFocus
    };
  }

  onChange = (cxEvent, isValid, domEvent) => {
    // const typeahead = document.getElementById(
    //   "searchableSelectInput-weekDaysOptions"
    // ); // this.refs.typeahead;
    // console.log(typeahead);
    // console.log("this.refs.typeahead", this.refs.typeahead);
    // console.log(this.state.weeksDaysOption, cxEvent);
    const { value } = cxEvent.target;
    // this.props.onChange(cxEvent, isValid, domEvent);
    this.setState({ weeksDaysOption: value });
  };

  getValue() {
    return this.state.weeksDaysOption;
  }

  // for testing
  setValue(newValue) {
    this.setState({
      weeksDaysOption: newValue
    });
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  isPopup() {
    return true;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      // get ref from React component
      // const typeahead = document.getElementById(
      //   "searchableSelectInput-weekDaysOptions"
      // ); // this.refs.typeahead;
      // eslint-disable-next-line no-console
      // console.log(typeahead);
      // typeahead.focus();
      this.setState({
        highlightAllOnFocus: false
      });
    } else {
      //
    }
  }

  render() {
    return (
      <div>
        <SearchableSelect
          // displayPlaceholder={false}
          className="xmm-scrollable-select"
          ref="typeahead"
          htmlId="weekDaysOptions"
          label=""
          name="weekDaysOptions"
          maxHeight={200}
          onChange={this.onChange}
          options={DaysOfWeekOptions}
          // required
          value={this.state.weeksDaysOption}
          displayLabel={false}
        />
      </div>
    );
  }
}
