/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../../components/app-context";
import Card from "@cx/ui/Card";
import Col from "@cx/ui/Col";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import { IntervalRecordDef } from "../../../../constants/ModuleConstants";
import IntervalsAPI from "../../../reusable/Intervals/index";
import { selectFirstItemFromArrayOfMaps } from "../../../../commonUtil/utils/list";
import { isSameValue } from "../../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { xlate } from "../../../../commonUtil/i18n/locales";

class Intervals extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onIntervalSelected = this.onIntervalSelected.bind(this);
    this.onAddIntervalEvent = this.onAddIntervalEvent.bind(this);
    this.onRemoveIntervalEvent = this.onRemoveIntervalEvent.bind(this);
    this.onIntervalSaved = this.onIntervalSaved.bind(this);
    this.onRefreshIntervals = this.onRefreshIntervals.bind(this);
    this.onSelectInterval = this.onSelectInterval.bind(this);
    this.isDirty = this.isDirty.bind(this);
    this.reset = this.reset.bind(this);
    // Note that this callback might be called later during rendering of the VehcileGroupForm
    this.intervalFormRef = null;
    this.setIntervalFormRef = ref => {
      this.intervalFormRef = ref;
    };
    this.initializeLocaleValues();
    this.state = {
      pageTitle: context.localeStrings["xmm.portal.common.intervals"],
      selectedInterval: null,
      sortedIntervals: [],
      dealerIntervalsMap: {},
      intervalRecord: Object.assign({}, IntervalRecordDef),
      selectedItem: null
    };
  }
  componentDidMount() {
    window.addEventListener(
      "intervalSelectedEvent",
      this.onIntervalSelected,
      false
    );
    window.addEventListener("addIntervalEvent", this.onAddIntervalEvent, false);
    window.addEventListener(
      "removeIntervalEvent",
      this.onRemoveIntervalEvent,
      false
    );
    window.addEventListener("intervalSaved", this.onIntervalSaved, false);
    window.addEventListener("refreshIntervals", this.onRefreshIntervals, false);
    window.addEventListener("selectInterval", this.onSelectInterval, false);
    this.getNamedIntervals(true);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "intervalSelectedEvent",
      this.onIntervalSelected,
      false
    );
    window.removeEventListener(
      "addIntervalEvent",
      this.onAddIntervalEvent,
      false
    );
    window.removeEventListener(
      "removeIntervalEvent",
      this.onRemoveIntervalEvent,
      false
    );
    window.removeEventListener("intervalSaved", this.onIntervalSaved, false);
    window.removeEventListener(
      "refreshIntervals",
      this.onRefreshIntervals,
      false
    );
    window.removeEventListener("selectInterval", this.onSelectInterval, false);
  }
  initializeLocaleValues() {
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deleteError = xlate("xmm.portal.errors.delete_interval");
  }
  /* Method returns record if exists within same make */
  findIntervalByMake(make, intervalId) {
    if (make === "" || intervalId === "" || isNaN(intervalId)) {
      return null;
    }
    // console.log("findIntervalBy Make", this.state.dealerIntervalsMap);
    const intervals = this.state.dealerIntervalsMap[make];
    if (!intervals) {
      return null;
    }
    const interval = intervals.filter(item => {
      return item.intervalId.toString() === intervalId.toString();
    });
    return interval.length !== 0 ? interval[0] : null;
  }
  onSelectInterval = event => {
    const { interval } = event.detail;
    this.setSelectedItem(interval);
  };
  onRefreshIntervals = event => {
    this.getNamedIntervals();
  };
  onIntervalSaved = event => {
    const { interval, addMode } = event.detail;
    const { sortedIntervals } = this.state;
    const maps = sortedIntervals.filter(map => {
      return map[interval.make] !== undefined;
    });
    if (maps.length === 0) {
      return;
    }
    const map = maps[0];
    const list = this.addOrUpdateIntervalToMap(interval, map, addMode);
    // const list = map[interval.make];
    // if (addMode) {
    //   list.push(interval);
    // } else {
    //   for (let index = 0; index < list.length; index++) {
    //     const { intervalId } = list[index];
    //     if (isSameValue(intervalId, interval.intervalId)) {
    //       list[index] = interval;
    //       break;
    //     }
    //   }
    // }
    const sortedList = list.sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      }
      return a.name > b.name ? 1 : -1;
    });
    map[interval.make] = sortedList;
    this.setState({ sortedIntervals }, () => {
      this.setSelectedItem(interval);
    });
  };
  addOrUpdateIntervalToMap = (interval, map, addMode) => {
    const list = map[interval.make];
    if (addMode) {
      list.push(interval);
    } else {
      for (let index = 0; index < list.length; index++) {
        const { intervalId } = list[index];
        if (isSameValue(intervalId, interval.intervalId)) {
          list[index] = interval;
          break;
        }
      }
    }
    return list;
  };
  removeInterval = interval => {
    const { sortedIntervals, dealerIntervalsMap } = this.state;
    const maps = sortedIntervals.filter(map => {
      return map[interval.make] !== undefined;
    });
    if (maps && maps.length !== 0) {
      const map = maps[0];
      this.removeItemFromMap(interval, map);
      this.removeItemFromMap(interval, dealerIntervalsMap);
      this.setState({
        sortedIntervals,
        dealerIntervalsMap
      });
    }
  };
  removeItemFromMap(interval, map) {
    const list = map[interval.make];
    if (list) {
      const newList = list.filter(item => {
        return item.intervalId.toString() !== interval.intervalId.toString();
      });
      map[interval.make] = newList;
    }
  }
  onAddIntervalEvent = event => {
    const { value, variant, dealerCode } = event.detail;
    const newIntervalItem = Object.assign({}, IntervalRecordDef);
    newIntervalItem.make = value;
    newIntervalItem.dealerCode = dealerCode;
    newIntervalItem.variant = variant;
    newIntervalItem.units = this.context.dealer.defaultUnits;
    gtmEvents.gtmTrackEvent("xmm.intervals.add_click");
    this.setSelectedItem(newIntervalItem);
  };
  /* custom event handler to delete single interval */
  onRemoveIntervalEvent = event => {
    const { intervalFormRef } = this;
    intervalFormRef.updateStatusBox(this.deletingMsg, "pending", false);
    const { intervalId } = event.detail;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/deleteDealerInterval";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: {
          intervalId
        },
        params: {},
        headers
      },
      response => {
        if (response && response.response.statusCode === 0) {
          intervalFormRef.updateStatusBox(this.deletedMsg, "success", true);
          setTimeout(() => {
            this.setSelectedItem(null);
            this.removeInterval(event.detail);
            window.dispatchEvent(
              new CustomEvent("removeIntervalFromList", {
                detail: event.detail,
                bubbles: true,
                cancelable: true
              })
            );
          }, 500);
          // const status = xlate("xmm.portal.common.interval_deleted");
          // toast.success(status);
        } else {
          // toast.error(xlate("xmm.portal.errors.delete_interval"));
          intervalFormRef.updateStatusBox(
            this.deleteError,
            "error",
            false,
            true
          );
        }
      },
      error => {
        // toast.error(error.message);
        intervalFormRef.updateStatusBox(error.message, "error", false, true);
      }
    );
  };
  /* Handler - event fired from GroupNameList when interval is selected */
  onIntervalSelected = event => {
    const { selectedItem } = event.detail;
    if (this.state.selectedItem !== selectedItem) {
      this.setSelectedItem(selectedItem);
    }
  };
  // Right place - Hook new API when ready 'XMM_FIND_INTERVALS" - http://xwsdev5.xtime.com/ddsoe/rest/oe
  /* This rest API returns Named intervals for a given dealercode */
  getNamedIntervals(selectFirstFlag) {
    const { dealerCode, locale } = this.context;
    if (!dealerCode) {
      return;
    }
    // const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/findDealerIntervals";
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/findNamedIntervals";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          dealerCode,
          locale
        }
      },
      response => {
        if (response) {
          const final = [];
          response.forEach(op => {
            const cloneObj = Object.assign({}, op);
            // transform  mileageTo, mileageFrom for single mileagepoint interval case [varaint level]
            if (!op.mileageFrom && !op.mileageTo && op.mileages) {
              cloneObj.mileageFrom = 0;
              cloneObj.mileageTo = parseInt(op.mileages, 10);
              // cloneObj.isSingleMileage = true;
            } else {
              // cloneObj.isSingleMileage = false;
            }
            final.push(cloneObj);
          });
          // console.log("getNamedIntervals per Dealer", final);
          const makeIntervalsMap = this.createMakeIntervalsMap(final);
          const sortedIntervals = this.createSortedIntervalsByMake(
            makeIntervalsMap
          );
          this.setState(
            {
              sortedIntervals,
              dealerIntervalsMap: makeIntervalsMap
            },
            () => {
              if (this.intervalFormRef) {
                this.intervalFormRef.setDealerIntervalsMap(makeIntervalsMap);
              }
              if (selectFirstFlag) {
                const firstInterval = selectFirstItemFromArrayOfMaps(
                  sortedIntervals
                );
                if (firstInterval) {
                  this.setSelectedItem(firstInterval);
                }
              }
            }
          );
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }
  /* This utils returns supported makes hashmap with intervals[] */
  createMakeIntervalsMap(datalist) {
    const makeIntervalsMap = {}; // hashmap
    // build a hash map of key/value pair: key: make value: [item]
    datalist.forEach(item => {
      if (!item.name || item.name === "") {
        item.name = "UNKNOWN";
        // item.isUnnamed = true;
      } else {
        // item.isUnnamed = false;
      }
      if (makeIntervalsMap[item.make] === undefined) {
        makeIntervalsMap[item.make] = [item];
      } else {
        makeIntervalsMap[item.make].push(item);
      }
    });
    // Here, put in the missing makes into the maps
    const { makelist } = this.context;
    const makeKeys = Object.keys(makeIntervalsMap);
    const missingMakes = makelist
      .filter(m => {
        return !makeKeys.includes(m.make);
      })
      .map(m1 => {
        return m1.make;
      });
    missingMakes.forEach(m => {
      makeIntervalsMap[m] = [];
    });
    // console.log("createMakeIntervalsMap", makeIntervalsMap);
    return makeIntervalsMap;
  }

  /* This method returns list sorted by Make */

  createSortedIntervalsByMake = makeIntervalsMap => {
    // console.log("Hashmap intervals", JSON.stringify(makeIntervalsMap, null, 2));
    // sort the make keys alphabetically
    const makeKeys = Object.keys(makeIntervalsMap);
    const sortMakeKeys = makeKeys.sort((a, b) => {
      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison;
    });
    // build the one-dimensional array of objects (each object has key = make and value = [item])
    const sortedMakeIntervalList = sortMakeKeys.map(key => {
      return {
        [key]: makeIntervalsMap[key]
      };
    });
    // console.log("createSortedIntervalsByMake", sortedMakeIntervalList);
    return sortedMakeIntervalList;
  };

  /* Read selected interval record and set to Interval Form */
  /*
   {
      "intervalId": 670059,
      "name": "Every 15,000 miles",
      "abbreviation": "15k+",
      "units": "miles",
      "mileageFrom": 15000,
      "mileageTo": 150000,
      "mileageInterval": 15000,
      "mileages": "15000,30000,45000,60000,75000,90000,105000,120000,135000,150000",
      "make": "CADILLAC",
      "variant": "CADILLACUSA_ENH2",
      "usageCount": 0,
      "isUnnamed": false
    }
  */

  // add case - make,variant, dealercode passed in intervalRecord
  // edit case - selected interval record passed
  loadIntervalRecord = intervalRecord => {
    Object.entries(intervalRecord).forEach(([key, val]) => {
      if (val && typeof val === "number") {
        intervalRecord[key] = val.toString();
      }
    });
    // check if usageCount exist in record
    if (!intervalRecord.hasOwnProperty("usageCount")) {
      intervalRecord.usageCount = 0;
    }
    // console.log("transform intervalRecord", intervalRecord);
    this.setState({ intervalRecord }, () => {
      if (this.intervalFormRef) {
        this.intervalFormRef.setIntervalRecord(intervalRecord);
      }
    });
  };

  /* Call this handler when Add Interval clicked & item selected from Interval selected - set interval record;
     Delete interval case - set default interval record */
  setSelectedItem = selectedItem => {
    // pre-selected interval case
    if (selectedItem) {
      // console.log("setSelectedItem", selectedItem);
      this.loadIntervalRecord(selectedItem);
      this.setState({ selectedItem });
    } else {
      // delete interval case
      const intervalRecord = Object.assign({}, IntervalRecordDef);
      if (this.intervalFormRef) {
        this.intervalFormRef.setIntervalRecord(intervalRecord);
      }
      this.setState({ selectedItem: null, intervalRecord });
    }
    this.reset(false);
  };

  isDirty = () => {
    return this.intervalFormRef.isDirty();
  };

  reset = dirty => {
    // this.refs.intervalFormRef.setDirty(dirty);
    if (this.intervalFormRef) {
      this.intervalFormRef.reset(dirty);
    }
  };

  render() {
    const msgSection = null;
    const AddIntervalButton = IntervalsAPI.AddIntervalButton;
    const header = (
      <React.Fragment>
        {msgSection}
        <div>
          <Grid htmlId="pageHead">
            <Row className="show-grid">
              <Col xs={4} sm={4} className="full-col">
                <h3>{this.state.pageTitle}</h3>
                <AddIntervalButton
                  isFormDirty={this.isDirty}
                  resetForm={this.reset}
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
    const GroupNameFilterCmp = IntervalsAPI.GroupNameFilter;
    const IntervalFormCmp = IntervalsAPI.IntervalForm;
    const intervalFormInstance = (
      <IntervalFormCmp
        key={"intervalFormCmp"}
        ref={this.setIntervalFormRef}
        className="xmm-intervals-root"
        formClassName="xmm-interval-form"
        editForm={true}
      />
    );

    const groupNameFilter = (
      <GroupNameFilterCmp
        groupNames={this.state.sortedIntervals}
        selectedItem={this.state.selectedItem}
      />
    );

    return (
      <div className="xmm-center-container">
        {header}
        <Card htmlId="intervalsCard" className="xmm-manage-vehicle">
          {groupNameFilter}
          {intervalFormInstance}
        </Card>
      </div>
    );
  }
}

export default Intervals;

Intervals.propTypes = {};
