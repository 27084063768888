import React, { Component } from "react";
import { bool, PropTypes } from "prop-types";
import SlidingDrawer from "@cx/ui/SlidingDrawer";
/**
 * @class SlidingDrawerPage
 * This Component used in common layouts
 * ! Dupliate wrapper comp, not used
 */
export default class SlidingDrawerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      sliderWidth: props.sliderWidth,
      showSlide: props.showSlide,
      toggleDrawer: props.toggleDrawer
    };
  }
  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const slideWidth = Math.round(window.innerWidth - 400);
    this.setState({ sliderWidth: slideWidth });
  }

  render() {
    return (
      <div>
        <SlidingDrawer
          htmlId="SlidingDrawerPage"
          show={this.props.showSlide}
          panelWidth={this.state.sliderWidth}
          hide={this.props.toggleDrawer} // To be removed after upgrading to CX 4
          onHide={this.props.toggleDrawer}
          closeButton={true}
          position="right"
          className="xmm-flex-slider"
        >
          <h3>{this.props.title}</h3>
          {this.props.children}
        </SlidingDrawer>
      </div>
    );
  }
}

SlidingDrawerPage.propTypes = {
  title: PropTypes.string,
  sliderWidth: PropTypes.number,
  showSlide: bool,
  children: PropTypes.object,
  toggleDrawer: PropTypes.func
};
