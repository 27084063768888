/* eslint-disable no-console */
import React, { Component } from "react";
import Card from "@cx/ui/Card";
import Col from "@cx/ui/Col";
import IconSearch from "@cx/ui/Icons/IconSearch";
import Row from "@cx/ui/Row";
import TextInput from "@cx/ui/TextInput";
import { toast } from "@cx/ui/Toast";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import { PropTypes } from "prop-types";

import { makeSecureRestApi } from "../../../../api/xmmAxios";
import FindOpCodesDialog from "../../../../commonUtil/dialog/FindOpCodesDialog";
import {
  toAscii,
  toEmptyStringIfUndefined
} from "../../../../commonUtil/utils/string";
import { AppContext } from "../../../../components/app-context";
import { OperationContext } from "../../Operations/operation-context";

export default class IntegratedOperations extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);

    this.inspectToggleRef = React.createRef();

    const { showInspect, inspectRecord } = context;
    this.state = {
      dmsOpcode: "",
      enabled: 1,
      serviceId: "",
      serviceName: "",
      description: "",
      disableDeclinedApp: true,
      dirty: false,
      showInspect,
      inspectRecord
    };
  }

  componentDidMount() {
    this.getDeclinedService();
    this.getInspectSettings();
  }

  updateState(response) {
    if (response && response.response && response.response.service) {
      this.setState(prevState => ({
        dmsOpcode: toEmptyStringIfUndefined(
          response.response.service.dmsOpcode
        ),
        enabled: response.response.service.enabled,
        serviceId: response.response.service.serviceId,
        serviceName: response.response.service.name,
        description: "",
        dirty: false,
        disableDeclinedApp:
          response.response.isDeclinedApplicable === "N" ? true : false
      }));
      this.refs.toggleRef.setState({
        checked: response.response.service.enabled === 1
      });
    } else {
      if (
        response &&
        response.response &&
        response.response.isDeclinedApplicable === "Y"
      ) {
        this.setState(prevState => ({
          dmsOpcode: "",
          enabled: 1,
          serviceId: "",
          serviceName: "",
          description: "",
          dirty: false,
          disableDeclinedApp: false
        }));
        this.refs.toggleRef.setState({
          checked: true
        });
      } else {
        if (this.refs.toggleRef) {
          this.refs.toggleRef.setState({
            checked: false
          });
        }
      }
    }
  }

  getDeclinedService() {
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getDeclinedService",
        method: "get",
        data: {},
        params: { dealerCode }
      },
      response => {
        this.updateState(response);
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  editDeclinedService(checked) {
    const { dealerCode, localeStrings } = this.context;
    const { dmsOpcode } = this.state;
    const enabled = checked ? 1 : 0;
    // const opcode = toEmptyStringIfUndefined(dmsOpcode);
    makeSecureRestApi(
      {
        url:
          "/ops/proxyapi/internaloeproxy/rest/internal/xmmservices/editDeclinedService",
        method: "get",
        data: {},
        params: { dealerCode, dmsOpcode, enabled }
      },
      response => {
        // console.log(response);
        this.updateState(response);
        toast.success(localeStrings["xmm.portal.common.successful_save"]);
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  onClick = (cxEvent, domEvent) => {
    const checked = cxEvent.target.value === "checked";
    this.refs.toggleRef.setState({ checked });
    this.setState({ dirty: true, enabled: checked ? 1 : 0 });
    this.editDeclinedService(checked);
  };

  onBlur = (cxEvent, domEvent) => {
    if (this.state.dirty) {
      this.editDeclinedService(this.state.enabled === 1);
    }
  };

  onChange = (cxEvent, isValid, domEvent) => {
    if (
      toEmptyStringIfUndefined(cxEvent.target.value) !==
      toEmptyStringIfUndefined(this.state.dmsOpcode)
    ) {
      const dmsOpcode = toAscii(cxEvent.target.value);
      this.setState({ dirty: true, dmsOpcode });
    }
  };

  openOpcodeModal = () => {
    this.setState({ showOpCodeModal: true });
  };

  getInspectSettings() {
    const { showInspect } = this.state;
    this.inspectToggleRef.current.setState({
      checked: showInspect
    });
  }
  render() {
    const contextValue = {
      localeStrings: this.context.localeStrings,
      discardUnsavedChanges: this.context.discardUnsavedChanges,
      webKey: this.context.webKey,
      dealerCode: this.context.dealerCode,
      appContext: this.context // send appContent as props
    };
    const { localeStrings } = this.context;
    const findOpCodesModal = (
      <FindOpCodesDialog
        showValidateCatalog={true}
        dealerCode={contextValue.dealerCode}
        serviceId={this.state.serviceId.toString()}
        internalName={this.state.serviceName}
        dmsOpcode={this.state.dmsOpcode}
        dmsDescription={this.state.description}
        localeStrings={contextValue.localeStrings}
        manualOpcodes={this.context.manualOpcodes}
        show={this.state.showOpCodeModal}
        closeDialog={() => {
          this.setState({ showOpCodeModal: false });
        }}
        setManualOpcodes={this.context.setManualOpcodes}
        setOpcodeValue={dmsOpcode => {
          this.onChange({ target: { value: dmsOpcode } });
          if (this.state.dirty) {
            this.editDeclinedService(this.state.enabled === 1);
          }
        }}
      />
    );
    const maskStyle = this.state.disableDeclinedApp ? "xmm-mask" : "";
    const declinedHeader =
      localeStrings["xmm.portal.integratedoperations.declined_services_lbl"];

    const clsInspect = this.state.showInspect ? "" : "hide";
    const inspectWidget = (
      <div className="xmm-center-container">
        <Card htmlId="inspectSettingsCard" className={clsInspect}>
          <div className="settings-card-header">
            <div className="header-left">
              <h4>{localeStrings["xmm.portal.inspect_settings_lbl"]}</h4>
            </div>
          </div>
          <br />
          <Row>
            <Col xs={4} md={4}>
              <span className="float-right">
                {localeStrings["xmm.portal.inspect_common_ops_lbl"]}
              </span>
            </Col>
            <Col xs={8} md={8}>
              <ToggleSwitch
                ref={this.inspectToggleRef}
                htmlId="inspect-toggle"
                defaultChecked={false}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4}>
              <span className="float-right">
                {localeStrings["xmm.portal.inspect_store_lbl"]}
              </span>
            </Col>
            <Col xs={8} md={8}>
              <h5> {this.state.inspectRecord.externalId || ""} </h5>
            </Col>
          </Row>
        </Card>
      </div>
    );

    return (
      <div className="xmm-center-container">
        <OperationContext.Provider value={contextValue}>
          {findOpCodesModal}
          <h3>{localeStrings["xmm.portal.nav.integrated_operations"]}</h3>
          <Row>
            <Col md={12}>
              <Card
                htmlId="declinedServicesCard"
                header={declinedHeader}
                className={maskStyle}
              >
                <div className="xmm-help-text">
                  {
                    localeStrings[
                      "xmm.portal.integratedoperations.provide_opcode_txt"
                    ]
                  }
                  <br />
                  {
                    localeStrings[
                      "xmm.portal.integratedoperations.opcode_shared_txt"
                    ]
                  }
                  <br />
                  {
                    localeStrings[
                      "xmm.portal.integratedoperations.multiple-opcode_txt"
                    ]
                  }
                </div>
                <br />
                <div className="card__dropdown">
                  <ToggleSwitch
                    ref="toggleRef"
                    htmlId="toggle-card"
                    onClick={this.onClick}
                  />
                </div>
                <Row>
                  <Col xs={2} md={2}>
                    <span className="float-right">
                      {localeStrings["xmm.portal.grid.opcode"]}{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="dmsOpcode"
                      label="Op Code"
                      name="dmsOpcode"
                      displayLabel={false}
                      disabled={!this.state.enabled}
                      onBlur={this.onBlur}
                      onChange={this.onChange}
                      value={this.state.dmsOpcode}
                    >
                      <TextInput.Addon
                        addonType="button"
                        htmlId="dmsOpcodeAppendButton"
                        className="btn--icon"
                        onClick={event => {
                          this.setState({
                            showOpCodeModal: true
                          });
                        }}
                      >
                        <IconSearch htmlId="iconSearchForm" />
                      </TextInput.Addon>
                    </TextInput>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {inspectWidget}
        </OperationContext.Provider>
      </div>
    );
  }
}
