/* eslint-disable no-console */
import React, { useContext, useState } from "react";
import Tab from "@cx/ui/Tab";
import Tabs from "@cx/ui/Tabs";
import { PropTypes } from "prop-types";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
import {
  deepCopyFromTemplate,
  compareObject,
  doesEmpty
} from "../../../../../../commonUtil/utils/object";
import {
  isDifferentValue,
  toEmptyStringIfUndefined,
  isSameValue
} from "../../../../../../commonUtil/utils/string";
import { InspectionItemCategory } from "../../../../../reusable/operation";
import { OperationContext } from "../../../operation-context";
import Preview from "./Preview";
import OperationFormNew from "./OperationFormNew";
import PricingOpcode from "../overrides/PricingOpcode";
import * as formvalidator from "../../../../../formik/formvalidator";
import { toast } from "@cx/ui/Toast";
import { Formik } from "formik";

import {
  AddAnyMakePayload,
  EditAnyMakePayload,
  getCatalogFromMakeVariantMap
} from "../../../../../../constants/ModuleConstants";

// If func()component is not destroyed, always read props from parent and pass to children
function OperationTabsNew(props) {
  const opContext = useContext(OperationContext);
  const [formData, setFormData] = useState(props.loadOperation); // set default object or passed props in state
  // Demo purpose - if we need to fetch data for children per operation from cache, try this
  /*
  React.useEffect(() => {
    if (previewlist.length === 0 && props.editOption === "edit") {
      // rest call
    } else {
      console.log(
        "else - Tabs previewlist for service",
        props.loadOperation.serviceId
      );
    }
    // pass [] - this is only executed once;  pass an array of objects (dependencies) to useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  // Bug - Normalise null values,array values  to avoid warnings of formik {initialValues} which is uncontrolled component;
  // https://github.com/jaredpalmer/formik/issues/568
  const initialData = formvalidator.encodeNullValues(formData);
  // console.log("encode formik values", JSON.stringify(props, null, 2));

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  /* formik submit handler */
  const handleSubmit = (values, actions) => {
    // console.log("formik submit called", values, actions);
    sleep(1000).then(() => {
      // submit them do the server if no errors
      // console.log("form dirty", JSON.stringify(values, null, 2));
      if (values) {
        actions.setSubmitting(true);

        const { make } = values;
        if (make === "ANY") {
          callBackSaveAnyMakeOperation(values, actions);
        } else {
          callBackSaveOperation(values, actions);
        }
      } else {
        const error = new Error("There was an error saving your changes.");
        actions.setErrors([error]);
      }
    });
  };
  // eslint-disable-next-line no-unused-vars
  const showAlert = () => {
    // popup case
    // console.log("calling showAlert from Child");
  };

  // Since there is no dirtycheck on form fields, we set all visible fields in payload
  function getEditPayload(values) {
    if (toEmptyStringIfUndefined(values.make) !== "") {
      const makeList = opContext.appContext.makelist.filter(
        m => m.value === values.make
      );
      if (makeList && makeList.length > 0) {
        values.dealerCode = makeList[0].dealerCode;
        values.variant = makeList[0].variant;
      }
    }
    values.modUser = opContext.userName;
    const keys = Object.keys(values);
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (typeof values[key] === "boolean") {
        values[key] = values[key] | 0;
      }
    } // for

    if (props.editOption === "add" || values.make === "ANY") {
      return values;
    }

    // edit case
    const diffs = compareObject(props.loadOperation, values);
    // console.log("old vs new payload", diffs);
    const { dealerCode, user } = opContext.appContext;
    const { make, variant, serviceId } = values;
    const payload = {
      make,
      variant,
      dealerCode,
      serviceId,
      modUser: user.userName
    };
    if (diffs) {
      const keys = Object.keys(diffs);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (isDifferentValue(values[key], props.loadOperation[key])) {
          // if (SerivceLocaleKeys.includes(key)) {
          //   SerivceLocaleKeys.forEach(serviceLocaleKey => {
          //     payload[serviceLocaleKey] = values[serviceLocaleKey];
          //   });
          // } else {
          payload[key] = values[key];
          // }
        }
      }
    }
    return payload;
  }

  function getContentAndPriceStatuses(dealerOperation, actions) {
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationsList",
        method: "get",
        data: {},
        params: {
          dealerCode: dealerOperation.dealerCode,
          serviceId: dealerOperation.serviceId
        }
      },
      response => {
        const datalist = response.data;
        if (datalist && datalist.length !== 0) {
          const contentAndPriceStatuses = datalist[0];
          dealerOperation.contentEnabledAlacarte =
            contentAndPriceStatuses.contentEnabledAlacarte;
          dealerOperation.contentEnabledMenus =
            contentAndPriceStatuses.contentEnabledMenus;
          dealerOperation.priceStatus = contentAndPriceStatuses.priceStatus;
          dealerOperation.laborTimeRange =
            contentAndPriceStatuses.laborTimeRange;
          dealerOperation.dealerLaborRateCodeId =
            contentAndPriceStatuses.dealerLaborRateCodeId;
          dealerOperation.numOpcodeOverrides =
            contentAndPriceStatuses.numOpcodeOverrides;
          dealerOperation.numPricingOverrides =
            contentAndPriceStatuses.numPricingOverrides;
        }
        setFormData(dealerOperation); // update local state {formData}
        // when state {formData} updated, Main fun() updates {initialData}
        if (actions) {
          actions.resetForm(formvalidator.encodeNullValues(dealerOperation));
        }
        // update context {loadOperation}
        opContext.updateGridAfterSave(props.editOption, dealerOperation);

        if (actions) {
          // const msg =
          //   opContext.localeStrings["xmm.portal.common.successful_save"];
          actions.setStatus({
            msg: opContext.appContext.localeStrings["xmm.portal.common.saved"],
            type: "success",
            autoClose: true
          });
          // toast.success(msg);
        }
      },
      error => {
        if (actions) {
          const msg = error["message"]
            ? error.message
            : "There was an error saving your changes.";
          actions.setStatus({ msg });
        }
        // toast.error(error.message);
        actions.setStatus({
          msg: error.message,
          type: "error",
          autoClose: false
        });
      }
    );
  }

  function deleteServiceHours(values, actions, postObj) {
    // const headers = {
    //   Accept: "application/json",
    //   "Content-Type": "application/json"
    // };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/deleteServiceHours",
        method: "get",
        data: {},
        params: { serviceId: formData.serviceId }
      },
      data => {
        //
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error deleting the service hours.";
        // toast.error(msg);
        actions.setStatus({
          msg,
          type: "error",
          autoClose: false
        });
      }
    );
  }
  function updateServiceTags(values, actions, postObj) {
    const tags = [];
    if (postObj.serviceTag) {
      tags.push(postObj.serviceTag);
    }
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/setServiceTags",
        method: "post",
        data: { serviceId: formData.serviceId, tags }
      },
      data => {
        console.log("updateServiceTags", data);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error updating the service tag.";
        // toast.error(msg);
        actions.setStatus({
          msg,
          type: "error",
          autoClose: false
        });
      }
    );
  }

  // callback to save operation and update grid level row record
  function callBackSaveOperation(values, actions) {
    const postObj = getEditPayload(values);
    // console.log("payload to save", JSON.stringify(postObj, null, 2));
    if (postObj.serviceHoursCount !== undefined) {
      // delete service hours
      if (postObj.serviceHoursCount === 0) {
        deleteServiceHours(values, actions, postObj);
      }
      if (Object.keys(postObj).length === 1) {
        postObj.dealerCode = values.dealerCode;
      }
    }
    // if not null and not undefined then save it
    if (!doesEmpty(postObj.serviceTag, true)) {
      updateServiceTags(values, actions, postObj);
    }
    // const keys = Object.keys(postObj);
    const restEndPoint =
      props.editOption === "edit"
        ? "/ops/proxyapi/ddsproxy/rest/table/dealerOperation/" +
          formData.serviceId +
          "?_method=put"
        : "/ops/proxyapi/ddsproxy/rest/table/dealerOperation?_method=post";
    const { makeVariantMap } = opContext.appContext;
    const { baseLocale } = makeVariantMap[values.make];
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Variant-Base-Locale": baseLocale
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: postObj,
        params: {},
        headers
      },
      data => {
        if (data && data.success) {
          // copy new values from response to form.values - preserving custom properties
          const {
            internalName,
            name,
            extId,
            description,
            inspectionItem,
            serviceTag
          } = values;
          const dealerOperation = Object.assign(data.dealerOperation, {
            internalName,
            name,
            description,
            extId
          });
          const { engageEnabled } = opContext.appContext;
          if (engageEnabled) {
            dealerOperation.inspectionItem = inspectionItem;
            dealerOperation.serviceTag = serviceTag;
          }
          getContentAndPriceStatuses(dealerOperation, actions);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        // actions.setStatus({ msg: status });
        toast.error(msg);
        // actions.resetForm(formvalidator.encodeNullValues(values));
        // actions.setStatus({ msg: "" });
      }
    );
  }
  // callback to save operation for ANY make and update grid level row record
  function callBackSaveAnyMakeOperation(values, actions) {
    getEditPayload(values);
    const metaVehicleScopeUpdated = isDifferentValue(
      formData.metaVehicleScope,
      values.metaVehicleScope
    );
    const { user, dealerCode } = opContext.appContext;
    values.dealerCode = dealerCode;
    const copyTemplate =
      props.editOption === "add" ? AddAnyMakePayload : EditAnyMakePayload;
    const postObj = deepCopyFromTemplate(values, copyTemplate);
    postObj.modUser = user.userName;
    postObj.locale = opContext.appContext.locale;
    const { engageEnabled } = opContext.appContext;
    if (engageEnabled) {
      postObj.tags = [];
      if (values.serviceTag && values.serviceTag !== InspectionItemCategory) {
        postObj.tags.push(values.serviceTag);
      }
    }
    const restEndPoint =
      props.editOption === "edit"
        ? "/ops/proxyapi/ddsproxy/rest/proc/editService/"
        : "/ops/proxyapi/ddsproxy/rest/proc/addService";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: postObj,
        params: {},
        headers
      },
      data => {
        const { response } = data;
        if (response && response.statusCode === 0) {
          const { service } = response;
          // if (service) {
          //   // editService doesn't return correct metaVehicleScope
          //   updateAnyServiceData(service, values);
          //   // when state {formData} updated, Main fun() updates {initialData}
          //   const dealerOperation = deepCopyFromTemplate(service, values);
          //   formvalidator.encodeNullValues(dealerOperation);
          //   setFormData(dealerOperation); // update local state {formData}
          //   if (metaVehicleScopeUpdated) {
          //     editMetaVehiclesScopeForAnyMake(dealerOperation, actions);
          //   }
          //   getContentAndPriceStatuses(dealerOperation, actions);
          // }
          getDealerService(
            service.serviceId,
            values,
            actions,
            metaVehicleScopeUpdated
          );
        } else {
          actions.resetForm(values);
          const msg = "There was an error saving your changes.";
          // actions.setStatus({ msg: status });
          // toast.error(status);
          actions.setStatus({
            msg,
            type: "error",
            autoClose: false
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        // actions.setStatus({ msg });
        toast.error(msg);
        // actions.setStatus({
        //   msg,
        //   type: "error",
        //   autoClose: false
        // });
      }
    );
  }
  function getDealerService(
    serviceId,
    values,
    actions,
    metaVehicleScopeUpdated
  ) {
    const restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/getDealerService";
    const { locale } = opContext.appContext;
    const params = {
      serviceId,
      locale,
      strictLocaleMatch: 0,
      ignoreDurationUnits: 1
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        params
      },
      data => {
        if (Array.isArray(data) && data.length !== 0) {
          const service = data[0];
          if (service) {
            // editService doesn't return correct metaVehicleScope
            updateAnyServiceData(service, values);
            // when state {formData} updated, Main fun() updates {initialData}
            const dealerOperation = deepCopyFromTemplate(service, values);
            formvalidator.encodeNullValues(dealerOperation);
            setFormData(dealerOperation); // update local state {formData}
            if (metaVehicleScopeUpdated) {
              editMetaVehiclesScopeForAnyMake(dealerOperation, actions);
            }
            getContentAndPriceStatuses(dealerOperation, actions);
          }
        } else {
          actions.resetForm(values);
          const msg = "There was an error saving your changes.";
          // actions.setStatus({ msg: status });
          // toast.error(status);
          actions.setStatus({
            msg,
            type: "error",
            autoClose: false
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        // actions.setStatus({ msg });
        toast.error(msg);
        // actions.setStatus({
        //   msg,
        //   type: "error",
        //   autoClose: false
        // });
      }
    );
  }
  function updateAnyServiceData(service, values) {
    // add metaVehicleScope and serviceCategoryName to service object
    service.metaVehicleScope = values.metaVehicleScope;
    if (
      isDifferentValue(formData.serviceCategoryId, values.serviceCategoryId)
    ) {
      if (values.serviceCategoryId) {
        const serviceCategoryName =
          opContext.appContext.serviceCategoryValueLabelMap[
            values.serviceCategoryId.toString()
          ];
        if (serviceCategoryName) {
          service.serviceCategoryName = serviceCategoryName;
        }
      }
    }
  }
  // we're exporting a component.
  function editMetaVehiclesScopeForAnyMake(dealerOperation, actions) {
    const {
      serviceId,
      metaVehicleScope,
      metaVehicleFilterId
    } = dealerOperation;
    const data = {
      requestType: "",
      authId: "",
      serviceId,
      metaVehicleScope,
      metaVehicleFilterId
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/editMetaVehiclesScope",
        method: "post",
        data
      },
      result => {
        if (result.response && result.response.statusCode === 0) {
          console.log("metaVehicleScope", metaVehicleScope);
        } else {
          const msg = "There was an error saving a la carte vehicles.";
          // toast.error(status);
          actions.setStatus({
            msg,
            type: "error",
            autoClose: false
          });
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }
  function getPricingMethod(make) {
    const { makeVariantMap } = opContext.appContext;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    return pricingMethod;
  }

  const formSchema = formvalidator.getFormValidationSchema(
    props.editOption,
    opContext.localeStrings
  );
  const SettingsPage = (
    <Formik
      initialValues={initialData}
      onSubmit={handleSubmit}
      validationSchema={formSchema}
      children={formikProps => (
        <OperationFormNew
          editOption={props.editOption}
          editForm={true}
          loadOperation={formData}
          formikProps={formikProps}
        />
      )}
    />
  );

  const PreviewPage = <Preview loadOperation={props.loadOperation} />;
  const PricingPage = (
    <PricingOpcode
      operation={props.loadOperation}
      updatePriceStatus={dealerOperation => {
        getContentAndPriceStatuses(dealerOperation);
      }}
    />
  );
  // Check if operation related to tellUsMore
  const hasTellUsMore = isSameValue(
    props.loadOperation.serviceId,
    opContext.appContext.tellUsMoreServiceId
  );
  const disabledPreview =
    props.editOption === "add" ||
    formData.make === "" ||
    formData.make === "ANY";

  const disabledPricingOpcodes =
    props.editOption === "add" ||
    formData.make === "" ||
    hasTellUsMore ||
    (formData.make === "ANY" && getPricingMethod(formData.make) === 0);

  const tabSection = (
    <Tabs htmlId="OperationTabs">
      <Tab
        label={
          opContext.localeStrings["xmm.portal.operations.form.settings_tab"]
        }
        active
      >
        {SettingsPage}
      </Tab>
      <Tab
        label={
          opContext.localeStrings["xmm.portal.operations.tab.pricing_opcodes"]
        }
        disabled={disabledPricingOpcodes}
      >
        {PricingPage}
      </Tab>

      <Tab
        disabled={disabledPreview}
        label={opContext.localeStrings["xmm.portal.operations.tab.preview"]}
      >
        {PreviewPage}
      </Tab>
    </Tabs>
  );

  return <div>{tabSection}</div>;
}

export default OperationTabsNew;

OperationTabsNew.propTypes = {
  editOption: PropTypes.string,
  loadOperation: PropTypes.object,
  updateGridAfterSave: PropTypes.func
};
