/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import { makeSecureRestApi } from "../../../../api/xmmAxios";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import { isArrayExist, isEmpty } from "../../../../commonUtil/utils/object";
import { blankValueFormatter } from "../../../../commonUtil/utils/formatter";
import {
  datetimeComparator,
  formatDateTimezone
} from "../../../../commonUtil/utils/date";
import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import * as gtag from "../../../utils/gtag-manager";
import Tooltip from "@cx/ui/Tooltip";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
let publishLocale = "en_US";
export default class PublishHistory extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    publishLocale = context.locale;
    const localeStrings = context.localeStrings;
    const gridOptions = {
      // ag-grid props
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false,
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        // minWidth: 120,
        autoHeight: true,
        filter: true,
        rowGroup: false,
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        }
      },
      multiSortKey: "ctrl",
      rowData: null,
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.loading_msg"),
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.no_records_msg"),
        isLoading: false,
        noRows: true
      },
      // Note: Get locale strings from util fun() for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    gtmEvents.trackGtagPageview("/publish/publish-history");
  }
  onFirstDataRendered(params) {
    this.sizeToFit();
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadHistoryData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };

  updateState(data) {
    if (data) {
      let datalist = [];
      // console.log("publish history response", data);
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
      }
      if (isArrayExist(datalist) && datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      const list = datalist.map((m, index) => {
        const row = Object.assign({}, m);
        row.recordId = index;
        row.user = null;
        const userName = toEmptyStringIfUndefined(m.publishedByUsername);
        if (
          userName &&
          userName !== "Automated Publish" &&
          userName !== "automationuser"
        ) {
          row.isUser = true;
        } else {
          row.isUser = false;
        }
        return row;
      });
      this.setState(
        {
          rowData: list
        },
        (prevState, props) => {
          this.sizeToFit();
        }
      );
    }
  }
  loadHistoryData() {
    const { dealerCode } = this.context;
    const payload = {
      dealerCode
    };
    const restUrl = "/ops/proxyapi/ddsproxy/rest/table/dealerPublishHistory";

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    this.gridApi && this.gridApi.showLoadingOverlay();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          this.updateState(data);
        }
      },
      error => {
        toast.error(error.message);
        this.gridApi && this.gridApi.showNoRowsOverlay();
      }
    );
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "rawPublishDate",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }

  getColumnList(localeStrings) {
    const baseCols = [
      /* {
        headerName: "ID",
        field: "recordId",
        width: 50
      }, */
      {
        headerName: localeStrings["xmm.portal.grid.make"],
        headerClass: "ag-text-header",
        field: "make",
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        suppressSizeToFit: true,
        maxWidth: 180,
        minWidth: 150,
        width: 150,
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.publish_history.grid.datetime"],
        headerClass: "ag-text-header",
        field: "rawPublishDate",
        // valueGetter: publishDateGetter, // Use this getter to support Date filter
        valueFormatter: publishDateFormatter,
        sortingOrder: ["asc", "desc"],
        comparator: datetimeComparator,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"],
          filterOptions: ["contains"],
          debounceMs: 500,
          textCustomComparator(filter, value, filterText) {
            const formatVal = formatFullDate(value);
            const filterTextLoweCase = filterText.toLowerCase();
            const valueLowerCase = formatVal.toString().toLowerCase();
            switch (filter) {
              case "contains":
                return valueLowerCase.indexOf(filterTextLoweCase) >= 0;
              case "notContains":
                return valueLowerCase.indexOf(filterTextLoweCase) === -1;
              case "equals":
                return valueLowerCase === filterTextLoweCase;
              case "notEqual":
                return valueLowerCase !== filterTextLoweCase;
              case "startsWith":
                return valueLowerCase.indexOf(filterTextLoweCase) === 0;
              case "endsWith":
                // eslint-disable-next-line no-case-declarations
                const idx = valueLowerCase.lastIndexOf(filterTextLoweCase);
                return (
                  idx >= 0 &&
                  idx === valueLowerCase.length - filterTextLoweCase.length
                );
              default:
                // should never happen
                console.warn("Invalid filter type", filter);
                return false;
            }
          }
        }
        /* Deprecate - Date filter
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear"],
          comparator(filterLocalDateAtMidnight, cellValue) {
            const cellDate = formatDateForSorting(cellValue);
            console.log(filterLocalDateAtMidnight, cellDate);
            if (cellDate == null) return -1;

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          }
        }*/
      },
      {
        headerName:
          localeStrings["xmm.portal.publish_history.grid.publishedby"],
        headerClass: "ag-text-header",
        sortingOrder: ["asc", "desc", null],
        field: "publishedByUsername",
        cellRendererFramework: this.nameCellRenderer,
        cellClass(params) {
          if (toEmptyStringIfUndefined(params.value)) {
            if (
              params.value.indexOf("Automated") !== -1 ||
              params.value.indexOf("automationuser") !== -1
            ) {
              return "";
            } else {
              return "hand-cursor xmm-link-cell";
            }
          }
        },
        valueFormatter: blankValueFormatter,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      }
    ];
    return baseCols;
  }
  /* Cell renderer to display User Name  */
  nameCellRenderer(params) {
    const { value, data } = params;
    // console.log("name renderer", params.data, value);
    const userName = toEmptyStringIfUndefined(value);
    if (!userName) {
      return "-";
    } else if (
      userName === "Automated Publish" ||
      userName === "automationuser"
    ) {
      return userName;
    } else {
      const keyId = "rec-" + data.recordId;
      let renderTip = null;
      if (!data.user) {
        renderTip = value;
      } else {
        const { user } = data;
        const tooltip = (
          <div>
            {xlate("xmm.portal.common.first_name")}
            {": "}
            {user.firstName} <br />
            {xlate("xmm.portal.common.last_name")}
            {": "} {user.lastName} <br />
            {xlate("xmm.portal.common.training_role")}
            {": "} {user.trainingRole}
          </div>
        );

        renderTip = (
          <div className="xmm-override-msg">
            <Tooltip htmlId={keyId} tooltipContent={tooltip}>
              {value}
            </Tooltip>
          </div>
        );
      }
      return renderTip;
    }
  }

  /* "cellClicked" event handler  */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "publishedByUsername") {
      // callback to display tooltip with user details
      const userName = toEmptyStringIfUndefined(params.value);
      if (
        userName &&
        userName !== "Automated Publish" &&
        userName !== "automationuser" &&
        !params.data.user
      ) {
        const api = this.gridApi;
        this.getUserData(params, userName, api);
      }
    }
  }
  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
    }
  }
  // App level - This call returns User object with userName
  getUserData = (params, userName, api) => {
    const restUrl = "/ops/userapi/getUserDetails/" + userName;
    let user = null;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: {}
      },
      response => {
        if (response) {
          if (!isArrayExist(response) && !isEmpty(response)) {
            if (!isEmpty(response.data)) {
              user = response.data;
              params.data.user = user;
              // method to refresh specific rows
              if (api) {
                // iterate through every node in the grid
                const rowNodes = [];
                api.forEachNode(function(rowNode, index) {
                  if (rowNode.data.publishedByUsername === userName) {
                    // console.log("node ", rowNode.data.publishedByUsername);
                    rowNode.data.user = user;
                    rowNode.setData(rowNode.data);
                    rowNodes.push(rowNode);
                  }
                });
                // const rowNode = api.getRowNode(params.data.recordId);
                const refreshParams = {
                  force: true,
                  rowNodes
                };
                setTimeout(function() {
                  api.refreshCells(refreshParams);
                }, 100);
              }
            } else {
              const config = {
                method: "get",
                url: restUrl,
                statusCode: 200
              };
              gtag.trackUnknowError(response, config);
            }
          }
        }
      },
      error => {
        const { response } = error;
        if (response && response.data) {
          if (response.data.indexOf("User not found") !== -1) {
            // msg = xlate("xmm.portal.errors.user_not_found");
          } else {
            const msg = error["message"]
              ? error.message
              : "There was an error while getting user details.";
            toast.error(msg, {
              autoClose: 2000,
              closeOnClick: true
            });
          }
        }
      }
    );
    return user;
  };
  render() {
    return (
      <div
        id="historyGrid"
        className="ag-grid-container ag-theme-balham xmm-publish-history-grid"
      >
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressRowClickSelection={true}
          suppressMenuHide={false}
          // suppressContextMenu={true}
          rowData={this.state.rowData}
          animateRows={true}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          loadingOverlayComponent={this.state.loadingOverlayComponent}
          loadingOverlayComponentParams={
            this.state.loadingOverlayComponentParams
          }
          noRowsOverlayComponent={this.state.noRowsOverlayComponent}
          noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
          statusBar={this.state.statusBar}
          components={this.state.components}
          onCellClicked={this.onCellClickedEvent}
          onColumnResized={this.handleColumnResized}
          sideBar={this.state.sideBar}
          columnTypes={this.state.columnTypes}
          multiSortKey={this.state.multiSortKey}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          enableBrowserTooltips={true}
          enterMovesDownAfterEdit={true}
          enterMovesDown={true}
          rowHeight={50}
          onFirstDataRendered={this.onFirstDataRendered}
        />
      </div>
    );
  }
}

function publishDateFormatter(params) {
  const val = toEmptyStringIfUndefined(params.data.rawPublishDate);
  let dateStr = "";
  if (val) {
    //  Format date string as June 15, 2007 at 2:05am (Central Daylight Time)
    dateStr = formatDateTimezone(val, true, publishLocale);
    return dateStr;
  } else {
    return "-";
  }
}
// This util to support textFilter on Date-time column
function formatFullDate(cellValue) {
  const val = toEmptyStringIfUndefined(cellValue);
  let dateStr = "";
  if (val) {
    //  Format date string as June 15, 2007 at 2:05am PDT
    dateStr = formatDateTimezone(val, true, publishLocale);
    return dateStr;
  } else {
    return "-";
  }
}

/*
// This Getter returns Date format (MM/DD/YYYY) to support Date sorter
function publishDateGetter(params) {
  const val = toEmptyStringIfUndefined(params.data.rawPublishDate);
  let dateStr = "";
  if (val) {
    //  Format date string as 12/24/2019
    dateStr = formatDateTimezone(val, "MM/DD/YYYY", false);
    return dateStr;
  } else {
    return "-";
  }
}
// This util returns Date object for given full datetime string; to support date filter
function formatDateForSorting(cellValue) {
  const val = toEmptyStringIfUndefined(cellValue);
  let dateStr;
  if (val) {
    dateStr = new Date(val);
    return dateStr;
  } else {
    return "-";
  }
}
*/
