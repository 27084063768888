/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import { PackageContext } from "../package-context";
import { toEmptyStringIfUndefined } from "../../../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import {
  findRecord,
  findVariant
} from "../../../../../commonUtil/utils/object";
import VehicleGroupSelector from "../../../../reusable/VehicleGroups/VehicleGroupSelector";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { toastMessageFormatter } from "../../../../../commonUtil/utils/formatter";
// import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class Vehicles extends Component {
  static contextType = PackageContext;
  static propTypes = {
    onChangeVehicleGroup: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onBlurVehicleGroup = this.onBlurVehicleGroup.bind(this);
    this.onChangeVehicleGroup = this.onChangeVehicleGroup.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    const { makelist, bulkEditMake, dealerCode } = context;
    this.initializeLocaleValues();
    this.state = {
      metaVehicleScope: "", // All Vehicles
      metaVehicleFilterId: "",
      make: bulkEditMake,
      dealerCode,
      variant: findVariant(makelist, bulkEditMake),
      dirty: false,
      valid: false,
      errors: {
        metaVehicleFilterId: ""
      }
    };
  }

  componentDidMount() {
    if (this.state.make) {
      this.findMake();
    }
  }
  initializeLocaleValues() {
    this.vehicleGroupLabel = xlate("xmm.portal.common.vehiclegroup");
    this.bulkeditTextLabel = xlate(
      "xmm.portal.common.bulkedit_packages_lbl"
    ).replace("%1", this.vehicleGroupLabel);
    this.selectLabel = xlate("xmm.portal.common.select_label");
    this.applyEditLabel = xlate("xmm.portal.common.apply_edit");
    this.vehiclesLabel = xlate("xmm.portal.common.vehicles");
    this.bulkeditError = xlate("xmm.portal.errors.bulkedit_packages");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.fieldRequiredError = xlate("xmm.portal.errors.field_is_required");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }
  /* This method returns make obj from make selection */
  findMake() {
    const makeName = this.context.bulkEditMake;
    const rawMakes = this.context.makelist;
    const property = "make";
    const selectedMake = findRecord(rawMakes, property, makeName);
    if (selectedMake && typeof selectedMake === "object") {
      this.setState({
        make: selectedMake.make,
        dealerCode: selectedMake.dealerCode,
        variant: selectedMake.variant
      });
    }
  }

  onBlurVehicleGroup = (cxEvent, domEvent) => {
    this.validate("metaVehicleFilterId");
  };

  onChangeVehicleGroup = event => {
    const { data } = event.detail;
    const record = {
      metaVehicleScope: "1",
      metaVehicleFilterId: ""
    };
    if (record && data) {
      const { metaVehicleFilterId, metaVehicleScope } = data;
      record.metaVehicleScope = metaVehicleScope;
      if (metaVehicleScope === "1") {
        record.metaVehicleFilterId = "";
      } else {
        record.metaVehicleScope = "0";
        record.metaVehicleFilterId = metaVehicleFilterId;
      }
      this.setState(
        {
          metaVehicleScope: record.metaVehicleScope,
          metaVehicleFilterId: record.metaVehicleFilterId
        },
        () => {
          this.markDirty("metaVehicleFilterId", true);
        }
      );
    }
  };

  // validation utils
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
  }

  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    let valid = false;
    const { errors } = this.state;
    const { metaVehicleScope, metaVehicleFilterId } = this.state;
    if (!fieldName || fieldName === "metaVehicleFilterId") {
      if (
        metaVehicleScope === "" ||
        (metaVehicleScope === "0" && !metaVehicleFilterId)
      ) {
        errors["metaVehicleFilterId"] = this.fieldRequiredError;
        valid = false;
      } else {
        errors["metaVehicleFilterId"] = "";
        valid = true;
      }
    }
    this.setState({ valid, errors });
    return valid;
  }

  getEditPayload() {
    const { selectionlist } = this.context;
    const { metaVehicleFilterId } = this.state;
    const selections = [];
    const requestObj = {
      requestId: "",
      authId: ""
    };
    if (selectionlist && selectionlist.length > 0) {
      selectionlist.forEach(op => {
        if (!op.id) {
          // donot add to list
        } else {
          selections.push(op.id.toString());
        }
      });
      requestObj["packages"] = selections;
    }
    // All vehicles
    if (!metaVehicleFilterId) {
      requestObj.metaVehicleScope = 1;
      requestObj.metaVehicleFilterId = metaVehicleFilterId;
    } else {
      // Choose vehicles
      requestObj.metaVehicleScope = 0;
      requestObj.metaVehicleFilterId = parseInt(metaVehicleFilterId, 10);
    }
    requestObj.metaVehicleTab = [];
    // console.log("bulkedit postobj", requestObj);
    return requestObj;
  }

  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null || value.length === 0) {
        // In case of valid error string
      } else if (value && value.length > 1) {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }

  handleApply = e => {
    e.preventDefault();
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors, metaVehicleScope } = this.state;
    let isValid = true;
    // check if any errors exist on fields
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid || !metaVehicleScope) {
      // toast.warning(this.correctInvalidFielsError);
      this.updateStatusBox(this.correctInvalidFielsError, "warning", false);
      return;
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const payload = this.getEditPayload();
    const restEndPoint =
      "ops/proxyapi/ddsproxy/rest/proc/bulkUpdPkgOvrdWMVFltrId";
    // call rest api
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) {
          // const regex = /\n/gi;
          let msgToShow = "";
          // Error case
          if (data.error) {
            msgToShow = this.bulkeditError;
            this.updateStatusBox(msgToShow, "error", false, true);
          }
          // Overlap case - duplicate packages found
          if (data.response && data.response.statusCode !== 0) {
            msgToShow = toastMessageFormatter(data.response.statusMessage);
            this.updateStatusBox(msgToShow, "error", false, true);
          } else if (data.response && data.response.statusCode === 0) {
            // Success case
            this.updateStatusBox(this.savedMsg, "success", true);
            // callback to close slider and refresh grid rows.
            setTimeout(() => {
              this.props.closeSlider();
            }, 2000);
            const record = {
              menuTypeId: this.state.metaVehicleFilterId,
              make: this.state.make
            };
            this.context.updateGridAfterSave(record);
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    this.trackBulkEditEvent("Vehicles");
  };
  // push event to GTM
  trackBulkEditEvent = fieldName => {
    const tagObj = {
      eventResult: "Bulk Edit - " + fieldName,
      eventFeature: "Menus> Packages Page",
      eventFeatures: "packages page> BulkEdit> " + fieldName,
      trackPageUrl: "menus/packages/bulk-edit/" + fieldName.toLowerCase()
    };
    gtmEvents.gtmTrackEventWithParams("xmm.packages.bulk_edit_click", tagObj);
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    const {
      valid,
      dirty,
      errors,
      metaVehicleFilterId,
      metaVehicleScope,
      make,
      dealerCode,
      variant
    } = this.state;

    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const vehicleGroup = {
      make, // in
      variant, // in
      dealerCode, // in
      metaVehicleScope, // in
      metaVehicleFilterId: toEmptyStringIfUndefined(metaVehicleFilterId) // in
    };
    const markDisabled = !valid || !dirty;

    return (
      <div>
        <Row>
          <Col xs={7} sm={7}>
            <div className="xmm-help-text">{this.bulkeditTextLabel}</div>
          </Col>
          <Col xs={5} sm={5}>
            <div className="xmm-tab-bar no-margin">
              {statusBox}{" "}
              <Button
                htmlId="applyVehicleBtn"
                className="float-right"
                size="sm"
                disabled={markDisabled}
                onClick={this.handleApply}
                buttonStyle="primary"
              >
                {this.applyEditLabel}
              </Button>
            </div>
          </Col>
        </Row>
        <Card>
          <Col xs={2} md={2} className="full-col">
            <span className="xmm-label pull-right">{this.vehiclesLabel}</span>
          </Col>
          <Col xs={6} sm={6} className="full-col">
            <VehicleGroupSelector
              ref="intervalSelectorRef"
              data={vehicleGroup}
              error={errors.metaVehicleFilterId}
              context={this.context.appContext}
              // onBlur={this.onBlurVehicleGroup}
              onChange={this.onChangeVehicleGroup}
              autoLoad={true}
            />
          </Col>
        </Card>
      </div>
    );
  }
}

export default Vehicles;

Vehicles.propTypes = {
  closeSlider: PropTypes.func
};

/* eslint-enable no-console */
