/* eslint-disable no-console */
import React, { Component } from "react";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";
import IconAdd from "@cx/ui/Icons/IconAdd";
import IconRemove from "@cx/ui/Icons/IconRemove";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import SearchableSelect from "@cx/ui/SearchableSelect";
// import { toast } from "@cx/ui/Toast";
import { PropTypes } from "prop-types";
import IntervalSelector from "../../../../reusable/Intervals/IntervalSelector";
import VehicleGroupSelector from "../../../../reusable/VehicleGroups/VehicleGroupSelector";
// import { optionListComparator } from "../../../../../commonUtil/utils/list";
// import { getAllVehicleGroupName } from "../../../../../commonUtil/utils/menu";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../commonUtil/utils/string";
// import { hasErrorStrings } from "../../../../../commonUtil/utils/error";
// import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import {
  // initBulkEditDealerRules,
  MVC_ALL_VEHICLES,
  MVC_CHOOSE_VEHICLES
} from "../../../../../constants/ModuleConstants";
import { AppContext } from "../../../../../components/app-context";
import { xlate } from "../../../../../commonUtil/i18n/locales";

export default class SelectMenuCriteria extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.isValid = this.isValid.bind(this);
    this.onMenuTypeChange = this.onMenuTypeChange.bind(this);
    this.onIntervalBlur = this.onIntervalBlur.bind(this);
    this.onIntervalChange = this.onIntervalChange.bind(this);
    this.onVehicleGroupBlur = this.onVehicleGroupBlur.bind(this);
    this.onVehicleGroupChange = this.onVehicleGroupChange.bind(this);
    this.validate = this.validate.bind(this);

    const { menuTypes, ruleCriteria } = props;
    // const { menuTypeId, vehicleGroup, interval } = ruleCriteria;
    this.initializeLocaleValues();
    this.state = {
      menuTypes,
      ruleCriteria,
      // menuTypeId,
      // vehicleGroup,
      // interval,
      errors: { menuTypeId: "", metaVehicleFilterId: "", intervalId: "" }
    };
  }
  componentDidMount() {}
  componentWillUnmount() {}
  initializeLocaleValues() {
    this.fieldRequiredError = xlate("xmm.portal.errors.field_is_required");
  }
  onMenuTypeChange = (cxEvent, isValid, domEvent) => {
    if (domEvent && domEvent.type === "blur") {
      const { errors } = this.state;
      if (cxEvent.target.value.length === 0) {
        this.setState({ errors: { ...errors, menuTypeId: "Required Field" } });
      } else {
        this.setState({ errors: { ...errors, menuTypeId: "" } });
      }
      return;
    }
    const { value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    const { ruleCriteria } = this.state;
    if (isDifferentValue(ruleCriteria.menuTypeId, optionValue)) {
      ruleCriteria.menuTypeId = optionValue;
      this.setState({ ruleCriteria }, () => {
        this.props.onChange(cxEvent);
      });
    }
  };
  onIntervalBlur = event => {
    const { errors, ruleCriteria } = this.state;
    const { interval } = ruleCriteria;
    const { intervalId } = interval;
    let errorText = "";
    if (toEmptyStringIfUndefined(intervalId) === "") {
      errorText = this.fieldRequiredError;
    }
    if (errors.intervalId !== errorText) {
      this.setState({ errors: { ...errors, intervalId: errorText } });
    }
    this.validate();
  };
  onIntervalChange = event => {
    const { data } = event.detail;
    if (data) {
      const { intervalId, intervalName } = data;
      const { ruleCriteria } = this.state;
      const { interval } = ruleCriteria;
      interval.intervalId = intervalId;
      interval.intervalName = intervalName;
      this.setState({ ruleCriteria }, () => {
        this.props.onChange(event);
      });
    }
  };
  onVehicleGroupBlur = event => {
    // TODO
  };
  onVehicleGroupChange = event => {
    const { data } = event.detail;
    // const record = {
    //   metaVehicleScope: MVC_ALL_VEHICLES,
    //   metaVehicleFilterId: ""
    // };
    const { ruleCriteria } = this.state;
    const { vehicleGroup } = ruleCriteria;
    if (vehicleGroup && data) {
      const { metaVehicleFilterId, metaVehicleScope, name } = data;
      vehicleGroup.metaVehicleFilterName = name;
      vehicleGroup.metaVehicleScope = metaVehicleScope;
      if (metaVehicleScope === MVC_ALL_VEHICLES) {
        vehicleGroup.metaVehicleFilterId = "";
      } else {
        vehicleGroup.metaVehicleScope = MVC_CHOOSE_VEHICLES;
        vehicleGroup.metaVehicleFilterId = metaVehicleFilterId;
      }
      this.setState({ ruleCriteria }, () => {
        this.props.onChange(event);
      });
    }
  };
  validate = () => {
    const { errors } = this.state;
    const { menuTypeId, intervalId, metaVehicleFilterId } = errors;
    return !menuTypeId && !intervalId && !metaVehicleFilterId;
  };
  isValid = () => {
    const { menuTypeId, interval } = this.state.ruleCriteria;
    return (
      toEmptyStringIfUndefined(menuTypeId) !== "" &&
      (toEmptyStringIfUndefined(interval.intervalId) !== "" ||
        toEmptyStringIfUndefined(interval.intervalName) !== "")
    );
  };
  addCriteria = event => {
    const { index, parentHandle } = this.props;
    parentHandle.addCriteria(index);
  };
  removeCriteria = event => {
    const { index, parentHandle } = this.props;
    parentHandle.removeCriteria(index);
  };

  render() {
    const { index, totalCriteriaFields } = this.props;
    const { errors, menuTypes, ruleCriteria } = this.state;
    const { menuTypeId, vehicleGroup, interval } = ruleCriteria;

    return (
      <React.Fragment>
        <Row>
          <Col xs={3} md={3} className="full-padding-right-col">
            <SearchableSelect
              displayLabel={false}
              displayPlaceholder={true}
              placeholder="Select"
              className="xmm-scrollable-select"
              htmlId="menuTypeId"
              label="Menu Type"
              name="menuTypeId"
              enableMultiSelect={false}
              maxHeight={150}
              // onBlur={this.onVehicleGroupBlur}
              onChange={this.onMenuTypeChange}
              options={menuTypes}
              value={menuTypeId}
              layout="horizontal"
              error={errors.menuTypeId}
            />
          </Col>
          <Col xs={4} md={4} className="full-padding-right-col">
            <VehicleGroupSelector
              data={vehicleGroup}
              error={errors.metaVehicleFilterId}
              context={this.context}
              hideAllVehicles={false}
              autoLoad={true}
              onBlur={this.onVehicleGroupBlur}
              onChange={this.onVehicleGroupChange}
            />
          </Col>
          <Col xs={4} md={4} className="full-padding-right-col">
            <IntervalSelector
              ref="intervalSelectorRef"
              context={this.context}
              data={interval}
              includeWildCardIntervals={true}
              error={errors.intervalId}
              onBlur={this.onIntervalBlur}
              onChange={this.onIntervalChange}
            />
          </Col>
          <Col xs={1} md={1} className="full-col float-right">
            <ButtonToolbar>
              <Button
                htmlId={`IconAdd${index}`}
                className="btn--icon float-right"
                aria-label="Add"
                buttonStyle="link"
                size="sm"
                onClick={this.addCriteria}
              >
                <IconAdd />
              </Button>
              <Button
                htmlId={`IconRemove${index}`}
                className="btn--icon float-right"
                aria-label="Remove"
                buttonStyle="link"
                size="sm"
                hidden={totalCriteriaFields === 1}
                onClick={this.removeCriteria}
              >
                <IconRemove />
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

SelectMenuCriteria.propTypes = {
  ruleCriteria: PropTypes.object,
  menuTypes: PropTypes.array,
  parentHandle: PropTypes.object,
  index: PropTypes.number,
  totalCriteriaFields: PropTypes.number,
  onChange: PropTypes.func
};
