/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import { toNumber } from "../../../../../commonUtil/utils/value";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import { AppContext } from "../../../../app-context";

class CatalogRow extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onChangePriceMethod = this.onChangePriceMethod.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);

    const { catalog } = props;
    this.state = {
      pricingMethod: catalog.pricingMethod,
      showPricingPackage: catalog.showPricingPackage,
      showPricingAlacarte: catalog.showPricingAlacarte
    };
  }
  componentDidMount() {}

  /* when PricingMethod" is set "0" value; other configure flags should be disabled and unchecked */
  onChangePriceMethod = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;

    if (value) {
      let payload = {};
      const newValue = toNumber(value);
      // console.log(name, typeof value, typeof newValue);
      const { catalog, onUpdate } = this.props;
      catalog[name] = newValue;
      payload[name] = newValue;
      // For No Pricing case, set catalog level pricing fields to {0}
      if (newValue === 0) {
        catalog["pricingMethod"] = 0;
        catalog["showPricingBusiness"] = 0;
        catalog["showPricingPackage"] = 0;
        catalog["showPricingAlacarte"] = 0;
        catalog["showPricingMsa"] = 0;
        catalog["showPricingConsumer"] = 0;
        catalog["showPricingConsumerMenu"] = 0;
        catalog["showPricingConsumerAlacarte"] = 0;
        catalog["showPricing"] = 0;
        payload = {
          pricingMethod: 0,
          showPricingBusiness: 0,
          showPricingPackage: 0,
          showPricingAlacarte: 0,
          showPricingMsa: 0,
          showPricingConsumer: 0,
          showPricingConsumerMenu: 0,
          showPricingConsumerAlacarte: 0,
          showPricing: 0
        };
        this.setState({
          pricingMethod: 0,
          showPricingPackage: 0,
          showPricingAlacarte: 0
        });
      } else {
        payload[name] = newValue;
        this.setState({
          [name]: newValue
        });
      }
      onUpdate(catalog, name, payload);
    }
  };
  /* checkbox change handler */
  onChangeCheckbox = event => {
    const target = event.target;
    const { name } = target;
    const newValue = target.checked ? 1 : 0;
    this.setState({ [name]: newValue });
    const { catalog, onUpdate } = this.props;
    catalog[name] = newValue;
    const payload = {
      [name]: newValue
    };
    onUpdate(catalog, name, payload);
  };

  render() {
    const { localeStrings } = this.context;
    const { catalog } = this.props;
    const markDisabled = catalog.pricingMethod === 0 ? true : false;
    const isAny = catalog.make === "ANY" ? true : false;
    const clsAny = isAny ? "xmm-form-content" : "hidden";
    const clsRow = catalog.make !== "ANY" ? "xmm-form-content" : "hide";
    const anyMakeRow = (
      <div className="xmm-colspan-label">
        {localeStrings["xmm.portal.settings.not_applicable_lbl"]}
        <Tooltip
          htmlId="tipRight"
          position="right"
          tooltipContent={
            localeStrings["xmm.portal.settings.not_applicable_tip"]
          }
        >
          <IconInfoOutline htmlId="iconInfo" />
        </Tooltip>
      </div>
    );
    const pricingMethodOptions = [
      {
        value: "2",
        label: localeStrings["xmm.portal.settings.total_flat_lbl"]
      },
      {
        value: "0",
        label: localeStrings["xmm.portal.settings.no_pricing_lbl"]
      }
    ];
    // Do not display Calculated Workflow for Makes with IsExternalPricing = 0
    if (catalog.isExternalPricing === 1) {
      pricingMethodOptions.splice(0, 0, {
        value: "1",
        label: localeStrings["xmm.portal.settings.calculated_parts_lbl"]
      });
    }
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {catalog.make}
        </Col>
        <Col md={10} sm={10} className={clsRow}>
          <div className="xmm-input-col" id={"pricingMethod-" + catalog.make}>
            <SelectInput
              htmlId="pricingMethod"
              label="Price by"
              displayLabel={false}
              name="pricingMethod"
              onChange={this.onChangePriceMethod}
              placeholder={localeStrings["xmm.portal.common.select_label"]}
              displayPlaceholder={false}
              options={pricingMethodOptions}
              value={this.state.pricingMethod}
            />
          </div>
          <div className="xmm-fixed-col">
            <div
              className="xmm-checkbox-container"
              id={"showPricingPackage-" + catalog.make}
            >
              <input
                className="form-checkbox"
                name="showPricingPackage"
                id="showPricingPackage"
                type="checkbox"
                checked={this.state.showPricingPackage || 0}
                disabled={markDisabled}
                onChange={this.onChangeCheckbox}
              />
              <span className="xmm-checkmark" />
              <span className="label-checkbox" />
            </div>
          </div>
          <div className="xmm-fixed-col">
            <div
              className="xmm-checkbox-container"
              id={"showPricingAlacarte-" + catalog.make}
            >
              <input
                className="form-checkbox"
                name="showPricingAlacarte"
                id="showPricingAlacarte"
                type="checkbox"
                checked={this.state.showPricingAlacarte || 0}
                disabled={markDisabled}
                onChange={this.onChangeCheckbox}
              />
              <span className="xmm-checkmark" />
              <span className="label-checkbox" />
            </div>
          </div>
        </Col>
        <Col md={10} sm={10} className={clsAny}>
          {anyMakeRow}
        </Col>
      </Row>
    );
  }
}

export default CatalogRow;
CatalogRow.propTypes = {
  catalog: PropTypes.object,
  onUpdate: PropTypes.func
};
