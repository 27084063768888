/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../../components/app-context";
import CustomLoadingOverlay from "../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import {
  toEmptyStringIfUndefined,
  isEmptyString
} from "../../../../../commonUtil/utils/string";
import {
  isArrayExist,
  doesEmpty
} from "../../../../../commonUtil/utils/object";
import { formatDateTimezone } from "../../../../../commonUtil/utils/date";
import { blankValueFormatter } from "../../../../../commonUtil/utils/formatter";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import TextEditor from "../../../../../commonUtil/editors/TextEditor";
import { applyCustomKeyNavigation } from "../../../../../commonUtil/utils/keyNavigation";
import { loadAgGridLocale } from "../../../../../i18n/LocaleSender";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class ManageDealerOpcodes extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);

    this.initializeLocaleValues();

    const localeStrings = context.localeStrings;
    const gridOptions = {
      // other state props
      allMakesValidationState: props.allMakesValidationState, // get status from cache
      supportedMakes: [],
      // ag-grid props
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        minWidth: 120,
        autoHeight: true,
        filter: true,
        rowGroup: false,
        suppressKeyboardEvent: applyCustomKeyNavigation
      },
      multiSortKey: "ctrl",
      rowData: [],
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        textEditor: TextEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      columnTypes: {
        nonEditableColumn: { editable: false }
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: false
    };
    this.state = gridOptions;
  }
  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };

  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // console.log("Manage Grid makes", this.props.allMakesValidationState);
    // Use Props instead of call same rest API
    this.getAllMakesValidationStatus();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  getAllMakesValidationStatus() {
    const { allMakesValidationState } = this.props;
    if (allMakesValidationState.length > 0) {
      this.gridApi && this.gridApi.showLoadingOverlay();
      const final = this.mergeDealerCatalogs(allMakesValidationState, this);
      if (isArrayExist(final) && final.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      this.sizeToFit();
      // console.log("formatted catalogs", final);
      setTimeout(() => {
        this.setState({
          supportedMakes: final
        });
      }, 200);
    }
  }
  /* This returns formatted status text based on status object */
  getValidationStatus(statusObj) {
    const { locale } = this.context;
    let bannerText = "";
    let lastValidationDate = "";
    if (statusObj.hasOwnProperty("validationInProgress")) {
      // When "inprogress" status;
      if (statusObj.validationInProgress === 1) {
        bannerText = "Validation In Progress";
      } else if (
        statusObj.validationInProgress === 0 &&
        !isEmptyString(statusObj.lastValidation)
      ) {
        lastValidationDate = formatDateTimezone(
          statusObj.lastValidation,
          false,
          locale
        );
        bannerText = xlate("xmm.portal.msg.opcodes_last_validated_on").replace(
          "%1",
          lastValidationDate
        );
      } else if (
        statusObj.validationInProgress === 0 &&
        isEmptyString(statusObj.lastValidation)
      ) {
        bannerText = xlate("xmm.portal.msg.opcodes_never_validated");
      }
    }
    return bannerText;
  }
  // Merge All opcode validation status with supported catalogs(context)
  mergeDealerCatalogs(datalist, me) {
    const final = [];
    const { dealerCatalogs } = this.context;
    if (dealerCatalogs.length > 0) {
      dealerCatalogs.forEach(function(makeobj, idx) {
        const cloneObj = Object.assign({}, makeobj);
        const { priorityOpcodeStrings } = makeobj;
        datalist.forEach(function(record, index) {
          if (record.make === makeobj.make) {
            cloneObj.priorityOpcodeStrings = doesEmpty(priorityOpcodeStrings)
              ? ""
              : priorityOpcodeStrings;
            cloneObj.validationStatus = me.getValidationStatus(record);
          }
        });
        final.push(cloneObj);
      });
    }
    return final;
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.make;
  }

  /* "cellClicked" event handler  */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "make") {
      // callback logic if needed
      console.log("row data", params.data);
    }
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    // console.log("onCellValueChanged", params.oldValue, params.newValue, params);
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // console.log("onCellValueChanged", params.colDef.field, params.data);
      this.onSaveCellEdit(params);
    }
  }
  onSaveCellEdit(params) {
    const field = params.colDef.field;
    if (field && field === "priorityOpcodeStrings") {
      this.savePayload(params, params.value);
    }
  }
  /* celledit handler to save edits */
  savePayload(gridParams, value) {
    const { updateStatusBox } = this.props;
    updateStatusBox(this.savingMsg, "pending", false);
    const record = gridParams.data;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const payload = this.getCellEditPayload(record, value);
    const restEndPoint = `ops/dealerapi/saveDealerCatalogSettings/${
      record.dealerCode
    }/${record.make}`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) {
          this.context.updateDealerCatalogs(data);
          if (this.gridApi && data) {
            const recData = Object.assign(record, data);
            const rowNode = this.gridApi.getRowNode(record.make);
            rowNode.setData(recData);
            const gridParams = {
              force: true,
              rowNodes: [rowNode]
            };
            this.callRefreshAfterMillis(gridParams, this.gridApi);
            updateStatusBox(this.savedMsg, "success", true);
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        updateStatusBox(msg, "error", false, true);
      }
    );
  }

  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function() {
      gridApi.refreshCells(params);
    }, 200);
  }

  /* This method returns post request payload for single celleditor changes to save
     Proc call accepts same order.
  */
  getCellEditPayload(record, value) {
    const payload = {
      priorityOpcodeStrings: value
    };

    return payload;
  }

  /* Deprecated - update AppContext {makelist, dealerCatalogs} when 'priorityOpcodeStrings' updated
  updateContextMakes(record) {
    // This filter will update context object directly
    const { makelist } = this.context;
    const found = makelist.filter(function(obj) {
      return obj.make === record.make;
    });
    if (found.length === 1) {
      found[0].priorityOpcodeStrings = record.priorityOpcodeStrings;
    }
  }
  */
  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.grid.make"],
        headerClass: "ag-text-header",
        field: "make",
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        width: 120,
        minWidth: 120,
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName:
          localeStrings["xmm.portal.opcode_validation.grid.prioritized_opcode"],
        headerClass: "ag-text-header",
        field: "priorityOpcodeStrings",
        editable: true,
        cellEditor: "textEditor",
        cellEditorParams: {
          maxLength: 32,
          cellHeight: 25
        },
        cellClass: "editable-cell xmm-blue-cell",
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
        filterParams: {
          buttons: ["clear"]
        },
        sortingOrder: ["asc", "desc"],
        width: 200,
        minWidth: 200
      },
      // derived column
      {
        headerName: localeStrings["xmm.portal.grid.status"],
        headerClass: "ag-text-header",
        field: "validationStatus",
        editable: false,
        sortingOrder: ["asc", "desc"],
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        valueFormatter: blankValueFormatter,
        width: 200,
        minWidth: 180,
        maxWidth: 220
      }
    ];
    return baseCols;
  }

  render() {
    const gridWidget = (
      <div
        id="manageDealerOpcodesGrid"
        className="xmm-publish-grid-container ag-theme-balham ag-grid-overlay-scroll"
      >
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressMenuHide={false}
          suppressContextMenu={true}
          rowData={this.state.supportedMakes}
          rowSelection={"multiple"}
          animateRows={true}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          loadingOverlayComponent={this.state.loadingOverlayComponent}
          loadingOverlayComponentParams={
            this.state.loadingOverlayComponentParams
          }
          noRowsOverlayComponent={this.state.noRowsOverlayComponent}
          noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
          statusBar={this.state.statusBar}
          components={this.state.components}
          singleClickEdit={true}
          stopEditingWhenGridLosesFocus={true}
          onColumnResized={this.handleColumnResized}
          onCellValueChanged={this.onCellValueChanged}
          onCellClicked={this.onCellClickedEvent}
          getRowNodeId={this.getRowNodeId}
          sideBar={this.state.sideBar}
          columnTypes={this.state.columnTypes}
          multiSortKey={this.state.multiSortKey}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          enableBrowserTooltips={true}
          // enterMovesDownAfterEdit={true}
          enterMovesDown={true}
          rowHeight={50}
        />
      </div>
    );
    const footer = (
      <div className="xmm-modal-footer">
        <h5>{xlate("xmm.portal.opcode_validation.prioritize_opcode_lbl")} </h5>
        <div className="xmm-modal-help-text">
          {xlate("xmm.portal.opcode_validation.prioritize_opcode_helpTxt")}
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div>
          {gridWidget}
          {footer}
        </div>
      </React.Fragment>
    );
  }
}
export default ManageDealerOpcodes;

ManageDealerOpcodes.propTypes = {
  allMakesValidationState: PropTypes.array,
  updateStatusBox: PropTypes.func
};
/* eslint-enable no-console */
