/**
 *
 * @param {node} parent
 * @param {node} node
 */
export const navigateToMenuLink = (parent, node) => {
  window.dispatchEvent(
    new CustomEvent("navMenuLinkChanged", {
      detail: { parent, node },
      bubbles: true,
      cancelable: true
    })
  );
};
/**
 *
 * @param {string} parentId
 * @param {node} childNode
 * @param {number} panelHeight
 * @returns
 */
export const shouldShowContentPanelUp = (parentId, childNode, panelHeight) => {
  const gridBoundingBox = document
    .querySelector(parentId)
    .getBoundingClientRect();
  const childBoundingBox = childNode.getBoundingClientRect();
  return childBoundingBox.bottom + panelHeight > gridBoundingBox.bottom;
};
/**
 *
 * @param {string} make
 * @param {string} template
 * @returns
 */
export function getAllVehicleGroupName(make, template) {
  return template.replace("%1", make);
  // return localeStrings["xmm.portal.common.all_make_vehicles"].replace("%1", make);
}
