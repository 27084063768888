import React from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import { toNumber } from "../../../../../commonUtil/utils/value";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import NoPricingRow from "./NoPricingRow";
import { AppContext } from "../../../../app-context";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class PartsLaborRow extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangePricingMethod = this.onChangePricingMethod.bind(this);
    this.onChangeLaborTime = this.onChangeLaborTime.bind(this);
    this.initializeLocaleValues();
    const { catalog } = props;
    this.state = {
      newVehiclePricingMethod: catalog.newVehiclePricingMethod,
      laborTimePrecision: catalog.laborTimePrecision,
      roundUpFluidQuantities: catalog.roundUpFluidQuantities
    };
  }
  componentDidMount() {}
  initializeLocaleValues() {
    this.hidePricingLabel = xlate("xmm.portal.common.hide_pricing");
    this.useDefaultPricingLabel = xlate(
      "xmm.portal.common.use_default_pricing"
    );
    this.laborTimeScaleLabel = xlate("xmm.portal.settings.labor_scale_lbl");
    this.pricingForVehiclesLabel = xlate(
      "xmm.portal.common.pricing_for_vehicles"
    );
    this.notApplicableLabel = xlate("xmm.portal.settings.not_applicable_lbl");
    this.notApplicableTipLabel = xlate(
      "xmm.portal.settings.not_applicable_tip"
    );
    this.hunredthsLabel = xlate("xmm.portal.common.hundredths");
    this.tenthsLabel = xlate("xmm.portal.common.tenths");
  }
  /* checkbox change handler */
  onChangeCheckbox = event => {
    const target = event.target;
    const { name } = target;
    const newValue = target.checked ? 1 : 0;
    this.setState({ [name]: newValue });
    const { catalog, onUpdate } = this.props;
    catalog[name] = newValue;
    const payload = {
      [name]: newValue
    };
    onUpdate(catalog, name, payload);
  };

  onChangeLaborTime = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (value) {
      this.setState({ [name]: value });
      const { catalog, onUpdate } = this.props;
      catalog[name] = value;
      const payload = {
        [name]: value
      };
      onUpdate(catalog, name, payload);
    }
  };
  /* send integer value for newVehiclePricingMethod field changes */
  onChangePricingMethod = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    if (value) {
      this.setState({ [name]: value });
      const newValue = toNumber(value);
      const { catalog, onUpdate } = this.props;
      catalog[name] = newValue;
      const payload = {
        [name]: newValue
      };
      onUpdate(catalog, name, payload);
    }
  };

  render() {
    const { localeStrings } = this.context;
    const { catalog } = this.props;
    const isAny = catalog.make === "ANY" ? true : false;
    const clsAny = isAny ? "xmm-form-content" : "hidden";
    const clsRow = catalog.make !== "ANY" ? "xmm-form-content" : "hide";
    const anyMakeRow = (
      <div className="xmm-colspan-label">
        {this.notApplicableLabel}
        <Tooltip
          htmlId="tipRight"
          position="right"
          tooltipContent={this.notApplicableTipLabel}
        >
          <IconInfoOutline htmlId="iconInfo" />
        </Tooltip>
      </div>
    );
    const flatPricingtip =
      localeStrings["xmm.portal.settings.flat_pricing_row_tip"];
    const flatPricingRow = (
      <div className="xmm-colspan-label">
        {this.notApplicableLabel}
        <Tooltip
          htmlId="tipFlat"
          position="right"
          tooltipContent={flatPricingtip}
        >
          <IconInfoOutline htmlId="iconInfo" />
        </Tooltip>
      </div>
    );
    const defaultRow = (
      <>
        <div className="xmm-input-col">
          <SelectInput
            htmlId="newVehiclePricingMethod"
            label={this.pricingForVehiclesLabel}
            displayLabel={false}
            name="newVehiclePricingMethod"
            onChange={this.onChangePricingMethod}
            placeholder="Select"
            displayPlaceholder={false}
            options={[
              {
                value: "1",
                label: this.useDefaultPricingLabel
              },
              { value: "0", label: this.hidePricingLabel }
            ]}
            value={this.state.newVehiclePricingMethod}
          />
        </div>
        <div className="xmm-input-col">
          <SelectInput
            htmlId="laborTimePrecision"
            label={this.laborTimeScaleLabel}
            displayLabel={false}
            name="laborTimePrecision"
            onChange={this.onChangeLaborTime}
            placeholder="Select"
            displayPlaceholder={false}
            options={[
              {
                value: "hundredths",
                label: this.hunredthsLabel
              },
              { value: "tenths", label: this.tenthsLabel }
            ]}
            value={this.state.laborTimePrecision}
          />
        </div>
        <div className="xmm-fixed-col xmm-inline-tip">
          <div className="xmm-checkbox-container">
            <input
              className="form-checkbox"
              name="roundUpFluidQuantities"
              id="roundUpFluidQuantities"
              type="checkbox"
              checked={this.state.roundUpFluidQuantities || 0}
              onChange={this.onChangeCheckbox}
            />
            <span className="xmm-checkmark" />
            <span className="label-checkbox" />
          </div>
        </div>
      </>
    );
    const noPricingRow = <NoPricingRow />;
    const rowHtml =
      catalog.pricingMethod === 0
        ? noPricingRow
        : catalog.pricingMethod === 2
        ? flatPricingRow
        : defaultRow;
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {catalog.make}
        </Col>
        <Col md={10} sm={10} className={clsRow}>
          {rowHtml}
        </Col>
        <Col md={10} sm={10} className={clsAny}>
          {anyMakeRow}
        </Col>
      </Row>
    );
  }
}

export default PartsLaborRow;
PartsLaborRow.propTypes = {
  catalog: PropTypes.object,
  onUpdate: PropTypes.func
};
